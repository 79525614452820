import React from 'react';
import { connect } from 'react-redux';
import { Grid, CardMedia } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { RewardDetailImage } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import {
  AccentText,
  BoldSpan,
  Card,
  DefaultText,
  DefaultTitle,
  IconButton,
  InfoText,
  Linkify,
  MainLayoutComponent,
  Quantity,
  RichText,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import { useIntl } from 'react-intl';
import _ from 'lodash';

const DEFAULT_QUANTITY = 1;

const styles = {
  panel: {
    backgroundColor: 'initial',
    borderRadius: 'initial',
    boxShadow: 'none',
  },
  panelSummary: {
    margin: 'none',
    padding: 'initial',
  },
  panelSummaryIcon: {
    color: '#00E58D',
  },
  panelDetails: {
    padding: 'initial',
  },
};

const ChallengeRewardDetail = ({ reward, ...props }) => {
  const intl = useIntl();
  const { classes } = props;
  const { account } = props.accountDetail;
  const categoryIcon = _.get(reward, 'category.icon.path');
  const image = reward
    ? reward.image
      ? reward.image.path
      : reward.customImage
    : null;
  return (
    <react.fragment>
      {reward && (
        <grid container="" spacing="{2}">
          <grid item="" xs="">
            <grid container="" spacing="{2}">
              <grid item="" xs="">
                <grid container="" spacing="{2}">
                  <grid item="" xs="{12}">
                    <defaulttext lowercase="">
                      <boldspan>{reward.name}</boldspan>
                    </defaulttext>
                  </grid>
                  <grid item="">
                    <defaulttext>
                      <boldspan>
                        <fontawesomeicon icon="{faFolderOpen}"></fontawesomeicon>{' '}
                        {_.get(reward, 'category.name')}
                      </boldspan>
                    </defaulttext>
                  </grid>
                  <grid item="">
                    <defaulttext>
                      <boldspan>
                        {intl
                          .formatMessage({ id: 'reward.detail.value_text' })
                          .format(reward.value)}
                      </boldspan>
                    </defaulttext>
                  </grid>
                </grid>
              </grid>
              {((account.role.code === 'C' && reward.type.code === 'P') ||
                (account.role.code === 'M' && reward.type.code === 'T')) && (
                <grid item="">
                  <grid container="" spacing="{1}" direction="column" alignItems="center">
                    <grid item="">
                      <defaulttext>
                        {intl.formatMessage({
                          id: 'reward.order_item_list.quantity_label',
                        })}
                      </defaulttext>
                    </grid>
                    <grid item="">
                      <quantity initial="{DEFAULT_QUANTITY}" minimum="{1}" onChange="{this.handleQuantityChange.bind(this)}"></quantity>
                    </grid>
                  </grid>
                </grid>
              )}
              <grid item="" xs="{12}">
                <linkify>
                  <richtext initial="{JSON.parse(reward.description)}" readOnly="{true}" onChange="{()" ==""> {}}
                  />
                </richtext></linkify>
              </grid>
              <grid item="" xs="{12}">
                {(reward.deliveryPlace || reward.deliveryMode) && (
                  <defaulttext>
                    <boldspan>
                      {intl.formatMessage({ id: 'reward.form.delivery_area' })}
                    </boldspan>
                  </defaulttext>
                )}
                <defaulttext lowercase="">
                  <boldspan>
                    {reward.deliveryPlace &&
                      intl
                        .formatMessage({
                          id: 'reward.detail.delivery_place_text',
                        })
                        .format('')}
                  </boldspan>{' '}
                  {reward.deliveryPlace}
                </defaulttext>
                <defaulttext lowercase="">
                  <boldspan>
                    {reward.deliveryMode &&
                      intl
                        .formatMessage({
                          id: 'reward.detail.delivery_mode_text',
                        })
                        .format('')}
                  </boldspan>{' '}
                  {reward.deliveryMode}
                </defaulttext>
                {reward.deliveryTime && reward.deliveryTime !== '' && (
                  <defaulttext lowercase="">
                    <boldspan>
                      {intl
                        .formatMessage({
                          id: 'reward.detail.delivery_time_text',
                        })
                        .format('')}
                    </boldspan>{' '}
                    {reward.deliveryTime}
                  </defaulttext>
                )}
              </grid>
            </grid>
          </grid>
          <grid item="" xs="{12}" md="auto">
            <rewarddetailimage image="{image}"></rewarddetailimage>
          </grid>
        </grid>
      )}
    </react.fragment>
  );
};

const mapStateToProps = ({ accountDetail, rewardDetail }) => ({
  accountDetail,
  rewardDetail,
});

export default connect(mapStateToProps)(
  withStyles(styles)(ChallengeRewardDetail),
);
