import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { I18nWrapper } from '../../../../components';
import { AnonymousLayout, AnonymousLayoutMobile } from '../AnonymousLayout';

const AnonymousRoutes = ({ component: Component, ...props }) => {
  const { path } = props;
  const { account } = props.accountDetail;
  const context = useContext(I18nWrapper.Context);

  if (account) {
    if (!account.useTermsAccepted || !account.privacyPolicyAccepted) {
      return <redirect to="/accept-terms"></redirect>;
    }

    if (account.forceResetPassword) {
      return <redirect to="/force-reset-password"></redirect>;
    }

    return <redirect to="/"></redirect>;
  }

  if (localStorage.getItem('locale')) {
    context.selectLanguage(localStorage.getItem('locale'));
  }

  const { detect } = require('detect-browser');
  const browser = detect();
  const isMobileApp =
    browser.name === 'ios-webview' || browser.name === 'chromium-webview';

  if (!isMobileApp) {
    return <anonymouslayout exact="" path="{path}" component="{Component}"></anonymouslayout>;
  } else {
    return <anonymouslayoutmobile exact="" path="{path}" component="{Component}"></anonymouslayoutmobile>;
  }
};

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(AnonymousRoutes);
