import React from 'react';
import { CardMedia, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
  RefreshButton,
  Card,
  DefaultTitle,
  DefaultText,
  Dialog,
} from '../../../../../../../../components';

const UpdateAppModal = () => {
  const intl = useIntl();
  const image = require('../../../../../../../../assets/img/system/layout/update_prompt_image.png');
  return (
    <dialog open="{true}" onClose="{()" ==""> {}}>
      <grid container="" spacing="{3}" style="{{" textAlign:="" 'center'="" }}="">
        <grid item="" xs="{12}">
          <grid 10="" container="" justify="center" style="{{" marginBottom:="" }}="">
            <cardmedia image="{image}" style="{{" width:="" 200,="" height:="" 160,="" backgroundPosition:="" '15px="" bottom',="" }}=""></cardmedia>
          </grid>
          <div>
            <defaulttitle style="{{" fontSize:="" 22,="" textTransform:="" 'none',="" fontFamily:="" 'Avenir',="" fontWeight:="" 'bold',="" lineHeight:="" 1,="" }}="">
              {intl.formatMessage({ id: 'common.update_app_prompt_title' })}
            </defaulttitle>
          </div>
        </grid>
        <grid item="" xs="{12}">
          <defaulttext lowercase="" style="{{" lineHeight:="" 1,="" }}="">
            {intl.formatMessage({ id: 'common.update_app_prompt_message' })}
          </defaulttext>
        </grid>
        <grid item="" xs="{12}">
          <refreshbutton></refreshbutton>
        </grid>
      </grid>
    </dialog>
  );
};

export default UpdateAppModal;
