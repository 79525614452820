import React from 'react';
import { IconButton, Grid } from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { Chip } from '..';

const Quantity = ({ initial, minimum, onChange, ...props }) => {
  const [quantity, setQuantity] = React.useState(initial);

  function handleAddClick() {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    onChange(newQuantity);
  }

  function handleRemoveClick() {
    if (quantity > minimum) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      onChange(newQuantity);
    }
  }

  return (
    <div>
      <grid container="" spacing="{1}" alignItems="center">
        {onChange && (
          <grid item="">
            <iconbutton size="small" onClick="{handleRemoveClick}">
              <removeicon fontSize="small"></removeicon>
            </iconbutton>
          </grid>
        )}
        <grid item="" style="{{" width:="" 60,="" textAlign:="" 'center'="" }}="">
          <chip label="{quantity}"></chip>
        </grid>
        {onChange && (
          <grid item="">
            <iconbutton size="small" onClick="{handleAddClick}">
              <addicon fontSize="small"></addicon>
            </iconbutton>
          </grid>
        )}
      </grid>
    </div>
  );
};

export default Quantity;
