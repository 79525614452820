/** @typedef {import('@spider:src/enums/OrderStatuses').OrderStatusesType} OrderStatus*/
/** @typedef {'KADEOS' | 'KADEOS-UNIVERSAL' | 'KADEOS-INFINITE' | 'KADEOS-ONLINE'} ProductType */
/** @typedef {{city: string, zipcode: string, street: string,country: string, name: string }} OrderAddress */
/** @typedef {{
 *   uuid: string,
 *   date: number,
 *   product: ProductType,
 *   ref: string,
 *   orderNumber: number,
 *   total: number,
 *   points: number,
 *   status: OrderStatus,
 *   address: OrderAddress
 * }} OrderItem
 */

/** @type {OrderItem[]} */
export const ordersList = [
  {
    uuid: '550e8400-e29b-41d4-a716-446655440000',
    date: 1677609600000,
    product: 'KADEOS',
    ref: '20230301_123456_CKU_PP',
    orderNumber: 87963,
    total: 765,
    points: 700,
    status: 'TREATED',
    address: {
      name: 'CRC/Commandes -Immeuble Columbus',
      street: '166-180 bd Gabriel Peri',
      zipcode: '92245',
      city: 'Malakoff',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440001',
    date: 1680288000000,
    product: 'KADEOS-UNIVERSAL',
    ref: '20230401_234567_CKU_PP',
    orderNumber: 87964,
    total: 1200,
    points: 1000,
    status: 'DONE',
    address: {
      name: "Centre d'Affaires Paris Nord",
      street: '183 Avenue Descartes',
      zipcode: '93150',
      city: 'Le Blanc-Mesnil',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440002',
    date: 1682880000000,
    product: 'KADEOS-INFINITE',
    ref: '20230501_345678_CKU_PP',
    orderNumber: 87965,
    total: 500,
    points: 450,
    status: 'CANCELLED',
    address: {
      name: 'Tour Eiffel Business Center',
      street: '5 Avenue Anatole France',
      zipcode: '75007',
      city: 'Paris',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440003',
    date: 1685558400000, // June 1, 2023
    product: 'KADEOS-ONLINE',
    ref: '20230601_456789_CKU_PP',
    orderNumber: 87966,
    total: 890,
    points: 800,
    status: 'SAVED',
    address: {
      name: 'Tour Eiffel Business Center',
      street: '5 Avenue Anatole France',
      zipcode: '75007',
      city: 'Paris',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440004',
    date: 1688150400000, // July 1, 2023
    product: 'KADEOS',
    ref: '20230701_567890_CKU_PP',
    orderNumber: 87967,
    total: 1500,
    points: 1300,
    status: 'TREATED',
    address: {
      name: 'CRC/Commandes -Immeuble Columbus',
      street: '166-180 bd Gabriel Peri',
      zipcode: '92245',
      city: 'Malakoff',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440005',
    date: 1690828800000, // August 1, 2023
    product: 'KADEOS-UNIVERSAL',
    ref: '20230801_678901_CKU_PP',
    orderNumber: 87968,
    total: 2000,
    points: 1800,
    status: 'DONE',
    address: {
      name: "Centre d'Affaires Paris Nord",
      street: '183 Avenue Descartes',
      zipcode: '93150',
      city: 'Le Blanc-Mesnil',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440006',
    date: 1693507200000, // September 1, 2023
    product: 'KADEOS-INFINITE',
    ref: '20230901_789012_CKU_PP',
    orderNumber: 87969,
    total: 1750,
    points: 1600,
    status: 'TREATED',
    address: {
      name: 'Tour Eiffel Business Center',
      street: '5 Avenue Anatole France',
      zipcode: '75007',
      city: 'Paris',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440007',
    date: 1696099200000, // October 1, 2023
    product: 'KADEOS-ONLINE',
    ref: '20231001_890123_CKU_PP',
    orderNumber: 87970,
    total: 950,
    points: 900,
    status: 'SAVED',
    address: {
      name: 'CRC/Commandes -Immeuble Columbus',
      street: '166-180 bd Gabriel Peri',
      zipcode: '92245',
      city: 'Malakoff',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440008',
    date: 1698777600000, // November 1, 2023
    product: 'KADEOS',
    ref: '20231101_901234_CKU_PP',
    orderNumber: 87971,
    total: 1100,
    points: 1000,
    status: 'DONE',
    address: {
      name: "Centre d'Affaires Paris Nord",
      street: '183 Avenue Descartes',
      zipcode: '93150',
      city: 'Le Blanc-Mesnil',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440009',
    date: 1701369600000, // December 1, 2023
    product: 'KADEOS-UNIVERSAL',
    ref: '20231201_012345_CKU_PP',
    orderNumber: 87972,
    total: 800,
    points: 750,
    status: 'CANCELLED',
    address: {
      name: 'Tour Eiffel Business Center',
      street: '5 Avenue Anatole France',
      zipcode: '75007',
      city: 'Paris',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440010',
    date: 1704048000000, // January 1, 2024
    product: 'KADEOS-INFINITE',
    ref: '20240101_123456_CKU_PP',
    orderNumber: 87973,
    total: 1300,
    points: 1200,
    status: 'TREATED',
    address: {
      name: 'Tour Eiffel Business Center',
      street: '5 Avenue Anatole France',
      zipcode: '75007',
      city: 'Paris',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440011',
    date: 1706726400000, // February 1, 2024
    product: 'KADEOS-ONLINE',
    ref: '20240201_234567_CKU_PP',
    orderNumber: 87974,
    total: 600,
    points: 550,
    status: 'SAVED',
    address: {
      name: 'CRC/Commandes -Immeuble Columbus',
      street: '166-180 bd Gabriel Peri',
      zipcode: '92245',
      city: 'Malakoff',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440012',
    date: 1709251200000, // March 1, 2024
    product: 'KADEOS',
    ref: '20240301_345678_CKU_PP',
    orderNumber: 87975,
    total: 1800,
    points: 1650,
    status: 'DONE',
    address: {
      name: "Centre d'Affaires Paris Nord",
      street: '183 Avenue Descartes',
      zipcode: '93150',
      city: 'Le Blanc-Mesnil',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440013',
    date: 1711929600000, // April 1, 2024
    product: 'KADEOS-UNIVERSAL',
    ref: '20240401_456789_CKU_PP',
    orderNumber: 87976,
    total: 2500,
    points: 2300,
    status: 'TREATED',
    address: {
      name: 'CRC/Commandes -Immeuble Columbus',
      street: '166-180 bd Gabriel Peri',
      zipcode: '92245',
      city: 'Malakoff',
      country: 'FRANCE',
    },
  },
  {
    uuid: '550e8400-e29b-41d4-a716-446655440014',
    date: 1714521600000, // May 1, 2024
    product: 'KADEOS-INFINITE',
    ref: '20240501_567890_CKU_PP',
    orderNumber: 87977,
    total: 1400,
    points: 1300,
    status: 'SAVED',
    address: {
      name: 'Tour Eiffel Business Center',
      street: '5 Avenue Anatole France',
      zipcode: '75007',
      city: 'Paris',
      country: 'FRANCE',
    },
  },
];
