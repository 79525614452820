import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import {
  AppBarSubTitle,
  BlueText,
  Card,
  DefaultText,
  BigText,
  InfoText,
  Loader,
  MainLayoutComponent,
  ProgressButton,
  Select,
  Switch,
  TextField,
  Tooltip,
  Stepper,
  RichTextField,
  TransferList,
  GreenRadio,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DefaultTitle,
} from '../../../../../../../../components';
import { CategoryIconInput } from '../../../../../../components';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const InfoStep = ({ categories, kpis, badge, icons }) => {
  const intl = useIntl();

  return (
    <react.fragment>
      <grid container="" spacing="{1}">
        <grid item="" xs="{12}">
          <defaulttitle>
            {intl.formatMessage({ id: 'badge.creation.info_title' })}
          </defaulttitle>
        </grid>
        <grid item="" xs="{12}">
          <card>
            <grid container="" spacing="{2}">
              <grid item="" xs="{12}">
                <textfield lowercase="" name="publicTitle" label="Titre" fullWidth="" initial="{_.get(badge," 'publicTitle')}="" required=""></textfield>
              </grid>
              <grid item="" xs="{12}">
                <textfield lowercase="" name="privateTitle" label="Sous-titre" fullWidth="" initial="{_.get(badge," 'privateTitle')}="" required=""></textfield>
              </grid>
              <grid item="" xs="{12}">
                <textfield lowercase="" name="description" label="Description" fullWidth="" initial="{_.get(badge," 'description')}="" required=""></textfield>
              </grid>
              <grid item="" xs="{12}">
                <select name="category" initial="{badge.category}" label="{intl.formatMessage({" id:="" 'admin.goal.category_label',="" })}="" options="{categories}" optionValueName="id" optionTextName="name" fullWidth="" required=""></select>
              </grid>
              <grid item="" xs="{12}">
                <categoryiconinput name="icon" label="Icône" icons="{[icons]}" initial="{_.get(badge," 'icon')}=""></categoryiconinput>
              </grid>
            </grid>
          </card>
        </grid>
      </grid>
    </react.fragment>
  );
};

export default InfoStep;
