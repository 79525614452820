import React from 'react';
import { Avatar, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '../../..';

const styles = {
  avatar: {
    width: 48,
    height: 48,
  },
};

const GenericPlayerGoal = ({ goal, ...props }) => {
  const { classes } = props;
  const photoSrc =
    goal.photo != null ? goal.photo : '/assets/img/user/avatar.svg';

  return (
    <div>
      <grid container="" spacing="{1}">
        <grid item="">
          <avatar src="{photoSrc}" className="{classes.avatar}"></avatar>
        </grid>
        <grid item="" xs="">
          <textfield lowercase="" name="{goal.id}" label="{`${goal.firstname}" ${goal.lastname}`}="" initial="{goal.target}" type="number"></textfield>
        </grid>
      </grid>
    </div>
  );
};

export default withStyles(styles)(GenericPlayerGoal);
