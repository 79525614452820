export const RewardAllocationConfigurationCodes = Object.freeze({
  CARD_MANUFACTURING_FEES: 'CARD_MANUFACTURING_FEES',
  COMMISSION: 'COMMISSION',
  CUSTOMER_CODE_ALOHA: 'CUSTOMER_CODE_ALOHA',
  GIVER_NAME: 'GIVER_NAME',
  MAXIMUM_ANNUAL_AMOUNT: 'MAXIMUM_ANNUAL_AMOUNT',
  MAXIMUM_BALANCE_AMOUNT: 'MAXIMUM_BALANCE_AMOUNT',
  MINIMUM_CREATION_AMOUNT: 'MINIMUM_CREATION_AMOUNT',
  MINIMUM_LOADING_AMOUNT: 'MINIMUM_LOADING_AMOUNT',
  DELIVERY_FEES: 'DELIVERY_FEES',
  MAXIMUM_AMOUNT: 'MAXIMUM_AMOUNT',
  MINIMUM_AMOUNT: 'MINIMUM_AMOUNT',
});
