import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DefaultText } from '..';

const styles = {
  root: {
    height: 40,
  },
};

const AppBarSubTitle = ({ title, ...props }) => {
  const { classes } = props;

  return (
    <grid container="" className="{classes.root}" alignItems="center" justify="center">
      <grid item="">
        <defaulttext>{title}</defaulttext>
      </grid>
    </grid>
  );
};

export default withStyles(styles)(AppBarSubTitle);
