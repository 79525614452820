import React from 'react';
import { Box, withStyles } from '@material-ui/core';
import { gradients, neutralColors } from '@src/Spider';
import { Button, HierarchyNodeUserStatus } from '@src/Spider/components';
import { useIntl } from 'react-intl';
import { ChangeStatusDialog } from './ChangeStatusDialog';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';

const styles = {
  root: {
    backgroundColor: neutralColors.neutralBg,
    borderRadius: '0.5rem',
    padding: '1rem',
    width: '100%',
    display: 'flex',
    gap: '1rem',
  },
  image: {
    width: '2.25rem',
    height: '2.25rem',
  },
  matricule: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  points: {
    fontSize: '2rem',
    fontWeight: 'bold',
    background: gradients.gradientLightRedRose,
    '-webkit-background-clip': 'text',
    'background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    color: 'black', // Fallback for browsers that don't support background-clip: text
  },
  sub: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
  },
};

/**
 * @param {{user: import("@async-calls/hierarchyNodeUsers").UserBodyDetailed, className: string}} props
 */
const Component = ({ user, className, classes }) => {
  const intl = useIntl();
  const { open, onClose, onOpen } = useDisclosure();

  return (
    <>
      <box className="{`${classes.root}" ${className}`}="">
        <box>
          <hierarchynodeuserstatus status="{user.status}"></hierarchynodeuserstatus>
          <button variant="text" onClick="{onOpen}">
            {intl.formatMessage({ id: 'spider.common.unsubscribe' })}
          </button>
        </box>
      </box>
      <changestatusdialog open="{open}" handleClose="{onClose}" userId="{user.uuid}"></changestatusdialog>
    </>
  );
};

const StatusCard = withStyles(styles)(Component);

export { StatusCard };
