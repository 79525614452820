import { ReactComponent as CKUCard } from '../../assets/img/homeParticipantsConvert/cku-card.svg';
import { ReactComponent as CardDetailGlobe } from '../../assets/img/homeParticipantsConvert/card-details-globe.svg';
import { ReactComponent as CardDetailShop } from '../../assets/img/homeParticipantsConvert/card-details-shop.svg';
import { ReactComponent as CardDetailCalendar } from '../../assets/img/homeParticipantsConvert/card-details-calendar.svg';
import { DecorationCard } from '../../scenes/Home/components/UserHome/components/DecorationCard';
import { withStyles } from '@mui/styles';
import React from 'react';
import { PointCommandIds } from '../../enums';
import { useIntl } from 'react-intl';
import { Link, Typography } from '@material-ui/core';
import { SystemCode } from '../../enums/systemCode';

const styles = {
  design: {
    position: 'absolute',
    // width: '26.125rem',
    height: '6.66488rem',
    // right: '-2.6875rem',
    bottom: '3.25rem',
  },
  sphere: {
    width: '27.25rem',
    height: '26.0625rem',
    position: 'absolute',
    right: '-10.25rem',
    top: '-7.3125rem',
    borderRadius: '27.25rem',
    opacity: 0.3,
    filter: 'blur(100px)',
    background: 'linear-gradient(135deg, #FAAF0C 0%, #FFDC60 100%)',
  },
};

const useCKU = () => {
  const intl = useIntl();
  const id = PointCommandIds.CKU;
  // component card
  // form field to show per step

  // CommandDesign={CKUDesign}
  const ComponentDecoration = ({ ...props }) => (
    <decorationcard CommandCard="{CKUCard}" classes="{props.classes}" title="{intl.formatMessage({" id:="" 'spider.convertPoints.home.cku.title'="" })}=""></decorationcard>
  );

  const cardDetails = [
    {
      Icon: CardDetailGlobe,
      label: intl.formatMessage({
        id: 'spider.convertPoints.home.cku.globeLabel',
      }),
    },
    {
      Icon: CardDetailShop,
      label: intl.formatMessage({
        id: 'spider.convertPoints.home.cku.shopLabel',
      }),
    },
    {
      Icon: CardDetailCalendar,
      label: intl.formatMessage({
        id: 'spider.convertPoints.home.cku.calendarLabel',
      }),
    },
  ];

  const getInformationStepDefaultFormData = () => {
    return {
      dateOfBirth: '',
      phone: '',
      line1: '',
      line2: '',
      zipCode: '',
      city: '',
    };
  };

  const HelperAbout = () => (
    <div>
      <div>
        <typography variant="{'h3'}" component="{'h3'}">
          {"Un catalogue d'offres négociées pour combler vos attentes à 100% !"}
        </typography>

        <div>
          <typography variant="{'body1'}" style="{{" fontWeight:="" 'bold'="" }}="">
            {'Pour en profiter, c’est simple :'}
          </typography>

          <div>Stepper</div>

          <typography variant="{'body1'}">{'Et  voilà !'}</typography>
          <typography variant="{'body2'}" style="{{" fontStyle:="" 'italic'="" }}="">
            {
              "Pour rappel, votre solde est valable pendant un an à partir de l'activation de votre compte."
            }
          </typography>

          <link className="{'bold" large'}="" onClick="{()" ==""> {}}>
            {'Tout savoir sur Kadéos Connect'}
          
        </div>
      </div>
      <div style="{{" background:="" 'linear-gradient(135deg,="" #FAAF0C="" 0%,="" #FFDC60="" 100%)',="" }}="">
        <ckucard></ckucard>
      </div>
    </div>
  );

  return {
    id,
    cardDetails,
    HelperAbout,
    Card: CKUCard,
    ComponentDecoration: withStyles(styles)(ComponentDecoration),
    informationStep: {
      defaultForm: getInformationStepDefaultFormData(),
      showAndValidateBirthDate: true,
      alert: {
        PP: {
          type: SystemCode.WARNING,
          title:
            'spider.convertPoints.stepper.informationStep.personalAddress.alertTitle',
          content:
            'spider.convertPoints.stepper.informationStep.personalAddress.alertMessage',
        },
        PM: {
          type: SystemCode.WARNING,
          title:
            'spider.convertPoints.stepper.informationStep.personalAddress.alertTitle',
          content:
            'spider.convertPoints.stepper.informationStep.personalAddress.alertMessage',
        },
      },
      address: {
        PP: true,
        PM: true,
      },
      addressTitle: {
        PP: 'spider.convertPoints.stepper.informationStep.personalAddress.titleDelivery',
        PM: 'spider.convertPoints.stepper.informationStep.personalAddress.titlePersonal',
      },
    },
  };
};

export default useCKU;
