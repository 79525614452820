import { useDispatch } from 'react-redux';
import { settingSideBarUpdateKey } from '../features/settings/preventLeavePageWithoutSave/slices';

const UseUpdateSideBar = () => {
  const dispatch = useDispatch();

  const updateSideBar = () => {
    dispatch(settingSideBarUpdateKey());
  };

  return { updateSideBar };
};

export default UseUpdateSideBar;
