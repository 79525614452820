import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { CategoryIconInput } from '../../components';
import {
  AppBarSubTitle,
  Card,
  Loader,
  ProgressButton,
  TextField,
} from '../../../../components';
import * as levelListActions from '../../../../services/Levels/LevelList/actions';
import * as levelCreationActions from '../../../../services/Levels/LevelListCreation/actions';
import * as levelIconListActions from '../../../../services/LevelIcons/LevelIconList/actions';
import { injectIntl } from 'react-intl';
import { toast } from 'react-toastify';

class AdminLevelCreation extends Component {
  componentDidMount() {
    const { levels } = this.props.levelList;
    const levelNumber = levels.length + 1;

    this.props.handleTitle('Administration');
    this.props.handleSubHeader(
      <appbarsubtitle title="{`Création" du="" level="" ${levelNumber}`}=""></appbarsubtitle>,
    );
    this.props.handleMaxWidth('sm');
    this.props.activateReturn();
    this.props.levelCreationActions.clearLevelListCreation();
    this.props.levelIconListActions.getUsableList();
  }

  renderLoader() {
    return <loader centered=""></loader>;
  }

  onSubmit(model) {
    const periodId = this.props.match.params.periodId;
    const { levels } = this.props.levelList;
    const level = {
      id: this.props.match.params.id,
      title: model.title,
      citation: model.citation,
      icon: model.icon,
      points: model.points,
      period: periodId,
    };

    this.props.levelCreationActions.createLevelList([
      ...levels.map(item => {
        return Object.assign(item, { icon: _.get(item, 'icon.id') });
      }),
      level,
    ]);
  }

  renderForm() {
    const { intl } = this.props;
    const { icons } = this.props.levelIconList;
    const { loading } = this.props.levelListCreation;
    const { levels } = this.props.levelList;
    const minimumPoints = _.max(levels.map(level => level.points));

    return (
      <formsy onValidSubmit="{this.onSubmit.bind(this)}">
        <grid container="" spacing="{4}">
          <grid item="" xs="{12}">
            <card>
              <grid container="" spacing="{2}">
                <grid item="" xs="{12}">
                  <textfield name="title" label="Nom" fullWidth=""></textfield>
                </grid>
                <grid item="" xs="{12}">
                  <textfield name="citation" label="Citation" fullWidth=""></textfield>
                </grid>
                <grid item="" xs="{12}">
                  <textfield name="points" label="{`Points" à="" atteindre="" (minimum="" ${minimumPoints})`}="" fullWidth="" validations="{{" isMoreThan:="" minimumPoints="" }}="" validationErrors="{{" `Le="" nombre="" de="" points="" doit="" être="" supérieur="" au="" niveau="" précédent="" :="" ${minimumPoints}`,=""></textfield>
                </grid>
                <grid item="" xs="{12}">
                  <categoryiconinput name="icon" label="Icône" icons="{[icons]}"></categoryiconinput>
                </grid>
              </grid>
            </card>
          </grid>
          <grid item="" xs="{12}">
            <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" centered="" loading="{loading}"></progressbutton>
          </grid>
        </grid>
      </formsy>
    );
  }

  render() {
    const { intl } = this.props;
    const { icons, loading } = this.props.levelIconList;
    const { success } = this.props.levelListCreation;

    if (success) {
      this.props.levelCreationActions.clearLevelListCreation();
      toast.success(
        intl.formatMessage({ id: 'common.create_success_message' }),
      );
      this.props.history.goBack();
    }

    return (
      <div>
        {loading && this.renderLoader()}
        {!loading && icons && this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = ({ levelListCreation, levelIconList, levelList }) => ({
  levelListCreation,
  levelList,
  levelIconList,
});

const mapDispatchToProps = dispatch => ({
  levelCreationActions: bindActionCreators(levelCreationActions, dispatch),
  levelIconListActions: bindActionCreators(levelIconListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AdminLevelCreation));
