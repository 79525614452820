import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@mui/styles';
import cku from '@spider:src/assets/img/cku.png'; // Carte Kadéos Universel Chargement
import cks from '@spider:src/assets/img/cks.png'; // Carte Kadéos
import ks from '@spider:src/assets/img/ks.png'; // Chèques Kadéos Infini
import ksc from '@spider:src/assets/img/ksc.png'; // Boutique en ligne Kadéos

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    maxWidth: '12rem',
    minWidth: '12rem',
  },
};

/**
 * @typedef {import('@src/mock/datasets/ordersList').ProductType} ProductType
 * @param {{product: ProductType}}
 */
const Component = ({ product, classes }) => {
  const { image, label } = selectProduct(product);
  return (
    <box className="{classes.root}">
      <img src="{image}">
      <typography>{label}</typography>
    </box>
  );
};

const ProductType = withStyles(styles)(Component);
export { ProductType };

/**
 *@param {ProductType} product
 *@returns {{image: string, label: string}}
 */

const selectProduct = product => {
  switch (product) {
    case 'KADEOS': {
      return {
        image: cks,
        label: 'Carte Kadéos',
      };
    }
    case 'KADEOS-UNIVERSAL': {
      return {
        image: cku,
        label: 'Carte Kadéos Universel Chargement',
      };
    }
    case 'KADEOS-INFINITE': {
      return {
        image: ks,
        label: 'Chèques Kadéos Infini',
      };
    }
    case 'KADEOS-ONLINE': {
      return {
        image: ksc,
        label: 'Boutique en ligne Kadéos',
      };
    }
  }
};
