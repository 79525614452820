export const GET_CHALLENGE_TYPE_USABLE_POINTS =
  'GET_CHALLENGE_TYPE_USABLE_POINTS';
export const GET_CHALLENGE_TYPE_USABLE_POINTS_BY_CHALLENGE =
  'GET_CHALLENGE_TYPE_USABLE_POINTS_BY_CHALLENGE';
export const GET_CHALLENGE_TYPE_USABLE_POINTS_SUCCESS =
  'GET_CHALLENGE_TYPE_USABLE_POINTS_SUCCESS';
export const GET_CHALLENGE_TYPE_USABLE_POINTS_ERROR =
  'GET_CHALLENGE_TYPE_USABLE_POINTS_ERROR';
export const CLEAR_CHALLENGE_TYPE_USABLE_POINTS =
  'CLEAR_CHALLENGE_TYPE_USABLE_POINTS';
