import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import { approveSystemFilesKeySlice } from './slices';

function* approveSystemFiles(slice, endpoint, action) {
  try {
    const { userId, fileId } = action.payload;
    yield call(endpoint, userId, fileId);
    yield put(slice.actions.approveSuccess());
  } catch (error) {
    yield put(
      slice.actions.approveFailure(error?.response?.data?.error ?? 'UNKNOWN'),
    );
  }
}

function* watchApproveSystemFiles(slice, endpoint) {
  yield takeLatest(
    slice.actions.approveStart.type,
    approveSystemFiles,
    slice,
    endpoint,
  );
}

export default function* approveSystemFilesSaga() {
  yield all([
    watchApproveSystemFiles(
      approveSystemFilesKeySlice,
      api.hierarchyNodeUsers.approveSystemFile,
    ),
  ]);
}
