import { useIntl } from 'react-intl';
import useFileDownload from '../UseFileDownload';

const UseParticipantsHelper = () => {
  const intl = useIntl();
  const { downloadBlob } = useFileDownload();

  const onDownloadImportPattern = () => {
    const file = new Blob(
      [
        "MATRICULE;NOM;PRENOM;EMAIL;RESPONSABLE N+1;STATUT D'INSCRIPTION;RAISON SOCIALE;ADRESSE 1;ADRESSE 2;CODE POSTAL;VILLE\r\n",
      ],
      {
        type: 'text/csv',
      },
    );
    downloadBlob(file, 'participant-modele.csv');
  };

  const importRequirements = {
    helpInfo: {
      helpInfo: [
        intl.formatMessage({
          id: 'spider.participants.helpImport.helpInfo.waitedFormat',
        }),
        intl.formatMessage({
          id: 'spider.participants.helpImport.helpInfo.orderFormat',
        }),
        intl.formatMessage({
          id: 'spider.participants.helpImport.helpInfo.optionalInfo',
        }),
      ],

      requirement: [
        {
          title: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.number.title',
          }),
          description: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.number.description',
          }),
          required: true,
        },
        {
          title: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.lastName.title',
          }),
          description: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.lastName.description',
          }),
          required: true,
          category: null,
        },
        {
          title: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.name.title',
          }),
          description: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.name.description',
          }),
          required: true,
          category: null,
        },
        {
          title: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.email.title',
          }),
          description: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.email.description',
          }),
          required: true,
          category: null,
        },
        {
          title: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.senior.title',
          }),
          description: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.senior.description',
          }),
          required: false,
          category: null,
        },
        {
          title: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.inscriptionStatus.title',
          }),
          description: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.inscriptionStatus.description',
          }),
          required: true,
          category: null,
        },
        {
          title: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.socialRaison.title',
          }),
          description: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.socialRaison.description',
          }),
          required: true,
          category: intl.formatMessage({
            id: 'spider.participants.helpImport.category.moralePerson',
          }),
        },
        {
          title: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.primaryAddress.title',
          }),
          description: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.primaryAddress.description',
          }),
          required: true,
          category: intl.formatMessage({
            id: 'spider.participants.helpImport.category.moralePerson',
          }),
        },
        {
          title: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.secondaryAddress.title',
          }),
          description: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.secondaryAddress.description',
          }),
          required: false,
          category: intl.formatMessage({
            id: 'spider.participants.helpImport.category.moralePerson',
          }),
        },
        {
          title: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.postalCode.title',
          }),
          description: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.postalCode.description',
          }),
          required: true,
          category: intl.formatMessage({
            id: 'spider.participants.helpImport.category.moralePerson',
          }),
        },
        {
          title: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.city.title',
          }),
          description: intl.formatMessage({
            id: 'spider.participants.helpImport.requirements.city.description',
          }),
          required: true,
          category: intl.formatMessage({
            id: 'spider.participants.helpImport.category.moralePerson',
          }),
        },
      ],
    },
  };

  return { importRequirements, onDownloadImportPattern };
};

export default UseParticipantsHelper;
