import React from 'react';
import {
  IconButton,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { neutralColors, themeColors } from '../../../../../../../themes/colors';
import useAuthorizedRoutes from '../../../../../../../hooks/UseAuthorizedRoutes';
import { Stack } from '@mui/material';
import { AccountDropdown } from '../Account';
import OperationDialog from '../../../OperationDialog';

const useStyles = makeStyles(theme => ({
  expandedAppBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: themeColors.primaryContrastText,
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    flexDirection: 'column',
  },
  toolbarContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  menuItems: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0rem 1rem',
    marginTop: '1rem',
  },
  iconButton: {
    backgroundColor: themeColors.primaryContrastText,
    color: themeColors.primaryRegular,
  },
  separator: {
    borderBottom: `1px solid ${neutralColors.neutral200}`,
    margin: '0rem 2rem',
  },
  account: {
    justifyContent: 'space-between',
    width: '100%',
    margin: '0.75rem 0rem',
  },
}));

const MobileMenu = ({
  handleDrawerToggle,
  selectedIndex,
  handleListeItemClick,
  LogoImg,
}) => {
  const classes = useStyles();
  const { menus } = useAuthorizedRoutes();

  return (
    <div className="{classes.expandedAppBar}">
      <toolbar style="{{" justifyContent:="" 'space-between'="" }}="">
        <img height="48px" src="{LogoImg}" alt="logo">
        <iconbutton aria-label="close drawer" onClick="{handleDrawerToggle}" className="{classes.iconButton}">
          <closeicon></closeicon>
        </iconbutton>
      </toolbar>
      <div className="{classes.separator}"></div>
      <stack direction="column">
        <accountdropdown className="{classes.account}" handleDrawerToggle="{handleDrawerToggle}"></accountdropdown>
        <div className="{classes.separator}"></div>
        <operationdialog></operationdialog>
        <div className="{classes.separator}"></div>
        <div className="{classes.menuItems}">
          <list>
            {menus.map(item => (
              <listitem button="" key="{item.text}" selected="{selectedIndex" =="=" item.src}="" component="{Link}" to="{item.src}" onClick="{event"> handleListeItemClick(event, item.src)}
              >
                <listitemicon>{item.icon}</listitemicon>
                <listitemtext primary="{item.text}"></listitemtext>
              </listitem>
            ))}
          </list>
        </div>
      </stack>
    </div>
  );
};

export default MobileMenu;
