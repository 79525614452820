import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import { orderRewardKeySlice } from './slices';

function* orderReward(slice, endpoint, action) {
  try {
    const { userId, form, items, lfss_form } = action.payload;
    const data = yield call(endpoint, userId, form, items, lfss_form);
    yield put(slice.actions.orderSuccess(data.data));
  } catch (error) {
    yield put(
      slice.actions.orderFailure(error?.response?.data?.error ?? 'UNKNOWN'),
    );
  }
}

function* watchOrderReward(slice, endpoint) {
  yield takeLatest(slice.actions.orderStart.type, orderReward, slice, endpoint);
}

export default function* orderRewardSaga() {
  yield all([watchOrderReward(orderRewardKeySlice, api.rewards.rewardOrder)]);
}
