import React from 'react';
import { withRouter } from 'react-router-dom';
import { ListItemText, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItem, ListItemIcon } from './components';
import { Badge } from '../../../../../../../Badge';
import configureStore from '../../../../../../../../../../store/configureStore';
import local from '../../../../../../../../../../data/local/local';
import { useClearCache } from 'react-clear-cache';

const DrawerButton = ({
  badgeContent,
  icon,
  onNavigate,
  text,
  src,
  MaterialIcon,
  ...props
}) => {
  const selected =
    src === '/'
      ? src === props.history.location.pathname
      : props.history.location.pathname.startsWith(src);
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  const handleClick = () => {
    if (src == '/logout') {
      local.removeAccessToken();
      local.removeRefreshToken();
      local.removeStore();
      emptyCacheStorage();
      window.location = '/';
    } else {
      if (onNavigate) onNavigate();
      props.history.push(src);
    }
  };

  return (
    <listitem button="" selected="{selected}" onClick="{handleClick}">
      <badge badgeContent="{badgeContent}" color="secondary">
        <listitemicon 20="" style="{{" width:="" }}="">
          {icon && <fontawesomeicon icon="{icon}"></fontawesomeicon>}
          {MaterialIcon && <materialicon 20="" style="{{" fontSize:="" }}=""></materialicon>}
        </listitemicon>
      </badge>
      <listitemtext>{text}</listitemtext>
    </listitem>
  );
};

export default withRouter(DrawerButton);
