import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const UseSessionStorageSliceForm = ({
  key = 'sessionStorageData',
  isDataSavable,
  sliceKey,
  sliceProp,
  sliceSetter,
  setClear,
  defaultFormData = { onHandleFormChange: false },
  automaticInit = false,
  automaticSave = false,
}) => {
  const dispatch = useDispatch();

  const dataFromSlice = useSelector(state => state[sliceKey]);

  const sessionStorageData = sessionStorage.getItem(key);
  const data =
    sessionStorageData !== null
      ? JSON.parse(sessionStorageData) !== null
        ? JSON.parse(sessionStorageData)
        : null
      : null;
  const [formData, setFormData] = useState(
    dataFromSlice[sliceProp] !== null
      ? dataFromSlice[sliceProp]
      : data !== null
        ? data
        : defaultFormData,
  );

  const setData = formData => {
    if (!formData) return;
    const { onHandleFormChange, ...formDataRest } = formData;
    sessionStorage.setItem(key, JSON.stringify(formDataRest));
  };

  const handleFormChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
      onHandleFormChange: true,
    }));
  };

  const initDefault = () => {
    // fill up the slice if the session storage is on
    if (dataFromSlice[sliceProp] === null && data !== null) {
      dispatch(sliceSetter({ ...data, onHandleFormChange: false }));
    } else if (dataFromSlice[sliceProp] !== null && data === null) {
      setData(dataFromSlice[sliceProp]);
    }
  };

  const saveStateData = (data, force = false) => {
    if (!force && (!automaticSave || !data || !data.onHandleFormChange)) return;

    if (!isDataSavable(data)) {
      clearSavedState();
      return;
    }

    dispatch(sliceSetter(data));
    setData(data);
  };

  useEffect(() => {
    if (automaticInit) {
      initDefault();
    }
  }, [automaticInit]);

  useEffect(() => {
    saveStateData(formData);
  }, [formData, automaticSave]);

  const clearData = () => {
    sessionStorage.removeItem(key);
  };

  const clearSavedState = () => {
    dispatch(setClear());
    clearData();
  };

  return { handleFormChange, formData, initDefault, saveStateData };
};

export default UseSessionStorageSliceForm;
