import React from 'react';
import { Redirect, useParams } from 'react-router-dom/';
import { useAllContracts } from '../Spider/hooks/useAllContracts';

export const MaintenanceProvider = ({ children }) => {
  const { contract } = useParams();
  const { data } = useAllContracts();
  const currentContract = data.find(el => el.code === contract);

  if (currentContract?.status === 'MAINTENANCE') {
    return <redirect to="{`/nodes/${contract}/maintenance`}"></redirect>;
  }

  return <>{children}</>;
};
