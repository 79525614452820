import React from 'react';
import { TableBody } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faRandom, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { TeamRank } from './components';
import {
  Table,
  TableHead,
  TableHeadCell,
  TableRow,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import { useIntl } from 'react-intl';

const TeamRanking = ({ ranking, teamId }) => {
  const intl = useIntl();
  return (
    <div>
      <table>
        <tablehead>
          <tablerow>
            <tableheadcell align="left" colSpan="{2}">
              <fontawesomeicon icon="{faSortAmountDown}"></fontawesomeicon>
            </tableheadcell>
            <tableheadcell>
              {intl.formatMessage({ id: 'ranking.columns.team' })}
            </tableheadcell>

            <tableheadcell align="center">
              {Resources.TEAM_RANKING_POINTS_COLUMN}
            </tableheadcell>
          </tablerow>
        </tablehead>
        <tablebody>
          {ranking.map(rank => {
            const selected = rank.teamId == teamId;
            return <teamrank key="{rank.id}" rank="{rank}" selected="{selected}"></teamrank>;
          })}
        </tablebody>
      </table>
    </div>
  );
};

export default TeamRanking;
