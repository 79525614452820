import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  AdministratorCollaboratorSelector,
  MainLayoutComponent,
  ManagerCollaboratorSelector,
  DefaultTitle,
} from '../../../../components';
import { Grid } from '@material-ui/core';
import * as Resources from '../../../../Resources';
import { injectIntl } from 'react-intl';

class CoachingHome extends MainLayoutComponent {
  componentDidMount() {
    const { intl } = this.props;
    this.props.handleTitle(intl.formatMessage({ id: 'coaching_list.title' }));
  }

  handleClick(id) {
    this.props.history.push(`/coaching/${id}`);
  }

  render() {
    const { account } = this.props.accountDetail;
    const { intl } = this.props;

    const handleCollaboratorClick = collaborator => {
      const collaboratorUrl = `/coaching/${collaborator.id}`;
      this.props.history.push(collaboratorUrl);
    };

    if (!account.hasCoachingAccess) {
      return <redirect to="{'/'}"></redirect>;
    }

    if (account.role.code == 'C') {
      return <redirect to="{`/coaching/${account.id}`}"></redirect>;
    }

    return (
      <>
        {account.role.code == 'M' && (
          <managercollaboratorselector onClick="{this.handleClick.bind(this)}"></managercollaboratorselector>
        )}
        {(account.role.code == 'A' || account.role.code == 'S') && (
          <grid container="" spacing="{1}">
            <grid item="" xs="{12}">
              <administratorcollaboratorselector contextUrl="coaching/team/" onClickCollaborator="{handleCollaboratorClick}"></administratorcollaboratorselector>
            </grid>
          </grid>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(withRouter(injectIntl(CoachingHome)));
