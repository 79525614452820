const fetchUrlAsFile = async (url, fileName) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], fileName, { type: blob.type });
};

export const convertUrlToFile = async url => {
  const fileName = url.split('/').pop();
  return fetchUrlAsFile(url, fileName);
};

export const importLocalFiles = requireContext => {
  return requireContext.keys().map(async item => {
    const localFileUrl = requireContext(item);
    const fileName = item.split('/').pop();
    return fetchUrlAsFile(localFileUrl, fileName);
  });
};
