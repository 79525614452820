import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Loader, UserProfile } from '../../../../../../components';

const styles = {
  root: {
    padding: 16,
  },
};

const SubHeader = ({ ...props }) => {
  const { classes } = props;
  const { collaborator, loading: collaboratorDetailLoading } =
    props.collaboratorDetail;
  const { badges, loading: currentCollaboratorBadgeSummaryListLoading } =
    props.currentCollaboratorBadgeSummaryList;
  const loading =
    collaboratorDetailLoading || currentCollaboratorBadgeSummaryListLoading;

  const renderLoader = () => {
    return (
      <div className="{classes.root}">
        <loader centered=""></loader>
      </div>
    );
  };

  const renderData = () => {
    return <userprofile user="{collaborator}"></userprofile>;
  };

  return (
    <div>
      {loading && renderLoader()}
      {!loading && collaborator && badges && renderData()}
    </div>
  );
};

const mapStateToProps = ({
  collaboratorDetail,
  currentCollaboratorBadgeSummaryList,
}) => ({
  collaboratorDetail,
  currentCollaboratorBadgeSummaryList,
});

export default connect(mapStateToProps)(withStyles(styles)(SubHeader));
