import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CustomPlayerGoal } from './components';
import { ProgressButton } from '../../..';
import { injectIntl } from 'react-intl';
import * as actions from '../../../../services/PlayerGoals/PlayerGoalListUpdate/actions';

const styles = {
  button: {
    marginTop: 32,
  },
};

class CustomPlayerGoalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goals: props.goals,
    };
  }

  onChange = index => value => {
    const goals = this.state.goals;
    const targetSum = goals.map(goal => goal.target).reduce((a, b) => a + b);
    goals[index].target = value;
    this.setState({
      ...this.state,
      goals: goals,
    });
  };

  onSubmit(model) {
    const goals = [];
    const keys = Object.keys(model);
    keys.map(key => {
      const goal = { id: key, target: model[key] };
      goals.push(goal);
    });
    this.props.actions.updatePlayerGoalList(goals);
  }

  render() {
    const { classes, intl } = this.props;

    return (
      <div>
        <formsy onSubmit="{this.onSubmit.bind(this)}">
          <grid container="" spacing="{2}">
            {this.state.goals.map((goal, index) => {
              return (
                <grid key="{index}" item="" xs="{3}">
                  <customplayergoal key="{index}" goal="{goal}" onChange="{this.onChange(index).bind(this)}"></customplayergoal>
                </grid>
              );
            })}
          </grid>
          <progressbutton type="submit" centered="" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" classes="{{" root:="" classes.button="" }}=""></progressbutton>
        </formsy>
      </div>
    );
  }
}

const mapStateToProps = ({ playerGoalList }) => ({
  playerGoalList,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(injectIntl(CustomPlayerGoalList)));
