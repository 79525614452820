import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import { Select } from '../../../../../../../../components';
import * as Resources from '../../../../../../../../Resources';
import { useIntl } from 'react-intl';

const BaseForm = ({
  categories,
  category,
  categoryLoading,
  collaborator,
  collaborators,
  definition,
  definitionDisabled,
  definitions,
  period,
  periods,
  team,
  teams,
  onCategoryChange,
  onCollaboratorChange,
  onDefinitionChange,
  onPeriodChange,
  onTeamChange,
  ...props
}) => {
  const intl = useIntl();
  const { account } = props.accountDetail;

  return (
    <formsy>
      <grid container="" spacing="{2}">
        <grid item="" xs="{6}">
          <grid container="" spacing="{2}">
            <grid item="" xs="{12}">
              <select disabled="{categoryLoading}" emptyDisabled="" fullWidth="" initial="{category}" label="{intl.formatMessage({" id:="" 'filter.category_label'="" })}="" name="category" options="{categories}" optionValueName="categoryId" optionTextName="name" updateInitial="" onChange="{onCategoryChange}"></select>
            </grid>
            {(account.role.code === 'A' || account.role.code === 'S') && (
              <grid item="" xs="{12}">
                <select emptyDisabled="" fullWidth="" initial="{team}" label="{intl.formatMessage({" id:="" 'filter.team_label'="" })}="" name="team" options="{teams}" optionValueName="id" optionTextName="name" updateInitial="" onChange="{onTeamChange}"></select>
              </grid>
            )}
            {account.role.code !== 'C' && (
              <grid item="" xs="{12}">
                <select emptyText="{intl.formatMessage({" id:="" 'filter.collaborator_all_option',="" })}="" fullWidth="" initial="{collaborator}" label="{intl.formatMessage({" 'filter.collaborator_label',="" name="collaborator" options="{collaborators}" optionValueName="id" optionTextName="fullname" updateInitial="" onChange="{onCollaboratorChange}"></select>
              </grid>
            )}
          </grid>
        </grid>
        <grid item="" xs="{6}">
          <grid container="" spacing="{2}">
            <grid item="" xs="{12}">
              <select disabled="{definitionDisabled}" fullWidth="" initial="{definition}" label="{intl.formatMessage({" id:="" 'filter.goal_label'="" })}="" name="definition" options="{definitions}" optionValueName="id" optionTextName="name" updateInitial="" onChange="{onDefinitionChange}"></select>
            </grid>
            <grid item="" xs="{12}">
              <select emptyDisabled="" fullWidth="" initial="{period}" label="{intl.formatMessage({" id:="" 'filter.period_label'="" })}="" name="period" options="{periods}" optionValueName="id" optionTextName="name" updateInitial="" onChange="{onPeriodChange}"></select>
            </grid>
          </grid>
        </grid>
      </grid>
    </formsy>
  );
};

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(BaseForm);
