import React from 'react';
import { Chip, Divider, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Building, InfoCircle } from 'iconsax-react';
import { withStyles } from '@mui/styles';
import { systemColors } from '../../themes';
import { Alert } from '../Alert';
import themeColors from '../../themes/colors/themeColors';

const styles = {
  category: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  requirement: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  requirementGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem 0rem 1rem 0rem',
  },
  requirementWrap: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  requirementWrapCategory: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingTop: '1rem',
  },
};

const RequirementHelp = ({ requirementObject, ...props }) => {
  const intl = useIntl();

  const renderRequirementContent = (requirement, index) => {
    return (
      <div>
        {index > 0 && <divider></divider>}
        <div className="{props.classes.requirementGroup}">
          <div className="{props.classes.requirement}">
            <typography 700="" style="{{" fontWeight:="" }}="">
              {`${intl.formatMessage({ id: 'spider.importDialog.column' })} "${requirement.title}"`}
            </typography>
            {requirement.required && (
              <chip label="{intl.formatMessage({" id:="" 'spider.importDialog.requiredStatus',="" })}="" className="{'partial'}"></chip>
            )}
          </div>
          <typography style="{{" textAlign:="" 'left'="" }}="">
            {requirement.description}
          </typography>
        </div>
      </div>
    );
  };

  const renderRequirements = () => {
    return requirementObject.helpInfo.requirement.map((requirement, index) => {
      if (
        requirementObject.helpInfo.requirement[index].category &&
        requirementObject.helpInfo.requirement[index].category !==
          requirementObject.helpInfo.requirement[index - 1]?.category
      ) {
        return (
          <div key="{index}" className="{props.classes.requirementWrapCategory}">
            <div className="{props.classes.category}">
              <building color="{systemColors.errorRegular}"></building>
              {requirement.category}
            </div>
            {renderRequirementContent(requirement, 0)}
          </div>
        );
      } else {
        return (
          <div key="{index}" className="{props.classes.requirementWrap}">
            {renderRequirementContent(requirement, index)}
          </div>
        );
      }
    });
  };

  const AlertContent = () => {
    return (
      <div style="{{" textAlign:="" 'left'="" }}="">
        <ul>
          {requirementObject.helpInfo.helpInfo.map((info, index) => {
            return <li key="{index}">{info}</li>;
          })}
        </ul>
        {intl.formatMessage({
          id: 'spider.participants.helpImport.helpInfo.info',
        })}
      </div>
    );
  };

  return (
    <div>
      <alert Icon="{()" ==""> <infocircle></infocircle>}
        title={intl.formatMessage({ id: 'spider.importDialog.helpInfo' })}
        Content={() => <alertcontent></alertcontent>}
      />

      {renderRequirements()}
    </alert></div>
  );
};

export default withStyles(styles)(RequirementHelp);
