import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import { updateContractDetailsKeySlice } from './slices';

function* updateContractDetails(slice, endpoint, action) {
  try {
    const { teamGroupId, contractDetails } = action.payload;
    yield call(endpoint, teamGroupId, contractDetails);
    yield put(slice.actions.updateSuccess());
  } catch (error) {
    yield put(
      slice.actions.updateFailure(error?.response?.data?.error ?? 'UNKNOWN'),
    );
  }
}

function* watchUpdateContractDetails(slice, endpoint) {
  yield takeLatest(
    slice.actions.updateStart.type,
    updateContractDetails,
    slice,
    endpoint,
  );
}

export default function* updateConfigsSaga() {
  yield all([
    watchUpdateContractDetails(
      updateContractDetailsKeySlice,
      api.contractDetails.update,
    ),
  ]);
}
