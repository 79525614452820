import React from 'react';
import { Card, DefaultText } from '../../../../../../components';
import { Grid } from '@material-ui/core';

const UpdateMobileAppModal = () => {
  const { detect } = require('detect-browser');
  const browser = detect();
  const isIos = browser.name === 'ios-webview';
  return (
    <>
      <div style="{{" position:="" 'fixed',="" top:="" 0,="" left:="" width:="" '100%',="" height:="" backgroundColor:="" 'rgba(0,="" 0.5)',="" zIndex:="" 9000,="" }}=""></div>
      <div style="{{" position:="" 'fixed',="" bottom:="" '50%',="" left:="" transform:="" 'translateX(-50%)',="" width:="" '80vw',="" zIndex:="" 10000,="" }}="">
        <card>
          <div>
            <grid container="">
              <grid item="" xs="{8}">
                <grid container="">
                  <grid item="" xs="{12}">
                    <defaulttext lowercase="" style="{{" fontWeight:="" 'bold'="" }}="">
                      Mettre à jour l'application
                    </defaulttext>
                  </grid>
                  <grid item="" xs="{12}">
                    <defaulttext lowercase="">
                      Cette version n'est plus prise en charge. Veuillez mettre
                      à jour votre application.
                    </defaulttext>
                  </grid>
                </grid>
              </grid>
              <grid 10="" item="" xs="{4}" container="" alignItems="center" style="{{" paddingLeft:="" }}="">
                <grid item="">
                  <a href="{" isIos="" ?="" process.env.REACT_APP_MOBILE_APP_STORE_URL="" :="" process.env.REACT_APP_MOBILE_PLAY_STORE_URL="" }="" target="_blank" style="{{" textDecoration:="" 'none'="" }}="">
                    Mettre à jour
                  </a>
                </grid>
              </grid>
            </grid>
          </div>
        </card>
      </div>
    </>
  );
};

export default UpdateMobileAppModal;
