export const HierarchyNodeUserStatuses = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
  PENDING_ACTIVATION_EMAIL: 'PENDING_ACTIVATION_EMAIL',
  PENDING_ACTIVATION: 'PENDING_ACTIVATION',
});

/**
 *  @typedef { HierarchyNodeUserStatuses[keyof HierarchyNodeUserStatuses] } HierarchyNodeUserStatusesType - Statuses for a user, should use the enum in `HierarchyNodeUserStatuses.jsxÌ
 * @exports { HierarchyNodeUserStatusesType };
 */
