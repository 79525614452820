import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Formsy from 'formsy-react';
import { Grid, CardMedia, IconButton } from '@material-ui/core';
import {
  Card,
  DefaultText,
  Dialog,
  Avatar,
  RichText,
  RichTextField,
  ProgressButton,
  FileInput,
  HiddenInput,
  PostCommentForm,
  Linkify,
  TextField,
  Button,
} from '../../../../../../components';
import { PostMenu, Content } from '../../../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faImage,
  faPlayCircle,
  faTrash,
  faFlag,
} from '@fortawesome/free-solid-svg-icons';
import { useIntl, injectIntl } from 'react-intl';
import _ from 'lodash';
import { is } from 'date-fns/locale';

const styles = {
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover p': {
      color: 'rgb(15,111,222)',
      textDecoration: 'underline',
    },
  },
  noLink: {
    textDecoration: 'none',
    cursor: 'unset',
  },
  commentWrapper: {
    padding: '5px 15px',
    background: '#f5f5f5',
    borderRadius: 10,
    height: '100%',
  },
  commentContent: {
    background: '#f5f5f5',
  },
  commentField: {
    '& .MuiInput-underline:before, .MuiInput-underline:after': {
      display: 'none',
    },
  },
};

const PostCommentContent = ({
  classes,
  comment,
  onDelete,
  onReport,
  isAuthor,
  onUpdate,
  ...props
}) => {
  const intl = useIntl();
  const [edit, setEdit] = useState(false);
  const [content, setContent] = useState(comment.content);

  useEffect(() => {
    setContent(comment.content);
  }, [comment]);

  const menuEntries = _.compact([
    isAuthor
      ? {
          title: intl.formatMessage({ id: 'common.delete' }),
          onClick: () => onDelete(comment.id),
          icon: <fontawesomeicon icon="{faTrash}"></fontawesomeicon>,
        }
      : null,
    isAuthor
      ? {
          title: intl.formatMessage({ id: 'common.edit' }),
          onClick: () => setEdit(true),
          icon: <fontawesomeicon icon="{faEdit}"></fontawesomeicon>,
        }
      : null,
    !isAuthor
      ? {
          title: intl.formatMessage({ id: 'common.report' }),
          onClick: () => onReport(comment.id),
          icon: <fontawesomeicon icon="{faFlag}"></fontawesomeicon>,
        }
      : null,
  ]);

  const hasProfile = user =>
    _.get(user, 'role_code') === 'C' && _.get(user, 'team');

  const goToUserProfile = user => {
    if (hasProfile(user)) {
      props.history.push(
        `/teams/${user.team.id}/collaborators/${user.id}/detail`,
      );
    }
  };

  return (
    <grid item="" xs="{12}">
      <grid container="" spacing="{1}">
        <grid item="">
          <span onClick="{()" ==""> goToUserProfile(comment.author)}
            className={
              hasProfile(comment.author) ? classes.link : classes.noLink
            }
          >
            <avatar src="{comment.author.photo}" fallbackName="{comment.author.fullname}"></avatar>
          </span>
        </grid>
        <grid item="" xs="">
          <div className="{classes.commentWrapper}">
            <grid container="">
              <grid item="" xs="{12}" container="">
                <grid item="" xs="">
                  <grid container="">
                    <grid item="" xs="{12}">
                      <span onClick="{()" ==""> goToUserProfile(comment.author)}
                        className={
                          hasProfile(comment.author)
                            ? classes.link
                            : classes.noLink
                        }
                      >
                        <defaulttext bold="">
                          {comment.author.fullname}
                        </defaulttext>
                      </span>
                    </grid>
                    <grid item="" xs="{12}">
                      {comment.author.title && (
                        <grid item="">
                          <defaulttext lowercase="" style="{{" fontSize:="" 11,="" marginBottom:="" 5,="" color:="" '#888',="" }}="">
                            {comment.author.title}
                          </defaulttext>
                        </grid>
                      )}
                    </grid>
                  </grid>
                </grid>
                <grid item="">
                  <postmenu entries="{menuEntries}"></postmenu>
                </grid>
              </grid>
              <grid item="" xs="{12}">
                {edit && (
                  <formsy onValidSubmit="{model" ==""> {
                      onUpdate(Object.assign({}, comment, model));
                      setEdit(false);
                    }}
                  >
                    <grid container="" spacing="{1}">
                      <grid item="" xs="{12}" className="{classes.commentField}">
                        <textfield name="content" initial="{content}" updateInitial="" multiline="" lowercase="" style="{{" width:="" '100%'="" }}="" onChange="{value" ==""> setContent(value)}
                          placeholder={intl.formatMessage({
                            id: 'newsfeed.comment_placeholder',
                          })}
                        />
                      </textfield></grid>
                      {content && (
                        <grid item="" xs="{12}" container="" spacing="{1}" justifyContent="flex-end">
                          <grid item="">
                            <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}=""></progressbutton>
                          </grid>
                          <grid item="">
                            <button onClick="{()" ==""> setEdit(false)}
                              color='secondary'
                            >
                              {intl.formatMessage({ id: 'common.cancel' })}
                            </button>
                          </grid>
                        </grid>
                      )}
                    </grid>
                  </formsy>
                )}

                {!edit && (
                  <content content="{comment.content}" seeMoreClass="{classes.commentContent}"></content>
                )}
              </grid>
            </grid>
          </div>
        </grid>
      </grid>
    </grid>
  );
};

export default withStyles(styles)(withRouter(PostCommentContent));
