import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

const UseSystemFileRefactor = ({
  fixedCacheKey,
  onFileUpdated,
  onFileDeleted,
  deleteSystemFileMutation,
  updateSystemFileMutation,
}) => {
  const intl = useIntl();

  const [deleteSystemFileMutate] = deleteSystemFileMutation();

  const [updateSystemFileMutate] = updateSystemFileMutation();

  const deleteSystemFile = async imageData => {
    if (!imageData?.uuid) {
      toast.error(
        intl.formatMessage({
          id: 'spider.systemFile.delete.invalidData',
        }),
      );
    }
    try {
      await deleteSystemFileMutate({
        fixedCacheKey,
        imageId: imageData.uuid,
      }).unwrap();
      toast.success(
        intl.formatMessage({
          id: 'spider.systemFile.delete.success',
        }),
      );
      onFileDeleted();
    } catch (e) {
      toast.error(
        intl.formatMessage({
          id: 'spider.systemFile.delete.error',
        }),
      );
      console.error('File deletion error:', e);
    }
  };

  /**
   * Gère la mise à jour d'un fichier unique dans le système
   * @param {File} fileData - Le fichier à télécharger
   * @param {string} code - Le code identifiant du fichier
   * @param {Object} additionalPropsUpdate - Propriétés supplémentaires pour la mise à jour
   */
  const updateSystemFile = async (fileData, code, additionalPropsUpdate) => {
    const file = new FormData();
    file.append('code', code);
    file.append('src', fileData);
    try {
      await updateSystemFileMutate({
        fixedCacheKey,
        file,
        code,
        ...additionalPropsUpdate,
      }).unwrap();
      toast.success(
        intl.formatMessage({
          id: 'spider.systemFile.update.success',
        }),
      );
      onFileUpdated();
    } catch (e) {
      toast.error(
        intl.formatMessage({
          id: 'spider.systemFile.update.error',
        }),
      );
    }
  };

  return { deleteSystemFile, updateSystemFile };
};

export default UseSystemFileRefactor;
