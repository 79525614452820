import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { PointSummary } from '../../../../components';
import {
  Loader,
  ManagerCollaboratorSelector,
} from '../../../../../../components';
import * as Resources from '../../../../../../Resources';
import { useIntl } from 'react-intl';
import * as teamCollaboratorPointSummaryDetailActions from '../../../../../../services/TeamCollaboratorPointSummaries/TeamCollaboratorPointSummaryDetail/actions';

const StoreTeamCollaboratorDepartment = ({
  category,
  name,
  period,
  ...props
}) => {
  const intl = useIntl();
  const { summary, loading } = props.teamCollaboratorPointSummaryDetail;

  function handleCollaboratorClick(id) {
    var url = `/rewards/collaborators/${id}`;
    if (category || period || name) url += '?';
    if (category) url += `category=${category}`;
    if (category && period) url += '&';
    if (period) url += `period=${period}`;
    if ((category || period) && name) url += '&';
    if (name) url += `name=${name}`;
    props.history.push(url);
  }

  function renderLoader() {
    return <loader centered=""></loader>;
  }

  function renderData() {
    return (
      <grid container="" spacing="{4}">
        <grid item="" xs="{12}">
          {loading && renderLoader()}
          {!loading && summary && (
            <pointsummary points="{summary.points}" usedPoints="{summary.usedPoints}" validatedValues="{summary.validatedValues}" waitingPoints="{summary.waitingPoints}"></pointsummary>
          )}
        </grid>
        <grid item="" xs="{12}">
          <grid container="" spacing="{1}">
            <grid item="" xs="{12}">
              <managercollaboratorselector loadDisabled="" onClick="{handleCollaboratorClick}"></managercollaboratorselector>
            </grid>
          </grid>
        </grid>
      </grid>
    );
  }

  return <div>{renderData()}</div>;
};

const mapStateToProps = ({ teamCollaboratorPointSummaryDetail }) => ({
  teamCollaboratorPointSummaryDetail,
});

const mapDispatchToProps = dispatch => ({
  teamCollaboratorPointSummaryDetailActions: bindActionCreators(
    teamCollaboratorPointSummaryDetailActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(StoreTeamCollaboratorDepartment));
