import React, { Component } from 'react';

class MainLayoutComponent extends Component {
  constructor(props) {
    super(props);
    props.clear();
  }
}

export default MainLayoutComponent;
