import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Formsy from 'formsy-react';
import { neutralColors, systemColors } from '../../../../themes';
import { Divider, Typography } from '@material-ui/core';
import { ReactComponent as PointUser } from '../../../../assets/img/points-user.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/img/warning-icon.svg';
import { useIntl } from 'react-intl';
import TextField from '../../../../components/TextField/TextField';
import { Button } from '../../../../components';
import useSessionStorageSliceForm from '../../../../hooks/UseSessionStorageSliceForm';
import {
  clearInformationDataStep,
  setInformationDataStep,
} from '../../../../features/convertPoint/stepperData/slices';
import { useAuth } from '../../../../../auth';
import { ParticipantType } from '../../../../enums/participantType';
import { createTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import useCommandReward from '../../../../hooks/CommandReward/UseCommandReward';
import { Building, ProfileCircle } from 'iconsax-react';
import { Alert } from '../../../../components/Alert';
import { SystemCode } from '../../../../enums/systemCode';
import { PointCommandIds } from '../../../../enums';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  formFields: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [createTheme().breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  block: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    width: '100%',
  },
  blockWrapper: {
    display: 'flex',
    gap: '1.5rem',
    flexDirection: 'column',
    [createTheme().breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  userInformation: {
    display: 'flex',
    padding: '1rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.5rem',
    alignSelf: 'stretch',
    borderRadius: '0.5rem',
    background: neutralColors.neutralColdWhite,
  },
  informationCard: {
    display: 'flex',
    padding: '1rem 1rem 1rem 1rem',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'center',
    borderRadius: '1rem',
    overflow: 'clip',
    position: 'relative',
    backgroundColor: neutralColors.neutralColdWhite,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const InformationStep = ({ nextStep, previousStep, ...props }) => {
  const { hierarchyNodeUser } = useAuth();
  const intl = useIntl();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('md'));

  const phoneMaxLength = 17;
  const cityMaxLength = 29;
  const zipCodeMaxLength = 5;
  const addressMaxLength = 35;
  const maxDate = new Date();

  const stepperData = useSelector(state => state.getStepperDataKey);
  const { informationStep } = useCommandReward(stepperData.commandId);
  const hasDivider =
    !isMobile &&
    hierarchyNodeUser.type === ParticipantType.MORALE &&
    stepperData.commandId.uuid === PointCommandIds.CKU;

  const [isSubmitDisable, setIsValidDisable] = useState(true);

  const { formData, handleFormChange, saveStateData } =
    useSessionStorageSliceForm({
      key: 'informationStep',
      defaultFormData: {
        ...informationStep.defaultForm,
      },
      sliceKey: 'getStepperDataKey',
      sliceProp: 'informationDataStep',
      setClear: clearInformationDataStep,
      sliceSetter: setInformationDataStep,
      isDataSavable: () => true,
    });

  const validInformationStep = () => {
    nextStep();
  };

  return (
    <formsy name="{'information-form'}" onValidSubmit="{validInformationStep}" className="{props.classes.root}" onValid="{()" ==""> {
        setIsValidDisable(false);
        saveStateData(formData, true);
      }}
      onInvalid={() => setIsValidDisable(true)}
    >
      <div className="{props.classes.rootTitle}">
        <pointuser fill="{neutralColors.neutral900}" style="{{" width:="" '1.5rem',="" height:="" '1.5rem'="" }}=""></pointuser>
        <typography variant="{'h3'}" component="{'h3'}">
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.informationStep.title',
          })}
        </typography>
      </div>
      <typography variant="{'body1'}" style="{{" fontStyle:="" 'italic'="" }}="">
        {intl.formatMessage({
          id: 'spider.convertPoints.stepper.informationStep.subtitle',
        })}
      </typography>
      <div className="{props.classes.userInformation}">
        <div style="{{" display:="" 'flex',="" flexDirection:="" 'row',="" gap:="" '0.5rem',="" alignItems:="" 'center',="" }}="">
          {hierarchyNodeUser.type === ParticipantType.PHYSIC ? (
            <profilecircle color="{systemColors.errorRegular}"></profilecircle>
          ) : (
            <building color="{systemColors.errorRegular}"></building>
          )}
          <div style="{{" display:="" 'flex',="" flexDirection:="" 'column'="" }}="">
            <typography variant="{'body1'}" style="{{" fontWeight:="" 'bold'="" }}="">
              {`${hierarchyNodeUser.firstname} ${hierarchyNodeUser.lastname}`}
            </typography>
            <typography variant="{'body2'}">
              {`${hierarchyNodeUser.email} - ${hierarchyNodeUser.first_identifier}`}
            </typography>
          </div>
        </div>
        <typography variant="{'body2'}">
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.informationStep.mailInformation',
          })}
        </typography>
      </div>
      <typography variant="{'h4'}" component="{'h3'}">
        {intl.formatMessage({
          id: 'spider.convertPoints.stepper.informationStep.personalInformation.title',
        })}
      </typography>
      <div className="{props.classes.formFields}">
        {informationStep.showAndValidateBirthDate && (
          <textfield name="{'dateOfBirth'}" value="{formData.dateOfBirth}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.informationStep.personalInformation.dateOfBirthField',="" })}="" variant="{'outlined'}" type="date" required="" InputLabelProps="{{" shrink:="" true,="" }}="" validations="{{" isDateLessThan:="" maxDate,="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.is_date_less_than',="" })="" .format(="" maxDate.toLocaleString('fr-FR',="" {="" day:="" '2-digit',="" month:="" year:="" 'numeric',="" ),="" onChange="{handleFormChange}"></textfield>
        )}
        <textfield name="{'phone'}" value="{formData.phone}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.informationStep.personalInformation.phoneField',="" })}="" variant="{'outlined'}" required="" validations="{{" isPhoneNumber:="" formData.phone,="" maxLength:="" phoneMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" 'common.form.is_phone_number',="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(phoneMaxLength),="" onChange="{handleFormChange}"></textfield>
      </div>
      <div className="{props.classes.blockWrapper}">
        {hierarchyNodeUser.type === ParticipantType.MORALE
          ? informationStep?.address?.PM
          : informationStep?.address?.PP && (
              <div className="{props.classes.block}">
                <typography variant="{'h4'}" component="{'h4'}">
                  {intl.formatMessage({
                    id:
                      hierarchyNodeUser.type === ParticipantType.MORALE
                        ? informationStep.addressTitle.PM
                        : informationStep.addressTitle.PP,
                  })}
                </typography>
                {(hierarchyNodeUser.type === ParticipantType.MORALE
                  ? informationStep?.alert?.PM
                  : informationStep?.alert?.PP) && (
                  <alert type="{" hierarchyNodeUser.type="==" ParticipantType.MORALE="" ?="" informationStep.alert.PM.type="" :="" informationStep.alert.PP.type="" }="" Icon="{()" ==""> <warningicon></warningicon>}
                    title={intl.formatMessage({
                      id:
                        hierarchyNodeUser.type === ParticipantType.MORALE
                          ? informationStep.alert.PM.title
                          : informationStep.alert.PP.title,
                    })}
                    titleVariant={'subtitle1'}
                    titleComponent={'span'}
                    Content={() => (
                      <typography variant="{'body2'}">
                        {intl.formatMessage({
                          id:
                            hierarchyNodeUser.type === ParticipantType.MORALE
                              ? informationStep.alert.PM.content
                              : informationStep.alert.PP.content,
                        })}
                      </typography>
                    )}
                  />
                )}
                <div className="{props.classes.formFields}">
                  <textfield name="{'line1'}" value="{formData.line1}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.informationStep.personalAddress.addressField',="" })}="" variant="{'outlined'}" required="" validations="{{" maxLength:="" addressMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(addressMaxLength),="" onChange="{handleFormChange}"></textfield>
                  <textfield name="{'line2'}" value="{formData.line2}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.informationStep.personalAddress.complementaryAddressField',="" })}="" variant="{'outlined'}" validations="{{" maxLength:="" addressMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(addressMaxLength),="" onChange="{handleFormChange}"></textfield>
                  <textfield name="{'zipCode'}" value="{formData.zipCode}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.informationStep.personalAddress.zipCodeField',="" })}="" variant="{'outlined'}" required="" validations="{{" maxLength:="" zipCodeMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" isInt:="" 'common.form.is_int_error',="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(zipCodeMaxLength),="" onChange="{handleFormChange}"></textfield>
                  <textfield name="{'city'}" value="{formData.city}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.informationStep.personalAddress.cityField',="" })}="" variant="{'outlined'}" required="" validations="{{" maxLength:="" cityMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(cityMaxLength),="" onChange="{handleFormChange}"></textfield>
                </div>
              </alert></div>
            )}
        {hasDivider && <divider orientation="vertical" flexItem=""></divider>}
        {hierarchyNodeUser.type === ParticipantType.MORALE && (
          <div className="{props.classes.block}">
            <typography variant="{'h4'}" component="{'h4'}">
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.informationStep.deliveryAddress.title',
              })}
            </typography>
            <alert type="{" stepperData.commandId.uuid="==" PointCommandIds.CKU="" ?="" SystemCode.INFO="" :="" SystemCode.WARNING="" }="" Icon="{()" ==""> <warningicon></warningicon>}
              title={intl.formatMessage({
                id: 'spider.convertPoints.stepper.informationStep.deliveryAddress.alertTitle',
              })}
              titleVariant={'subtitle1'}
              titleComponent={'span'}
              Content={() => (
                <typography variant="{'body2'}">
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.informationStep.deliveryAddress.alertMessage',
                  })}
                </typography>
              )}
            />
            {hierarchyNodeUser.legal_entity_address && (
              <div className="{props.classes.informationCard}">
                <div style="{{" display:="" 'flex',="" flexDirection:="" 'column',="" gap:="" '0.5rem',="" }}="">
                  {hierarchyNodeUser.legal_entity_address.company_name && (
                    <typography variant="{'body2'}">
                      {hierarchyNodeUser.legal_entity_address.company_name}
                    </typography>
                  )}
                  {hierarchyNodeUser.legal_entity_address.line1 && (
                    <typography variant="{'body2'}">
                      {hierarchyNodeUser.legal_entity_address.line1}
                    </typography>
                  )}
                  {hierarchyNodeUser.legal_entity_address.line2 && (
                    <typography variant="{'body2'}">
                      {hierarchyNodeUser.legal_entity_address.line2}
                    </typography>
                  )}
                  {hierarchyNodeUser.legal_entity_address.city &&
                    hierarchyNodeUser.legal_entity_address.postal_code && (
                      <typography variant="{'body2'}">
                        {`${hierarchyNodeUser.legal_entity_address.postal_code} ${hierarchyNodeUser.legal_entity_address.city}`}
                      </typography>
                    )}
                </div>
              </div>
            )}
          </alert></div>
        )}
      </div>
      <div className="{props.classes.actions}">
        <button color="{'primary'}" variant="{'outlined'}" size="{'large'}" onClick="{previousStep}">
          {intl.formatMessage({ id: 'spider.common.back' })}
        </button>
        <button color="{'primary'}" variant="{'contained'}" size="{'large'}" type="{'submit'}" disabled="{isSubmitDisable}">
          {intl.formatMessage({ id: 'spider.common.submit' })}
        </button>
      </div>
    </formsy>
  );
};

export default withStyles(styles)(InformationStep);
