export const UserRoles = Object.freeze({
  ADMINISTRATOR: 'ADMINISTRATOR',
  SUPER_MANAGER: 'SUPER_MANAGER',
  MANAGER: 'MANAGER',
  COLLABORATOR: 'COLLABORATOR',
});

/**
 *  @typedef {UserRoles[keyof UserRoles]} UserRolesType - Roles for a user, should use the enum in `userRoles.jsxÌ
 * @exports { UserRolesType };
 */
