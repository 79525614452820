import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@material-ui/core';
import { PointSummary } from '../../../../components';
import {
  AdministratorCollaboratorSelector,
  DefaultTitle,
  Loader,
} from '../../../../../../components';
import * as Resources from '../../../../../../Resources';
import { useIntl } from 'react-intl';
import * as collaboratorGlobalPointSummaryDetailActions from '../../../../../../services/CollaboratorGlobalPointSummaries/CollaboratorGlobalPointSummaryDetail/actions';
import * as collaboratorRewardOrderCountActions from '../../../../../../services/CollaboratorRewardOrders/CollaboratorRewardOrderCount/actions';

const CollaboratorRewardManagement = ({
  periodId,
  accountDetail,
  ...props
}) => {
  const intl = useIntl();
  const { summary, loading: collaboratorGlobalPointSummaryDetailLoading } =
    props.collaboratorGlobalPointSummaryDetail;
  const { orders, loading: collaboratorRewardOrderCountLoading } =
    props.collaboratorRewardOrderCount;
  const { account } = accountDetail;
  const loading = collaboratorRewardOrderCountLoading;

  useEffect(() => {
    props.collaboratorGlobalPointSummaryDetailActions.getCollaboratorGlobalPointSummary(
      periodId,
    );
    props.collaboratorRewardOrderCountActions.countWaitingCollaboratorRewardOrders();
  }, [periodId]);

  function renderLoader() {
    return <loader centered=""></loader>;
  }

  function handleCollaboratorClick(collaboratorId) {
    var url = `/rewards/collaborators/${collaboratorId}`;
    if (periodId) url += `?period=${periodId}`;
    props.history.push(url);
  }

  function handleTrackingClick() {
    props.history.push('/rewards/tracking/collaborators');
  }

  function renderData() {
    const handleCollaboratorClick = collaborator => {
      const collaboratorUrl = `/rewards/collaborators/${collaborator.id}`;
      props.history.push(collaboratorUrl);
    };
    return (
      <div>
        <grid container="" spacing="{4}">
          <grid item="" xs="{12}">
            {collaboratorGlobalPointSummaryDetailLoading && renderLoader()}
            {!collaboratorGlobalPointSummaryDetailLoading && summary && (
              <pointsummary points="{summary.points}" usedPoints="{summary.usedPoints}" validatedValues="{summary.validatedValues}" waitingPoints="{summary.waitingPoints}" orders="{orders}" onTrackingClick="{" account.role.code="==" 'A'="" ?="" handleTrackingClick="" :="" null="" }=""></pointsummary>
            )}
          </grid>
          <grid item="" xs="{12}">
            <grid container="" spacing="{1}">
              <grid item="" xs="{12}">
                <administratorcollaboratorselector 1="" contextUrl="teams/" contextUrlOptions="{{" page:="" }}="" onClickCollaborator="{handleCollaboratorClick}"></administratorcollaboratorselector>
              </grid>
            </grid>
          </grid>
        </grid>
      </div>
    );
  }

  return (
    <div>
      {loading && renderLoader()}
      {!loading && orders != null && renderData()}
    </div>
  );
};

const mapStateToProps = ({
  collaboratorGlobalPointSummaryDetail,
  collaboratorRewardOrderCount,
  accountDetail,
}) => ({
  collaboratorGlobalPointSummaryDetail,
  collaboratorRewardOrderCount,
  accountDetail,
});

const mapDispatchToProps = dispatch => ({
  collaboratorGlobalPointSummaryDetailActions: bindActionCreators(
    collaboratorGlobalPointSummaryDetailActions,
    dispatch,
  ),
  collaboratorRewardOrderCountActions: bindActionCreators(
    collaboratorRewardOrderCountActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CollaboratorRewardManagement));
