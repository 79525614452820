import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  MainLayoutComponent,
  TeamSelector,
  AdministratorCollaboratorSelector,
  DefaultTitle,
} from '../../../../components';
import { Grid } from '@material-ui/core';
import * as Resources from '../../../../Resources';
import { injectIntl } from 'react-intl';

class HomeStats extends MainLayoutComponent {
  componentDidMount() {
    const { intl } = this.props;
    this.props.handleTitle(intl.formatMessage({ id: 'statistics.title' }));
  }

  handleClick(id) {
    this.props.history.push(`/stats/teams/${id}/categories`);
  }

  render() {
    const { account } = this.props.accountDetail;
    const { intl } = this.props;

    if (!account.hasStatisticsAccess) {
      return <redirect to="{'/'}"></redirect>;
    }

    if (account.role.code === 'C') {
      return <redirect to="{`/stats/collaborators/${account.id}/categories`}"></redirect>;
    }

    if (account.role.code === 'M' && account.team) {
      return <redirect to="{`/stats/teams/${account.team.id}/categories`}"></redirect>;
    }

    if (account.role.code === 'A' || account.role.code == 'S') {
      return (
        <>
          <teamselector onClick="{this.handleClick.bind(this)}"></teamselector>
        </>
      );
    }

    return <div></div>;
  }
}

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(withRouter(injectIntl(HomeStats)));
