import { all } from 'redux-saga/effects';

import { default as getHistoryImportsSaga } from './historyImports/sagas';
import { default as getCreateImportSaga } from './createImport/sagas';
import { default as getExportLogSaga } from './exportLog/sagas';

export default function* importLogsSaga() {
  yield all([
    getHistoryImportsSaga(),
    getCreateImportSaga(),
    getExportLogSaga(),
  ]);
}
