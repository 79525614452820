import React, { useState, useRef, useEffect } from 'react';
import Formsy from 'formsy-react';
import {
  Spreadsheet,
  Loader,
  DefaultTitle,
  DefaultText,
  ProgressButton,
  Button,
  Avatar,
  TextField,
  InfoText,
  HiddenInput,
  DatePicker,
} from '../../../../../../components';
import { CollaboratorInputSpreadsheet, CollaboratorInputCreateForm } from '../';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { useIntl, injectIntl } from 'react-intl';
import {
  Grid,
  IconButton,
  withWidth,
  isWidthUp,
  CardMedia,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import * as collaboratorInputCreationActions from '../../../../../../services/CollaboratorInput/CollaboratorInputCreation/actions';
import _ from 'lodash';

const styles = {
  spreadsheet: {
    paddingLeft: 0,
    width: '100%',
  },
  userAvatar: {
    width: 30,
    height: 30,
  },
  customImageInput: {
    display: 'none',
  },
  customImage: {
    height: '100%',
    backgroundColor: '#f7f8fc',
    cursor: 'pointer',
    opacity: 0.75,
    '&:hover': {
      opacity: 1,
    },
  },
  image: {
    height: '100%',
    width: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'top',
  },
  link: {
    fontSize: 16,
    cursor: 'pointer',
    color: 'rgb(15,111,222)',
    opacity: 1,
  },
};

const ChallengeKpiCollaboratorUpdate = ({
  data: dataList,
  input,
  width,
  classes,
  close,
  filterDate,
  filterData,
  minDate,
  maxDate,
  kpi,
  image,
  filterInputList,
  ...props
}) => {
  const intl = useIntl();
  const [page, setPage] = useState(input && input.length > 0 ? 0 : 1);
  // default collaborator data
  let data = dataList && dataList.length > 0 ? dataList[0] : null;
  // filter data list by date
  if (dataList) {
    if (dataList.length > 1) {
      data = dataList.filter(d => {
        const collaboratorDataDate = new Date(parseInt(d.dataStart) * 1000);
        const filterDateDate = new Date(filterDate);
        collaboratorDataDate.setHours(0, 0, 0, 0);
        filterDateDate.setHours(0, 0, 0, 0);
        return collaboratorDataDate >= filterDateDate;
      })[0];
    }
  }

  return (
    <div 1="" 600="" style="{{" maxWidth:="" !image="" &&="" page="==" ?="" :="" '100%'="" }}="">
      <grid item="" xs="{12}" style="{{" width:="" 'calc(100%="" -="" 15px)'="" }}="">
        {page === 0 && (
          <grid container="" spacing="{1}">
            <grid item="" xs="{12}">
              <defaulttitle 18="" style="{{" textTransform:="" 'none',="" fontSize:="" }}="">
                {`${intl.formatMessage({
                  id: 'challenge.kpi_results.collaborator_title',
                })} : `}
                <span style="{{" fontWeight:="" 'bold'="" }}="">{_.get(kpi, 'name')}</span>
              </defaulttitle>
            </grid>
            <grid item="" xs="{12}">
              <defaulttext style="{{" textTransform:="" 'none',="" fontSize:="" 14,="" opacity:="" 0.8="" }}="">
                {intl.formatMessage({ id: 'challenge.kpi_results.subtitle' })}
              </defaulttext>
            </grid>
          </grid>
        )}
        {page === 1 && (
          <grid 600="" container="" spacing="{1}" style="{{" maxWidth:="" image="" ?="" 'auto'="" :="" }}="">
            <grid item="" xs="{12}">
              <defaulttitle style="{{" textTransform:="" 'none',="" fontSize:="" 18,="" width:="" '50%'="" }}="">
                {`${intl.formatMessage({
                  id: 'challenge.kpi_results.collaborator_new_title',
                })} : `}
                <span style="{{" fontWeight:="" 'bold'="" }}="">{_.get(kpi, 'name')}</span>
              </defaulttitle>
              <grid item="" xs="{12}">
                <defaulttext style="{{" textTransform:="" 'none',="" fontSize:="" 14,="" opacity:="" 0.8="" }}="">
                  {intl.formatMessage({ id: 'challenge.kpi_results.subtitle' })}
                </defaulttext>
              </grid>
            </grid>
          </grid>
        )}
      </grid>
      <grid 5="" container="" spacing="{2}" style="{{" marginBottom:="" }}="">
        {page === 1 && input && input.length > 0 && (
          <grid item="" onClick="{()" ==""> setPage(0)} className={classes.link}>
            <fontawesomeicon 5="" icon="{faChevronLeft}" style="{{" marginRight:="" }}=""></fontawesomeicon>
            {intl.formatMessage({ id: 'challenge.kpi_results.back_button' })}
          </grid>
        )}
        {page === 0 && (
          <grid item="">
            <button onClick="{()" ==""> setPage(1)}>
              {intl.formatMessage({ id: 'challenge.kpi_results.add_data' })}
            </button>
          </grid>
        )}
      </grid>
      {page === 0 && (
        <collaboratorinputspreadsheet data="{input}" close="{close}" filterInputList="{filterInputList}" isReadOnly="{!_.get(kpi," 'custom')}="" hideFilters=""></collaboratorinputspreadsheet>
      )}
      {page === 1 && (
        <collaboratorinputcreateform data="{data}" kpi="{kpi}" filterDate="{filterDate}" filterData="{filterData}" minDate="{minDate}" maxDate="{maxDate}" onSuccess="{()" ==""> {
            toast.success(
              intl.formatMessage({ id: 'common.update_success_message' }),
            );
            close();
          }}
          onError={() => {
            props.collaboratorInputCreationActions.clearCollaboratorInputCreation();
            toast.error(
              intl.formatMessage({ id: 'common.update_error_message' }),
            );
          }}
          image={image}
        />
      )}
    </collaboratorinputcreateform></div>
  );
};

const mapStateToProps = ({ collaboratorInputCreation }) => ({
  collaboratorInputCreation,
});

const mapDispatchToProps = dispatch => ({
  collaboratorInputCreationActions: bindActionCreators(
    collaboratorInputCreationActions,
    dispatch,
  ),
});

export default withWidth()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(ChallengeKpiCollaboratorUpdate)),
);
