import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allHierarchyNodesFetchingStart } from '../features/base/allPagesFetchingFromApi/slices';
import { HierarchyNodeTypes } from '../enums';

export const useAllContracts = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(
    state => state.allHierarchyNodesFetching,
  );

  useEffect(() => {
    if (!data?.length) {
      dispatch(allHierarchyNodesFetchingStart([HierarchyNodeTypes.CONTRACT]));
    }
  }, [dispatch]);

  return { data, loading };
};
