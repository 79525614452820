import React from 'react';
import { connect } from 'react-redux';
import { CardMedia, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CollaboratorList, LevelCondition, Points } from './components';
import {
  AccentText,
  DefaultText,
  DefaultTitle,
  InfoText,
  ProgressBar,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import { useIntl } from 'react-intl';
import '../../../../helpers/NumberHelper';
import '../../../../helpers/StringHelper';
import _ from 'lodash';

const styles = {
  icon: {
    height: 45,
    width: 45,
    overflow: 'hidden',
    borderRadius: 25,
  },
  bigIcon: {
    height: 67,
    width: 67,
    overflow: 'hidden',
    borderRadius: 40,
  },
  categoryIcon: {
    height: 30,
    width: 30,
    border: '2px solid white',
  },
  wrapper: {
    filter: 'grayscale(1)',
    opacity: 0.3,
  },
};

const BadgeLevel = ({ level, ...props }) => {
  const intl = useIntl();
  const { classes } = props;
  const { collaborator } = props.collaboratorDetail;

  const iconData = level.code
    ? require(`../../../../assets/img/system/badge/icons/${level.code}.svg`)
    : _.get(level, 'icon.path');

  const counter = level.counter <= level.target ? level.counter : level.target;
  const progression = (counter / level.target).toFullPercentage();
  const hasLevel = collaborator
    ? level.level <= collaborator.level.number
    : false;

  return (
    <div style="{{" position:="" 'relative'="" }}="">
      {!hasLevel && (
        <div style="{{" position:="" 'absolute',="" left:="" '50%',="" top:="" marginLeft:="" -95,="" marginTop:="" -15,="" }}="">
          <levelcondition level="{level}"></levelcondition>
        </div>
      )}
      <div className="{!hasLevel" ?="" classes.wrapper="" :="" ''}="">
        <grid container="" spacing="{2}">
          <grid item="" container="" xs="{12}" spacing="{2}">
            <grid item="">
              <cardmedia image="{iconData}" className="{classes.icon}"></cardmedia>
            </grid>
            <grid item="" xs="" zeroMinWidth="">
              <defaulttitle 16="" lowercase="" noWrap="" style="{{" fontWeight:="" 'bold',="" fontSize:="" }}="">
                {level.publicTitle}
              </defaulttitle>
              <infotext lowercase="">
                {intl
                  .formatMessage({ id: 'badge.level.rank_text' })
                  .format(level.rank)}
              </infotext>
            </grid>
            <grid item="">
              <points level="{level}"></points>
            </grid>
          </grid>
          <grid item="">
            <defaulttext lowercase="">{level.privateTitle}</defaulttext>
          </grid>
          <grid item="" container="" xs="{12}" spacing="{1}">
            <grid item="" container="" xs="{12}">
              <grid item="" xs="">
                <defaulttext lowercase="">
                  {intl
                    .formatMessage({ id: 'badge.level.counter_text' })
                    .format(counter)}{' '}
                  <infotext lowercase="" component="span">
                    /{' '}
                    {intl
                      .formatMessage({ id: 'badge.level.target_text' })
                      .format(level.target)}
                  </infotext>
                </defaulttext>
              </grid>
              <grid item="">
                <accenttext 14="" style="{{" fontWeight:="" 'bold',="" fontSize:="" }}="">
                  {progression} %
                </accenttext>
              </grid>
            </grid>
            <grid item="" xs="{12}">
              <progressbar value="{progression}" gradient="" animate=""></progressbar>
            </grid>
          </grid>
          <grid item="" xs="{12}">
            <grid container="" justify="space-between" alignItems="center">
              <grid 30="" item="" style="{{" minHeight:="" }}="">
                <collaboratorlist collaborators="{level.collaborators}"></collaboratorlist>
              </grid>

              <grid 30="" item="" style="{{" minHeight:="" }}="">
                {level.category && (
                  <grid container="" spacing="{1}" alignItems="center">
                    <grid item="">
                      <cardmedia image="{_.get(level," 'category.icon.path')}="" className="{classes.categoryIcon}"></cardmedia>
                    </grid>
                    <grid item="">
                      <defaulttext 16="" lowercase="" style="{{" fontSize:="" }}="">
                        {_.get(level, 'category.name')}
                      </defaulttext>
                    </grid>
                  </grid>
                )}
              </grid>
            </grid>
          </grid>
        </grid>
      </div>
    </div>
  );
};

const mapStateToProps = ({ collaboratorDetail }) => ({
  collaboratorDetail,
});

export default connect(mapStateToProps)(withStyles(styles)(BadgeLevel));
