export const GET_COLLABORATOR_CHALLENGE_RANK_LIST_BY_COLLABORATOR_CHALLENGE =
  'GET_COLLABORATOR_CHALLENGE_RANK_LIST_BY_COLLABORATOR_CHALLENGE';
export const GET_COLLABORATOR_CHALLENGE_RANK_LIST_BY_TEAM_COLLABORATOR_CHALLENGE =
  'GET_COLLABORATOR_CHALLENGE_RANK_LIST_BY_TEAM_COLLABORATOR_CHALLENGE';
export const GET_COLLABORATOR_CHALLENGE_RANK_LIST_BY_TEAM_GROUP_COLLABORATOR_CHALLENGE =
  'GET_COLLABORATOR_CHALLENGE_RANK_LIST_BY_TEAM_GROUP_COLLABORATOR_CHALLENGE';
export const GET_COLLABORATOR_CHALLENGE_RANK_LIST_SUCCESS =
  'GET_COLLABORATOR_CHALLENGE_RANK_LIST_SUCCESS';
export const GET_COLLABORATOR_CHALLENGE_RANK_LIST_ERROR =
  'GET_COLLABORATOR_CHALLENGE_RANK_LIST_ERROR';
export const GET_COLLABORATOR_CHALLENGE_RANK_LIST_CLEAR =
  'GET_COLLABORATOR_CHALLENGE_RANK_LIST_CLEAR';
