import React, { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';

const IntercomWidgetLogin = ({ appId }) => {
  useEffect(() => {
    const intercomSettings = {
      app_id: process.env.REACT_APP_INTERCOM_ID,
    };
    Intercom(intercomSettings);

    return () => {
      Intercom('shutdown');
    };
  }, [appId]);

  return null;
};

export default IntercomWidgetLogin;
