const themeColors = {
  primaryContrastText: '#FFFFFF',
  primaryDark: '#384052',
  primaryLight: '#64748B',
  primaryRegular: '#0F172A',
  secondaryBg: '#F0F6FF',
  secondaryLight: '#298EFA',
  secondaryLighter: '#CCE2FE',
  secondaryRegular: '#0768F4',
};

export default themeColors;
