export const GET_COLLABORATOR_GOAL_LIST_BY_TEAM_COLLABORATOR_GOAL =
  'GET_COLLABORATOR_GOAL_LIST_BY_TEAM_COLLABORATOR_GOAL';
export const GET_COLLABORATOR_GOAL_LIST_SUCCESS =
  'GET_COLLABORATOR_GOAL_LIST_SUCCESS';
export const GET_COLLABORATOR_GOAL_LIST_ERROR =
  'GET_COLLABORATOR_GOAL_LIST_ERROR';

export const GET_COLLABORATOR_GOAL_LIST_BY_GOAL =
  'GET_COLLABORATOR_GOAL_LIST_BY_GOAL';
export const GET_COLLABORATOR_GOAL_LIST_BY_GOAL_SUCCESS =
  'GET_COLLABORATOR_GOAL_LIST_BY_GOAL_SUCCESS';
export const GET_COLLABORATOR_GOAL_LIST_BY_GOAL_ERROR =
  'GET_COLLABORATOR_GOAL_LIST_BY_GOAL_ERROR';
