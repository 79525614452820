import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Tag } from './components';
import { DefaultTitle, ErrorText, InfoText, Avatar } from '../../..';
import * as Resources from '../../../../Resources';
import { useIntl } from 'react-intl';
import '../../../../helpers/StringHelper';
import _ from 'lodash';

const styles = {
  root: {
    padding: 16,
  },
  tag: {
    borderRadius: 5,
  },
};

const TeamSimple = ({ team, classes, ...props }) => {
  const intl = useIntl();
  const players = team.collaborators.length;
  const managerPhoto =
    team.manager && team.manager.photo
      ? team.manager.photo
      : '/assets/img/user/avatar.svg';

  return (
    <div>
      <grid container="" spacing="{2}" style="{{" textAlign:="" 'left'="" }}="">
        <grid item="">
          <avatar src="{managerPhoto}" entityId="{_.get(team," 'manager.id')}="" fallbackName="{_.get(team," 'manager.fullname')}=""></avatar>
        </grid>
        <grid item="" xs="" zeroMinWidth="">
          <div>
            <grid container="">
              <grid item="" xs="{12}" zeroMinWidth="">
                <defaulttitle noWrap="" style="{{" fontWeight:="" 'bold',="" textTransform:="" 'none',="" fontSize:="" 16,="" }}="">
                  {team.name}
                </defaulttitle>
              </grid>
              <grid item="" xs="{12}" zeroMinWidth="">
                {team.manager && (
                  <infotext 14="" style="{{" textTransform:="" 'none',="" fontSize:="" }}="" noWrap="">
                    {intl
                      .formatMessage({ id: 'team.detail.manager_text' })
                      .format(team.manager.firstname, team.manager.lastname)}
                  </infotext>
                )}
                {!team.manager && (
                  <errortext 14="" style="{{" textTransform:="" 'none',="" fontSize:="" }}="" noWrap="">
                    {intl.formatMessage({ id: 'team.detail.no_manager_text' })}
                  </errortext>
                )}
              </grid>
            </grid>
          </div>
        </grid>
        <grid item="">
          <tag className="{classes.tag}" color="{team.color.hex}">
            {intl
              .formatMessage({ id: 'team.detail.collaborators_text' })
              .format(players)}
          </tag>
        </grid>
      </grid>
    </div>
  );
};

export default withStyles(styles)(TeamSimple);
