import React, { useState } from 'react';
import { Grid, CardMedia, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { AvatarGroup } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import {
  faFireAlt,
  faLock,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { ChallengeImage, ChallengeType, ChallengeReward } from '..';
import {
  DefaultText,
  InfoText,
  TimerTag,
  BoldTitle,
  Avatar,
  Card,
  AccentText,
  Tooltip,
} from '../../../../components';
import { useIntl } from 'react-intl';
import _ from 'lodash';

const styles = theme => {
  return {
    challengeImage: {
      height: '600px',
    },
    imageContainer: {
      position: 'relative',
    },
    timerContainer: {
      position: 'absolute',
      right: 0,
      top: 16,
    },
    avatarGroup: {
      marginLeft: '-2px',
      flexWrap: 'wrap',

      height: 35,
      overflow: 'hidden',
    },
    avatar: {
      width: 35,
      height: 35,
    },
    bigText: {
      fontSize: 18,
    },
    smallText: {
      fontSize: 15,
    },
    challengeType: {
      lineHeight: 35,
      verticalAlign: 'center',
      whiteSpace: 'nowrap',
    },
    tooltip: {
      minWidth: 320,
    },
    transparentTooltip: {
      background: 'transparent',
    },
    accentText: {
      position: 'absolute',
      top: -5,
      left: 15,
      color: theme.palette.primary.main,
      zIndex: 100,
    },
    circularProgress: {
      width: 20,
      height: 20,
      marginLeft: 10,
      color: theme.palette.primary.main,
      marginBottom: -5,
    },
  };
};

const Challenge = ({
  challenge,
  scoreByTeam,
  fetchWonAwards,
  fetchCurrentRank,
  fetchGoalPoints,
  fetchTopParticipants,
  ...props
}) => {
  const intl = useIntl();
  const { classes, configs } = props;
  const { account } = props.accountDetail;
  const [wonAwards, setWonAwards] = useState(
    fetchWonAwards ? null : challenge.wonAwards,
  );
  const [rank, setRank] = useState(fetchCurrentRank ? null : challenge.rank);
  const [wonAwardsLoading, setWonAwardsLoading] = useState(false);
  const [rankLoading, setRankLoading] = useState(false);
  const [initialized, setInitialized] = useState();
  const [goalPoints, setGoalPoints] = useState();
  const [topParticipants, setTopParticipants] = useState();
  // const [participants, setParticipants] = useState();

  const allowRank = account.hasChallengeRankAccess;

  if (!initialized) {
    setInitialized(true);
    if (fetchWonAwards) {
      setWonAwardsLoading(true);
      fetchWonAwards(_.get(challenge, 'id'))
        .then(results => {
          setWonAwardsLoading(false);
          setWonAwards(results.data);
        })
        .catch(() => {
          setWonAwardsLoading(false);
        });
    }
    if (fetchCurrentRank) {
      setRankLoading(true);
      fetchCurrentRank(_.get(challenge, 'id'))
        .then(results => {
          setRankLoading(false);
          setRank(results.data);
        })
        .catch(() => {
          setRankLoading(false);
        });
    }
    if (fetchGoalPoints) {
      fetchGoalPoints(_.get(challenge, 'sourceId')).then(results => {
        setGoalPoints(results.data);
      });
    }
    if (fetchTopParticipants) {
      fetchTopParticipants(_.get(challenge, 'sourceId')).then(results => {
        setTopParticipants(results.data);
      });
    }
  }

  const hasParticipants = !_.isEmpty(topParticipants);

  const coinImage = require(
    `../../../../assets/img/system/challenge/icons/coin.png`,
  );
  const giftImage = require(
    `../../../../assets/img/system/challenge/icons/gift.png`,
  );

  const displayTitle =
    configs &&
    _.get(
      configs.find(c => c.code === 'CTTA'),
      'value',
      'false',
    ).toBoolean();
  const displayRank = rank && allowRank;

  const challengeTypeInfos = {
    CT: {
      rank: (
        <span>
          <span style="{{" fontWeight:="" 'bold'="" }}="">{challenge.participants}</span>{' '}
          {intl.formatMessage({ id: 'challenge.teams' }).format('')}
        </span>
      ),
    },
    TP: {
      rank: (
        <span>
          <span style="{{" fontWeight:="" 'bold'="" }}="">{challenge.participants}</span>{' '}
          {intl.formatMessage({ id: 'challenge.teams' }).format('')}
        </span>
      ),
    },
    CC: {
      rank: (
        <span>
          <span>
            {challenge.totalParticipants &&
            challenge.participants !== challenge.totalParticipants ? (
              <span>
                <span style="{{" fontWeight:="" 'bold'="" }}="">
                  {challenge.participants}
                </span>{' '}
                / {challenge.totalParticipants}
              </span>
            ) : (
              <span style="{{" fontWeight:="" 'bold'="" }}="">
                {challenge.participants}
              </span>
            )}
          </span>
          {intl.formatMessage({ id: 'challenge.collaborators' }).format('')}
        </span>
      ),
    },
    TG: {
      rank: (
        <span>
          <span style="{{" fontWeight:="" 'bold'="" }}="">{challenge.participants}</span>{' '}
          {intl.formatMessage({ id: 'challenge.team_groups' }).format('')}
        </span>
      ),
    },
  };
  const isTeamGroupChallenge = challenge.typeCode === 'TG';
  const participantTypeIsManager = challenge.participant_type === 'MANAGER'

  return (
    <div>
      <grid container="" spacing="{2}">
        <grid item="" xs="{12}">
          <div className="{classes.imageContainer}">
            <div className="{classes.timerContainer}">
              <timertag date="{challenge.end}"></timertag>
            </div>
            <challengeimage image="{challenge.custom_image" ||="" challenge.image}="" style="{{" height:="" hasParticipants="" ?="" ''="" :="" '189px'="" }}=""></challengeimage>
          </div>
        </grid>
        <grid item="" style="{{" width:="" '100%'="" }}="">
          <grid container="" spacing="{1}" direction="column">
            {displayTitle && (
              <grid item="">
                <boldtitle 1="" lowercase="" style="{{" lineHeight:="" }}="">
                  {challenge.name}
                </boldtitle>
              </grid>
            )}
            {!isTeamGroupChallenge && (
              <grid item="" style="{{" maxHeight:="" '27px'="" }}="">
                <grid container="" spacing="{2}" style="{{" alignItems:="" 'baseline'="" }}="" direction="row">
                  <grid item="">
                    <defaulttext lowercase="" className="{classes.smallText}">
                      {!rankLoading && displayRank && (
                        <div>
                          <span 15="" style="{{" fontWeight:="" 'bold',="" fontSize:="" }}="">
                            {rank == 1
                              ? intl
                                  .formatMessage({ id: 'challenge.first_rank' })
                                  .format(rank)
                              : intl
                                  .formatMessage({ id: 'challenge.other_rank' })
                                  .format(rank)}
                          </span>
                          <infotext component="span" className="{classes.smallText}">
                            {' '}
                            / {challenge.participants}
                          </infotext>
                        </div>
                      )}
                      {!rankLoading && !displayRank && (
                        <div>
                          &nbsp;
                          {challengeTypeInfos[challenge.typeCode].rank}
                        </div>
                      )}
                      {rankLoading && allowRank && (
                        <span>
                          <circularprogress className="{classes.circularProgress}"></circularprogress>
                        </span>
                      )}
                    </defaulttext>
                  </grid>
                  {/* enable_manager_score is undefined for collaborator challenges */}
                  {challenge.enable_manager_score !== false && (
                    <grid item="">
                      <defaulttext lowercase="" className="{classes.smallText}">
                        &nbsp;
                        <span style="{{" fontWeight:="" 'bold'="" }}="">
                          {intl
                            .formatMessage({ id: 'challenge.points' })
                            .format('')}
                        </span>
                        {goalPoints && parseFloat(goalPoints).toLocaleString()}
                      </defaulttext>
                    </grid>
                  )}
                  {wonAwardsLoading && (
                    <grid item="">
                      <circularprogress className="{classes.circularProgress}"></circularprogress>
                    </grid>
                  )}
                  {wonAwards && (
                    <grid item="">
                      <grid container="" spacing="{1}">
                        <grid item="">
                          <defaulttext lowercase="" style="{{" fontSize:="" 15,="" fontWeight:="" 'bold'="" }}="">
                            {intl.formatMessage({
                              id: 'challenge.awards_title',
                            })}{' '}
                            :
                          </defaulttext>
                        </grid>
                        {wonAwards.length === 0 && (
                          <grid item="" style="{{" position:="" 'relative'="" }}="">
                            <tooltip 13="" title="{" <span="" style="{{" fontSize:="" }}="">
                                  {intl.formatMessage({
                                    id: 'challenge.no_awards_title',
                                  })}
                                
                              }
                            >
                              <div>
                                <accenttext lowercase="" className="{classes.accentText}">
                                  <fontawesomeicon icon="{faLock}"></fontawesomeicon>
                                </accenttext>
                                <div style="{{" filter:="" 'grayscale(1)'="" }}="">
                                  {_.get(challenge, 'rewardTypeCode') ===
                                  'G' ? (
                                    <cardmedia image="{giftImage}" style="{{" height:="" 18,="" width:="" marginRight:="" 5,="" }}=""></cardmedia>
                                  ) : (
                                    <cardmedia image="{coinImage}" style="{{" height:="" 18,="" width:="" marginRight:="" 5,="" }}=""></cardmedia>
                                  )}
                                </div>
                              </div>
                            </tooltip>
                          </grid>
                        )}
                        {wonAwards.length > 0 && (
                          <react.fragment>
                            {wonAwards[0].reward ? (
                              <grid item="">
                                <tooltip 320="" className="{`${classes.tooltip}" ${classes.transparentTooltip}`}="" title="{" <div="" style="{{" minWidth:="" }}="">
                                      <card>
                                        <challengereward reward="{wonAwards[0].reward}"></challengereward>
                                      </card>
                                    </tooltip></grid></react.fragment></grid></grid></grid></grid></grid></grid></grid></div>
                                  }
                                >
                                  <cardmedia image="{giftImage}" style="{{" height:="" 18,="" width:="" marginRight:="" 5,="" }}=""></cardmedia>
                                
                              
                            ) : (
                              <grid item="">
                                <tooltip 13="" title="{" <Grid="" container="" spacing="{1}" style="{{" fontSize:="" }}="">
                                      <grid item="">{wonAwards[0].points}</grid>
                                      <grid item="">
                                        <cardmedia image="{coinImage}" style="{{" height:="" 15,="" width:="" marginTop:="" -1,="" }}=""></cardmedia>
                                      </grid>
                                    </tooltip></grid>
                                  }
                                >
                                  <cardmedia image="{coinImage}" style="{{" height:="" 18,="" width:="" marginRight:="" 5,="" }}=""></cardmedia>
                                
                              
                            )}
                          
                        )}
                      
                    
                  )}
                  {!hasParticipants && (
                    <grid item="" style="{{" marginLeft:="" 'auto'="" }}="">
                      {participantTypeIsManager ? (
                        <fontawesomeicon icon="{faUserTie}"></fontawesomeicon>
                      ) : (
                        <defaulttext className="{classes.smallText}">
                          <challengetype type="{challenge.typeCode}"></challengetype>
                        </defaulttext>
                      )}
                    </grid>
                  )}
                
              
            )}
            <grid item="">
              <grid container="" spacing="{1}" direction="row" style="{{" flexWrap:="" 'noWrap'="" }}="">
                {hasParticipants && allowRank && (
                  <react.fragment>
                    <grid item="" style="{{" width:="" '100%'="" }}="">
                      <avatargroup className="{classes.avatarGroup}" max="{15}">
                        {topParticipants.map((participant, index) => (
                          <avatar 1="" key="{index}" src="{_.get(participant," 'collaborator.photo')}="" entityId="{_.get(participant," 'collaborator.id')}="" className="{classes.avatar}" fallbackName="{" _.get(participant,="" 'collaborator.photo')="" ?="" ''="" :="" 'collaborator.fullname')="" ||="" 'collaborator.rank')="" index="" +="" }="" backgroundColor="{" challenge.typeCode="==" 'CT'="" 'TG'="" 'white'="" null="" color="{" '#555'="" borderColor="{" 'team.color.hex')="" tooltip="{" 'team.name')="" 'team_group.name')=""></avatar>
                        ))}
                      </avatargroup>
                    </grid>
                    <grid item="" className="{classes.challengeType}">
                      <defaulttext align="right" style="{{" display:="" 'flex',="" lineHeight:="" '2.5'="" }}="">
                        <span style="{{" marginRight:="" '2px'="" }}="">
                          {participantTypeIsManager ? (
                            <fontawesomeicon icon="{faUserTie}"></fontawesomeicon>
                          ) : (
                            <challengetype type="{challenge.typeCode}"></challengetype>
                          )}
                        </span>
                        {participantTypeIsManager ? (
                          <span>
                            {intl.formatMessage({
                              id: `challenge.types.manager`,
                            })}
                          </span>
                        ) : (
                          <span>
                            {intl.formatMessage({
                              id: `challenge.types.${challenge.typeCode}`,
                            })}
                          </span>
                        )}
                      </defaulttext>
                    </grid>
                  </react.fragment>
                )}
              </grid>
            </grid>
          
        
      
    