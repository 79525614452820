import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import Sagas from '../services/Sagas';
import local from '../data/local/local';
import { baseApi } from './api-query';

const sagaMiddleware = createSagaMiddleware();

// Simple persist middleware
const persistMiddleware = store => next => action => {
  const result = next(action);
  const stateToPersist = {
    accountDetail: store.getState().accountDetail,
    activeHierarchyNodeUser: store.getState().activeHierarchyNodeUser,
  };
  localStorage.setItem('reduxState', JSON.stringify(stateToPersist));
  return result;
};

const loadPersistedState = () => {
  try {
    const serializedState = localStorage.getItem('reduxState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch {
    return console.warn('Unable to load persisted state');
  }
};

const clearPersistedState = () => {
  localStorage.removeItem('reduxState');
};

export const configureAppStore = () => {
  const currentVersion = '0.9.3';
  const lastVersion = local.getVersion();

  const preloadedState = loadPersistedState();

  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware => [
      sagaMiddleware,
      ...getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
      persistMiddleware,
      baseApi.middleware,
    ],
    devTools: process.env.NODE_ENV !== 'production',
  });

  if (currentVersion !== lastVersion) {
    clearPersistedState();
    local.setVersion(currentVersion);
  }

  sagaMiddleware.run(Sagas);

  return store;
};
