import React from 'react';
import { Box, withStyles, Typography, Link } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { PointTransaction } from '../../../features/lists/points/types';

const styles = {};

/**
 *
 * @param {{transaction: PointTransaction}} props
 */
const Component = ({ transaction }) => {
  const intl = useIntl();
  const { sub_type, source } = transaction;
  switch (sub_type) {
    case 'EXPIRATION':
      return '-';
    case 'LOAD':
      return (
        <box>
          <typography>
            {intl.formatMessage({ id: 'spider.common.loading' })}
          </typography>
        </box>
      );
    case 'ORDER':
      return (
        <box>
          <typography>Commande</typography>
          {Boolean(source?.name) && (
            <link component="{RouterLink}" to="/">
              <typography variant="subtitle1" style="{{" fontSize:="" '0.75rem',="" whiteSpace:="" 'nowrap'="" }}="">
                <span>Ref </span>
                <span>{source.name}</span>
              </typography>
            
          )}
        </box>
      );
    case 'ORDER_CANCELLATION':
      return (
        <box>
          <typography>Annulation de commande</typography>
          {Boolean(source?.name) && (
            <link component="{RouterLink}" to="/">
              <typography variant="subtitle1" style="{{" fontSize:="" '0.75rem',="" whiteSpace:="" 'nowrap'="" }}="">
                <span>Ref </span>
                <span>{source.name}</span>
              </typography>
            
          )}
        </box>
      );
    case 'WITHDRAWAL':
      return (
        <box>
          <typography>Retrait</typography>
        </box>
      );
    default:
      return <typography>{sub_type}</typography>;
  }
};

const SubType = withStyles(styles)(Component);
export { SubType };
