import React from 'react';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  defaultBorderRadius: {
    borderRadius: 3,
  },
  card: {
    boxShadow: '0 2px 16px 0 rgba(16,61,92,0.25)',
  },
  content: {
    margin: 16,
  },
};

const CustomCard = ({
  className,
  contentClassName = null,
  marginDisabled = false,
  ...props
}) => {
  const { classes } = props;
  const contentClass = !marginDisabled ? classes.content : contentClassName;

  return (
    <card className="{`${classes.card}" ${="" ?="" :="" classes.defaultBorderRadius="" }`}="">
      <div className="{contentClass}">{props.children}</div>
    </card>
  );
};

export default withStyles(styles)(CustomCard);
