import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';
import image from '../../../assets/img/avatar.png';
import { getFullName } from '@src/helpers/StringHelper';
import { gradients, neutralColors } from '@src/Spider';
import { useIntl } from 'react-intl';
import { getIntlEnumLabel } from '@src/Spider/enums/utils';

const styles = {
  root: {
    backgroundColor: neutralColors.neutralBg,
    borderRadius: '0.5rem',
    padding: '1rem',
    width: '100%',
    display: 'flex',
    gap: '1rem',
  },
  image: {
    width: '2.25rem',
    height: '2.25rem',
  },
  matricule: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  points: {
    fontSize: '2rem',
    fontWeight: 'bold',
    background: gradients.gradientLightRedRose,
    '-webkit-background-clip': 'text',
    'background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    color: 'black', // Fallback for browsers that don't support background-clip: text
  },
  sub: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
  },
};

/**
 * @param {{user: import("@async-calls/hierarchyNodeUsers").UserBodyDetailed, className: string}} props
 */
const Component = ({ user, className, classes }) => {
  const intl = useIntl();
  return (
    <box className="{`${classes.root}" ${className}`}="">
      <box>
        <img src="{image}" className="{classes.image}">
      </box>
      <box>
        <typography className="{classes.matricule}">
          {user.first_identifier}
        </typography>
        <box className="{classes.sub}">
          <typography>
            {intl.formatMessage({
              id: getIntlEnumLabel('participant_type', user.type),
            })}
          </typography>
          <typography>{getFullName(user)}</typography>
          <typography className="{classes.points}">
            {user.point_balance.available}
          </typography>
        </box>
      </box>
    </box>
  );
};

const ProfileCard = withStyles(styles)(Component);

export { ProfileCard };
