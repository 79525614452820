import React from 'react';
import { DialogContent, Drawer, Paper, Typography } from '@material-ui/core';
import { DialogIndicator } from '../../../../../../components/DialogIndicator';
import { createTheme } from '@material-ui/core/styles';
import { Button } from '../../../../../../components';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { neutralColors } from '../../../../../../themes';

const styles = {
  dialogTitle: {
    display: 'flex !important',
    flexDirection: 'column !important',
    padding: '1rem !important',
    width: '100%',
  },
  closeTitleDialog: {
    display: 'flex !important',
    width: '100%',
    flexDirection: 'row-reverse !important',
    padding: '1rem !important',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '1rem',
    overflow: 'auto',
    backgroundColor: neutralColors.neutralWhite,
    [createTheme().breakpoints.up('md')]: {
      backgroundColor: neutralColors.neutralBg,
      padding: '0rem 2rem 2rem 2rem !important',
    },
  },
  dialogPaperContent: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '1rem',
    marginRight: 'auto',
    marginLeft: 'auto',
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
    gap: '1rem',
    [createTheme().breakpoints.up('md')]: {
      gap: '2rem',
      padding: '1rem',
      width: '75%',
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      borderTopLeftRadius: '0.75rem',
      borderTopRightRadius: '0.75rem',
      border: 'none',
      height: '90vh',
      [createTheme().breakpoints.up('sm')]: {
        height: '100vh',
        border: '4px solid',
        borderBottom: 'none',
        borderColor: neutralColors.neutral300,
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    minHeight: '48px',
    [createTheme().breakpoints.down('sm')]: {
      minHeight: '6px',
    },
  },
  activities: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
  },
  activity: {
    display: 'flex',
    paddingBottom: '1rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1rem',
    overflow: 'clip',
    borderRadius: '1rem',
    backgroundColor: neutralColors.neutralColdWhite,
  },
};

const ThemeContentDialog = ({
  theme,
  open,
  setOpen,
  onConvert,
  disableConvert,
  ...props
}) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('sm'));

  const onClose = () => {
    setOpen(false);
  };

  return (
    <drawer anchor="bottom" open="{open}" onClose="{onClose}" className="{props.classes.drawer}">
      <dialogcontent className="{props.classes.dialogContent}">
        <div className="{props.classes.header}" onClick="{onClose}">
          <dialogindicator></dialogindicator>
        </div>
        {!isMobile && (
          <div className="{props.classes.closeTitleDialog}">
            <button variant="{'contained'}" color="{'primary'}" size="{'small'}" className="{'icon" light'}="" onClick="{onClose}">
              <closeicon></closeicon>
            </button>
          </div>
        )}
        <div className="{props.classes.dialogTitle}">
          <typography variant="{'h1'}" component="{'h1'}" className="{'title" underline-center'}="">
            {intl.formatMessage({ id: theme.title })}
          </typography>
        </div>
        <paper className="{props.classes.dialogPaperContent}">
          <div style="{{" display:="" 'flex',="" flexDirection:="" 'column',="" gap:="" '1rem'="" }}="">
            {theme.introduction && (
              <typography variant="{'subtitle1'}">
                {intl.formatMessage({ id: theme.introduction.line1 })}
                <br>
                {intl.formatMessage({ id: theme.introduction.line2 })}
                <br>
                {intl.formatMessage({ id: theme.introduction.line3 })}
                <br>
                {intl.formatMessage({ id: theme.introduction.line4 })}
              </typography>
            )}

            {isMobile && (
              <div>
                <button color="{'primary'}" variant="{'contained'}" size="{'large'}" onClick="{onConvert}" disabled="{disableConvert}">
                  {intl.formatMessage({
                    id: 'spider.convertPoints.home.rewardsMain.convertAction',
                  })}
                </button>
              </div>
            )}
          </div>

          {theme.items && theme.items.length > 0 && (
            <div className="{props.classes.activities}">
              {theme.items.map((item, index) => (
                <div key="{index}" className="{props.classes.activity}">
                  <img alt="{'test'}" src="{item.src}" style="{{" height:="" '11rem',="" objectFit:="" 'cover'="" }}="">
                  <div style="{{" display:="" 'flex',="" flexDirection:="" 'column',="" gap:="" '0.25rem',="" padding:="" '0rem="" 1rem',="" alignItems:="" 'flex-start',="" }}="">
                    <typography variant="{'h4'}" component="{'h4'}">
                      {intl.formatMessage({ id: item.title })}
                    </typography>
                    <typography variant="{'body2'}">
                      {intl.formatMessage({ id: item.description })}
                    </typography>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div>
            <typography variant="{'subtitle1'}" style="{{" fontWeight:="" 'bold'="" }}="">
              {intl.formatMessage({
                id: 'spider.convertPoints.home.themes.concludeMessage.line1',
              })}
            </typography>
            <typography variant="{'subtitle1'}">
              {intl.formatMessage({
                id: 'spider.convertPoints.home.themes.concludeMessage.line2',
              })}
            </typography>
          </div>
        </paper>

        {!isMobile && (
          <div>
            <button color="{'primary'}" variant="{'contained'}" size="{'large'}" onClick="{onConvert}" disabled="{disableConvert}">
              {intl.formatMessage({
                id: 'spider.convertPoints.home.rewardsMain.convertAction',
              })}
            </button>
          </div>
        )}
      </dialogcontent>
    </drawer>
  );
};

export default withStyles(styles)(ThemeContentDialog);
