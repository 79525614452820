import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dialog, Grid, IconButton } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Awards, Goals, Infos, AwardType } from './components';
import {
  ProgressButton,
  Select,
  TransferList,
  TransferTreeList,
  Card,
  BlueText,
  Switch,
  Tooltip,
  BigText,
  ErrorText,
  Loader,
  AccentText,
  DefaultTitle,
  Button,
  DialogTitle,
  DialogActions,
  TextField,
  Collaborator,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import {
  faArrowDown,
  faArrowUp,
  faExchangeAlt,
  faInfoCircle,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { TeamPersonalizedList } from '../TeamPersonalizedList';
import { ChallengeSearchBar } from '../ChallengeSearchBar';
import Formsy from 'formsy-react';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => {
  return {
    teamDialog: {
      width: '90%',
      '& .MuiFormControlLabel-root': {
        marginLeft: 0,
      },
    },
    itemIcon: {
      position: 'relative',
      left: '50%',
      top: '-25px',
      marginTop: '-10px',
      zIndex: 40,
    },
    addIcon: {
      color: theme.palette.primary.main,
    },
    deleteIcon: {
      color: '#E50000',
    },
  };
};

const ChallengeFormStepper = ({
  actionLoading,
  awardTypes,
  rewardTypes,
  categories,
  images,
  isCreation,
  isDuplication,
  isUpdate,
  kpis,
  units,
  period,
  team,
  teamGroup,
  types,
  goalAdding,
  onGoalAdded,
  currentStep,
  isLastStep,
  handleNextStep,
  handlePreviousStep,
  challenge,
  setStart,
  setEnd,
  setType,
  setParticipants,
  setAwardType,
  handleAddGoal,
  setNewKpiOpen,
  setConfigRewardOpen,
  rewardImages,
  rewardCategories,
  awardError,
  dupplication,
  participantsChoiceExpanded,
  onPersonalizedTeamOpen,
  onUpdateTeam,
  ...props
}) => {
  const intl = useIntl();
  const { classes } = props;
  const id = challenge.id || null;
  const name = challenge.name || null;
  const description = challenge.description || null;
  const start = challenge.start || null;
  const end = challenge.end || null;
  const type = challenge.type || null;
  const image = challenge.image || null;
  const customImage = challenge.customImage || null;
  const awardType = challenge.awardType ? challenge.awardType : null;
  const rewardType = challenge.rewardType ? challenge.rewardType : null;
  const awards = challenge.awards || [];
  const goals = challenge.goals || null;
  const live = challenge.live || null;
  const participants = challenge.participants || [];
  const participantType = challenge.participantType || null;

  const typeObject = types.find(
    x => x.code === challenge.typeCode || x.id === challenge.type,
  );

  const typeId = typeObject ? typeObject.id : null;
  const typeCode = typeObject ? typeObject.code : null;
  var finalTypes = types;
  const { account } = props.accountDetail;

  const isTeamGroupType =
    typeObject && (typeObject.code === 'TG' || typeObject.code === 'TP');
  const isMobile = isWidthDown('xs', props.width);

  const typesData = {
    R: {
      minimumParticipants: 2,
      order: 1,
      icon: require(
        `../../../../assets/img/system/challenge/icons/Ribbons.png`,
      ),
      availableReward: isTeamGroupType ? ['gift'] : ['points', 'gift'],
    },
    M: {
      order: 2,
      disabled: isTeamGroupType,
      icon: require(`../../../../assets/img/system/challenge/icons/Rocket.png`),
      availableReward: ['points'],
    },
    P: {
      order: 3,
      icon: require(`../../../../assets/img/system/challenge/icons/Levels.png`),
      availableReward: isTeamGroupType ? ['gift'] : ['points', 'gift'],
    },
    C: {
      order: 4,
      icon: require(`../../../../assets/img/system/challenge/icons/race.png`),
      availableReward: isTeamGroupType ? ['gift'] : ['gift', 'points'],
    },
  };

  if (!isUpdate) {
    if (account.role.code === 'M') {
      finalTypes = finalTypes.filter(x => x.code === 'CM');
    } else if (
      (account.role.code === 'A' || account.role.code === 'S') &&
      !team
    ) {
      finalTypes = finalTypes.filter(x => x.code !== 'CM');
    }
  }
  const hasChallengeManager = finalTypes.find(x => x.code === 'CM') != null;

  function handleEndChange(newEnd) {
    setEnd(newEnd);
  }

  function handleStartChange(newStart) {
    setStart(newStart);
  }

  function handleUpdateTeam(teamId) {
    onUpdateTeam(teamId);
  }

  function handleTypeChange(newType) {
    setParticipants([], () => {
      setType(Number(newType));
    });
  }
  const maxAwardType = awardTypes[0].id;
  const finalInitialType = awardType ? awardType : maxAwardType;
  const isMaxAward = parseInt(awardType) === maxAwardType;
  let fields;
  let title;
  const currentAwardType = awardTypes.find(at => at.id === parseInt(awardType));

  switch (currentStep.order) {
    case 1: {
      title = intl.formatMessage({ id: 'challenge.form.participants_title' });
      fields = (
        <grid item="" xs="{12}">
          <grid container="" direction="column" spacing="{2}">
            {!!(participants || !dupplication) && (
              <react.fragment>
                <grid item="" xs="{12}">
                  <grid container="" justifyContent="center" spacing="{4}" alignItems="center">
                    <grid item="" xs="{4}">
                      {(typeId || !dupplication) && (
                        <select isContrast="" disabled="{isUpdate}" fullWidth="" initial="{typeId}" label="{intl.formatMessage({" id:="" 'challenge.form.info_type_label',="" })}="" name="type" options="{types.map(t" =="">
                            Object.assign({}, t, {
                              name: intl.formatMessage({
                                id: `challenge.types.${t.code}`,
                              }),
                            }),
                          )}
                          optionTextName='name'
                          optionValueName='id'
                          required
                          validationErrors={{
                            isDefaultRequiredValue: intl.formatMessage({
                              id: 'common.form.required_error',
                            }),
                          }}
                          onChange={handleTypeChange}
                        />
                      )}
                    </select></grid>
                    {_.get(typeObject, 'code') === 'TP' && (
                      <grid item="" style="{{" paddingTop:="" '30px'="" }}="">
                        <iconbutton size="small" onClick="{onPersonalizedTeamOpen}">
                          <fontawesomeicon icon="{faPlus}" className="{classes.addIcon}"></fontawesomeicon>
                        </iconbutton>
                      </grid>
                    )}
                  </grid>
                </grid>
                {type && (
                  <grid item="">
                    <card>
                      <transferlist listIn="{teamGroup}" teamGroupMode="{_.get(typeObject," 'code')="==" 'TG'}="" teamPersonalizedMode="{" _.get(typeObject,="" 'TP'="" }="" noSelection="{_.get(typeObject," 'TP'}="" enableCollaboratorSelect="{" 'CC'="" ||="" enableTeamSelect="{_.includes(" ['CC',="" 'CT',="" 'TP'],="" 'code'),="" )}="" onChange="{setParticipants}" selected="{participants}" defaultChoicesExpanded="{participantsChoiceExpanded}" onUpdateTeam="{handleUpdateTeam}" showParticipantType="" initialParticipantType="{participantType}"></transferlist>
                    </card>
                  </grid>
                )}
              </react.fragment>
            )}

            {!(participants && participants.length > 0) && dupplication && (
              <loader centered=""></loader>
            )}
          </grid>
        </grid>
      );
      break;
    }
    case 2: {
      title = intl.formatMessage({ id: 'challenge.form.mode_title' });
      const participantsNumber = _.includes(
        ['CC', 'TG', 'TP'],
        _.get(typeObject, 'code'),
      )
        ? participants.length
        : _.uniq(participants.map(p => p.team)).length;
      fields = (
        <grid item="" xs="{12}">
          <awardtype types="{awardTypes}" typesData="{typesData}" currentType="{awardType}" setType="{setAwardType}" participantsNumber="{participantsNumber}" participantType="{typeObject}"></awardtype>
        </grid>
      );
      break;
    }
    case 3: {
      title = intl.formatMessage({ id: 'challenge.form.infos_title' });
      fields = (
        <grid item="" xs="{12}">
          <infos description="{description}" end="{end}" image="{image}" customImage="{customImage}" images="{images}" name="{name}" period="{period}" start="{start}" type="{type}" types="{finalTypes}" onEndChange="{handleEndChange}" onStartChange="{handleStartChange}"></infos>
        </grid>
      );
      break;
    }
    case 4: {
      title = intl.formatMessage({ id: 'challenge.form.indicators_title' });
      fields = (
        <grid item="" xs="{12}">
          <goals categories="{categories}" challengeTypeCode="{typeCode}" goals="{goals}" kpis="{kpis}" units="{units}" goalAdding="{goalAdding}" onGoalAdded="{onGoalAdded}" period="{period}" start="{start}" onEndChange="{handleEndChange}" onStartChange="{handleStartChange}" handleAddGoal="{handleAddGoal}" end="{end}" setNewKpiOpen="{setNewKpiOpen}" awardType="{currentAwardType}" participantType="{participantType}"></goals>
        </grid>
      );
      break;
    }
    case 5: {
      title = intl.formatMessage({ id: 'challenge.form.rewards_title' });

      const availableRewardTypes = rewardTypes.filter(
        rt =>
          typesData[currentAwardType.code].availableReward.indexOf(
            rt.code === 'G' ? 'gift' : 'points',
          ) >= 0,
      );
      fields = (
        <grid item="" xs="{12}">
          <awards challengeId="{id}" challengeTypeCode="{typeCode}" challengeTypeId="{typeId}" end="{end}" hasChallengeManager="{hasChallengeManager}" initialAwards="{awards}" initialLive="{live}" initialType="{awardType}" initialRewardType="{rewardType}" isCreation="{isCreation}" isDuplication="{isDuplication}" isUpdate="{isUpdate}" start="{start}" team="{team}" types="{awardTypes}" rewardTypes="{availableRewardTypes}" setConfigRewardOpen="{setConfigRewardOpen}" rewardImages="{rewardImages}" rewardCategories="{rewardCategories}"></awards>
          {awardError && (
            <errortext style="{{" textAlign:="" 'center'="" }}="">
              {intl.formatMessage({ id: 'challenge.form.award_error' })}
            </errortext>
          )}
        </grid>
      );
      break;
      // case 6:
      //   title = 'Sélection des options'
      //   fields = <grid item="" xs="{12}">
      //     {isMaxAward && <card>
      //         <grid container="" alignItems="center">
      //             <grid item="">
      //                 <switch name="live" label="{intl.formatMessage({id:" "challenge.award_list.list_live_label"})}="" initial="{live}"></switch>
      //             </grid>
      //             <grid item="">
      //                 <tooltip title="{intl.formatMessage({id:" "challenge.award_list.list_live_infos"})}="">
      //                     <bluetext>
      //                         <fontawesomeicon icon="{faInfoCircle}"></fontawesomeicon>
      //                     </bluetext>
      //                 </tooltip>
      //             </grid>
      //           </grid>
      //       </card>}
      //   </grid>
      //   break;
    }
    case 6: {
      title = '';
      fields = (
        <grid item="" xs="{12}">
          <card>
            <div style="{{" textAlign:="" 'center',="" margin:="" 'auto'="" }}="">
              <p style="{{" fontSize:="" 19,="" color:="" '#555555'="" }}="">
                {intl.formatMessage({ id: 'challenge.form.congratulations' })}
              </p>
              <p style="{{" fontSize:="" 19,="" color:="" '#555555'="" }}="">
                {intl.formatMessage({
                  id: 'challenge.form.congratulations_message',
                })}
              </p>
            </div>
          </card>
        </grid>
      );
      break;
    }
  }

  return (
    <div>
      <grid 0="" 40="" container="" spacing="{4}" style="{{" paddingBottom:="" isMobile="" ?="" :="" }}="">
        <grid item="" style="{{" textAlign:="" 'center',="" width:="" '100%'="" }}="">
          <bigtext isContrast="">{title}</bigtext>
        </grid>
        {fields}
        {!isMobile && (
          <grid item="" xs="{12}">
            <grid container="" spacing="{4}" direction="row" justify="center">
              {currentStep.order > 1 && (
                <grid item="">
                  <progressbutton onClick="{e" ==""> {
                      e.preventDefault();
                      handlePreviousStep();
                    }}
                    color='secondary'
                    text={intl.formatMessage({ id: 'common.previous' })}
                    centered
                  />
                </progressbutton></grid>
              )}
              {!isLastStep && (
                <grid item="">
                  <progressbutton text="{intl.formatMessage({" id:="" 'common.next'="" })}="" centered="" disabled="{" !(participants="" &&="" participants.length=""> 0) && dupplication
                    }
                  />
                </progressbutton></grid>
              )}
              {isLastStep && (
                <grid item="">
                  <progressbutton text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" loading="{actionLoading}" centered=""></progressbutton>
                </grid>
              )}
            </grid>
          </grid>
        )}
      </grid>
    </div>
  );
};

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(
  withStyles(styles)(withWidth()(ChallengeFormStepper)),
);
