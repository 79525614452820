import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  success: false,
  loading: false,
  error: null,
};

const createApproveSystemFilesSlice = name =>
  createSlice({
    name: name,
    initialState,
    reducers: {
      approveStart: state => {
        state.success = false;
        state.loading = true;
        state.error = null;
      },
      approveSuccess: state => {
        state.success = true;
        state.loading = false;
        state.error = null;
      },
      approveFailure: (state, action) => {
        state.success = false;
        state.loading = false;
        state.error = action.payload;
      },
      clearApproveSystemFilesState: () => initialState,
    },
  });

export const approveSystemFilesKeySlice = createApproveSystemFilesSlice(
  'approveSystemFilesKey',
);

export const {
  approveStart: approveSystemFilesKeyStart,
  approveSuccess: approveSystemFilesKeySuccess,
  approveFailure: approveSystemFilesKeyFailure,
  clearApproveSystemFilesState: approveSystemFilesKeyClear,
} = approveSystemFilesKeySlice.actions;

export default {
  approveSystemFilesKey: approveSystemFilesKeySlice.reducer,
};
