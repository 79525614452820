import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Regulation } from './components/Regulation';
import { withStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { SystemFileCodes } from '../../../../enums';
import { InfoCircle } from 'iconsax-react';
import { Alert } from '../../../../components/Alert';

const styles = {
  regulationPage: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  regulations: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
};

const RegulationsTab = ({ ...props }) => {
  const intl = useIntl();

  const [regulations, setRegulations] = useState([
    {
      id: 1,
      label: intl.formatMessage({
        id: 'spider.hierarchy_node.platform_customization.regulations.legalNotice',
      }),
      multiple: false,
      code: SystemFileCodes.LEGAL_MENTIONS,
      alert: () => (
        <alert Icon="{()" ==""> <infocircle></infocircle>}
          title={intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.regulations.alertTitleLegalMentions',
          })}
          Content={() =>
            intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.regulations.alertUpdateReplaceLastDocument',
            })
          }
        />
      ),
    },
    {
      id: 2,
      label: intl.formatMessage({
        id: 'spider.hierarchy_node.platform_customization.regulations.dataProtection',
      }),
      multiple: false,
      code: SystemFileCodes.RGPD,
      alert: () => (
        <alert Icon="{()" ==""> <infocircle></infocircle>}
          title={intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.regulations.alertTitleDataProtection',
          })}
          Content={() =>
            intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.regulations.alertUpdateReplaceLastDocument',
            })
          }
        />
      ),
    },
    {
      id: 3,
      label: intl.formatMessage({
        id: 'spider.hierarchy_node.platform_customization.regulations.platformRegulation',
      }),
      multiple: true,
      code: SystemFileCodes.REGLEMENT,
      alert: () => <></>,
    },
  ]);

  return (
    <div className="{props.classes.regulationPage}">
      <typography variant="{'h3'}" component="{'h3'}">
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.regulations.title',
        })}
      </typography>

      <typography variant="{'body1'}">
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.regulations.subtitle',
        })}
      </typography>

      <div className="{props.classes.regulations}">
        {regulations.map(regulation => (
          <regulation key="{regulation.id}" regulation="{regulation}"></regulation>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(RegulationsTab);
</alert></alert>