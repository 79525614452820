import React, { useState } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
} from '@material-ui/core';
import {
  Card,
  Dialog,
  DefaultTitle,
  DialogTitle,
  Collaborator,
  Button,
  Avatar,
  TextField,
  Loader,
  DialogActions,
  ProgressButton,
  TransferList,
} from '../../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { TeamPersonalizedCreationForm } from './components/TeamPersonalizedCreationForm';
import { AvatarGroup } from '@material-ui/lab';
import _ from 'lodash';
import { ChallengeSearchBar } from '../ChallengeSearchBar';
import Formsy from 'formsy-react';

// const styles = {
//   teamDialog: {
//     width: "90%",
//     "& .MuiFormControlLabel-root": {
//       marginLeft: 0,
//     },
//   },
// };

const styles = theme => {
  return {
    teamDialog: {
      width: '90%',
      '& .MuiFormControlLabel-root': {
        marginLeft: 0,
      },
    },
    itemIcon: {
      position: 'relative',
      left: '50%',
      top: '-25px',
      marginTop: '-10px',
      zIndex: 40,
    },
    addIcon: {
      color: theme.palette.primary.main,
    },
    deleteIcon: {
      color: '#E50000',
    },
  };
};

const TeamPersonalizedList = ({
  collaborators,
  onChange,
  selected,
  ...props
}) => {
  const intl = useIntl();
  const { classes } = props;
  const [newTeamOpen, setNewTeamOpen] = useState(false);
  const [teams, setTeams] = useState(selected || []);
  const [teamSelected, setTeamSelected] = useState(-1);
  const [expandIcon, setExpandIcon] = useState(faChevronDown);
  const [team, setTeam] = useState({
    name: '',
    lookup_id: '',
    collaborators: [],
  });
  const [search, setSearch] = useState('');
  const [resultSearch, setResultSearch] = useState([]);

  const onExpand = (event, expanded, callback) => {
    setExpandIcon(expanded === true ? faChevronUp : faChevronDown);
  };

  const onTeamUpdated = team => {
    if (teamSelected !== -1 && teams[teamSelected]) {
      let newTeams = [...teams];
      newTeams[teamSelected] = team;
      setTeams(newTeams);
    }
    onNewTeamClose();
  };

  const updateTeam = collab => {
    const index = team.collaborators.findIndex(c => c.id === collab.id);
    if (index !== -1) {
      team.collaborators.splice(index, 1);
    } else {
      team.collaborators.push(collab);
    }
    setResultSearch([]);
    // this.setState({ team: team, resultSearch: [] })
    this.handleSearch(search);
  };

  const handleSearch = () => {
    if (!search) {
      setResultSearch([]);
    } else {
      const result = [];
      collaborators.forEach(c => {
        if (c && c.collaborators) {
          c.collaborators.forEach(c1 => {
            if (
              c1.firstname.toLowerCase().includes(search.toLowerCase()) ||
              c1.lastname.toLowerCase().includes(search.toLowerCase())
            ) {
              // Check collaborator is already in team
              const index = team.collaborators.findIndex(c2 => c2.id === c1.id);
              let isAlreadyInTeam = false;
              teams.forEach(t => {
                const i = t.collaborators.findIndex(tc => tc.id === c1.id);
                if (i !== -1) isAlreadyInTeam = true;
              });
              if (index === -1 && !isAlreadyInTeam) {
                result.push(c1);
              }
            }
          });
        }
      });
      setResultSearch(result);
      // this.setState({ search: newValue, resultSearch: result })
    }
  };

  const onTeamAdded = team => {
    let newTeams = [...teams];
    newTeams.push(team);
    setTeams(newTeams);
    setTimeout(() => {
      onChange(newTeams);
    }, 600);
    onNewTeamClose();
  };

  const onDeleteTeam = teamId => {
    if (teams && teams[teamId]) {
      let newTeams = [...teams];
      newTeams.splice(teamId, 1);
      setTeams(newTeams);
    }
  };

  const onUpdateTeam = teamId => {
    setTeamSelected(teamId);
    setTeam({
      name: teams[teamId].name || '',
      lookup_id: teams[teamId].lookup_id || '',
      collaborators: teams[teamId].collaborators || [],
    });
    setNewTeamOpen(true);
  };

  const onNewTeamOpen = () => {
    setTeam({
      name: '',
      lookup_id: '',
      collaborators: [],
    });
    setSearch('');
    setResultSearch([]);
    setNewTeamOpen(true);
    setTeamSelected(-1);
  };

  const onNewTeamClose = () => {
    setNewTeamOpen(false);
    setTeam({
      name: '',
      lookup_id: '',
      collaborators: [],
    });
    setTeamSelected(-1);
  };

  const onSubmit = model => {
    // const team = this.state.team
    if (!team.collaborators || team.collaborators.length === 0) {
      return;
    }
    team.name = model.name;
    team.lookup_id = model.lookup_id;
    if (teamSelected !== -1) {
      onTeamUpdated(team);
    } else {
      onTeamAdded(team);
    }
  };

  return (
    <div>
      <dialog open="{newTeamOpen}" onClose="{onNewTeamClose}" classes="{{" paper:="" classes.teamDialog="" }}="" maxWidth="md">
        <dialogtitle>
          {intl.formatMessage({ id: 'team.perso.creation_title' })}
        </dialogtitle>

        <teampersonalizedcreationform onNewTeamClose="{onNewTeamClose}" collaborators="{collaborators}" teams="{teams}" onTeamAdded="{onTeamAdded}" onTeamUpdated="{onTeamUpdated}" teamSelected="{teamSelected}"></teampersonalizedcreationform>
        {/*<formsy onValidSubmit="{onSubmit}">*/}
        {/*  <grid container="" spacing="{4}">*/}
        {/*    <grid item="" xs="{12}" container="" spacing="{2}">*/}
        {/*      <grid item="" xs="{12}">*/}
        {/*        <card>*/}
        {/*          <grid container="" spacing="{2}">*/}
        {/*            <grid item="" xs="{6}">*/}
        {/*              <textfield* }="" {="" *="" name="name" initial="{_.get(team," 'name')}*="" label="{intl.formatMessage({" id:="" 'team.form.name'="" })}*="" fullWidth*="" required*="" lowercase*="" validationErrors="{{*/}" isDefaultRequiredValue:="" intl.formatMessage({*="" 'common.form.required_error',*="" }),*="" }}*=""></textfield*>*/}
        {/*            </grid>*/}
        {/*            <grid item="" xs="{6}">*/}
        {/*              <textfield* }="" {="" *="" name="lookup_id" initial="{_.get(team," 'lookup_id')}*="" label="{intl.formatMessage({" id:="" 'team.form.id'="" })}*="" fullWidth*="" lowercase*=""></textfield*>*/}
        {/*            </grid>*/}
        {/*          </grid>*/}
        {/*        </card>*/}
        {/*      </grid>*/}
        {/*    </grid>*/}
        {/*    <grid item="" xs="{12}" container="" spacing="{2}">*/}
        {/*      <grid item="" xs="{12}">*/}
        {/*        <challengesearchbar* }="" {="" *="" search="{search}*/}" onChange="{handleSearch}*/}" fullSize*=""></challengesearchbar*>*/}
        {/*        <grid container="" spacing="{2}" style="{{" marginTop:="" '10px'="" }}="">*/}
        {/*          <grid item="" xs="{6}" direction="column">*/}
        {/*            <defaulttitle>*/}
        {/*              Joueurs disponible*/}
        {/*            </defaulttitle>*/}
        {/*            {search && resultSearch.length === 0 ? (*/}
        {/*              <loader centered=""></loader>*/}
        {/*            ) : (*/}
        {/*              <grid container="" spacing="{2}" direction="column">*/}
        {/*                {search && resultSearch?.map((collaborator) => {*/}
        {/*                    return (*/}
        {/*                      <grid* }="" {="" *="" key="{collaborator.id}*/}" item*="" xs="{12}*/}" onClick="{()" ==""> updateTeam(collaborator)}*/}
        {/*                      >*/}
        {/*                        <collaborator* }="" {="" *="" key="{collaborator.id}*/}" collaborator="{collaborator}*/}"></collaborator*>*/}
        {/*                        { team.collaborators.find((c) => {*/}
        {/*                          return c.id === collaborator.id*/}
        {/*                        }) !== undefined ? (*/}
        {/*                          <iconbutton* }="" {="" *="" size="small" className="{classes.itemIcon}*/}">*/}
        {/*                            <fontawesomeicon* }="" {="" *="" icon="{faMinus}*/}" className="{classes.deleteIcon}*/}"></fontawesomeicon*>*/}
        {/*                          */}
        {/*                        ) : (*/}
        {/*                          <iconbutton* }="" {="" *="" size="small" className="{classes.itemIcon}*/}">*/}
        {/*                            <fontawesomeicon* }="" {="" *="" icon="{faPlus}*/}" className="{classes.addIcon}*/}"></fontawesomeicon*>*/}
        {/*                          */}
        {/*                        )}*/}
        {/*                      </iconbutton*></iconbutton*></grid*></grid>*/}
        {/*                    );*/}
        {/*                  })}*/}
        {/*              </grid>*/}
        {/*            )}*/}
        {/*          </grid>*/}
        {/*          <grid item="" xs="{6}">*/}
        {/*            <defaulttitle>*/}
        {/*              Joueurs de l'équipe*/}
        {/*            </defaulttitle>*/}
        {/*            {team.collaborators.length === 0 ? (*/}
        {/*              <defaulttitle>*/}
        {/*                Aucun joueurs*/}
        {/*              </defaulttitle>*/}
        {/*            ) : (*/}
        {/*              <grid container="" spacing="{2}" direction="column">*/}
        {/*                {team.collaborators?.map((collaborator) => {*/}
        {/*                  return (*/}
        {/*                    <grid* }="" {="" *="" key="{collaborator.id}*/}" item*="" xs="{12}*/}" onClick="{()" ==""> updateTeam(collaborator)}*/}
        {/*                    >*/}
        {/*                      <collaborator* }="" {="" *="" key="{collaborator.id}*/}" collaborator="{collaborator}*/}"></collaborator*>*/}
        {/*                      <iconbutton* }="" {="" *="" size="small" className="{classes.itemIcon}*/}">*/}
        {/*                        <fontawesomeicon* }="" {="" *="" icon="{faMinus}*/}" className="{classes.deleteIcon}*/}"></fontawesomeicon*>*/}
        {/*                      */}
        {/*                    </iconbutton*></grid*></grid>*/}
        {/*                  );*/}
        {/*                })}*/}
        {/*              </grid>*/}
        {/*            )}*/}
        {/*          </grid>*/}
        {/*        </grid>*/}
        {/*      </grid>*/}
        {/*    */}
        {/*  */}
        {/*  <dialogactions>*/}
        {/*    <progressbutton* }="" {="" *="" type="submit" text="{intl.formatMessage({" id:="" "common.submit"="" })}*="" centered*=""></progressbutton*>*/}
        {/*    <button onClick="{onNewTeamClose}" color="secondary">*/}
        {/*      {intl.formatMessage({ id: "common.cancel" })}*/}
        {/*    </button>*/}
        {/*  </dialogactions>*/}
        {/*</formsy>*/}
      </dialog>
      {/*<transferlist* }="" {="" *="" teamGroupMode="{_.get(typeObject," 'code')="==" 'TG'}*="" enableCollaboratorSelect="{*/}" _.get(typeObject,="" 'CC'*="" }*="" enableTeamSelect="{_.includes(*/}" ['CC',="" 'CT'],*="" 'code')*="" )}*="" onChange="{onChange}*/}" selected="{selected}*/}" defaultChoicesExpanded="{participantsChoiceExpanded}*/}"></transferlist*>*/}
      <card>
        <grid container="" justifyContent="space-between">
          <grid item="">
            <defaulttitle>
              {intl.formatMessage({ id: 'team.perso.list_title' })}
            </defaulttitle>
          </grid>
          <grid item="">
            <grid item="" xs="{2}">
              <iconbutton color="primary" size="small">
                <fontawesomeicon icon="{faPlus}" onClick="{onNewTeamOpen}"></fontawesomeicon>
              </iconbutton>
            </grid>
          </grid>
        </grid>
        <grid container="" justifyContent="center">
          {teams.length === 0 ? (
            <defaulttitle>Aucune équipe</defaulttitle>
          ) : (
            <grid container="">
              {teams?.map((team, idx) => {
                return (
                  <grid item="" xs="{12}" key="{team.name}">
                    <expansionpanel onChange="{onExpand}" style="{{" marginBottom:="" '5px'="" }}="">
                      <expansionpanelsummary>
                        <grid container="" spacing="{2}" style="{{" textAlign:="" 'left'="" }}="">
                          <grid item="" xs="" zeroMinWidth="" alignContent="center">
                            <defaulttitle>{team.name}</defaulttitle>
                          </grid>
                          <grid item="">
                            <avatargroup max="{6}">
                              {team.collaborators.map(collaborator => {
                                return (
                                  <avatar key="{collaborator.id}" fontSize="{12}" src="{collaborator.photo}" entityId="{collaborator.id}"></avatar>
                                );
                              })}
                            </avatargroup>
                          </grid>
                        </grid>
                        <iconbutton size="small">
                          <fontawesomeicon icon="{expandIcon}"></fontawesomeicon>
                        </iconbutton>
                      </expansionpanelsummary>
                      <expansionpaneldetails>
                        <grid container="" spacing="{2}">
                          {team.collaborators?.map(collaborator => {
                            return (
                              <grid item="" xs="{6}">
                                <collaborator key="{collaborator.id}" collaborator="{collaborator}"></collaborator>
                              </grid>
                            );
                          })}
                          <grid container="" justifyContent="flex-end" style="{{" marginTop:="" '10px'="" }}="" spacing="{2}">
                            <grid item="">
                              <button onClick="{()" ==""> onDeleteTeam(idx)}>
                                Supprimer l'équipe
                              </button>
                            </grid>
                            <grid item="">
                              <button onClick="{()" ==""> onUpdateTeam(idx)}>
                                Modifier l'équipe
                              </button>
                            </grid>
                          </grid>
                        </grid>
                      </expansionpaneldetails>
                    </expansionpanel>
                  </grid>
                );
              })}
            </grid>
          )}
        </grid>
      </card>
    </div>
  );
};

const mapStateToProps = ({}) => ({});

export default connect(mapStateToProps)(
  withStyles(styles)(TeamPersonalizedList),
);
