import React from 'react';
import { Typography } from '@material-ui/core';
import { neutralColors } from '../../../../../../themes';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { ReactComponent as Equals } from '../../../../../../assets/img/homeParticipantsConvert/equals.svg';
import { ReactComponent as InfoCircle } from '../../../../../../assets/img/homeParticipantsConvert/info-circle.svg';
import { withStyles } from '@mui/styles';

const styles = {
  contentPriceConvert: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    alignItems: 'center',
    margin: 'auto',
    width: 'max-content',
  },
  contentPrice: {
    display: 'flex',
    padding: '0.5rem 1rem',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '0.5rem',
    borderRadius: '1rem',
    background: '#FAAF0C',
    [createTheme().breakpoints.down('md')]: {
      padding: '0.25rem 0.5rem',
      borderRadius: '0.5rem',
    },
  },
};

const PointPriceRatio = ({
  points = 450,
  pointsPrice = 410,
  showInfo = true,
  ...props
}) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('md'));

  return (
    <div className="{props.classes.contentPriceConvert}">
      <typography variant="{isMobile" ?="" 'h3'="" :="" 'h2'}="" component="{isMobile" style="{{" fontWeight:="" 'bold'="" }}="">
        {points.toFormatNumber(false)}
      </typography>
      <typography variant="{isMobile" ?="" 'h3'="" :="" 'h2'}="" component="{isMobile" style="{{" fontWeight:="" 'normal'="" }}="">
        {intl.formatMessage({ id: 'spider.convertPoints.home.points' })}
      </typography>

      <equals fill="{neutralColors.neutral900}"></equals>

      <div className="{props.classes.contentPrice}" style="{{" width:="" `'auto'`="" }}="">
        <typography variant="{isMobile" ?="" 'h3'="" :="" 'h2'}="" component="{isMobile" style="{{" fontWeight:="" 'bold',="" margin:="" 'auto'="" }}="">
          {pointsPrice.toFormatNumber()}
        </typography>

        {showInfo && <infocircle fill="{neutralColors.neutral900}"></infocircle>}
      </div>
    </div>
  );
};

export default withStyles(styles)(PointPriceRatio);
