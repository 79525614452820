import React from 'react';
import { Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BoldSpan, DefaultText, Chip } from '../../../../../../components';
import * as Resources from '../../../../../../Resources';
import { useIntl } from 'react-intl';

const useStyles = makeStyles({
  stepper: {
    padding: 0,
  },
});

const VerticalExplanation = ({ ...props }) => {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <div>
      <stepper orientation="vertical" className="{classes.stepper}">
        <step expanded="">
          <steplabel icon="{" <Chip="" label="{Resources.REWARD_DETAIL_OPERATION_STEP_1_NUMBER}"></steplabel>
            }
          >
            <defaulttext>
              <boldspan>
                {intl.formatMessage({
                  id: 'reward.detail.operation_step_1_title',
                })}
              </boldspan>
            </defaulttext>
          
          <stepcontent>
            <defaulttext lowercase="">
              {intl.formatMessage({
                id: 'reward.detail.operation_step_1_description',
              })}
            </defaulttext>
          </stepcontent>
        </step>
        <step expanded="">
          <steplabel icon="{" <Chip="" label="{Resources.REWARD_DETAIL_OPERATION_STEP_2_NUMBER}"></steplabel>
            }
          >
            <defaulttext>
              <boldspan>
                {intl.formatMessage({
                  id: 'reward.detail.operation_step_2_title',
                })}
              </boldspan>
            </defaulttext>
          
          <stepcontent>
            <defaulttext lowercase="">
              {intl.formatMessage({
                id: 'reward.detail.operation_step_2_description',
              })}
            </defaulttext>
          </stepcontent>
        </step>
        <step expanded="">
          <steplabel icon="{" <Chip="" label="{Resources.REWARD_DETAIL_OPERATION_STEP_3_NUMBER}"></steplabel>
            }
          >
            <defaulttext>
              <boldspan>
                {intl.formatMessage({
                  id: 'reward.detail.operation_step_3_title',
                })}
              </boldspan>
            </defaulttext>
          
          <stepcontent>
            <defaulttext lowercase="">
              {intl.formatMessage({
                id: 'reward.detail.operation_step_3_description',
              })}
            </defaulttext>
          </stepcontent>
        </step>
        <step expanded="">
          <steplabel icon="{" <Chip="" label="{intl.formatMessage({" id:="" 'reward.detail.operation_step_4_number',="" })}="" color="primary"></steplabel>
            }
          >
            <defaulttext>
              <boldspan>
                {intl.formatMessage({
                  id: 'reward.detail.operation_step_4_title',
                })}
              </boldspan>
            </defaulttext>
          
          <stepcontent>
            <defaulttext lowercase="">
              {intl.formatMessage({
                id: 'reward.detail.operation_step_4_description',
              })}
            </defaulttext>
          </stepcontent>
        </step>
      </stepper>
    </div>
  );
};

export default VerticalExplanation;
