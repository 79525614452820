import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  imports: null,
  success: false,
  loading: false,
  error: null,
};

const createGetHistoryImportsSlice = name =>
  createSlice({
    name: name,
    initialState,
    reducers: {
      fetchStart: state => {
        state.success = false;
        state.loading = true;
        state.error = null;
      },
      fetchSuccess: (state, data) => {
        state.imports = data.payload.data;
        state.success = true;
        state.loading = false;
        state.error = null;
      },
      fetchFailure: (state, action) => {
        state.success = false;
        state.loading = false;
        state.error = action.payload;
      },
      clearHistoryImportsState: () => initialState,
    },
  });

export const getHistoryImportsKeySlice = createGetHistoryImportsSlice(
  'getHistoryImportsKey',
);

export const {
  fetchStart: getHistoryImportsKeyStart,
  fetchSuccess: getHistoryImportsKeySuccess,
  fetchFailure: getHistoryImportsKeyFailure,
  clearHistoryImportsState: getHistoryImportsKeyClear,
} = getHistoryImportsKeySlice.actions;

export default {
  getHistoryImportsKey: getHistoryImportsKeySlice.reducer,
};
