import instance from '../instance';
import { buildEndpointUrlWithParams } from '../utils';

const baseUrl = 'hierarchy-nodes/';

const hierarchyNodes = {
  get(id) {
    return instance.get(`${baseUrl}${id}`);
  },
  list(type, page) {
    const params = {};
    if (type) params.type = type;
    if (page) params.page = page;

    const url = buildEndpointUrlWithParams(baseUrl, '', params);

    return instance.get(url);
  },
  hierarchyNodeUsers(id, own, page) {
    const params = new URLSearchParams();

    params.append('own', own ?? 'false');
    params.append('include_cku', 'true');
    params.append('include_point_balance', 'true');
    params.append('include_address', 'true');
    if (page) params.append('page', page);

    const url = buildEndpointUrlWithParams(
      baseUrl,
      `${id}/hierarchy-node-users`,
      params,
    );

    return instance.get(url);
  },
};

export default hierarchyNodes;
