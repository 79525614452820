import { call, put, takeLatest } from 'redux-saga/effects';
import { createRewardSuccess, createRewardError } from './actions';
import * as types from './actionTypes';
import api from '../../../data/api/api';

function* createReward(action) {
  try {
    const { data: reward } = yield call(api.rewards.create, action.reward);
    yield call(api.rewards.replaceImage, reward.id, action.rewardImage);
    yield put(createRewardSuccess());
  } catch (e) {
    yield put(createRewardError());
  }
}

function* watchRewardCreation() {
  yield takeLatest(types.CREATE_REWARD, createReward);
}

export default watchRewardCreation;
