import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleWare from 'redux-saga';
import Sagas from './sagas';
import simulateRewardAllocationReducer from '../../features/rewardAllocations/simulateRewardAllocation/slices';

const createSimulationRewardAllocationStore = () => {
  const sagaRewardAllocation = createSagaMiddleWare();

  const store = configureStore({
    reducer: {
      simulationKey:
        simulateRewardAllocationReducer.simulateRewardsAllocationsKey,
    },
    middleware: () => [sagaRewardAllocation],
  });

  sagaRewardAllocation.run(Sagas);

  return store;
};

export default createSimulationRewardAllocationStore;
