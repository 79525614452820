import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Formsy from 'formsy-react';
import { Grid, RadioGroup, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBarSubTitle,
  BlueText,
  Card,
  DefaultText,
  BigText,
  InfoText,
  Loader,
  MainLayoutComponent,
  ProgressButton,
  Select,
  Switch,
  TextField,
  Tooltip,
  Stepper,
  RichTextField,
  TransferList,
  GreenRadio,
  Dialog,
  DialogActions,
  DialogTitle,
  NavigationSwitch,
  HiddenInput,
  Button,
} from '../../../../components';
import { useIntl, injectIntl } from 'react-intl';
import * as Resources from '../../../../Resources';
import * as categoryListActions from '../../../../services/Categories/CategoryList/actions';
import * as goalTypeListActions from '../../../../services/GoalTypes/GoalTypeList/actions';
import * as kpiListActions from '../../../../services/Kpis/KpiList/actions';
import * as kpiCreationActions from '../../../../services/Kpis/KpiCreation/actions';
import * as periodicityListActions from '../../../../services/Periodicities/PeriodicityList/actions';
import * as goalDefinitionCreationActions from '../../../../services/GoalDefinitions/GoalDefinitionCreation/actions';
import * as teamListActions from '../../../../services/Teams/TeamList/actions';
import * as unitListActions from '../../../../services/Units/UnitList/actions';
import * as goalDefinitionRepartitionListActions from '../../../../services/GoalDefinitionRepartitions/GoalDefinitionRepartitionList/actions';
import * as teamGroupTreeAction from '../../../../services/TeamGroups/TeamGroupTree/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faPlus,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const styles = {
  indications: {
    '& .MuiInputBase-root': {
      display: 'none',
    },
  },
  link: {
    fontSize: 18,
    cursor: 'pointer',
    '&:hover, &.active': {
      color: 'rgb(15,111,222)',
      opacity: 1,
    },
  },
  kpiDialog: {
    width: 900,
  },
};

class AdminGoalCreation extends MainLayoutComponent {
  constructor(props) {
    super(props);
    this.state = {
      kpi: null,
      kpiCategory: null,
      type: null,
      newKpiOpen: false,
      steps: [
        { order: 1, name: 'KPI', active: true },
        { order: 2, name: 'Informations' },
        { order: 3, name: 'Participants' },
        { order: 4, name: 'Configuration' },
        { order: 5, name: 'Options' },
        { order: 6, name: 'Validation' },
      ],
      showIndicationTools: false,
      repartition: null,
      finalModel: {},
      custom: false,
      collaboratorEditable: 'manager',
    };
    this.props.goalDefinitionCreationActions.clearGoalDefinitionCreation();
    this.form = React.createRef();
  }

  componentDidMount() {
    const { intl } = this.props;
    this.props.handleTitle(intl.formatMessage({ id: 'admin.title' }));
    this.props.handleSubHeader(
      <appbarsubtitle title="{intl.formatMessage({" id:="" 'admin.goal.creation_title'="" })}=""></appbarsubtitle>,
    );
    this.props.handleMaxWidth('md');
    this.props.activateReturn();
    this.props.categoryListActions.getActiveCategoryList();
    this.props.goalTypeListActions.getGoalTypeList();
    this.props.kpiListActions.getKpiList();
    this.props.periodicityListActions.getPeriodicityList();
    this.props.teamListActions.getTeamList({
      simpleCollaborators: true,
      nestedCollaborators: true,
    });
    this.props.teamGroupTreeAction.getTeamGroupTree();
    this.props.unitListActions.getUnitList();
    this.props.goalDefinitionRepartitionListActions.getGoalDefinitionRepartitionList();
  }

  handleKpiChange(kpi) {
    this.setState({
      ...this.state,
      kpi: kpi,
    });
  }

  handleKpiCategoryChange = category => {
    this.setState({
      ...this.state,
      kpiCategory: category,
    });
  };

  handleTypeChange = type => {
    this.setState({
      ...this.state,
      type: type,
    });
  };

  handleIndicationChange = newIndication => {
    this.setState({
      ...this.state,
      finalModel: _.merge(this.state.finalModel, { indication: newIndication }),
    });
  };

  handleTeamsChange = team => {
    this.setState({
      ...this.state,
      currentTeam: team,
    });
  };

  handleRepartitionChange = repartition => {
    this.setState({
      ...this.state,
      repartition: repartition,
    });
  };

  changeStep(model) {
    const currentStep = this.state.steps.find(step => step.active === true);
    // Reset participants if we change goal type (team or individual)
    const customKpi =
      this.state.custom && model.kpiName
        ? {
            customKpi: {
              collaboratorEditable:
                this.state.collaboratorEditable === 'collaborator',
              unit: _.get(this.state, 'kpiUnit.id'),
              name: model.kpiName,
            },
          }
        : {};

    const apply = () => {
      this.setState({
        ...this.state,
        steps: this.state.steps.map(step => {
          if (step.order === currentStep.order) {
            return Object.assign(step, { active: false, completed: true });
          }
          if (step.order === currentStep.order + 1) {
            return Object.assign(step, { active: true });
          }
          return step;
        }),
        finalModel: Object.assign(this.state.finalModel, model, customKpi, {
          participants: this.state.participants,
        }),
      });
    };
    if (
      currentStep.order !== 3 ||
      _.get(this.state.participants, 'length', 0) > 0
    ) {
      if (model.type && this.state.finalModel.type !== model.type) {
        this.setParticipants([], apply);
      } else {
        apply();
      }
    }
  }

  setParticipants = (participants, callback) => {
    this.setState(
      {
        ...this.state,
        participants: participants,
      },
      callback,
    );
  };
  setParticipantMode = mode => {
    this.setState({
      ...this.state,
      participantMode: mode,
    });
  };

  handleSubmit(model) {
    const currentStep = this.state.steps.find(step => step.active === true);
    const nextStep = this.state.steps.find(
      step => step.order === currentStep.order + 1,
    );

    if (nextStep) {
      this.changeStep(model);
    } else {
      this.props.goalDefinitionCreationActions.createGoalDefinition(
        Object.assign(
          {
            editable: false,
            adminEditable: false,
            period: this.props.match.params.periodId,
          },
          this.state.finalModel,
          {
            indication: JSON.stringify(this.state.finalModel.indication),
            participants: this.state.finalModel.participants.map(p => ({
              id: p.id,
            })),
          },
        ),
      );
    }
  }

  handleSubmitKpi = model => {
    this.props.kpiCreationActions.createKpi(model);
    this.onNewKpiClose();
  };

  handlePreviousStep = () => {
    const currentStep = this.state.steps.find(step => step.active === true);
    const previousStep = this.state.steps.find(
      step => step.order === currentStep.order - 1,
    );
    if (previousStep) {
      this.setState({
        ...this.state,
        steps: this.state.steps.map(step => {
          if (step.order === currentStep.order) {
            return Object.assign(step, { active: false, completed: false });
          }
          if (step.order === currentStep.order - 1) {
            return Object.assign(step, { active: true, completed: false });
          }
          return step;
        }),
      });
    }
  };

  handleNextStep = () => {
    this.form.current.submit();
  };

  onNewKpiClose = () => {
    this.setState({
      ...this.state,
      newKpiOpen: false,
    });
  };
  onNewKpiOpen = () => {
    this.setState({
      ...this.state,
      newKpiOpen: true,
    });
  };
  setCustom = value => {
    const { intl } = this.props;
    this.setState({
      ...this.state,
      custom: value,
      collaboratorEditable: 'manager',
      kpi: value
        ? {
            periodicity: {
              description: intl.formatMessage({
                id: 'kpi.periodicity.goal_custom',
              }),
            },
          }
        : null,
      kpiUnit: null,
    });
  };

  setCollaboratorEditable = value => {
    const { intl } = this.props;
    this.setState({
      ...this.state,
      collaboratorEditable: value,
      kpi: {
        periodicity: {
          description:
            value === 'collaborator'
              ? intl.formatMessage({ id: 'kpi.periodicity.D' })
              : intl.formatMessage({ id: 'kpi.periodicity.goal_custom' }),
        },
      },
    });
  };

  setKpiUnitChange = unit => {
    const { units } = this.props.unitList;

    this.setState({
      ...this.state,
      kpiUnit: units.find(u => u.id === parseInt(unit)),
    });
  };

  renderLoader() {
    return <loader centered=""></loader>;
  }

  renderData() {
    const { intl } = this.props;
    const { categories } = this.props.categoryList;
    const { types } = this.props.goalTypeList;
    const { kpis } = this.props.kpiList;
    const { periodicities: fetchedPeriodicities } = this.props.periodicityList;
    const { loading } = this.props.goalDefinitionCreation;
    const { teams } = this.props.teamList;
    const { teamGroup, loading: teamGroupsLoading } = this.props.teamGroupTree;
    const { units } = this.props.unitList;
    const { repartitions } = this.props.goalDefinitionRepartitionList;
    const kpi = this.state.kpi ? kpis.find(k => k.id == this.state.kpi) : null;
    const { type } = this.state;
    const currentType = types.find(t => t.id === parseInt(type));
    const unit = kpi
      ? kpi.unit.name + (kpi.unit.symbol ? ` (${kpi.unit.symbol})` : '')
      : null;
    const currentStep = this.state.steps.find(step => step.active === true);

    const isLastStep = currentStep.order >= this.state.steps.length;
    const { classes } = this.props;
    let fields;
    let title;

    const kpiPeriodsList = kpi
      ? _.compact([
          kpi.start && kpi.end ? { start: kpi.start, end: kpi.end } : null,
          ...kpi.periods,
        ])
      : [];
    const kpiPeriods = (
      <react.fragment>
        {kpi && kpiPeriodsList.length > 0 && (
          <grid item="">
            <infotext>
              {intl.formatMessage({ id: 'admin.goal.kpi_periods_label' })}
            </infotext>
            <grid container="" spacing="{2}">
              {kpiPeriodsList.map(period => (
                <grid item="" key="{period.id}" xs="{12}">
                  {intl
                    .formatMessage({ id: 'admin.goal.indication.period_text' })
                    .format(
                      period.start.toDate2().toLocaleDateString(),
                      period.end.toDate2().toLocaleDateString(),
                    )}
                </grid>
              ))}
            </grid>
          </grid>
        )}
      </react.fragment>
    );

    const isCustomPeriodicity = kpi && kpi.periodicity.code === 'C';
    const periodicities = isCustomPeriodicity
      ? [
          Object.assign({}, kpi.periodicity, {
            order: fetchedPeriodicities.length + 1,
          }),
        ]
      : fetchedPeriodicities;

    switch (currentStep.order) {
      case 1:
        title = intl.formatMessage({ id: 'admin.goal.creation.kpi_title' });
        const format =
          (kpi && kpi.manual) || this.state.custom ? 'Manuel' : 'Automatique';

        fields = (
          <react.fragment>
            <grid item="" xs="{12}" sm="{6}">
              <grid container="" direction="column" spacing="{2}">
                <grid item="" xs="{12}">
                  <grid container="" alignItems="center" spacing="{1}" className="{classes.linkWrapper}">
                    <grid item="" onClick="{()" ==""> this.setCustom(false)}
                      className={`${classes.link} ${
                        !this.state.custom ? 'active' : ''
                      }`}
                    >
                      {intl.formatMessage({
                        id: 'challenge.form.goals.automatic',
                      })}
                    </grid>
                    <grid item="">
                      <navigationswitch onChange="{event" ==""> this.setCustom(event.target.checked)}
                        defaultChecked={this.state.custom}
                        color='default'
                        checked={this.state.custom}
                        inputProps={{
                          'aria-label': 'checkbox with default color',
                        }}
                      />
                      <hiddeninput name="{`custom`}" value="{this.state.custom}"></hiddeninput>
                    </navigationswitch></grid>
                    <grid item="" onClick="{()" ==""> this.setCustom(true)}
                      className={`${classes.link} ${
                        this.state.custom ? 'active' : ''
                      }`}
                    >
                      {intl.formatMessage({
                        id: 'challenge.form.goals.manual',
                      })}
                    </grid>
                  </grid>
                </grid>
                {this.state.custom && (
                  <react.fragment>
                    <grid item="" xs="{12}">
                      <grid container="" alignItems="center" spacing="{1}" className="{classes.linkWrapper}">
                        <grid item="">
                          <radiogroup row="" name="{`kpiCollaboratorEditable`}" onChange="{e" =="">
                              this.setCollaboratorEditable(e.target.value)
                            }
                            value={this.state.collaboratorEditable}
                          >
                            <formcontrollabel value="manager" control="{<GreenRadio"></formcontrollabel>}
                              label={intl.formatMessage({
                                id: 'challenge.form.goal_manager_editable_label',
                              })}
                            />

                            <formcontrollabel value="collaborator" control="{<GreenRadio"></formcontrollabel>}
                              label={intl.formatMessage({
                                id: 'challenge.form.goal_collaborator_editable_label',
                              })}
                            />
                          </radiogroup>

                          <hiddeninput name="{`collaboratorEditable`}" value="{this.state.collaboratorEditable}"></hiddeninput>
                        </grid>
                      </grid>
                    </grid>
                    <grid item="" xs="{12}">
                      <textfield name="{`kpiName`}" label="{intl.formatMessage({" id:="" 'challenge.form.goal_name_label',="" })}="" fullWidth="" required="" lowercase=""></textfield>
                    </grid>
                    <grid item="" xs="{12}">
                      <select fullWidth="" name="{`kpiUnit`}" label="{intl.formatMessage({" id:="" 'challenge.form.goal_unit_label',="" })}="" options="{units}" onChange="{this.setKpiUnitChange}" optionTextName="name" optionValueName="id" required=""></select>
                    </grid>
                  </react.fragment>
                )}
                {!this.state.custom && (
                  <react.fragment>
                    <grid item="">
                      <select name="kpiCategory" emptyText="{intl.formatMessage({" id:="" 'filter.all_category_label',="" })}="" label="{intl.formatMessage({" 'admin.goal.category_label',="" options="{categories}" optionValueName="id" optionTextName="name" fullWidth="" onChange="{this.handleKpiCategoryChange}"></select>
                    </grid>
                    <grid item="">
                      <select name="kpi" label="{intl.formatMessage({" id:="" 'admin.goal.kpi_label',="" })}="" initial="{this.state.finalModel.kpi}" options="{kpis.filter(" kpi="">
                            !this.state.kpiCategory ||
                            _.get(kpi, 'category.id') ===
                              parseInt(this.state.kpiCategory),
                        )}
                        optionValueName='id'
                        optionTextName='name'
                        onChange={this.handleKpiChange.bind(this)}
                        fullWidth
                        required
                      />
                    </select></grid>
                    <grid item="">
                      <button onClick="{this.onNewKpiOpen}" text="nouveau">
                        <fontawesomeicon icon="{faPlus}"></fontawesomeicon>
                        &nbsp;nouveau kpi
                      </button>
                    </grid>
                  </react.fragment>
                )}
              </grid>
            </grid>
            <grid item="" xs="{12}" sm="{6}">
              <grid container="" direction="column" spacing="{2}">
                <grid item="">
                  <infotext>
                    {intl.formatMessage({ id: 'admin.goal.unit_label' })}
                  </infotext>
                  <defaulttext 19="" lowercase="" style="{{" minHeight:="" }}="">
                    {unit || _.get(this.state, 'kpiUnit.name')}
                  </defaulttext>
                </grid>
                <grid item="">
                  <infotext>
                    {intl.formatMessage({ id: 'admin.goal.periodicity_label' })}
                  </infotext>
                  <defaulttext 19="" lowercase="" style="{{" minHeight:="" }}="">
                    {_.get(
                      kpi,
                      'periodicity.description',
                      _.get(this.state.kpi, 'periodicity.description'),
                    )}
                  </defaulttext>
                </grid>
                <grid item="">
                  <infotext>
                    {intl.formatMessage({ id: 'admin.goal.kpi_format_label' })}
                  </infotext>
                  {(kpi || this.state.custom) && (
                    <defaulttext 19="" lowercase="" style="{{" minHeight:="" }}="">
                      {format}
                    </defaulttext>
                  )}
                </grid>
                {kpiPeriods}
              </grid>
            </grid>
          </react.fragment>
        );
        break;
      case 2:
        title = intl.formatMessage({ id: 'admin.goal.creation.goal_title' });

        const filteredPeriodicities = periodicities.filter(
          p =>
            (p.order >= _.get(kpi, 'periodicity.order') && p.order > 1) ||
            this.state.custom,
        );

        fields = (
          <react.fragment>
            <grid item="" xs="{12}" sm="{6}">
              <textfield lowercase="" name="name" initial="{this.state.finalModel.name}" label="{intl.formatMessage({" id:="" 'admin.goal.name_label'="" })}="" fullWidth="" required=""></textfield>
            </grid>
            <grid item="" xs="{12}" sm="{6}">
              <select name="type" initial="{this.state.finalModel.type}" label="{intl.formatMessage({" id:="" 'admin.goal.type_label'="" })}="" options="{types}" optionValueName="id" optionTextName="description" onChange="{this.handleTypeChange}" fullWidth="" required=""></select>
            </grid>
            <grid item="" xs="{12}" sm="{6}">
              <select name="category" initial="{this.state.finalModel.category}" label="{intl.formatMessage({" id:="" 'admin.goal.category_label'="" })}="" options="{categories}" optionValueName="id" optionTextName="name" fullWidth="" required=""></select>
            </grid>
            <grid item="" xs="{12}" sm="{6}">
              <select name="periodicity" initial="{" isCustomPeriodicity="" ?="" kpi.periodicity.id="" :="" this.state.finalModel.periodicity="" }="" label="{intl.formatMessage({" id:="" 'admin.goal.periodicity_label',="" })}="" options="{filteredPeriodicities}" optionValueName="id" optionTextName="description" fullWidth="" required="" disabled="{isCustomPeriodicity}"></select>
            </grid>
            <grid item="" xs="{12}">
              <richtextfield name="indication" initial="{this.state.finalModel.indication}" readOnly="{false}" onChange="{this.handleIndicationChange}" label="{intl.formatMessage({" id:="" 'admin.goal.indication_label',="" })}="" padding="{'5px" 0'}="" fullWidth="" multiline="" rowsMax="{10}" required=""></richtextfield>
            </grid>
          </react.fragment>
        );
        break;
      case 3:
        title = intl.formatMessage({
          id: 'admin.goal.creation.participants_title',
        });
        fields = (
          <react.fragment>
            {!teams && this.renderLoader()}
            {teams && (
              <transferlist listIn="{teamGroup}" enableCollaboratorSelect="{this.state.type" =="=" '1'}="" enableTeamSelect="{true}" onChange="{this.setParticipants}" selected="{this.state.finalModel.participants}"></transferlist>
            )}
          </react.fragment>
        );
        break;
      case 4:
        title = intl.formatMessage({ id: 'admin.goal.creation.goals_title' });
        const labels = {
          D: 'jour',
          W: 'semaine',
          M: 'mois',
          Q: 'trimestre',
          S: 'semestre',
          Y: 'an',
          C: 'période',
        };

        const explanationPeriods = {
          D: 'jours',
          W: 'semaines',
          M: 'mois',
          Q: 'trimestres',
          S: 'semestres',
          Y: 'années',
          C: 'périodes',
        };

        const currentPeriodicity = periodicities.find(
          p => p.id === parseInt(this.state.finalModel.periodicity),
        );
        const currentRepartition = repartitions.find(
          r => r.id === parseInt(this.state.repartition),
        );
        // const currentType = types.find(t => t.id === parseInt(this.state.finalModel.type))
        // const currentPeriodicity = periodicities[0]
        // const currentRepartition = repartitions[0]
        // const currentType = types[1]

        const periodicityCode = currentPeriodicity
          ? currentPeriodicity.code
          : kpi.periodicity.code;
        const goalRepartitionLabel =
          parseInt(this.state.repartition) === _.get(repartitions, '[0]').id
            ? intl.formatMessage({ id: 'admin.goal.target_label' })
            : intl
                .formatMessage({ id: 'admin.goal.creation_target_label' })
                .format(
                  labels[periodicityCode],
                  currentType.code === 'C' ? 'individuel' : 'équipe',
                );

        const explanation =
          this.state.repartition &&
          (currentRepartition.code === 'G'
            ? intl
                .formatMessage({
                  id: `admin.goal.creation_repartition_global${
                    currentType.code === 'C' ? '' : '_team'
                  }`,
                })
                .format(explanationPeriods[periodicityCode])
            : intl
                .formatMessage({
                  id: `admin.goal.creation_repartition_individual${
                    currentType.code === 'C' ? '' : '_team'
                  }`,
                })
                .format(explanationPeriods[periodicityCode]));
        fields = (
          <react.fragment>
            <grid 20="" container="" alignItems="center" direction="column" style="{{" padding:="" }}="" spacing="{6}">
              <grid item="" xs="{12}" sm="{4}" style="{{" width:="" '100%'="" }}="">
                <select name="repartition" initial="{this.state.finalModel.repartition}" label="{intl.formatMessage({" id:="" 'admin.goal.repartition_label',="" })}="" options="{repartitions.map(r" =="">
                    r.code === 'I'
                      ? Object.assign(r, {
                          description: _.replace(
                            _.replace(
                              r.description,
                              'période',
                              labels[periodicityCode],
                            ),
                            /Individuelle/,
                            currentType.code === 'C'
                              ? 'Individuelle'
                              : 'Equipe',
                          ),
                        })
                      : r,
                  )}
                  optionValueName='id'
                  optionTextName='description'
                  onChange={this.handleRepartitionChange}
                  bigLabel
                  fullWidth
                  required
                />
              </select></grid>

              {this.state.repartition && (
                <grid item="" xs="{12}" sm="{4}" style="{{" width:="" '100%'="" }}="">
                  <grid container="" justify="center" direction="column" style="{{" position:="" 'relative'="" }}="">
                    <grid item="">
                      <textfield lowercase="" bigLabel="" type="number" name="target" initial="{this.state.finalModel.target}" label="{`👉" ${goalRepartitionLabel}`}="" fullWidth="" required=""></textfield>
                    </grid>

                    <grid item="" xs="{12}" sm="{6}" style="{{" display:="" 'none'="" }}="">
                      <textfield type="number" name="default" initial="{0}" label="{intl.formatMessage({" id:="" 'admin.goal.default_label',="" })}="" fullWidth="" required=""></textfield>
                    </grid>
                  </grid>
                </grid>
              )}
            </grid>

            <div 10="" style="{{" width:="" '70%',="" margin:="" 'auto',="" marginBottom:="" }}="">
              {explanation &&
                explanation
                  .split('\n')
                  .map(paragraph => (
                    <defaulttext style="{{" textTransform:="" 'none'="" }}="">
                      {paragraph}
                    </defaulttext>
                  ))}
            </div>
          </react.fragment>
        );
        break;
      case 5:
        title = intl.formatMessage({ id: 'admin.goal.creation.options_title' });

        fields = (
          <react.fragment>
            <grid container="" direction="row" justify="space-around">
              <grid item="">
                <grid container="" spacing="{1}" direction="column">
                  <grid item="">
                    <grid container="" alignItems="center">
                      <grid item="">
                        <switch name="live" initial="{this.state.finalModel.live}" label="{intl.formatMessage({" id:="" 'admin.goal.live_label',="" })}=""></switch>
                      </grid>
                      <grid item="">
                        <tooltip title="{intl.formatMessage({" id:="" 'admin.goal.live_infos',="" })}="">
                          <bluetext>
                            <fontawesomeicon icon="{faInfoCircle}"></fontawesomeicon>
                          </bluetext>
                        </tooltip>
                      </grid>
                    </grid>
                  </grid>

                  {_.get(currentType, 'code') === 'C' && (
                    <grid item="">
                      <switch name="editable" initial="{this.state.finalModel.editable}" label="{intl.formatMessage({" id:="" 'admin.goal.editable_label',="" })}=""></switch>
                    </grid>
                  )}
                  {_.get(currentType, 'code') === 'T' && (
                    <grid item="">
                      <switch name="admin_editable" initial="{this.state.finalModel.admin_editable}" label="{intl.formatMessage({" id:="" 'admin.goal.admin_editable_label',="" })}=""></switch>
                    </grid>
                  )}
                  <grid item="">
                    <switch name="allow_ranking" initial="{" this.state.finalModel.allow_ranking="" !="=" undefined="" ?="" :="" true="" }="" label="{intl.formatMessage({" id:="" 'admin.goal.allow_ranking_label',="" })}=""></switch>
                  </grid>
                </grid>
              </grid>
              <grid item="">
                <grid container="" spacing="{1}" direction="column">
                  <grid item="">
                    <switch name="allow_over_target" initial="{this.state.finalModel.allow_over_target}" label="{intl.formatMessage({" id:="" 'admin.goal.allow_over_target_label',="" })}=""></switch>
                  </grid>
                  <grid item="">
                    <switch name="past_editable" initial="{this.state.finalModel.past_editable}" label="{intl.formatMessage({" id:="" 'admin.goal.past_editable_label',="" })}=""></switch>
                  </grid>
                </grid>
              </grid>
            </grid>
          </react.fragment>
        );
        break;
      case 6:
        fields = (
          <div style="{{" textAlign:="" 'center',="" margin:="" 'auto'="" }}="">
            <p style="{{" fontSize:="" 19,="" color:="" '#555555'="" }}="">Félicitations 🎉 !</p>
            <p style="{{" fontSize:="" 19,="" color:="" '#555555'="" }}="">
              Il ne vous reste plus qu'à personnaliser vos objectifs selon vos
              besoins
            </p>
          </div>
        );
        break;
    }
    return (
      <react.fragment>
        <formsy ref="{this.form}" onValidSubmit="{this.handleSubmit.bind(this)}">
          <stepper steps="{this.state.steps}" actionLoading="{loading}"></stepper>
          <bigtext 10="" isContrast="" style="{{" textAlign:="" 'center',="" marginBottom:="" }}="">
            {title}
          </bigtext>
          <grid container="" spacing="{4}">
            <grid item="" xs="{12}">
              <card>
                <grid 200="" container="" spacing="{2}" style="{{" minHeight:="" }}="">
                  {fields}
                </grid>
              </card>
            </grid>
            {isLastStep && (
              <grid item="" xs="{12}">
                <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" loading="{loading}" centered=""></progressbutton>
              </grid>
            )}
          </grid>
        </formsy>
        <grid item="">
          <grid container="" spacing="{4}" direction="row" justify="center">
            {currentStep.order > 1 && (
              <grid item="">
                <progressbutton onClick="{this.handlePreviousStep}" color="secondary" text="{intl.formatMessage({" id:="" 'common.previous'="" })}="" loading="{loading}" centered=""></progressbutton>
              </grid>
            )}
            {!isLastStep && (
              <grid item="">
                <progressbutton onClick="{this.handleNextStep}" text="{intl.formatMessage({" id:="" 'common.next'="" })}="" loading="{loading}" centered=""></progressbutton>
              </grid>
            )}
          </grid>
        </grid>
      </react.fragment>
    );
  }

  render() {
    const { intl } = this.props;
    const { categories, loading: categoryListLoading } =
      this.props.categoryList;
    const { definition, error } = this.props.goalDefinitionCreation;
    const { types, loading: goalTypeListLoading } = this.props.goalTypeList;
    const { kpis, loading: kpiListLoading } = this.props.kpiList;
    const { periodicities, loading: periodicityListLoading } =
      this.props.periodicityList;
    const { teams, loading: teamLoading } = this.props.teamList;
    const { teamGroup, loading: teamGroupsLoading } = this.props.teamGroupTree;
    const { units, loading: unitLoading } = this.props.unitList;
    const { repartitions, loading: repartitionsLoading } =
      this.props.goalDefinitionRepartitionList;
    const loading =
      categoryListLoading ||
      goalTypeListLoading ||
      kpiListLoading ||
      periodicityListLoading ||
      repartitionsLoading ||
      unitLoading ||
      teamGroupsLoading;

    if (definition) {
      this.props.goalDefinitionCreationActions.clearGoalDefinitionCreation();
      this.props.history.goBack();
      toast.success(intl.formatMessage({ id: 'admin.goal.creation.success' }));
    }

    if (error) {
      toast.error(intl.formatMessage({ id: 'admin.goal.creation.error' }));
    }

    const criticities = [
      { order: 1, name: 'Basse' },
      { order: 2, name: 'Moyenne' },
      { order: 3, name: 'Haute' },
    ];

    return (
      <div>
        {loading && this.renderLoader()}
        {!loading &&
          categories &&
          types &&
          kpis &&
          periodicities &&
          units &&
          repartitions &&
          this.renderData()}
        <dialog open="{this.state.newKpiOpen}" onClose="{this.onNewKpiClose}" classes="{{" paper:="" this.props.classes.kpiDialog="" }}="">
          <dialogtitle>Demande de création de KPI</dialogtitle>
          <formsy onValidSubmit="{this.handleSubmitKpi.bind(this)}">
            <grid container="" direction="column" spacing="{2}">
              <grid item="">
                <grid container="" direction="row" spacing="{2}">
                  <grid item="" xs="{12}" sm="{6}">
                    <select name="criticity" label="{intl.formatMessage({" id:="" 'admin.goal.criticity_label',="" })}="" options="{criticities}" optionValueName="order" optionTextName="name" fullWidth="" required=""></select>
                  </grid>
                  <grid item="" xs="{12}" sm="{6}">
                    <select name="category" label="{intl.formatMessage({" id:="" 'admin.goal.category_label',="" })}="" options="{categories}" optionValueName="id" optionTextName="name" fullWidth=""></select>
                  </grid>
                </grid>
              </grid>
              <grid item="" xs="{12}" sm="{12}">
                <textfield name="name" label="{intl.formatMessage({" id:="" 'admin.goal.kpi_name_label',="" })}="" fullWidth="" required=""></textfield>
              </grid>
              <grid item="" xs="{12}" sm="{12}">
                <textfield name="description" label="{intl.formatMessage({" id:="" 'admin.goal.description_label',="" })}="" fullWidth="" required="" multiline="" rows="{4}" variant="outlined"></textfield>
              </grid>
            </grid>
            <dialogactions>
              <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" loading="{loading}" centered=""></progressbutton>
              <button onClick="{this.onNewKpiClose}" color="secondary">
                {intl.formatMessage({ id: 'common.cancel' })}
              </button>
            </dialogactions>
          </formsy>
        </dialog>
      </div>
    );
  }
}

const mapStateToProps = ({
  categoryList,
  goalTypeList,
  kpiList,
  periodicityList,
  goalDefinitionCreation,
  teamList,
  goalDefinitionRepartitionList,
  teamGroupTree,
  unitList,
}) => ({
  categoryList,
  goalTypeList,
  goalDefinitionRepartitionList,
  kpiList,
  periodicityList,
  goalDefinitionCreation,
  teamList,
  teamGroupTree,
  unitList,
});

const mapDispatchToProps = dispatch => ({
  categoryListActions: bindActionCreators(categoryListActions, dispatch),
  goalTypeListActions: bindActionCreators(goalTypeListActions, dispatch),
  kpiListActions: bindActionCreators(kpiListActions, dispatch),
  unitListActions: bindActionCreators(unitListActions, dispatch),
  kpiCreationActions: bindActionCreators(kpiCreationActions, dispatch),
  periodicityListActions: bindActionCreators(periodicityListActions, dispatch),
  goalDefinitionCreationActions: bindActionCreators(
    goalDefinitionCreationActions,
    dispatch,
  ),
  goalDefinitionRepartitionListActions: bindActionCreators(
    goalDefinitionRepartitionListActions,
    dispatch,
  ),
  teamGroupTreeAction: bindActionCreators(teamGroupTreeAction, dispatch),
  teamListActions: bindActionCreators(teamListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(injectIntl(AdminGoalCreation)));
