import React from 'react';
import { withRouter } from 'react-router-dom';
import { CardMedia, Divider, Grid, IconButton, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  AccentTag,
  Button,
  Card,
  DefaultText,
  Quantity,
  CollaboratorSimple,
  Avatar,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import { useIntl } from 'react-intl';
import '../../../../helpers/StringHelper';
import _ from 'lodash';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  divider: {
    marginLeft: -16,
    marginRight: -16,
  },
  icon: {
    width: 39,
    height: 39,
  },
  image: {
    borderRadius: 16,
    height: 150,
    [theme.breakpoints.up('sm')]: {
      height: 100,
      width: 230,
    },
  },
  name: {
    overflow: 'hidden',
    position: 'relative',
    lineHeight: '1.5em',
    maxHeight: '3em',
    textAlign: 'justify',
    '&&:before': {
      content: '"..."',
      position: 'absolute',
      right: 0,
      bottom: 1,
      paddingLeft: 2,
      background: 'white',
    },
    '&&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      width: '1em',
      height: '1em',
      marginTop: '0.2em',
      background: 'white',
    },
  },
  points: {
    marginTop: 11,
  },
}));

const RewardOrderItemList = ({ items, onItemChange, ...props }) => {
  const intl = useIntl();
  const classes = useStyles();
  const hasItems = items.length > 0;
  const { account } = props.accountDetail;

  const handleItemChange = reward => quantity => {
    if (onItemChange) onItemChange(reward, quantity);
  };

  const handleRemoveItem = reward => () => {
    if (onItemChange) onItemChange(reward, 0);
  };

  function handleReturnClick() {
    props.history.goBack();
  }
  const itemsWithCollaborators = items.filter(item => item.collaborator);
  const itemsWithTeams = items.filter(item => item.team);

  return (
    <div>
      <grid container="" spacing="{1}">
        {itemsWithCollaborators.length > 0 && account.role.code !== 'C' && (
          <grid item="" xs="{12}">
            <chip size="small" label="{itemsWithCollaborators[0].collaborator.fullname}" avatar="{" <Avatar="" src="{_.get(itemsWithCollaborators[0].collaborator," 'photo')}="" entityId="{_.get(itemsWithCollaborators[0].collaborator," 'id')}="" fallbackName="{_.get(" itemsWithCollaborators[0].collaborator,="" 'fullname',="" )}="" fontSize="{10}"></chip>
              }
              style={{
                borderColor: _.get(
                  itemsWithCollaborators[0].collaborator,
                  'team.color.hex',
                ),
              }}
              variant='outlined'
            />
          </grid>
        )}
        {itemsWithTeams.length > 0 &&
          (account.role.code === 'A' || account.role.code === 'S') && (
            <grid item="" xs="{12}">
              <chip size="small" label="{itemsWithTeams[0].team.name}" style="{{" borderColor:="" _.get(itemsWithTeams[0].team,="" 'team.color.hex'),="" }}="" variant="outlined"></chip>
            </grid>
          )}
        <grid item="" xs="{12}">
          <card>
            <grid container="" spacing="{2}">
              {hasItems &&
                items.map((item, index) => {
                  const totalPoints = item.quantity * item.reward.points;

                  return (
                    <react.fragment>
                      {index > 0 && (
                        <grid key="{`D${item.reward.id}`}" item="" xs="{12}">
                          <divider className="{classes.divider}"></divider>
                        </grid>
                      )}
                      <grid key="{`R${item.reward.id}`}" item="" xs="{12}">
                        <grid container="" spacing="{2}">
                          <grid item="" xs="{12}" sm="auto">
                            <cardmedia image="{item.reward.image}" className="{classes.image}"></cardmedia>
                          </grid>
                          <grid item="" xs="">
                            <grid container="" spacing="{2}">
                              <grid item="" xs="{12}">
                                <defaulttext className="{classes.name}">
                                  {item.reward.name}
                                </defaulttext>
                              </grid>
                              <grid item="">
                                <accenttag className="{classes.points}">
                                  {intl
                                    .formatMessage({
                                      id: 'reward.order_item_list.points_value',
                                    })
                                    .format(item.reward.points)}
                                </accenttag>
                              </grid>
                              <grid item="">
                                <cardmedia image="{item.reward.category.icon.path}" className="{classes.icon}"></cardmedia>
                              </grid>
                            </grid>
                          </grid>
                          <grid item="">
                            <grid container="" spacing="{1}" direction="column" alignItems="center">
                              <grid item="">
                                <defaulttext>
                                  {intl.formatMessage({
                                    id: 'reward.order_item_list.quantity_label',
                                  })}
                                </defaulttext>
                              </grid>
                              <grid item="">
                                <quantity initial="{item.quantity}" minimum="{0}" onChange="{" onItemChange="" ?="" handleItemChange(item.reward)="" :="" null="" }=""></quantity>
                              </grid>
                              <grid item="">
                                <accenttag>
                                  {intl
                                    .formatMessage({
                                      id: 'reward.order_item_list.points_value',
                                    })
                                    .format(totalPoints)}
                                </accenttag>
                              </grid>
                            </grid>
                          </grid>
                          {onItemChange && (
                            <grid item="">
                              <iconbutton size="small" onClick="{handleRemoveItem(item.reward)}">
                                <fontawesomeicon icon="{faTrashAlt}"></fontawesomeicon>
                              </iconbutton>
                            </grid>
                          )}
                          <grid item="" xs="{12}">
                            <grid container="" spacing="{2}">
                              <grid item="">
                                <defaulttext>
                                  <fontawesomeicon icon="{faFolderOpen}"></fontawesomeicon>{' '}
                                  {item.reward.category.name}
                                </defaulttext>
                              </grid>
                              <grid item="">
                                <defaulttext>
                                  {intl.formatMessage({
                                    id: 'reward.order_item_list.value_label',
                                  })}{' '}
                                  : {'{0} €'.format(item.reward.value)}
                                </defaulttext>
                              </grid>
                            </grid>
                          </grid>
                        </grid>
                      </grid>
                    </react.fragment>
                  );
                })}
              {!hasItems && (
                <grid item="" xs="{12}">
                  <defaulttext>
                    {intl.formatMessage({
                      id: 'reward.order_item_list.empty_text',
                    })}
                  </defaulttext>
                </grid>
              )}
              {onItemChange && (
                <grid item="" xs="{12}">
                  <button onClick="{handleReturnClick}">
                    {intl.formatMessage({
                      id: 'reward.order_item_list.close_button',
                    })}
                  </button>
                </grid>
              )}
            </grid>
          </card>
        </grid>
      </grid>
    </div>
  );
};
const mapStateToProps = ({ accountDetail }) => ({ accountDetail });
export default connect(mapStateToProps)(withRouter(RewardOrderItemList));
