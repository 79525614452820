import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { RowsPerPageSelector } from '../RowsPerPageSelector';
import { systemColors } from '../../themes';
import { ImportLogsStatuses } from '../../enums/importLogsStatuses';
import { Button } from '../index';
import { DocumentDownload } from 'iconsax-react';
import Pagination from '@material-ui/lab/Pagination';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useImportLogFile from '../../hooks/UseImportLogFile';
import usePagination from '../../hooks/UsePagination';
import { getHistoryImportsKeyStart } from '../../features/importLogs/historyImports/slices';
import HistoryNoteEmptyData from '../../assets/img/empty-import-logs-note.png';
import { Loader } from '../../../components';
import { EmptyTableView } from '../EmptyTableView';

const styles = {
  root: {
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
  },
  emptyData: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '1rem',
  },
};

const ImportLogsHistory = ({
  refreshState,
  importLogType,
  emptyLabelParams,
  ...props
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { contract } = useParams();
  const historyImport = useSelector(state => state.getHistoryImportsKey);

  const { getChipsLabel, getChipsStatus, exportImportLog } = useImportLogFile({
    onFileExported: () => {},
  });

  const download = row => {
    exportImportLog(row.uuid, contract);
  };

  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = usePagination({
    dataLength: historyImport.success ? historyImport.imports.count : 0,
  });

  useEffect(() => {
    dispatch(
      getHistoryImportsKeyStart({
        teamGroupId: contract,
        types: [importLogType],
        pagination,
      }),
    );
  }, [refreshState, pagination.page, pagination.rowsPerPage]);

  const columns = [
    {
      field: 'date',
      label: intl.formatMessage({
        id: 'spider.common.history.table.date',
      }),
    },
    {
      field: 'name',
      label: intl.formatMessage({
        id: 'spider.common.history.table.name',
      }),
    },
    {
      field: 'lines',
      label: intl.formatMessage({
        id: 'spider.common.history.table.lines',
      }),
    },
    {
      field: 'status',
      label: intl.formatMessage({
        id: 'spider.common.history.table.status',
      }),
    },
    {
      field: 'action',
      label: intl.formatMessage({
        id: 'spider.common.history.table.action',
      }),
    },
  ];

  return (
    <paper className="{props.classes.root}">
      <rowsperpageselector rowsPerPage="{pagination.rowsPerPage}" handleChangeRowsPerPage="{handleChangeRowsPerPage}"></rowsperpageselector>
      <tablecontainer>
        <table>
          <tablehead>
            <tablerow>
              {(historyImport.loading || historyImport?.imports?.count > 0) &&
                columns.map(column => {
                  return <tablecell key="{column.id}">{column.label}</tablecell>;
                })}
            </tablerow>
          </tablehead>
          <tablebody>
            {historyImport.loading ? (
              <tablerow>
                <tablecell colSpan="{columns.length}">
                  <loader centered=""></loader>
                </tablecell>
              </tablerow>
            ) : historyImport.success &&
              historyImport.imports.results?.length > 0 ? (
              historyImport.imports.results.map((row, rowIndex) => (
                <tablerow key="{rowIndex}">
                  <tablecell>
                    <div>
                      {row.created_at
                        .toDate()
                        .toLocaleString('fr-FR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })
                        .replaceAll('/', '.')}
                    </div>
                  </tablecell>
                  <tablecell>
                    <div>
                      {row.input_file.filename.replace('.csv', '')}
                      <br>
                      <div style="{{" fontSize:="" '10px'="" }}="">{`${row.creator.firstname} ${row.creator.lastname}`}</div>
                    </div>
                  </tablecell>
                  <tablecell>
                    <div>
                      {row.nbTotalLines}
                      <br>
                      {row.linesError > 0 ? (
                        <div style="{{" fontSize:="" '10px',="" color:="" systemColors.errorDark,="" }}="">{`dont ${row.nbFailedLines} en erreur`}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </tablecell>
                  <tablecell>
                    <chip label="{getChipsLabel(row.status)}" className="{getChipsStatus(row.status)}"></chip>
                  </tablecell>
                  <tablecell>
                    {[ImportLogsStatuses.FINISHED].includes(row.status) && (
                      <button variant="{'contained'}" color="{'primary'}" className="{'size-tiny" icon'}="" onClick="{()" ==""> download(row)}
                      >
                        <documentdownload size="{24}"></documentdownload>
                      </button>
                    )}
                  </tablecell>
                </tablerow>
              ))
            ) : (
              <tablerow>
                <tablecell>
                  <emptytableview imageSrc="{HistoryNoteEmptyData}" imageAlt="Aucun import" title="spider.importLog.dataTable.no_data_available_title" description="spider.importLog.dataTable.no_data_available_description" emptyLabelParams="{emptyLabelParams}"></emptytableview>
                </tablecell>
              </tablerow>
            )}
          </tablebody>
        </table>
      </tablecontainer>
      <pagination count="{paginationCount}" page="{pagination.page}" onChange="{handleChangePage}" shape="rounded"></pagination>
    </paper>
  );
};

export default withStyles(styles)(ImportLogsHistory);
