import React from 'react';
import { FileLayout } from '..';

const FileRoutes = ({ component: Component, ...rest }) => {
  const { path } = rest;

  return <filelayout exact="" path="{path}" component="{Component}"></filelayout>;
};

export default FileRoutes;
