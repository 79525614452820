import React from 'react';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { ArrowLeft } from 'iconsax-react';
import { createTheme } from '@material-ui/core/styles';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '2rem',
  },
  stepper: {
    width: '100%',
    margin: 'auto',
    [createTheme().breakpoints.up('md')]: {
      width: '80%',
    },
    [createTheme().breakpoints.up('lg')]: {
      width: '65%',
    },
  },
};

const ConvertPointsStepper = ({
  steps,
  activeStep,
  previousStep,
  ...props
}) => {
  const intl = useIntl();

  return (
    <div className="{props.classes.root}">
      <div className="{props.classes.rootTitle}">
        <button variant="{'contained'}" color="{'primary'}" size="{'small'}" className="{'icon" light'}="" onClick="{()" ==""> previousStep()}
        >
          <arrowleft></arrowleft>
        </button>
        <typography variant="{'h1'}" component="{'h1'}" className="{'underline-left'}">
          {intl.formatMessage({ id: 'spider.convertPoints.title' })}
        </typography>
      </div>

      <stepper alternativeLabel="" activeStep="{activeStep}" className="{props.classes.stepper}">
        {steps.map(step => (
          <step key="{step.label}">
            <steplabel icon="{step.icon}">{step.label}</steplabel>
          </step>
        ))}
      </stepper>
    </div>
  );
};

export default withStyles(styles)(ConvertPointsStepper);
