import React from 'react';
import { ReactComponent as CKUCard } from '../../assets/img/homeParticipantsConvert/cku-card.svg';
import { ReactComponent as CardDetailGlobe } from '../../assets/img/homeParticipantsConvert/card-details-globe.svg';
import { ReactComponent as CardDetailShop } from '../../assets/img/homeParticipantsConvert/card-details-shop.svg';
import { ReactComponent as CardDetailCalendar } from '../../assets/img/homeParticipantsConvert/card-details-calendar.svg';
import { DecorationCard } from '../../scenes/Home/components/UserHome/components/DecorationCard';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../auth';

const styles = {
  sphere: {},
  design: {},
};

const useDefaultHook = () => {
  const intl = useIntl();
  const { hierarchyNodeUser } = useAuth();
  // component card
  // form field to show per step

  const ComponentDecoration = ({ ...props }) => (
    <decorationcard title="{intl.formatMessage({" id:="" 'spider.convertPoints.home.default.title',="" })}="" classes="{props.classes}"></decorationcard>
  );

  const cardDetails = [
    {
      Icon: CardDetailGlobe,
      label: intl.formatMessage({
        id: 'spider.convertPoints.home.default.globeLabel',
      }),
    },
    {
      Icon: CardDetailShop,
      label: intl.formatMessage({
        id: 'spider.convertPoints.home.default.shopLabel',
      }),
    },
    {
      Icon: CardDetailCalendar,
      label: intl.formatMessage({
        id: 'spider.convertPoints.home.default.calendarLabel',
      }),
    },
  ];

  const getInformationStepDefaultFormData = () => {
    return {
      dateOfBirth: '',
      phone: '',
      line1: '',
      line2: '',
      zipCode: '',
      city: '',
    };
  };

  const HelperAbout = () => (
    <div>
      <div>
        <typography variant="{'h3'}" component="{'h3'}">
          {"Un catalogue d'offres négociées pour combler vos attentes à 100% !"}
        </typography>

        <div>
          <typography variant="{'body1'}" style="{{" fontWeight:="" 'bold'="" }}="">
            {'Pour en profiter, c’est simple :'}
          </typography>

          <div>Stepper</div>

          <typography variant="{'body1'}">{'Et  voilà !'}</typography>
          <typography variant="{'body2'}" style="{{" fontStyle:="" 'italic'="" }}="">
            {
              "Pour rappel, votre solde est valable pendant un an à partir de l'activation de votre compte."
            }
          </typography>

          <link className="{'bold" large'}="" onClick="{()" ==""> {}}>
            {'Tout savoir sur Kadéos Connect'}
          
        </div>
      </div>
      <div style="{{" background:="" 'linear-gradient(135deg,="" #FAAF0C="" 0%,="" #FFDC60="" 100%)',="" }}="">
        <ckucard></ckucard>
      </div>
    </div>
  );

  return {
    cardDetails,
    Card: CKUCard,
    HelperAbout,
    ComponentDecoration: withStyles(styles)(ComponentDecoration),
    informationStep: {
      defaultForm: getInformationStepDefaultFormData(),
      showAndValidateBirthDate: true,
      showAddress: true,
      address: {
        PP: true,
        PM: false,
      },
      addressTitle: {
        PP: 'spider.convertPoints.stepper.informationStep.personalAddress.titleCombine',
        PM: 'spider.convertPoints.stepper.informationStep.personalAddress.titlePersonal',
      },
    },
  };
};

export default useDefaultHook;
