import { createTheme, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    padding: 'Orem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    [createTheme().breakpoints.down('xs')]: {
      padding: '0rem 1rem',
    },
  },
}));

/**
 * Render a container component for the header.
 * @type {React.FC}
 * @param {ReactNode} props.children - The child components to be rendered inside the container.
 * @return {JSX.Element} The rendered container component.
 */
function TitleContainer({ children }) {
  const classes = useStyles();

  return <div className="{classes.root}">{children}</div>;
}

export { TitleContainer };
