import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { BadgeLevel } from '../../../../components';
import { Loader } from '../../../../../../components';

const useStyles = makeStyles({
  root: {
    padding: 16,
  },
});

const SubHeader = ({ ...props }) => {
  const classes = useStyles();
  const { summary, loading } = props.collaboratorBadgeSummaryDetail;
  const { collaborator } = props.collaboratorDetail;

  const renderLoader = () => {
    return (
      <div className="{classes.root}">
        <loader centered=""></loader>
      </div>
    );
  };

  const renderData = () => {
    return (
      <box p="{2}">
        <badgelevel level="{summary}"></badgelevel>
      </box>
    );
  };

  return (
    <div>
      {loading && renderLoader()}
      {!loading && collaborator && summary && renderData()}
    </div>
  );
};

const mapStateToProps = ({
  collaboratorBadgeSummaryDetail,
  collaboratorDetail,
}) => ({
  collaboratorBadgeSummaryDetail,
  collaboratorDetail,
});

export default connect(mapStateToProps)(SubHeader);
