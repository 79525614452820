import React from 'react';
import { ActionModal } from '@src/Spider/components/ActionModal';
import { useUpdateHierarchyNodeUserStatusMutation } from '@src/data/api/endpoints/hierarchyNodeUsers';
import { HierarchyNodeUserStatuses } from '@src/Spider/enums';

/**
 * @typedef {Object} ChangeStatusDialogProps
 * @property {() => void} handleClose - Function to close the dialog
 * @property {boolean} open - Controls dialog visibility
 * @property {string | number} userId - ID of the user whose status is being changed
 */

/**
 * Dialog component for changing user status
 * @param {ChangeStatusDialogProps} props
 * @returns {JSX.Element}
 */
export const ChangeStatusDialog = ({ handleClose, open, userId }) => {
  const [updateStatus, { isLoading }] =
    useUpdateHierarchyNodeUserStatusMutation();

  /**
   * Handles the status update operation
   * @returns {Promise<void>}
   */
  const handleStatusUpdate = async () => {
    try {
      await updateStatus({
        user_id: userId,
        requestBody: {
          status: HierarchyNodeUserStatuses.INACTIVE,
        },
      }).unwrap();

      // Handle success
      console.log('Status updated successfully');
      handleClose();
    } catch (error) {
      // Handle error
      console.error('Failed to update status:', error);
    }
  };

  return (
    <actionmodal open="{open}" handleClose="{handleClose}" title="spider.participants.details.modals.unsubscribe.title" description="spider.participants.details.modals.unsubscribe.description" confirmButtonText="spider.participants.details.modals.unsubscribe.confirm" cancelButtonText="spider.participants.details.modals.unsubscribe.cancel" onConfirm="{handleStatusUpdate}" isLoading="{isLoading}"></actionmodal>
  );
};
</void>