export const GET_GOAL_DEFINITION_LEVEL_COLLABORATOR_POINTS =
  'GET_GOAL_DEFINITION_LEVEL_COLLABORATOR_POINTS';
export const GET_GOAL_DEFINITION_LEVEL_COLLABORATOR_POINTS_BY_COLLABORATOR =
  'GET_GOAL_DEFINITION_LEVEL_COLLABORATOR_POINTS_BY_COLLABORATOR';
export const GET_GOAL_DEFINITION_LEVEL_COLLABORATOR_POINTS_BY_TEAM =
  'GET_GOAL_DEFINITION_LEVEL_COLLABORATOR_POINTS_BY_TEAM';
export const GET_GOAL_DEFINITION_LEVEL_COLLABORATOR_POINTS_SUCCESS =
  'GET_GOAL_DEFINITION_LEVEL_COLLABORATOR_POINTS_SUCCESS';
export const GET_GOAL_DEFINITION_LEVEL_COLLABORATOR_POINTS_ERROR =
  'GET_GOAL_DEFINITION_LEVEL_COLLABORATOR_POINTS_ERROR';
