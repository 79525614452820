import instance from '../instance';
import { OwnerType } from '@spider:src/enums/ownerType';
import { baseApi } from '@src/store/api-query';
import { OperationType } from '@spider:src/enums/operationType';
import { UserRolesType } from '@spider:src/enums/userRoles';
import { HierarchyNodeUserStatusesType } from '@spider:src/enums/hierarchyNodeUserStatuses';

/**
 * @param {string} id ID du noeud hiérarchique en base de données
 */
const baseUrl = id => `hierarchy-nodes/${id}/transactions`;


const pointsTransactions = {
  /**
   * @description Retourne la liste des points appartenant à un d'un hierarchy node
   * @param {PointsListParams} queryParams
   */
  list({ id, page, page_size, owner_types, hierarchy_node_user }) {
    const params = new URLSearchParams();
    if (page) params.append('page', page);
    if (page_size) params.append('page_size', page_size);
    if (hierarchy_node_user)
      params.append('hierarchy_node_user', hierarchy_node_user);
    if (owner_types && Array.isArray(owner_types)) {
      owner_types.forEach(type => params.append('owner_types', type));
    }
    return instance.get(baseUrl(id), { params });
  },
};

const pointsQueries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<pointslistparams, typeof="" builder.query,="" 'api-query',="" GetPointsTransitionsResponse="">}*/
    getPointsTransactionsList: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => pointsTransactions.list(params));
      },
    }),
  }),
});

export const {
  useGetPointsTransactionsListQuery,
  useLazyGetPointsTransactionsListQuery,
} = pointsQueries;

export default pointsTransactions;

// Types

/**
 * @typedef {Object} PointsListParams
 * @property {string} id ID du noeud hiérarchique en base de données
 * @property {number} [page] Page à retourner
 * @property {number} [page_size] Nombre de résultats à retourner par page
 * @property {OwnerType[]} [owner_types] Type d'owner souhaité
 * @property {string} [hierarchy_node_user] UUID d'un hierarchy node user pour avoir seulement les transactions de celui ci
 */

/**
 * @typedef {Object} PointTransaction
 * @property {string} uuid - Unique identifier for the transaction
 * @property {number} amount - The amount of points involved in the transaction
 * @property {number} date - The timestamp of the transaction (in milliseconds)
 * @property {Source} [source] - Information about the transaction source
 * @property {Owner} owner - Information about the transaction owner
 * @property {OperationType} sub_type - Sub-type of the transaction (e.g., "ORDER")
 */

/**
 * @typedef {Object} HierarchyNodeUserOwner
 * @property {string} uuid - Unique identifier for the owner
 * @property {string} first_identifier - Primary identifier for the owner
 * @property {string} firstname - First name of the owner
 * @property {string} lastname - Last name of the owner
 * @property {UserRolesType} role - Role of the owner
 * @property {HierarchyNodeUserStatusesType} status - Status of the owner (e.g., "ACTIVE")
 * @property {string} type - Type of the owner (e.g., "LEGAL_ENTITY")
 * @property {'HIERARCHY_NODE_USER'} owner_type - Type of ownership
 */

/**
 * @typedef {Object} HierarchyNodeOwner
 * @property {string} code - Code of the hierarchy node
 * @property {string} name - Name of the hierarchy node
 * @property {string} status - Status of the owner (e.g., "ACTIVE")
 * @property {'HIERARCHY_NODE'} owner_type - Type of ownership
 */

/**
 * @typedef {HierarchyNodeUserOwner | HierarchyNodeOwner} Owner
 */

/**
 * @typedef {Object} Source
 * @property {string} source.uuid - Unique identifier for the source
 * @property {string} source.name - Name of the source
 * @property {string} source.type - Type of the source (e.g., "REWARD_ORDER")
 */

/**
 * @typedef {string} UrlString - A string representing an URL
 */

/**
 * @typedef {Object} GetPointsTransitionsResponse
 * @property {number} count - Total number of transactions
 * @property {UrlString | null} next - Next URL to go to the next page of transactions
 * @property {UrlString | null} previous - Previous URL to go to the previous page of transactions
 * @property {PointTransaction[]} results - List of points transactions
 */
</pointslistparams,>