import React from 'react';
import { Button } from '../../../../components';
import { useIntl } from 'react-intl';
import { createTheme, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import UnknownPageImage from '../../../assets/img/illustration-404.png';
import { withStyles } from '@mui/styles';
import { neutralColors } from '../../../themes';
import { useMediaQuery } from '@mui/material';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    flexDirection: 'row',
    [createTheme().breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      gap: '2rem',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',

    gap: '2rem',
    [createTheme().breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  title: {
    [createTheme().breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  image: {
    width: '24rem',
    [createTheme().breakpoints.down('sm')]: {
      width: '16rem',
    },
  },
};

const UnknownPage = ({ ...props }) => {
  const intl = useIntl();
  const history = useHistory();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('md'));

  const goBackHome = () => {
    history.push('/nodes');
  };

  return (
    <div className="{props.classes.root}">
      <div className="{props.classes.content}">
        <div className="{props.classes.description}">
          <typography variant="{'h1'}" component="{'h1'}" className="{`${isMobile" ?="" 'underline-center'="" :="" 'underline-left'}="" ${props.classes.title}`}="">
            {intl.formatMessage({ id: 'spider.errorPages.unknownPage.title' })}
          </typography>
          <typography variant="{'body2'}" style="{{" color:="" neutralColors.neutral600="" }}="">
            {intl.formatMessage({
              id: 'spider.errorPages.unknownPage.description',
            })}
          </typography>
        </div>
        <button variant="{'contained'}" color="{'primary'}" size="{'small'}" onClick="{goBackHome}">
          {intl.formatMessage({
            id: 'spider.errorPages.unknownPage.backButtonLabel',
          })}
        </button>
      </div>
      <img className="{props.classes.image}" src="{UnknownPageImage}" alt="{'404" image'}="">
    </div>
  );
};

const Page = withStyles(styles)(UnknownPage);
export { Page as UnknownPage };
