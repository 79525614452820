import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  DefaultText,
  Loader,
  RoundedTab,
  RoundedTabs,
} from '../../../../../../components';
import * as Resources from '../../../../../../Resources';
import { useIntl } from 'react-intl';
import '../../../../../../helpers/StringHelper';

const styles = {
  root: {
    padding: 16,
  },
};

const SubHeader = ({ onChange, readonly, ...props }) => {
  const intl = useIntl();
  const { classes } = props;
  const { definition, loading } = props.goalDefinitionDetail;
  const [value, setValue] = React.useState(0);

  function handlePageChange(e, value) {
    setValue(value);
    if (onChange) onChange(value);
  }

  function renderLoader() {
    return (
      <div className="{classes.root}">
        <loader centered=""></loader>
      </div>
    );
  }

  function renderData() {
    return (
      <div>
        <div className="{classes.root}">
          {!readonly && (
            <defaulttext align="center">
              {intl
                .formatMessage({ id: 'admin.goal.update_base_title' })
                .format(definition.name)}
            </defaulttext>
          )}
          {readonly && (
            <defaulttext align="center">
              {intl
                .formatMessage({ id: 'admin.goal.update_readonly_title' })
                .format(definition.name)}
            </defaulttext>
          )}
        </div>
        <roundedtabs value="{value}" onChange="{handlePageChange}" variant="fullWidth">
          <roundedtab label="{intl.formatMessage({" id:="" 'admin.goal.update_base_tab'="" })}=""></roundedtab>
          <roundedtab label="{intl.formatMessage({" id:="" 'admin.goal.update_custom_tab'="" })}=""></roundedtab>
        </roundedtabs>
      </div>
    );
  }

  return (
    <div>
      {loading && renderLoader()}
      {!loading && definition && renderData()}
    </div>
  );
};

const mapStateToProps = ({ goalDefinitionDetail }) => ({
  goalDefinitionDetail,
});

export default withStyles(styles)(connect(mapStateToProps)(SubHeader));
