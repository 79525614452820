import React from 'react';
import { Timer } from '..';
import {
  AccentTag,
  DarkTag,
  LightTag,
  UltraLightTag,
  WarningTag,
} from '../../..';
import * as Resources from '../../../../../../Resources';
import { useIntl } from 'react-intl';
import { getDifferenceWithToday } from '../../../../../../helpers/DateHelper';
import '../../../../../../helpers/NumberHelper';

const TimerTag = ({ date, overtime }) => {
  const intl = useIntl();
  const difference = getDifferenceWithToday(date);
  const today = new Date().getTime();
  const text = intl
    .formatMessage({ id: 'admin.goal.thumbnail.timer_tag' })
    .format(difference >= 0 ? `+${difference}` : difference);
  const style = {
    borderRadius: 5,
  };

  return (
    <div>
      {overtime && date && difference === 0 && today > date && (
        <warningtag style="{style}">
          <timer overtime="{overtime}" date="{date.toDate2()}"></timer>
        </warningtag>
      )}
      {overtime && date && difference > 0 && (
        <warningtag style="{style}">{text}</warningtag>
      )}
      {!overtime && difference === 0 && (
        <accenttag style="{style}">
          <timer date="{date.toDate2()}"></timer>
        </accenttag>
      )}
      {difference < 0 && difference >= -5 && (
        <darktag style="{style}">{text}</darktag>
      )}
      {difference < -5 && difference >= -30 && (
        <lighttag style="{style}">{text}</lighttag>
      )}
      {difference < -30 && <ultralighttag style="{style}">{text}</ultralighttag>}
    </div>
  );
};

export default TimerTag;
