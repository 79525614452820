export const Operation = Object.freeze({
  EXPIRATION: 'EXPIRATION',
  LOAD: 'LOAD',
  ORDER: 'ORDER',
  ORDER_CANCELLATION: 'ORDER_CANCELLATION',
  WITHDRAWAL: 'WITHDRAWAL',
});

/**
 *  @typedef {Operation[keyof Operation]} OperationType - Roles for a user, should use the enum in `userRoles.jsxÌ
 * @exports { OperationType };
 */
