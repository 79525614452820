import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { GuestRoutes, UserRoutes } from './components';
import * as scenes from './scenes';
import { AuthCallback } from '../auth';
import { UserRoles } from './enums';
import { MaintenancePage, UnknownPage } from './scenes/ErrorPages';
import { IS_MAINTENANCE_MODE_ENABLED } from './global.constants';

export default () => {
  return (
    <browserrouter>
      <switch>
        {IS_MAINTENANCE_MODE_ENABLED && (
          <guestroutes path="*" component="{()" ==""> <maintenancepage isPartialMaintenance="{false}"></maintenancepage>}
          />
        )}
        {/* Auth routes */}
        <guestroutes exact="" path="/" component="{AuthCallback}"></guestroutes>
        <guestroutes exact="" path="/callback" component="{AuthCallback}"></guestroutes>
        <userroutes exact="" path="/nodes" component="{scenes.ContractSelection}" useGuestLayout="" authorizedRoles="{[UserRoles.COLLABORATOR," UserRoles.ADMINISTRATOR]}=""></userroutes>

        {/* Home routes */}
        <userroutes exact="" path="/nodes/:contract/home" component="{scenes.Home}" authorizedRoles="{[UserRoles.COLLABORATOR," UserRoles.ADMINISTRATOR]}=""></userroutes>

        {/* Convert Point routes */}
        <userroutes exact="" path="/nodes/:contract/conversion-points" component="{scenes.ConvertPoints}" authorizedRoles="{[UserRoles.COLLABORATOR]}"></userroutes>
        <userroutes exact="" path="/nodes/:contract/informations-personnelles" component="{scenes.ConvertPoints}" authorizedRoles="{[UserRoles.COLLABORATOR]}"></userroutes>
        <userroutes exact="" path="/nodes/:contract/declaration-urssaf" component="{scenes.ConvertPoints}" authorizedRoles="{[UserRoles.COLLABORATOR]}"></userroutes>
        <userroutes exact="" path="/nodes/:contract/recapitulatif-commande" component="{scenes.ConvertPoints}" authorizedRoles="{[UserRoles.COLLABORATOR]}"></userroutes>

        {/* Users routes */}
        <userroutes exact="" path="/nodes/:contract/users" component="{scenes.Participants}" authorizedRoles="{[UserRoles.ADMINISTRATOR]}"></userroutes>
        <userroutes path="/nodes/:contract/users/:user_id" component="{scenes.Users}" authorizedRoles="{[UserRoles.ADMINISTRATOR," UserRoles.MANAGER]}=""></userroutes>

        {/* Orders routes */}
        <userroutes exact="" path="/nodes/:contract/orders" component="{scenes.Orders}" authorizedRoles="{[UserRoles.COLLABORATOR]}"></userroutes>

        {/* Points routes */}
        <userroutes exact="" path="/nodes/:contract/points" component="{scenes.Points}" authorizedRoles="{[UserRoles.COLLABORATOR," UserRoles.ADMINISTRATOR]}=""></userroutes>

        {/* Challenges routes */}
        <userroutes exact="" path="/nodes/:contract/challenges" component="{scenes.Challenges}" authorizedRoles="{[UserRoles.COLLABORATOR]}"></userroutes>

        {/* Help routes */}
        <userroutes exact="" path="/nodes/:contract/help" component="{scenes.Help}" authorizedRoles="{[UserRoles.COLLABORATOR]}"></userroutes>

        {/* Administration routes */}
        <userroutes path="/nodes/:contract/administration" component="{scenes.Admin}" authorizedRoles="{[UserRoles.ADMINISTRATOR]}"></userroutes>

        {/* Account activation routes */}
        <guestroutes exact="" path="/nodes/:contract/activate" component="{scenes.AccountActivation}"></guestroutes>
        <guestroutes exact="" path="/nodes/:contract/finalize-activation" component="{scenes.AccountActivationFinalization}"></guestroutes>
        <guestroutes exact="" path="/nodes/:contract/account-activation-key-expired" component="{scenes.AccountActivationKeyExpired}"></guestroutes>
        <guestroutes exact="" path="/nodes/:contract/inaccessible" component="{scenes.ContractInaccessible}"></guestroutes>
        <guestroutes exact="" path="/nodes/:contract/maintenance" component="{()" ==""> <maintenancepage isPartialMaintenance=""></maintenancepage>}
        />

        {/* To keep at last position (404) */}
        <guestroutes path="*" component="{UnknownPage}"></guestroutes>
      </guestroutes></guestroutes></switch>
    </browserrouter>
  );
};
