export const CREATE_GOAL_ADVICE_LIST_BY_COLLABORATOR_GOAL =
  'CREATE_GOAL_ADVICE_LIST_BY_COLLABORATOR_GOAL';
export const CREATE_GOAL_ADVICE_LIST_BY_TEAM_COLLABORATOR_GOAL =
  'CREATE_GOAL_ADVICE_LIST_BY_TEAM_COLLABORATOR_GOAL';
export const CREATE_GOAL_ADVICE_LIST_BY_TEAM_GOAL =
  'CREATE_GOAL_ADVICE_LIST_BY_TEAM_GOAL';
export const CREATE_GOAL_ADVICE_LIST_SUCCESS =
  'CREATE_GOAL_ADVICE_LIST_SUCCESS';
export const CREATE_GOAL_ADVICE_LIST_ERROR = 'CREATE_GOAL_ADVICE_LIST_ERROR';
export const CLEAR_GOAL_ADVICE_LIST_CREATION =
  'CLEAR_GOAL_ADVICE_LIST_CREATION';
