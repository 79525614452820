import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import { getParticipantsListKeySlice } from './slices';

function* fetchParticipants(slice, endpoint, action) {
  try {
    const {
      id,
      page,
      page_size,
      own,
      include_address,
      include_cku,
      include_manager,
      include_point_balance,
    } = action.payload;
    const data = yield call(
      endpoint,
      id,
      page,
      page_size,
      own,
      include_address,
      include_cku,
      include_manager,
      include_point_balance,
    );
    yield put(slice.actions.fetchSuccess(data));
  } catch (error) {
    yield put(
      slice.actions.fetchFailure(error?.response?.data?.error ?? 'UNKNOWN'),
    );
  }
}

function* watchParticipants(slice, endpoint) {
  yield takeLatest(
    slice.actions.fetchStart.type,
    fetchParticipants,
    slice,
    endpoint,
  );
}

export default function* crudParticipantsSaga() {
  yield all([
    watchParticipants(getParticipantsListKeySlice, api.participants.list),
  ]);
}

export { crudParticipantsSaga as getParticipantsSaga };
