import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { Document as DocumentIcon } from 'iconsax-react';
import { useIntl } from 'react-intl';
import { Divider, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useParams } from 'react-router-dom';
import RegulationDocument from './components/RegulationDocument/RegulationDocument';
import { Button } from '@src/Spider/components';
import { Loader } from '@src/components';
import { RightDialogFileInput } from '@spider:components/RightDialogFileInput';
import { neutralColors } from '@spider:src/themes';
import { SystemFileStatuses } from '@spider:src/enums/systemFileStatuses';
import usePagination from '@spider:src/hooks/UsePagination';
import {
  useDeleteSystemImageMutation,
  useLazyGetHierarchyNodeSystemImagesListQuery,
  useUpdateHierarchyNodeSystemImageMutation,
} from '@async-calls/systemImages';
import UseSystemFileRefactor from '@src/Spider/hooks/UseSystemFileRefactor';

const styles = {
  regulationCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1rem',
    backgroundColor: neutralColors.neutralColdWhite,
    padding: '1rem',
    borderRadius: '1rem',
  },
  regulationHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  regulationTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
  documents: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
};

const Regulation = ({ regulation, ...props }) => {
  const intl = useIntl();
  const { contract } = useParams();

  const [isOpenScreenRightDialog, setIsOpenScreenRightDialog] = useState(false);

  const [fetch, { data, isLoading, isSuccess, isFetching }] =
    useLazyGetHierarchyNodeSystemImagesListQuery();

  const { pagination, handleChangePage, paginationCount } = usePagination({
    defaultRowPerPage: 4,
    dataLength: isSuccess ? data.count : 0,
  });

  useEffect(() => {
    fetch({
      fixedCacheKey: `regulation${regulation.id}`,
      hierarchyNodeId: contract,
      codes: [regulation.code],
      statuses: [SystemFileStatuses.ACTIVE, SystemFileStatuses.INACTIVE],
      pagination,
    });
  }, [pagination.page]);

  const onFileUpdated = () => {
    setIsOpenScreenRightDialog(false);
  };

  const { updateSystemFile } = UseSystemFileRefactor({
    fixedCacheKey: `regulation${regulation.id}`,
    onFileUpdated,
    onFileDeleted: () => {},
    deleteSystemFileMutation: useDeleteSystemImageMutation,
    updateSystemFileMutation: useUpdateHierarchyNodeSystemImageMutation,
  });

  const openRegulationUpload = () => {
    setIsOpenScreenRightDialog(true);
  };

  const updateFile = async files => {
    await updateSystemFile(files[0], regulation.code, {
      hierarchyNodeId: contract,
    });
  };

  return (
    <div className="{props.classes.regulationCard}">
      <div className="{props.classes.regulationHeader}">
        <div className="{props.classes.regulationTitle}">
          <documenticon></documenticon>
          <typography variant="{'h3'}" component="{'h3'}">
            {regulation.label}
          </typography>
        </div>

        {isSuccess && (
          <button variant="{'outlined'}" color="{'primary'}" onClick="{openRegulationUpload}">
            {data.results.length === 0
              ? intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.regulations.addDocument',
                })
              : intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.regulations.updateDocument',
                })}
          </button>
        )}
      </div>

      {(isLoading || isFetching) && <loader centered=""></loader>}
      {isSuccess && data.results.length === 0 && (
        <typography variant="{'body1'}" style="{{" color:="" neutralColors.neutral600="" }}="">
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.regulations.emptyRegulations',
          })}
        </typography>
      )}
      {isSuccess && (
        <div className="{props.classes.documents}">
          {data.results.map((document, index) => (
            <react.fragment key="{document.id}">
              {index !== 0 && <divider></divider>}
              <regulationdocument document="{document}" showStatus="{regulation?.multiple}"></regulationdocument>
            </react.fragment>
          ))}
        </div>
      )}

      {isSuccess && regulation?.multiple && (
        <pagination count="{paginationCount}" page="{pagination.page}" onChange="{handleChangePage}" shape="rounded"></pagination>
      )}

      <rightdialogfileinput open="{isOpenScreenRightDialog}" title="{regulation.label}" authorizedFileTypes="{['PDF']}" setOpen="{value" ==""> setIsOpenScreenRightDialog(value)}
        alertComponent={regulation.alert}
        onValid={updateFile}
      />
    </rightdialogfileinput></div>
  );
};

export default withStyles(styles)(Regulation);
