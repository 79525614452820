import { useLocation, matchPath } from 'react-router-dom';
import { useMemo } from 'react';

/**
 * @typedef {Object} NavItem
 * @property {string} src - The route path for the navigation item
 * @property {string} text - The display label for the navigation item
 * @property {React.JSX.Element} [icon] - Optional icon component for the navigation item
 */

/**
 * A custom hook for managing sidebar navigation state
 * @param {NavItem[]} navItems - An array of navigation items
 * @returns {{
 *   activeNavItem: NavItem | undefined,
 *   isNavItemActive: (path: string) => boolean
 * }}
 */
const useActiveNavigation = navItems => {
  const location = useLocation();

  /**
   * Memoized value of the currently active navigation item
   * @type {NavItem | undefined}
   */
  const activeNavItem = useMemo(() => {
    return navItems.find(item =>
      matchPath(location.pathname, {
        path: item.path,
        exact: item.exact ?? true,
        strict: false,
      }),
    );
  }, [location.pathname, navItems]);

  /**
   * Check if a given path matches the current location
   * @param {string} path - The path to check
   * @returns {boolean} - Whether the path is active
   */
  const isNavItemActive = path => {
    return (
      matchPath(location.pathname, {
        path,
        exact: true,
        strict: false,
      }) !== null
    );
  };

  return {
    activeNavItem,
    isNavItemActive,
  };
};

export { useActiveNavigation };
