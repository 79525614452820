import { ordersList } from './datasets/ordersList';

/**
 * @typedef {import('./datasets/ordersList').OrderItem} OrderItem
 * @typedef {{id: string, page?: number, page_size?: number}} OrdersListParams
 * @param {OrdersListParams} params
 * @returns {Promise<{data: {results: OrderItem[], count: number}}>}
 */
export const mockedGetOrderList = ({ id, page = 1, page_size = 10 }) => {
  console.log('mocked order list with ID', id);

  return new Promise(resolve => {
    setTimeout(() => {
      const startIndex = (page - 1) * page_size;
      const endIndex = startIndex + page_size;

      const paginatedResults = ordersList.slice(startIndex, endIndex);

      resolve({
        data: {
          results: paginatedResults,
          count: ordersList.length,
        },
      });
    }, 600); // 600ms delay to simulate network latency
  });
};

/**
 * @typedef {import('./datasets/ordersList').OrderItem} OrderItem
 * @typedef {{id: string }} OrdersListParams
 * @param {OrdersListParams} params
 * @returns {Promise<{data: OrderItem}>}
 */
export const mockedGetOrderDetails = ({ id }) => {
  return new Promise(resolve => {
    const data = ordersList.find(o => o.uuid === id);
    setTimeout(() => {
      if (!data) console.error('No order for this ID');
      resolve({
        data,
      });
    }, 600);
  });
};
