import React from 'react';
import { useIntl } from 'react-intl';

const UserRoleName = ({ role }) => {
  const intl = useIntl();

  return (
    <>
      {role
        ? intl.formatMessage({
            id: `spider.enums.user_role.${role.toString().toLocaleLowerCase()}`,
          })
        : null}
    </>
  );
};

export default UserRoleName;
