import React from 'react';
import { withStyles } from '@mui/styles';
import { Button } from '@spider:src/components';
import { DocumentDownload } from 'iconsax-react';
import { Chip } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { SystemFileStatuses } from '@spider:src/enums/systemFileStatuses';
import { neutralColors } from '@spider:src/themes';
import { formatDate } from '@src/helpers/DateHelper';

const styles = {
  regulationDocument: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  regulationDocumentInformation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    color: neutralColors.neutral600,
  },
};

const RegulationDocument = ({ document, showStatus = true, ...props }) => {
  const intl = useIntl();

  const openBlankDocument = () => {
    window.open(document.src, '_blank');
  };

  return (
    <div className="{props.classes.regulationDocument}">
      <div className="{props.classes.regulationDocumentInformation}">
        <div>{document.filename}</div>
        <div>{formatDate(document.created_on)}</div>
        {showStatus && (
          <chip label="{" document.status="==" SystemFileStatuses.ACTIVE="" ?="" intl.formatMessage({="" id:="" 'spider.hierarchy_node.platform_customization.regulations.statusActive',="" })="" :="" 'spider.hierarchy_node.platform_customization.regulations.statusInactive',="" }="" className="{`${document.status" =="=" SystemFileStatuses.ACTIVE.toLowerCase()="" SystemFileStatuses.INACTIVE.toLowerCase()}`}=""></chip>
        )}
      </div>
      <button variant="{'contained'}" color="{'primary'}" size="{'small'}" className="{'icon" light'}="" onClick="{openBlankDocument}">
        <documentdownload></documentdownload>
      </button>
    </div>
  );
};

export default withStyles(styles)(RegulationDocument);
