export const GET_VALIDATED_COLLABORATOR_REWARD_ORDER_SUMMARY_LIST =
  'GET_VALIDATED_COLLABORATOR_REWARD_ORDER_SUMMARY_LIST';
export const GET_WAITING_COLLABORATOR_REWARD_ORDER_SUMMARY_LIST =
  'GET_WAITING_COLLABORATOR_REWARD_ORDER_SUMMARY_LIST';
export const GET_PENDING_COLLABORATOR_REWARD_ORDER_SUMMARY_LIST =
  'GET_PENDING_COLLABORATOR_REWARD_ORDER_SUMMARY_LIST';
export const GET_COLLABORATOR_REWARD_ORDER_SUMMARY_LIST_SUCCESS =
  'GET_COLLABORATOR_REWARD_ORDER_SUMMARY_LIST_SUCCESS';
export const GET_COLLABORATOR_REWARD_ORDER_SUMMARY_LIST_ERROR =
  'GET_COLLABORATOR_REWARD_ORDER_SUMMARY_LIST_ERROR';
