import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Formsy from 'formsy-react';
import { Button } from '../../../../components';
import { useIntl } from 'react-intl';
import { neutralColors, systemColors } from '../../../../themes';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { ReactComponent as PointDeclaration } from '../../../../assets/img/points-declaration.svg';
import TextField from '../../../../components/TextField/TextField';
import useSessionStorageSliceForm from '../../../../hooks/UseSessionStorageSliceForm';
import {
  clearDeclarationDataStep,
  setDeclarationDataStep,
} from '../../../../features/convertPoint/stepperData/slices';
import { createTheme } from '@material-ui/core/styles';
import { useAuth } from '../../../../../auth';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formFields: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [createTheme().breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  informationCard: {
    display: 'flex',
    padding: '1rem 1rem 1rem 1rem',
    flexDirection: 'column',
    gap: '0.5rem',
    justifyContent: 'center',
    borderRadius: '1rem',
    overflow: 'clip',
    position: 'relative',
    backgroundColor: neutralColors.neutralColdWhite,
  },
  block: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
};

const DeclarationStep = ({ nextStep, previousStep, ...props }) => {
  const intl = useIntl();
  const { hierarchyNodeUser } = useAuth();

  const [isHonoredAttestation, setIsHonoredAttestation] = useState(false);
  const [formError, setFormError] = useState(false);
  const [isSubmitDisable, setIsValidDisable] = useState(true);

  const originalLastnameMaxLength = 50;
  const birthDepartmentMaxLength = 5;
  const birthCommuneMaxLength = 3;
  const birthCountryMaxLength = 60;
  const nationalityCountryMaxLength = 60;
  const socialSecurityNumberMaxLength = 15;

  const socialRaisonMaxLength = 35;
  const addressMaxLength = 35;
  const zipCodeMaxLength = 5;
  const cityMaxLength = 29;

  const maxDate = new Date();

  const defaultFormData = {
    firstname: hierarchyNodeUser.firstname,
    lastname: hierarchyNodeUser.lastname,
    originalLastname: '',
    email: hierarchyNodeUser.email,

    line1: '',
    line2: '',
    zipCode: '',
    city: '',

    dateOfBirth: '',
    birthDepartment: '',
    birthCommune: '',
    birthCountry: '',
    nationalityCountry: '',
    socialSecurityNumber: '',

    employerSocialRaison: '',
    employerAddress: '',
    employerComplementaryAddress: '',
    employerZipCode: '',
    employerCity: '',
  };

  const { formData, handleFormChange, saveStateData } =
    useSessionStorageSliceForm({
      key: 'declarationStep',
      defaultFormData,
      sliceKey: 'getStepperDataKey',
      sliceProp: 'declarationDataStep',
      setClear: clearDeclarationDataStep,
      sliceSetter: setDeclarationDataStep,
      isDataSavable: () => true,
    });

  const checkApproval = event => {
    setIsHonoredAttestation(event.target.checked);
    if (formError) {
      setFormError(false);
    }
  };

  const validDeclarationStep = () => {
    if (!isHonoredAttestation) {
      setFormError(true);
      return;
    }

    nextStep();
  };

  return (
    <formsy name="{'declaration-form'}" onValidSubmit="{validDeclarationStep}" className="{props.classes.root}" onValid="{()" ==""> {
        setIsValidDisable(false);
        saveStateData(formData, true);
      }}
      onInvalid={() => setIsValidDisable(true)}
    >
      <div className="{props.classes.rootTitle}">
        <pointdeclaration fill="{neutralColors.neutral900}" style="{{" width:="" '1.5rem',="" height:="" '1.5rem'="" }}=""></pointdeclaration>
        <typography variant="{'h3'}" component="{'h3'}">
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.title',
          })}
        </typography>
      </div>

      <typography variant="{'body1'}" style="{{" fontStyle:="" 'italic'="" }}="">
        {intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.subtitle',
        })}
      </typography>

      <div className="{props.classes.informationCard}">
        <typography variant="{'subtitle1'}" style="{{" color:="" systemColors.infoRegular,="" fontWeight:="" 'bold'="" }}="">
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.infoLfssTitle',
          })}
        </typography>
        <div style="{{" display:="" 'flex',="" flexDirection:="" 'column'="" }}="">
          <typography variant="{'body2'}">
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.infoLfssMessageOne',
            })}
          </typography>
          <typography variant="{'body2'}">
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.infoLfssMessageTwo',
            })}
          </typography>
          <typography variant="{'body2'}">
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.infoLfssMessageThird',
            })}
          </typography>
        </div>
      </div>

      <div className="{props.classes.block}">
        <typography variant="{'h4'}" component="{'h4'}">
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.identification.title',
          })}
        </typography>

        <div className="{props.classes.formFields}">
          <textfield name="{'firstname'}" value="{formData.firstname}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.identification.firstnameField',="" })}="" variant="{'outlined'}" required="" disabled="" validations="{{}}" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'lastname'}" value="{formData.lastname}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.identification.lastnameField',="" })}="" variant="{'outlined'}" required="" disabled="" validations="{{}}" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'originalLastname'}" value="{formData.originalLastname}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.identification.originalLastnameField',="" })}="" variant="{'outlined'}" validations="{{" maxLength:="" originalLastnameMaxLength,="" }}="" validationErrors="{{" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(originalLastnameMaxLength),="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'email'}" value="{formData.email}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.identification.emailField',="" })}="" variant="{'outlined'}" disabled="" required="" validations="{{}}" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}="" onChange="{handleFormChange}"></textfield>
        </div>
      </div>
      <div className="{props.classes.block}">
        <typography variant="{'h4'}" component="{'h4'}">
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.personalAddress.title',
          })}
        </typography>
        <div className="{props.classes.formFields}">
          <textfield name="{'line1'}" value="{formData.line1}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.personalAddress.addressField',="" })}="" variant="{'outlined'}" required="" validations="{{" maxLength:="" addressMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(addressMaxLength),="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'line2'}" value="{formData.line2}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.personalAddress.complementaryAddressField',="" })}="" variant="{'outlined'}" validations="{{" maxLength:="" addressMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(addressMaxLength),="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'zipCode'}" type="{'number'}" value="{formData.zipCode}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.personalAddress.zipCodeField',="" })}="" variant="{'outlined'}" required="" validations="{{" isInt:="" true,="" maxLength:="" addressMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" 'common.form.is_int_error',="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(zipCodeMaxLength),="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'city'}" value="{formData.city}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.personalAddress.cityField',="" })}="" variant="{'outlined'}" required="" validations="{{" maxLength:="" cityMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(cityMaxLength),="" onChange="{handleFormChange}"></textfield>
        </div>
      </div>
      <div className="{props.classes.block}">
        <typography variant="{'h4'}" component="{'h4'}">
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.personalInformation.title',
          })}
        </typography>
        <div className="{props.classes.formFields}">
          <textfield name="{'dateOfBirth'}" value="{formData.dateOfBirth}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.informationStep.personalInformation.dateOfBirthField',="" })}="" variant="{'outlined'}" type="date" required="" InputLabelProps="{{" shrink:="" true,="" }}="" validations="{{" isDateLessThan:="" maxDate,="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.is_date_less_than',="" })="" .format(="" maxDate.toLocaleString('fr-FR',="" {="" day:="" '2-digit',="" month:="" year:="" 'numeric',="" ),="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'birthDepartment'}" type="{'number'}" value="{formData.birthDepartment}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.personalInformation.depsOfBirth',="" })}="" required="" variant="{'outlined'}" validations="{{" isInt:="" true,="" maxLength:="" birthDepartmentMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" 'common.form.is_int_error',="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(birthDepartmentMaxLength),="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'birthCommune'}" value="{formData.birthCommune}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.personalInformation.communeOfBirth',="" })}="" variant="{'outlined'}" required="" validations="{{" maxLength:="" birthCommuneMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(birthCommuneMaxLength),="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'birthCountry'}" value="{formData.birthCountry}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.personalInformation.countryOfBirth',="" })}="" variant="{'outlined'}" required="" validations="{{" maxLength:="" birthCountryMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(birthCountryMaxLength),="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'nationalityCountry'}" value="{formData.nationalityCountry}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.personalInformation.nationality',="" })}="" variant="{'outlined'}" required="" validations="{{" maxLength:="" nationalityCountryMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(nationalityCountryMaxLength),="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'socialSecurityNumber'}" type="{'number'}" value="{formData.socialSecurityNumber}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.personalInformation.socialSecurityNumber',="" })}="" variant="{'outlined'}" required="" validations="{{" isInt:="" true,="" securitySocialSecondRange:="" formData.dateOfBirth,="" securitySocialThirdRange:="" securitySocialFourthRange:="" formData.birthDepartment,="" securitySocialFifthRange:="" formData.birthCommune,="" hasMoreCharactersThan:="" socialSecurityNumberMaxLength,="" maxLength:="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" 'common.form.is_int_error',="" 'common.form.securitySocialSecondRange',="" 'common.form.securitySocialThirdRange',="" 'common.form.securitySocialFourthRange',="" 'common.form.securitySocialFifthRange',="" intl="" .formatMessage({="" 'common.form.has_more_characters_than',="" })="" .format(socialSecurityNumberMaxLength),="" 'common.form.max_length_custom_error',="" onChange="{handleFormChange}"></textfield>
        </div>
      </div>
      <div className="{props.classes.block}">
        <typography variant="{'h4'}" component="{'h4'}">
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.employerAddress.title',
          })}
        </typography>
        <div className="{props.classes.formFields}">
          <textfield name="{'employerSocialRaison'}" value="{formData.employerSocialRaison}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.employerAddress.socialRaison',="" })}="" variant="{'outlined'}" required="" validations="{{" maxLength:="" socialRaisonMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(socialRaisonMaxLength),="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'employerAddress'}" value="{formData.employerAddress}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.employerAddress.addressField',="" })}="" variant="{'outlined'}" required="" validations="{{" maxLength:="" addressMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(addressMaxLength),="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'employerComplementaryAddress'}" value="{formData.employerComplementaryAddress}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.employerAddress.complementaryAddressField',="" })}="" variant="{'outlined'}" validations="{{" maxLength:="" addressMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(addressMaxLength),="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'employerZipCode'}" type="{'number'}" value="{formData.employerZipCode}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.employerAddress.zipCodeField',="" })}="" variant="{'outlined'}" required="" validations="{{" isInt:="" true,="" maxLength:="" zipCodeMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" 'common.form.is_int_error',="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(zipCodeMaxLength),="" onChange="{handleFormChange}"></textfield>
          <textfield name="{'employerCity'}" value="{formData.employerCity}" label="{intl.formatMessage({" id:="" 'spider.convertPoints.stepper.declarationStep.employerAddress.cityField',="" })}="" variant="{'outlined'}" required="" validations="{{" maxLength:="" cityMaxLength,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" intl="" .formatMessage({="" 'common.form.max_length_custom_error',="" })="" .format(cityMaxLength),="" onChange="{handleFormChange}"></textfield>
        </div>
      </div>

      <formcontrol required="" error="{formError}" style="{{" display:="" 'flex',="" justifyContent:="" 'left',="" flexDirection:="" 'column',="" }}="">
        <formcontrollabel control="{" <Checkbox="" checked="{isHonoredAttestation}" className="{'root'}" disableRipple="" checkedIcon="{<span" checkedIcon'}=""></formcontrollabel>}
              icon={<span className="{'icon'}"></span>}
              inputProps={{ 'aria-label': 'decorative checkbox' }}
              onChange={checkApproval}
              required
            />
          }
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.honorAttestation',
          })}
          style={{ margin: 0 }}
        />
        {formError && (
          <formhelpertext>
            {intl.formatMessage({ id: 'spider.documentApproval.errorLabel' })}
          </formhelpertext>
        )}
      </formcontrol>

      <div className="{props.classes.actions}">
        <button color="{'primary'}" variant="{'outlined'}" size="{'large'}" onClick="{previousStep}">
          {intl.formatMessage({ id: 'spider.common.back' })}
        </button>
        <button color="{'primary'}" variant="{'contained'}" size="{'large'}" type="{'submit'}" disabled="{isSubmitDisable" ||="" !isHonoredAttestation}="">
          {intl.formatMessage({ id: 'spider.common.submit' })}
        </button>
      </div>
    </formsy>
  );
};

export default withStyles(styles)(DeclarationStep);
