export const OrderStatuses = Object.freeze({
  TREATED: 'TREATED',
  CANCELLED: 'CANCELLED',
  SAVED: 'SAVED',
  DONE: 'DONE',
});

/**
 *  @typedef { OrderStatuses[keyof OrderStatuses] } OrderStatusesType - Statuses for an order , should use the enum in `OrderStatuses.jsx`
 * @exports { OrderStatusesType };
 */
