import React from 'react';
import { injectIntl } from 'react-intl';
import { MainLayoutComponent } from '../../../../components';
import {
  ParticipantTypeFilter,
  PlayerPointConfig,
  TeamPointConfig,
} from './components';

class AdminPointConfig extends MainLayoutComponent {
  constructor(props) {
    super(props);
    this.state = {
      type: 'P',
    };
  }

  handleTypeChange(type) {
    this.setState({
      ...this.state,
      type: type,
    });
  }

  componentDidMount() {
    const { intl } = this.props;
    this.props.activateReturn();
    this.props.handleTitle(intl.formatMessage({ id: 'admin.title' }));
    this.props.handleMaxWidth('md');
    this.props.handleSubHeader(
      <participanttypefilter handleTypeChange="{this.handleTypeChange.bind(this)}"></participanttypefilter>,
    );
  }

  render() {
    const periodId = this.props.match.params.periodId;
    return (
      <div>
        {this.state.type == 'P' && <playerpointconfig period="{periodId}"></playerpointconfig>}
        {this.state.type == 'T' && <teampointconfig period="{periodId}"></teampointconfig>}
      </div>
    );
  }
}

export default injectIntl(AdminPointConfig);
