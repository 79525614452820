import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  AdministratorCollaboratorSelector,
  DefaultTitle,
  MainLayoutComponent,
  ManagerCollaboratorSelector,
} from '../../../../components';
import { Grid } from '@material-ui/core';

import { useIntl, injectIntl } from 'react-intl';

class BadgeHome extends MainLayoutComponent {
  componentDidMount() {
    const { intl } = this.props;
    const { account } = this.props.accountDetail;
    this.props.handleTitle(
      account.badgeWording || intl.formatMessage({ id: 'badge.title' }),
    );
  }

  handleClick(id) {
    this.props.history.push(`/badges/collaborator/${id}`);
  }

  render() {
    const { account } = this.props.accountDetail;
    const { intl } = this.props;

    const handleCollaboratorClick = collaborator => {
      const collaboratorUrl = `/badges/collaborator/${collaborator.id}`;
      this.props.history.push(collaboratorUrl);
    };

    if (!account.hasBadgeAccess) {
      return <redirect to="{'/'}"></redirect>;
    }
    return (
      <div>
        {account.role.code == 'C' && (
          <redirect to="{`/badges/collaborator/${account.id}`}"></redirect>
        )}
        {account.role.code == 'M' && (
          <managercollaboratorselector onClick="{this.handleClick.bind(this)}"></managercollaboratorselector>
        )}
        {(account.role.code == 'A' || account.role.code == 'S') && (
          <grid container="" spacing="{1}">
            <grid item="" xs="{12}">
              <administratorcollaboratorselector 0="" contextUrl="badges/" contextUrlOptions="{{" page:="" }}="" onClickCollaborator="{handleCollaboratorClick}"></administratorcollaboratorselector>
            </grid>
          </grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(withRouter(injectIntl(BadgeHome)));
