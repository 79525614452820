import React, { createContext } from 'react';

const Context = createContext();

const ThemeWrapper = ({
  primaryColor,
  secondaryColor,
  backgroundColor,
  errorColor,
  successColor,
  children,
}) => {
  return (
    <context.provider value="{{" primaryColor,="" secondaryColor,="" backgroundColor,="" errorColor,="" successColor,="" }}="">
      {children}
    </context.provider>
  );
};

export default { ThemeWrapper, Context };
