import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  commandId: null,
  convertPointsDataStep: null,
  informationDataStep: null,
  declarationDataStep: null,
  summaryDataStep: null,
};

const createStepperDataSlice = name =>
  createSlice({
    name: name,
    initialState,
    reducers: {
      setCommandId: (state, data) => {
        state.commandId = data.payload;
      },
      clearCommandId: state => {
        state.commandId = null;
      },
      setConvertPointDataStep: (state, data) => {
        state.convertPointsDataStep = data.payload;
      },
      clearConvertPointDataStep: state => {
        state.convertPointsDataStep = null;
      },
      setInformationDataStep: (state, data) => {
        state.informationDataStep = data.payload;
      },
      clearInformationDataStep: state => {
        state.informationDataStep = null;
      },
      setDeclarationDataStep: (state, data) => {
        state.declarationDataStep = data.payload;
      },
      clearDeclarationDataStep: state => {
        state.declarationDataStep = null;
      },
      setSummaryDataStep: (state, data) => {
        state.summaryDataStep = data.payload;
      },
      clearSummaryDataStep: state => {
        state.summaryDataStep = null;
      },
      clearStepperDataState: () => initialState,
    },
  });

export const getStepperDataKeySlice =
  createStepperDataSlice('getStepperDataKey');

export const {
  setCommandId,
  clearCommandId,
  setConvertPointDataStep,
  clearConvertPointDataStep,
  setInformationDataStep,
  clearInformationDataStep,
  setDeclarationDataStep,
  clearDeclarationDataStep,
  setSummaryDataStep,
  clearSummaryDataStep,
  clearStepperDataState,
} = getStepperDataKeySlice.actions;

export default {
  getStepperDataKey: getStepperDataKeySlice.reducer,
};
