import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@mui/styles';
import Formsy from 'formsy-react';
import { gradients, neutralColors, systemColors } from '../../../../themes';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ReactComponent as PointSummary } from '../../../../assets/img/points-summary.svg';
import { ReactComponent as MailIcon } from '../../../../assets/img/homeParticipantsConvert/mail-icon.svg';
import { ReactComponent as SuccessIcon } from '../../../../assets/img/success-icon.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/img/warning-icon.svg';
import { ReactComponent as InfoCircle } from '../../../../assets/img/homeParticipantsConvert/info-circle.svg';
import { Button } from '../../../../components';
import { CloseCircle, Edit2 } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import { setHierarchyNodeUser, useAuth } from '../../../../../auth';
import { Loader } from '../../../../../components';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@material-ui/core/styles';
import {
  orderRewardKeyClear,
  orderRewardKeyStart,
} from '../../../../features/rewards/orderReward/slices';
import { useHistory, useParams } from 'react-router-dom';
import { ParticipantType } from '../../../../enums/participantType';
import { PointCommandIds } from '../../../../enums';
import { allHierarchyNodeUsersFetchingStart } from '../../../../features/base/allPagesFetchingFromApi/slices';
import useHandleToast from '../../../../hooks/UseHandleToast';
import { Alert } from '../../../../components/Alert';
import { SystemCode } from '../../../../enums/systemCode';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  block: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
  informationCard: {
    display: 'flex',
    padding: '1rem 1rem 1rem 1rem',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'center',
    borderRadius: '1rem',
    overflow: 'clip',
    position: 'relative',
    backgroundColor: neutralColors.neutralColdWhite,
  },
  priceConverted: {
    display: 'flex',
    padding: '1rem',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '0.25rem',
    margin: 'auto',
    background: gradients.gradientLightRedRoseBackground,
  },
  topRightButtonWrapper: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    [createTheme().breakpoints.down('md')]: {
      position: 'relative',
      display: 'flex',
      top: '0rem',
      right: '0rem',
      justifyContent: 'right',
    },
  },
  emailChip: {
    display: 'flex',
    padding: '0.25rem 0.25rem 0.25rem 0.75rem',
    alignItems: 'center',
    gap: '0.5rem',
    borderRadius: '3rem',
    border: '1px solid #006CFA',
    width: 'max-content',
  },
  goodNewsCard: {
    display: 'flex',
    padding: '1rem 0.5rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    borderRadius: '0.5rem',
    background: neutralColors.neutralColdWhite,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const SummaryStep = ({
  nextStep,
  previousStep,
  goConvert,
  goInformation,
  ...props
}) => {
  const { hierarchyNodeUser } = useAuth();
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { contract } = useParams();

  const stepperData = useSelector(state => state.getStepperDataKey);
  const allHierarchyNodeUsersFetchingState = useSelector(
    state => state.allHierarchyNodeUsersFetching,
  );
  const orderReward = useSelector(state => state.orderRewardKey);
  const simulationRewardAllocation = useSelector(
    state => state.simulateRewardsAllocationsKey,
  );
  const isMobile = useMediaQuery(createTheme().breakpoints.down('md'));

  const [isHonoredAttestation, setIsHonoredAttestation] = useState(false);
  const [formError, setFormError] = useState(false);

  const [open, setOpen] = React.useState(false);

  const pointBalanceRest = useMemo(() => {
    if (
      !hierarchyNodeUser.point_balance ||
      !hierarchyNodeUser.point_balance.available
    )
      return 0;
    return hierarchyNodeUser.point_balance.available;
  }, [hierarchyNodeUser]);

  const closeDialog = () => {
    history.push(`/nodes/${contract}/home`);
    setOpen(false);
  };

  const openDialog = () => setOpen(true);

  const optionalField = field => {
    if (!field || field?.length === 0) return null;
  };

  const isSubmittable = isHonoredAttestation;

  const address = useMemo(() => {
    if (
      !stepperData.informationDataStep ||
      !stepperData.informationDataStep.line1 ||
      !stepperData.informationDataStep.zipCode ||
      !stepperData.informationDataStep.city
    )
      return null;
    else {
      return {
        line1: stepperData.informationDataStep.line1,
        line2: optionalField(stepperData.informationDataStep.line2),
        postal_code: stepperData.informationDataStep.zipCode,
        city: stepperData.informationDataStep.city,
      };
    }
  }, [stepperData.informationDataStep]);

  const personal_address = useMemo(() => {
    return address;
  }, [address]);

  const delivery_address = useMemo(() => {
    if (
      stepperData.commandId.uuid !== PointCommandIds.KSC &&
      hierarchyNodeUser.type === ParticipantType.MORALE
    ) {
      return {
        ...hierarchyNodeUser.legal_entity_address,
      };
    } else if (stepperData.commandId.uuid !== PointCommandIds.KSC) {
      return address;
    } else {
      return null;
    }
  }, [address, hierarchyNodeUser, stepperData]);

  const validSummaryStep = () => {
    if (!isHonoredAttestation) {
      setFormError(true);
      return;
    }

    const lfss_form = simulationRewardAllocation.simulation.order_suggested
      .lfss_form_required
      ? {
          birth_name: optionalField(
            stepperData.declarationDataStep.originalLastname,
          ),
          birth_date: stepperData.declarationDataStep.dateOfBirth,
          birth_department: stepperData.declarationDataStep.birthDepartment,
          birth_city: stepperData.declarationDataStep.birthCommune, // todo: see if commune is city ?
          birth_country: stepperData.declarationDataStep.birthCountry,
          nationality_country:
            stepperData.declarationDataStep.nationalityCountry,
          social_security_number:
            stepperData.declarationDataStep.socialSecurityNumber,
          personal_address: {
            line1: stepperData.declarationDataStep.line1,
            line2: optionalField(stepperData.declarationDataStep.line2),
            postal_code: stepperData.declarationDataStep.zipCode,
            city: stepperData.declarationDataStep.city,
          },
          employer_address: {
            line1: stepperData.declarationDataStep.employerAddress,
            line2: optionalField(
              stepperData.declarationDataStep.employerComplementaryAddress,
            ),
            postal_code: stepperData.declarationDataStep.employerZipCode,
            city: stepperData.declarationDataStep.employerCity,
            company_name: stepperData.declarationDataStep.employerSocialRaison,
          },
        }
      : null;

    const form = {};

    if (
      stepperData.commandId.uuid === PointCommandIds.CKU &&
      !hierarchyNodeUser.cku.last_active_cku
    ) {
      form.phone_number = stepperData.informationDataStep.phone;
      form.birth_date = stepperData.informationDataStep.dateOfBirth;
    }

    if (stepperData.commandId.uuid !== PointCommandIds.CKU) {
      form.phone_number = stepperData.informationDataStep.phone;
    }

    if (
      stepperData.commandId.uuid === PointCommandIds.CKU &&
      personal_address &&
      !hierarchyNodeUser.cku.last_active_cku &&
      hierarchyNodeUser.type === ParticipantType.MORALE
    ) {
      form.personal_address = personal_address;
    }

    if (delivery_address && hierarchyNodeUser.type === ParticipantType.PHYSIC) {
      form.delivery_address = delivery_address;
    }

    const items =
      simulationRewardAllocation.simulation.order_suggested.items ?? [];

    dispatch(
      orderRewardKeyStart({
        userId: hierarchyNodeUser.uuid,
        lfss_form,
        form,
        items,
      }),
    );
  };

  const checkApproval = event => {
    setIsHonoredAttestation(event.target.checked);
    if (formError) {
      setFormError(false);
    }
  };

  useHandleToast(
    orderReward,
    dispatch,
    orderRewardKeyClear,
    'spider.hierarchy_node.platform_customization.personalization.update.success',
    'spider.hierarchy_node.platform_customization.personalization.update.error',
  );

  useEffect(() => {
    if (orderReward.success) {
      dispatch(allHierarchyNodeUsersFetchingStart([contract, true]));
      openDialog();
    }
  }, [orderReward]);

  useEffect(() => {
    if (!allHierarchyNodeUsersFetchingState.data) return;
    const currentHierarchyNodeUser =
      allHierarchyNodeUsersFetchingState.data.find(
        user => user.uuid === hierarchyNodeUser.uuid,
      );
    if (!currentHierarchyNodeUser) return;
    dispatch(setHierarchyNodeUser(currentHierarchyNodeUser));
  }, [allHierarchyNodeUsersFetchingState]);

  return orderReward.loading ? (
    <loader centered=""></loader>
  ) : (
    <formsy name="{'summary-form'}" onValidSubmit="{validSummaryStep}" className="{props.classes.root}">
      <div className="{props.classes.rootTitle}">
        <pointsummary fill="{neutralColors.neutral900}" style="{{" width:="" '1.5rem',="" height:="" '1.5rem'="" }}=""></pointsummary>
        <typography variant="{'h3'}" component="{'h3'}">
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.title',
          })}
        </typography>
      </div>

      <div className="{props.classes.block}">
        <typography variant="{'h4'}" component="{'h4'}">
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.points.title',
          })}
        </typography>

        <div className="{props.classes.informationCard}" style="{{" padding:="" isMobile="" ?="" '1rem'="" :="" '2.5rem="" 1rem="" 1rem'="" }}="">
          <div className="{props.classes.topRightButtonWrapper}">
            <button variant="{'contained'}" size="{'small'}" style="{{" color:="" neutralColors.neutral900,="" backgroundColor:="" neutralColors.neutralWhite,="" }}="" onClick="{()" ==""> goConvert()}
            >
              <edit2></edit2>
              {intl.formatMessage({ id: 'spider.common.modify' })}
            </button>
          </div>

          {stepperData.convertPointsDataStep && (
            <typography variant="{isMobile" ?="" 'h4'="" :="" 'h2'}="" component="{isMobile" style="{{" fontWeight:="" 'normal',="" textAlign:="" 'center'="" }}="">
              {intl
                .formatMessage({
                  id: 'spider.convertPoints.stepper.summaryStep.points.convertInfo',
                })
                .format(
                  parseInt(
                    stepperData.convertPointsDataStep.points,
                  ).toFormatNumber(false),
                )}
            </typography>
          )}

          <div className="{props.classes.priceConverted}">
            {simulationRewardAllocation.loading && <loader centered=""></loader>}
            {simulationRewardAllocation.success &&
              !simulationRewardAllocation.loading && (
                <typography variant="{'h1'}" component="{'h1'}" style="{{" fontSize:="" '3.5rem',="" fontWeight:="" 'bold',="" color:="" neutralColors.neutralWhite,="" }}="">
                  {intl
                    .formatMessage({
                      id: 'spider.convertPoints.stepper.pointStep.price',
                    })
                    .format(
                      simulationRewardAllocation.simulation.order_suggested.monetary_value.toFormatNumber(),
                    )}
                </typography>
              )}
          </div>

          {stepperData.commandId && (
            <typography variant="{isMobile" ?="" 'h4'="" :="" 'h2'}="" component="{isMobile" style="{{" fontWeight:="" 'normal',="" textAlign:="" 'center'="" }}="">
              {intl
                .formatMessage({
                  id: 'spider.convertPoints.stepper.summaryStep.inConvert',
                })
                .format(stepperData.commandId.name)}
            </typography>
          )}
        </div>
      </div>

      <div className="{props.classes.block}">
        <typography variant="{'h4'}" component="{'h4'}">
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.personalInformation.title',
          })}
        </typography>

        {stepperData.commandId === PointCommandIds.CKU &&
          hierarchyNodeUser.type === ParticipantType.PHYSIC && (
            <alert type="{SystemCode.WARNING}" Icon="{()" ==""> <warningicon></warningicon>}
              title={intl.formatMessage({
                id: 'spider.convertPoints.stepper.summaryStep.personalInformation.alertTitle',
              })}
              titleVariant={'subtitle1'}
              titleComponent={'span'}
              Content={() => (
                <typography variant="{'body2'}">
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.summaryStep.personalInformation.alertMessage',
                  })}
                </typography>
              )}
            />
          )}
        <div className="{props.classes.informationCard}">
          {!isMobile && (
            <typography variant="{'body1'}" style="{{" fontWeight:="" 'bold'="" }}="">
              Marie-Christine Fernandez-Robert
            </typography>
          )}
          <div className="{props.classes.topRightButtonWrapper}" style="{{" justifyContent:="" 'space-between',="" alignItems:="" 'center'="" }}="">
            {isMobile && (
              <typography variant="{'body1'}" style="{{" fontWeight:="" 'bold'="" }}="">
                {`${hierarchyNodeUser.firstname} ${hierarchyNodeUser.lastname}`}
              </typography>
            )}
            <button variant="{'contained'}" size="{'small'}" style="{{" color:="" neutralColors.neutral900,="" backgroundColor:="" neutralColors.neutralWhite,="" }}="" onClick="{()" ==""> goInformation()}
            >
              <edit2></edit2>
              {intl.formatMessage({ id: 'spider.common.modify' })}
            </button>
          </div>

          {stepperData.informationDataStep && (
            <div style="{{" display:="" 'flex',="" flexDirection:="" 'column',="" gap:="" '0.5rem',="" }}="">
              {stepperData.informationDataStep.dateOfBirth && (
                <typography variant="{'body2'}">
                  {intl
                    .formatMessage({
                      id: 'spider.convertPoints.stepper.summaryStep.personalInformation.bornOn',
                    })
                    .format(stepperData.informationDataStep.dateOfBirth)}
                </typography>
              )}
              {stepperData.informationDataStep.phone && (
                <typography variant="{'body2'}">
                  {stepperData.informationDataStep.phone}
                </typography>
              )}
              {personal_address && personal_address.line1 && (
                <typography variant="{'body2'}">
                  {personal_address.line1}
                </typography>
              )}
              {personal_address && personal_address.line2 && (
                <typography variant="{'body2'}">
                  {personal_address.line2}
                </typography>
              )}
              {personal_address &&
                personal_address.city &&
                personal_address.postal_code && (
                  <typography variant="{'body2'}">
                    {`${personal_address.postal_code} ${personal_address.city}`}
                  </typography>
                )}
            </div>
          )}

          <div style="{{" display:="" 'flex',="" alignItems:="" 'center',="" gap:="" '0.5rem'="" }}="">
            <typography variant="{'body2'}" style="{{" fontWeight:="" 'bold'="" }}="">
              Nous allons envoyer le code d’activation de votre carte à
              l’adresse suivante :
            </typography>
            <div className="{props.classes.emailChip}">
              <mailicon fill="{systemColors.infoRegular}"></mailicon>
              <typography variant="{'body1'}" style="{{" color:="" systemColors.infoRegular,="" fontWeight:="" 'bold'="" }}="">
                {hierarchyNodeUser.email}
              </typography>
            </div>
          </div>
        </div>
      </alert></div>

      {delivery_address !== null && (
        <div className="{props.classes.block}">
          <typography variant="{'h4'}" component="{'h4'}">
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.deliveryAddress.title',
            })}
          </typography>

          <div className="{props.classes.informationCard}">
            <div style="{{" display:="" 'flex',="" flexDirection:="" 'column',="" gap:="" '0.5rem',="" }}="">
              {delivery_address.company_name && (
                <typography variant="{'body2'}">
                  {delivery_address.company_name}
                </typography>
              )}
              {delivery_address.line1 && (
                <typography variant="{'body2'}">
                  {delivery_address.line1}
                </typography>
              )}
              {delivery_address.line2 && (
                <typography variant="{'body2'}">
                  {delivery_address.line2}
                </typography>
              )}
              {delivery_address.city && delivery_address.postal_code && (
                <typography variant="{'body2'}">
                  {`${delivery_address.postal_code} ${delivery_address.city}`}
                </typography>
              )}
            </div>
          </div>
        </div>
      )}

      {stepperData.commandId &&
        stepperData.commandId !== PointCommandIds.KSC && (
          <alert Icon="{()" ==""> <infocircle width="{'1.5rem'}" height="{'1.5rem'}"></infocircle>}
            title={intl
              .formatMessage({
                id: 'spider.convertPoints.stepper.summaryStep.deliveryAddress.alertTitle',
              })
              .format(stepperData.commandId.name)}
            titleVariant={'body2'}
            titleComponent={'span'}
            titleStyle={{
              fontWeight: 'normal',
              color: neutralColors.neutral600,
            }}
            style={{ marginTop: '-1rem' }}
          />
        )}

      {stepperData.commandId && (
        <formcontrol required="" error="{formError}" style="{{" display:="" 'flex',="" justifyContent:="" 'left',="" flexDirection:="" 'column',="" }}="">
          <formcontrollabel control="{" <Checkbox="" checked="{isHonoredAttestation}" className="{'root'}" disableRipple="" checkedIcon="{<span" checkedIcon'}=""></formcontrollabel>}
                icon={<span className="{'icon'}"></span>}
                inputProps={{ 'aria-label': 'decorative checkbox' }}
                onChange={checkApproval}
                required
              />
            }
            label={intl
              .formatMessage({
                id: 'spider.convertPoints.stepper.summaryStep.readAndLearnAttestation',
              })
              .format(stepperData.commandId.name)}
            style={{ margin: 0 }}
          />
          {formError && (
            <formhelpertext>
              {intl.formatMessage({ id: 'spider.documentApproval.errorLabel' })}
            </formhelpertext>
          )}
        </formcontrol>
      )}

      <div className="{props.classes.actions}">
        <button color="{'primary'}" variant="{'outlined'}" size="{'large'}" onClick="{previousStep}">
          {intl.formatMessage({ id: 'spider.common.back' })}
        </button>
        <button color="{'primary'}" variant="{'contained'}" size="{'large'}" type="{'submit'}" disabled="{!isSubmittable}">
          {intl.formatMessage({ id: 'spider.common.submit' })}
        </button>
      </div>

      <dialog open="{open}" onClose="{closeDialog}">
        <dialogtitle disableTypography="" style="{{" alignItems:="" 'baseline'="" }}="">
          <div style="{{" display:="" 'flex',="" width:="" '100%',="" justifyContent:="" 'flex-end',="" }}="">
            <button variant="{'contained'}" color="{'primary'}" size="{'small'}" className="{'icon" light'}="" onClick="{closeDialog}">
              <closecircle></closecircle>
            </button>
          </div>
        </dialogtitle>

        <dialogcontent style="{{" display:="" 'flex',="" flexDirection:="" 'column',="" gap:="" '2.5rem'="" }}="">
          <successicon style="{{" margin:="" 'auto'="" }}=""></successicon>

          <div>
            <typography variant="{'h1'}" component="{'h1'}" style="{{" width:="" '100%',="" textAlign:="" 'center'="" }}="">
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.orderRewardDialog.title',
              })}
            </typography>
            <typography variant="{'h1'}" component="{'h1'}" className="{'underline-center'}" style="{{" width:="" '100%',="" textAlign:="" 'center'="" }}="">
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.orderRewardDialog.subtitle',
              })}
            </typography>
          </div>

          <div>
            <typography variant="{'subtitle1'}" style="{{" textAlign:="" 'center'="" }}="">
              {intl
                .formatMessage({
                  id: 'spider.convertPoints.stepper.orderRewardDialog.message',
                })
                .format(
                  parseInt(
                    stepperData.convertPointsDataStep.points,
                  ).toFormatNumber(false),
                  stepperData.commandId ? stepperData.commandId.name : '',
                )}
            </typography>
            <typography variant="{'subtitle1'}" style="{{" textAlign:="" 'center'="" }}="">
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.orderRewardDialog.mailMessage',
              })}
            </typography>
          </div>

          {pointBalanceRest > 0 && (
            <div className="{props.classes.goodNewsCard}">
              <typography variant="{'h2'}" component="{'h2'}" style="{{" textAlign:="" 'center',="" background:="" 'linear-gradient(90deg,="" #F72717="" 0%,="" #FF007D="" 100%)',="" '-webkit-background-clip':="" 'text',="" '-webkit-text-fill-color':="" 'transparent',="" }}="">
                {intl.formatMessage({
                  id: 'spider.convertPoints.stepper.orderRewardDialog.convertGoodNews',
                })}
              </typography>
              <div>
                <typography variant="{'subtitle1'}" style="{{" textAlign:="" 'center'="" }}="">
                  {intl
                    .formatMessage({
                      id: 'spider.convertPoints.stepper.orderRewardDialog.messageGoodNews',
                    })
                    .format(pointBalanceRest.toFormatNumber(false))}
                </typography>
                <typography variant="{'subtitle1'}" style="{{" textAlign:="" 'center'="" }}="">
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.orderRewardDialog.subMessageGoodNews',
                  })}
                </typography>
              </div>

              <button size="{'small'}" color="{'primary'}" variant="{'contained'}" onClick="{closeDialog}">
                {intl.formatMessage({
                  id: 'spider.convertPoints.stepper.orderRewardDialog.continueConvert',
                })}
              </button>
            </div>
          )}
        </dialogcontent>

        {pointBalanceRest === 0 && (
          <dialogactions>
            <button size="{'small'}" color="{'primary'}" variant="{'contained'}" onClick="{closeDialog}">
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.pointBalanceDialog.closeAction',
              })}
            </button>
          </dialogactions>
        )}
      </dialog>
    </alert></formsy>
  );
};

export default withStyles(styles)(SummaryStep);
