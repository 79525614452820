import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faEdit,
  faSlidersH,
  faTrash,
  faChevronDown,
  faChevronUp,
  faCog,
  faShareAlt,
  faShare,
  faLink,
  faChainBroken,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { ChallengeParticipantsEdit } from '../../components/ChallengeParticipantsEdit';
import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Grid,
  CardMedia,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { SubHeader } from './components';
import '../../../../helpers/DateHelper';
import {
  ChallengeCondition,
  CollaboratorChallengeRankList,
  ChallengeDetailFilter,
  ChallengeCollaboratorFilter,
  ChallengeSearchBar,
} from '../../components';
import {
  IconButton,
  MainLayoutComponent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  ProgressButton,
  Loader,
  DefaultText,
  Dropdown,
  CollaboratorFilterAndSearchBar,
} from '../../../../components';
import {
  SubHeaderContainer,
  HeaderTitle,
  MainContainer,
} from '../../../../components/Common/components/MainLayout/components/MainLayout/components/';
import { useIntl, injectIntl } from 'react-intl';
import * as configListActions from '../../../../services/Configs/ConfigList/actions';
import * as collaboratorChallengeRankListActions from '../../../../services/CollaboratorChallengeRanks/CollaboratorChallengeRankList/actions';
import * as teamGroupCollaboratorChallengeDetailActions from '../../../../services/TeamGroupCollaboratorChallenges/TeamGroupCollaboratorChallengeDetail/actions';
import * as teamCollaboratorChallengeGoalListByTeamGroupActions from '../../../../services/TeamCollaboratorChallengeGoals/TeamCollaboratorChallengeGoalListByTeamGroup/actions';
import * as challengeDeleteActions from '../../../../services/Challanges/ChallengeDelete/actions';
import * as challengeSummaryActions from '../../../../services/Challanges/ChallengeSummary/actions';
import * as challengeParticipantListActions from '../../../../services/ChallengeParticipants/ChallengeParticipantList/actions';

import * as Resources from '../../../../Resources';
import { Tooltip } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';

import _ from 'lodash';

const styles = theme => {
  return {
    iconMargin: {
      marginLeft: 16,
    },
    wrapper: {
      height: 'calc(100vh - 65px)',
      marginTop: 0,
      overflowY: 'overlay',

      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(199, 199, 199, 0)',
        borderRadius: 5,
      },

      '&::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0)',
      },
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        '&:vertical': {
          width: 10,
        },
      },
    },
    activeScroll: {
      '&:hover': {
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(199, 199, 199, 1)',
          borderRadius: 5,
        },
      },
    },
    subheaderRoot: {
      background: 'transparent',
    },
    subheaderContainer: {
      zIndex: 500,
      paddingTop: 0,
    },
    subheaderContainerMobile: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    subheaderContainerChild: {
      marginBottom: 20,
    },
    shareButton: {
      border: '1px solid #ccc',
      borderRadius: '5px',
      padding: '15px 10px',
      cursor: 'pointer',
      transition: 'all ease-in 0.1s',
      '&:hover': {
        background: '#efefef',
        border: '1px solid #333',
      },
    },
    shareDialog: {
      width: 600,
    },
    activeColorPrimary: {
      color: theme.palette.primary.main,
    },
    defaultColor: {
      color: '#333',
    },
    activeColorSecondary: {
      background: theme.palette.secondary.main,
      boxShadow: `0 2px 16px 0 ${theme.palette.secondary.main}`,
    },
  };
};

class TeamGroupCollaboratorChallengeDetail extends MainLayoutComponent {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const teamGroup = params.get('teamGroup');
    const team = params.get('team');
    const name = params.get('name');
    const { account } = this.props.accountDetail;
    this.initialized = false;
    this.observer = new IntersectionObserver(this.handleObserver);
    this.rankLoader = React.createRef();
    this.teamsShareButton = React.createRef();
    this.state = {
      page: account.hasChallengeRankAccess ? 0 : 1,
      rankPage: 1,
      ranks: null,
      ranksLoaded: false,
      deletePromptOpen: false,
      mobileMenuAnchor: null,
      team: team,
      name,
      teamGroup: teamGroup,
      filter: {},
      shareDropdownOpen: false,
    };
  }

  // infinite scroll
  handleObserver = () => {
    const { loading, ranks: fetchedRanks } =
      this.props.collaboratorChallengeRankList;
    const hasNextRankPage =
      this.state.ranksLoaded && fetchedRanks && fetchedRanks.length > 0;
    if (hasNextRankPage) {
      this.loadNextRankPage();
    }
  };

  handleDuplicate() {
    const { challenge } = this.props.teamGroupCollaboratorChallengeDetail;
    var url = `/challenges/duplication/${challenge.sourceId}`;
    if (challenge.teamId) url += `?team=${challenge.teamId}`;
    this.props.history.push(url);
  }
  handleFilterOpen() {
    this.setState({
      ...this.state,
      filterOpen: true,
    });
  }

  handleFilterClose() {
    this.setState({
      ...this.state,
      filterOpen: false,
    });
  }

  // challenge collaborator filter
  handleFilterChange = (
    team,
    collaborator,
    year,
    start,
    end,
    type,
    teamGroup,
  ) => {
    const { challenge } = this.props.teamGroupCollaboratorChallengeDetail;

    this.setState(
      {
        ...this.state,
        filter: {
          collaborator,
          team,
          teamGroup,
        },
      },
      () => {
        this.props.challengeSummaryActions.getChallengeSummary(
          challenge.sourceId,
          collaborator,
          team,
          teamGroup,
        );
      },
    );
  };

  handleEdit() {
    const { challenge } = this.props.teamGroupCollaboratorChallengeDetail;
    this.props.history.push(`/challenges/modification/${challenge.sourceId}`);
  }

  handleEditParticipants = (value = true) => {
    this.setState({
      ...this.state,
      editParticipantsOpen: value,
    });
  };

  handleEditParticipantsSubmit = () => {
    this.setState(
      {
        ...this.state,
        editParticipantsOpen: false,
      },
      () => {
        this.applySearch();
      },
    );
  };

  async onDelete() {
    const { challenge } = this.props.teamGroupCollaboratorChallengeDetail;
    const { intl } = this.props;

    await this.props.challengeDeleteActions.deleteChallenge(challenge);
    toast.success(intl.formatMessage({ id: 'challenge.delete_success' }));
    setTimeout(() => {
      this.props.history.goBack();
    }, 200);
  }
  setDeletePromptOpen(isOpen) {
    this.setState({
      ...this.state,
      deletePromptOpen: isOpen,
    });
  }

  handlePageChange(page) {
    this.setState(
      {
        ...this.state,
        page: page,
      },
      () => {
        const scrollContainer = document.getElementById('challenge-scroll');
        window.scrollTo(0, 0);
        if (scrollContainer) {
          scrollContainer.scrollTo(0, 0);
        }
      },
    );
  }

  refresh(team, teamGroup, search) {
    const id = this.props.match.params.id;
    var url = `/challenges/detail/team-group-collaborator/${id}`;
    if (team) {
      url += `?team=${team}`;
    } else if (teamGroup) {
      url += `?teamGroup=${teamGroup}`;
    }
    if (search) {
      url += `${team || teamGroup ? '&' : '?'}name=${search}`;
    }
    this.props.history.replace(url);
  }

  handleFilterDetailChange(team, teamGroup) {
    this.props.collaboratorChallengeRankListActions.getCollaboratorChallengeRankListClear();
    this.setState(
      {
        ...this.state,
        rankPage: 1,
        ranks: null,
        ranksLoaded: false,
      },
      () => {
        this.refresh(team, teamGroup);
      },
    );
  }

  applySearch(search) {
    const params = new URLSearchParams(window.location.search);
    const team = params.get('team');
    const teamGroup = params.get('teamGroup');
    if (search !== this.state.name) {
      this.props.collaboratorChallengeRankListActions.getCollaboratorChallengeRankListClear();
      this.setState(
        {
          ...this.state,
          rankPage: 1,
          ranks: null,
          allRanks: null,
          ranksLoaded: false,
          name: search,
        },
        () => {
          this.refresh(team, teamGroup, search);
          this.loadNextRankPage();
        },
      );
    }
  }

  setMobileMenuAnchor = el => {
    this.initialized = false;
    this.setState({
      ...this.state,
      mobileMenuAnchor: el,
    });
  };

  handleResize = () => {
    if (this.mobileScreen !== this.props.width) {
      this.initialized = false;
      this.mobileScreen = this.props.width;
    }
  };
  componentDidMount() {
    const { intl } = this.props;
    const { account } = this.props.accountDetail;
    const id = this.props.match.params.id;
    this.props.handleTitle(
      account.challengeWording || intl.formatMessage({ id: 'challenge.title' }),
    );

    this.props.handleMaxWidth(false);
    this.props.activateReturn();

    const params = new URLSearchParams(window.location.search);
    const team = params.get('team');
    const teamGroup = params.get('teamGroup');
    const name = params.get('name');
    this.props.collaboratorChallengeRankListActions.getCollaboratorChallengeRankListByTeamGroupCollaboratorChallenge(
      id,
      null,
      team,
      teamGroup,
      name,
    );
    this.props.teamGroupCollaboratorChallengeDetailActions.getTeamGroupCollaboratorChallengeDetail(
      id,
    );
    this.props.teamCollaboratorChallengeGoalListByTeamGroupActions.getTeamCollaboratorChallengeGoalListByTeamGroup(
      id,
    );

    window.addEventListener('resize', this.handleResize);
    this.mobileScreen = this.props.width;
  }

  applyFilter = () => {
    const { summary } = this.props.challengeSummary;
    const { collaborator, team, teamGroup } = this.state.filter;

    if (summary) {
      this.props.challengeSummaryActions.getChallengeSummaryClear();
      if (collaborator) {
        this.props.history.replace(
          `/challenges/detail/collaborator/${summary.id}`,
        );
      } else if (team) {
        if (summary.typeCode == 'CT' || summary.typeCode == 'TP') {
          this.props.history.replace(`/challenges/detail/team/${summary.id}`);
        } else {
          this.props.history.replace(
            `/challenges/detail/team-collaborator/${summary.id}`,
          );
        }
      } else if (teamGroup) {
        if (summary.typeCode == 'CT') {
          this.props.history.replace(
            `/challenges/detail/team-group/${summary.id}`,
          );
        } else {
          this.props.history.replace(
            `/challenges/detail/team-group-collaborator/${summary.id}`,
          );
        }
        window.location.reload();
      }
    }
  };

  setShareDropdownOpen = value => {
    this.setState({
      ...this.state,
      shareDropdownOpen: value,
      copiedToClipboard: false,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { intl } = this.props;
    const { challenge } = this.props.teamGroupCollaboratorChallengeDetail;
    const { account } = this.props.accountDetail;
    const id = this.props.match.params.id;
    const { loading, ranks } = this.props.collaboratorChallengeRankList;
    const mobileScreen = isWidthDown('xs', this.props.width);

    const params = new URLSearchParams(window.location.search);
    const currentTeam = params.get('team');
    const currentTeamGroup = params.get('teamGroup');
    const name = params.get('name');

    this.applyFilter();

    if (
      currentTeam !== this.state.team ||
      currentTeamGroup !== this.state.teamGroup
    ) {
      this.setState(
        {
          ...this.state,
          team: currentTeam,
          teamGroup: currentTeamGroup,
          rankPage: 1,
          ranks: null,
          allRanks: null,
          ranksLoaded: false,
          name,
        },
        this.loadNextRankPage,
      );
    }

    const team = this.state.team;

    if (ranks && !loading && !this.state.ranksLoaded) {
      const rankList = [
        ...(this.state.allRanks ? this.state.allRanks : []),
        ...ranks,
      ];
      const filteredRankList = rankList;

      const rankingIsFilled = filteredRankList.length >= 20;
      this.setState(
        {
          ...this.state,
          ranksLoaded: rankingIsFilled,
          rankPage: this.state.rankPage + 1,
          ranks: _.uniqBy(filteredRankList, r => r.id),
          allRanks: rankList,
        },
        () => {
          if (!rankingIsFilled) {
            this.loadNextRankPage();
          }
        },
      );
    }
    if (this.rankLoader.current) this.observer.observe(this.rankLoader.current);

    if (!this.initialized && challenge) {
      const { classes } = this.props;
      this.initialized = true;
      const hasExclusiveManagerTeams =
        (account.team &&
          challenge.participantTeamIds.length === 1 &&
          challenge.participantTeamIds.indexOf(account.team.id) >= 0) ||
        (account.team_group &&
          _.intersection(
            _.get(account, 'team_group.allTeamIds'),
            challenge.participantTeamIds,
          ).length === challenge.participantTeamIds.length);

      const hasPartialManagerTeams =
        (account.team &&
          challenge.participantTeamIds.indexOf(account.team.id) >= 0) ||
        (account.team_group &&
          _.intersection(
            _.get(account, 'team_group.allTeamIds'),
            challenge.participantTeamIds,
          ).length > 0);

      const canEdit =
        (account.hasManagerChallengeEditAccess && hasExclusiveManagerTeams) ||
        account.role.code === 'A';

      const canEditParticipants =
        (account.hasManagerChallengeParticipantEditAccess &&
          hasPartialManagerTeams) ||
        account.role.code === 'A';

      const desktopMenu = (
        <div>
          {canEdit && (
            <tooltip title="{intl.formatMessage({" id:="" 'common.duplicate'="" })}="">
              <iconbutton size="{'small'}" onClick="{this.handleDuplicate.bind(this)}">
                <fontawesomeicon icon="{faCopy}"></fontawesomeicon>
              </iconbutton>
            </tooltip>
          )}
          {canEdit &&
            challenge.end.toDate2().getTime() > new Date().getTime() && (
              <react.fragment>
                <tooltip title="{intl.formatMessage({" id:="" 'common.delete'="" })}="">
                  <iconbutton size="{'small'}" onClick="{()" ==""> this.setDeletePromptOpen(true)}
                    className={classes.iconMargin}
                  >
                    <fontawesomeicon icon="{faTrash}"></fontawesomeicon>
                  </iconbutton>
                </tooltip>
                <tooltip title="{intl.formatMessage({" id:="" 'common.edit'="" })}="">
                  <iconbutton size="{'small'}" onClick="{this.handleEdit.bind(this)}" className="{classes.iconMargin}">
                    <fontawesomeicon icon="{faEdit}"></fontawesomeicon>
                  </iconbutton>
                </tooltip>
              </react.fragment>
            )}
          {canEditParticipants &&
            challenge.end.toDate2().getTime() > new Date().getTime() && (
              <tooltip title="{intl.formatMessage({" id:="" 'common.edit_participants'="" })}="">
                <iconbutton size="{'small'}" onClick="{this.handleEditParticipants}" className="{classes.iconMargin}">
                  <fontawesomeicon icon="{faUserPlus}"></fontawesomeicon>
                </iconbutton>
              </tooltip>
            )}

          <tooltip title="{intl.formatMessage({" id:="" 'common.share'="" })}="">
            <iconbutton size="{'small'}" onClick="{()" ==""> this.setShareDropdownOpen(true)}
              className={classes.iconMargin}
            >
              <fontawesomeicon icon="{faShare}"></fontawesomeicon>
            </iconbutton>
          </tooltip>

          <iconbutton size="small" onClick="{this.handleFilterOpen.bind(this)}" className="{classes.iconMargin}">
            <fontawesomeicon icon="{faSlidersH}"></fontawesomeicon>
          </iconbutton>
        </div>
      );
      const open = Boolean(this.state.mobileMenuAnchor);
      const arrowIcon = faCog;
      const mobileMenu = (
        <div>
          <iconbutton aria-controls="basic-menu" aria-haspopup="true" size="{'small'}" onClick="{event" =="">
              this.setMobileMenuAnchor(open ? null : event.currentTarget)
            }
            className={classes.iconMargin}
          >
            <fontawesomeicon icon="{arrowIcon}"></fontawesomeicon>
          </iconbutton>
          <menu id="basic-menu" anchorEl="{this.state.mobileMenuAnchor}" open="{open}" onClose="{()" ==""> this.setMobileMenuAnchor()}
            elevation={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{ marginTop: 30 }}
          >
            <menuitem onClick="{()" ==""> this.setShareDropdownOpen(true)}>
              <listitemicon 10="" style="{{" color:="" '#333',="" minWidth:="" 0,="" marginRight:="" }}="">
                <fontawesomeicon icon="{faShare}"></fontawesomeicon>
              </listitemicon>
              <listitemtext>
                {intl.formatMessage({ id: 'common.share' })}
              </listitemtext>
            </menuitem>
            {canEdit && (
              <menuitem onClick="{this.handleDuplicate.bind(this)}">
                <listitemicon 10="" style="{{" color:="" '#333',="" minWidth:="" 0,="" marginRight:="" }}="">
                  <fontawesomeicon icon="{faCopy}"></fontawesomeicon>
                </listitemicon>
                <listitemtext>
                  {intl.formatMessage({ id: 'common.duplicate' })}
                </listitemtext>
              </menuitem>
            )}
            {canEdit &&
              challenge.end.toDate2().getTime() > new Date().getTime() && (
                <react.fragment>
                  <menuitem onClick="{()" ==""> this.setDeletePromptOpen(true)}>
                    <listitemicon 10="" style="{{" color:="" '#333',="" minWidth:="" 0,="" marginRight:="" }}="">
                      <fontawesomeicon icon="{faTrash}"></fontawesomeicon>
                    </listitemicon>
                    <listitemtext>
                      {intl.formatMessage({ id: 'common.delete' })}
                    </listitemtext>
                  </menuitem>
                  <menuitem onClick="{this.handleEdit.bind(this)}">
                    <listitemicon 10="" style="{{" color:="" '#333',="" minWidth:="" 0,="" marginRight:="" }}="">
                      <fontawesomeicon icon="{faEdit}"></fontawesomeicon>
                    </listitemicon>
                    <listitemtext>
                      {intl.formatMessage({ id: 'common.edit' })}
                    </listitemtext>
                  </menuitem>
                </react.fragment>
              )}
            {canEditParticipants &&
              challenge.end.toDate2().getTime() > new Date().getTime() && (
                <menuitem onClick="{this.handleEditParticipants}">
                  <listitemicon 10="" style="{{" color:="" '#333',="" minWidth:="" 0,="" marginRight:="" }}="">
                    <fontawesomeicon icon="{faUserPlus}"></fontawesomeicon>
                  </listitemicon>
                  <listitemtext>
                    {intl.formatMessage({ id: 'common.edit_participants' })}
                  </listitemtext>
                </menuitem>
              )}
          </menu>
        </div>
      );
      this.props.handleButtons(mobileScreen ? mobileMenu : desktopMenu);
    }
    if (document.getElementById('challenge-scroll')) {
      document
        .getElementById('challenge-scroll')
        .addEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    const scroll =
      _.get(document.getElementById('challenge-scroll'), 'scrollTop') || 0;
    const scrollLimit = 45;
    if (scroll && scroll > scrollLimit && !this.state.displayScroll) {
      this.setState({
        ...this.state,
        displayScroll: true,
      });
    } else if (scroll <= scrollLimit && this.state.displayScroll) {
      this.setState({
        ...this.state,
        displayScroll: false,
      });
    }
  };

  loadNextRankPage = () => {
    const id = this.props.match.params.id;
    // load next rank page
    const params = new URLSearchParams(window.location.search);
    const team = params.get('team');
    const teamGroup = params.get('teamGroup');
    const name = params.get('name');
    this.props.collaboratorChallengeRankListActions.getCollaboratorChallengeRankListByTeamGroupCollaboratorChallenge(
      id,
      this.state.rankPage,
      team,
      teamGroup,
      name,
    );
    this.setState({
      ...this.state,
      ranksLoaded: false,
    });
  };

  getShareText = () => {
    const { challenge } = this.props.teamGroupCollaboratorChallengeDetail;
    const { intl } = this.props;

    const today = new Date();
    let daysLeft = 0;
    if (challenge) {
      daysLeft = Math.floor(
        (challenge.end * 1000 - today.getTime()) / (1000 * 3600 * 24),
      );
    }
    return intl.formatMessage({id: 'challenge.share.share_text_with_days'}, { challengeName: challenge && challenge.name, daysLeft: daysLeft })
  };
  getClipboardText = () => {
  };
  getClipboardText = () => {
    const { challenge } = this.props.teamGroupCollaboratorChallengeDetail;
    const { ranks } = this.state;
    const { intl } = this.props;
    const today = new Date();
    let daysLeft = 0;
    if (challenge) {
      daysLeft = Math.floor(
        (challenge.end * 1000 - today.getTime()) / (1000 * 3600 * 24),
      );
    }
    return (
      <div id="copy-clipboard">
        <div style="{{" backgroundColor:="" 'transparent'="" }}="">
          <b>
          {intl.formatMessage({id: 'challenge.share.share_text'}, { challengeName: challenge && challenge.name})}

          </b>
          <br>
          <br>
        </div>

        {daysLeft > 0 && (
          <div style="{{" backgroundColor:="" 'transparent'="" }}="">
            ⏱️<b> J-{daysLeft} </b>avant la fin !
          </div>
        )}
        <div style="{{" backgroundColor:="" 'transparent'="" }}="">
          <b>🔥 TOP 3 ACTUEL : </b>
          {ranks &&
            ranks
              .slice(0, 3)
              .reduce(
                (acc, rank, index) =>
                  `${acc}${index > 0 ? ' / ' : ''}${
                    rank.collaborator.fullname
                  }`,
                '',
              )}
          <br>
          <br>
        </div>

        <div style="{{" backgroundColor:="" 'transparent'="" }}="">
          <a href="{`${window.location.origin}/challenges`}">
            {intl.formatMessage({ id: 'challenge.click_to_view_challenge' })}
          </a>
        </div>
      </div>
    );
  };

  copyWithStyle = element => {
    const doc = document;
    const text = doc.getElementById(element);
    let range;
    let selection;

    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElement(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();

      range = doc.createRange();
      range.selectNodeContents(text);

      selection.removeAllRanges();
      selection.addRange(range);
    }

    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  };

  copyToClipboard = () => {
    const { intl } = this.props;
    this.setState({
      ...this.state,
      copiedToClipboard: true,
    });
    this.copyWithStyle('copy-clipboard');
    toast.success(intl.formatMessage({ id: 'challenge.share.copy_success' }));
  };

  render() {
    const { account } = this.props.accountDetail;
    const { classes } = this.props;
    const { loading, ranks: fetchedRanks } =
      this.props.collaboratorChallengeRankList;
    const { ranks } = this.state;
    const { challenge } = this.props.teamGroupCollaboratorChallengeDetail;
    const { goals } = this.props.teamCollaboratorChallengeGoalListByTeamGroup;
    const { configs, loading: configLoading } = this.props.configList;
    const { intl } = this.props;

    
    const hasNextRankPage =
      this.state.ranksLoaded && fetchedRanks && fetchedRanks.length > 0;
    const displayCollaboratorLevel =
      configs &&
      _.get(
        configs.filter(c => c.code === 'CCL'),
        '[0].value',
      ).toBoolean();
    const displayCollaboratorDepartment =
      configs &&
      _.get(
        configs.filter(c => c.code === 'CRLD'),
        '[0].value',
      ).toBoolean();
    const displayCollaboratorTeam =
      configs &&
      _.get(
        configs.filter(c => c.code === 'CRLT'),
        '[0].value',
      ).toBoolean();

    if (!account.hasChallengeAccess) {
      return <redirect to="{'/'}"></redirect>;
    }

    // Filter by team
    const params = new URLSearchParams(window.location.search);

    const team = params.get('team');
    const teamGroup = params.get('teamGroup');
    const isMobile = isWidthDown('xs', this.props.width);

    const filteredRanks = ranks;

    const teams_icon = require('../../../../assets/img/system/layout/teams_icon.png');

    const fetchUsers = (options = {}) => {
      this.props.challengeParticipantListActions.getChallengeParticipantCollaboratorList(
        { challengeId: challenge.sourceId, ...options },
      );
    };
    return (
      <div className="{`${classes.wrapper}" ${="" this.state.displayScroll="" ?="" classes.activeScroll="" :="" ''="" }`}="" id="challenge-scroll">
        <div style="{{" position:="" 'absolute',="" top:="" -500="" }}="">
          {this.getClipboardText()}
        </div>
        {challenge && (
          <span class="teams-share-button" style="{{" display:="" 'none'="" }}="" ref="{this.teamsShareButton}" data-href="https://app.firetiger.fr/challenges" data-msg-text="{this.getShareText()}"></span>
        )}

        {this.initialized && (
          <helmet>
            <script async="" defer="" src="https://teams.microsoft.com/share/launcher.js"></script>
          </helmet>
        )}
        <div style="{{" height:="" 20,="" width:="" '100%',="" position:="" 'fixed',="" left:="" 0,="" top:="" 62,="" zIndex:="" 102,="" }}="" className="{classes.activeColorSecondary}"></div>
        <maincontainer 0="" maxWidth="md" style="{{" paddingTop:="" }}="">
          <div 100="" style="{{" zIndex:="" }}="">
            <subheadercontainer hideHeader="" childrenContainerClass="{classes.subheaderContainerChild}" containerClass="{`${classes.subheaderContainer}" ${="" isMobile="" ?="" classes.subheaderContainerMobile="" :="" ''="" }`}="" rootClass="{classes.subheaderRoot}">
              <subheader onChange="{this.handlePageChange.bind(this)}" activateRank="{account.hasChallengeRankAccess}"></subheader>
            </subheadercontainer>
          </div>
          {challenge && this.state.page == 0 && (
            <grid container="" spacing="{1}" justify="flex-start" style="{{" marginBottom:="" 5,="" position:="" 'relative'="" }}="">
              <grid item="">
                <challengedetailfilter open="{this.state.filterOpen}" onClose="{this.handleFilterClose.bind(this)}" onChange="{this.handleFilterDetailChange.bind(this)}" team="{team}" teamGroup="{teamGroup}" myTeam="{account.team}" scopeTeams="{_.get(challenge," 'participantTeamIds')}=""></challengedetailfilter>
              </grid>
              <grid 100="" item="" style="{{" position:="" 'absolute',="" left:="" 90,="" top:="" 0,="" zIndex:="" }}="">
                <challengesearchbar search="{this.state.name}" onChange="{value" ==""> this.applySearch(value)}
                />
              </challengesearchbar></grid>
              <grid 5="" item="" style="{{" position:="" 'absolute',="" right:="" 5,="" top:="" }}="">
                <defaulttext 12="" isContrast="" lowercase="" style="{{" lineHeight:="" 2.5,="" fontSize:="" }}="">
                  {ranks &&
                    filteredRanks.length < challenge.totalParticipants &&
                    (team || teamGroup) && (
                      <react.fragment>
                        {filteredRanks.length} / {challenge.totalParticipants}{' '}
                        {intl.formatMessage({
                          id: 'transfer_list.participants',
                        })}
                      </react.fragment>
                    )}
                  {ranks &&
                    filteredRanks.length === challenge.totalParticipants &&
                    !team &&
                    !teamGroup && (
                      <react.fragment>
                        {challenge.totalParticipants}{' '}
                        {intl.formatMessage({
                          id: 'transfer_list.participants',
                        })}
                      </react.fragment>
                    )}
                </defaulttext>
              </grid>
            </grid>
          )}

          {account.hasChallengeRankAccess && this.state.page == 0 && ranks && (
            <react.fragment>
              <collaboratorchallengeranklist ranks="{ranks}" displayCollaboratorLevel="{displayCollaboratorLevel}" displayCollaboratorDepartment="{displayCollaboratorDepartment}" displayCollaboratorTeam="{displayCollaboratorTeam}"></collaboratorchallengeranklist>

              <div 20="" ref="{this.rankLoader}" style="{{" width:="" '100%',="" height:="" 1,="" marginTop:="" }}=""></div>

              {loading && (
                <div 10="" style="{{" marginTop:="" }}="">
                  <loader centered=""></loader>
                </div>
              )}
            </react.fragment>
          )}
          {loading && !ranks && (
            <div 10="" style="{{" marginTop:="" }}="">
              <loader centered=""></loader>
            </div>
          )}
          {this.state.page == 1 && _.get(challenge, 'source_team_group_id') && (
            <div 10="" style="{{" marginBottom:="" }}="">
              <collaboratorfilterandsearchbar open="{this.state.filterOpen}" teamGroup="{_.get(challenge," 'source_team_group_id')}="" onClose="{()" ==""> {}}
                onChange={this.handleFilterChange}
                scopeTeams={_.get(challenge, 'participantTeamIds')}
                scopeCollaborators={_.get(challenge, 'participantIds')}
                fetchUsers={fetchUsers}
                usersState={{
                  users: this.props.challengeParticipantList.participants,
                  loading: this.props.challengeParticipantList.loading,
                  hasError: this.props.challengeParticipantList.hasError,
                }}
              />
            </collaboratorfilterandsearchbar></div>
          )}
          {this.state.page == 1 && challenge && goals && (
            <challengecondition challenge="{challenge}" goals="{goals}"></challengecondition>
          )}
          <dialog open="{this.state.deletePromptOpen}" onClose="{()" ==""> this.setDeletePromptOpen(false)}
          >
            <dialogtitle>
              {intl.formatMessage({ id: 'challenge.delete_prompt' })}
            </dialogtitle>
            <dialogactions>
              <button onClick="{()" ==""> this.setDeletePromptOpen(false)}
                color='secondary'
              >
                {intl.formatMessage({ id: 'common.no' })}
              </button>
              <progressbutton type="button" text="{intl.formatMessage({" id:="" 'common.yes'="" })}="" loading="{loading}" onClick="{this.onDelete.bind(this)}"></progressbutton>
            </dialogactions>
          </dialog>
          <dialog classes="{{" paper:="" classes.shareDialog="" }}="" open="{this.state.shareDropdownOpen}" onClose="{()" ==""> this.setShareDropdownOpen(false)}
          >
            <dialogtitle>
              {intl.formatMessage({ id: 'challenge.share.title' })}
            </dialogtitle>
            <dialogcontent>
              <grid container="" direction="column" spacing="{2}">
                <grid item="">
                  <div className="{classes.shareButton}" onClick="{()" ==""> this.teamsShareButton.current.click()}
                  >
                    <grid container="" spacing="{1}">
                      <grid item="">
                        <cardmedia 20="" image="{teams_icon}" style="{{" width:="" 22,="" height:="" }}=""></cardmedia>
                      </grid>
                      <grid item="">
                        <defaulttext lowercase="">
                          {intl.formatMessage({
                            id: 'challenge.share.teams_share',
                          })}
                        </defaulttext>
                      </grid>
                    </grid>
                  </div>
                </grid>
                <grid item="">
                  <div className="{classes.shareButton}" onClick="{this.copyToClipboard}">
                    <grid container="" spacing="{1}" className="{" this.state.copiedToClipboard="" ?="" classes.activeColorPrimary="" :="" classes.defaultColor="" }="">
                      <grid 4="" item="" style="{{" marginLeft:="" 4,="" marginRight:="" }}="">
                        <fontawesomeicon icon="{faLink}"></fontawesomeicon>
                      </grid>
                      <grid item="">
                        <defaulttext className="{" this.state.copiedToClipboard="" ?="" classes.activeColorPrimary="" :="" classes.defaultColor="" }="" lowercase="">
                          {intl.formatMessage({
                            id: 'challenge.share.copy_link',
                          })}
                        </defaulttext>
                      </grid>
                    </grid>
                  </div>
                </grid>
              </grid>
            </dialogcontent>
          </dialog>
          {challenge && this.state.editParticipantsOpen && (
            <challengeparticipantsedit challengeId="{_.get(challenge," 'sourceId')}="" open="{this.state.editParticipantsOpen}" setOpen="{this.handleEditParticipants}" onSubmit="{this.handleEditParticipantsSubmit}"></challengeparticipantsedit>
          )}
        </maincontainer>
      </div>
    );
  }
}

const mapStateToProps = ({
  accountDetail,
  configList,
  collaboratorChallengeRankList,
  teamGroupCollaboratorChallengeDetail,
  teamCollaboratorChallengeGoalListByTeamGroup,
  challengeSummary,
  challengeParticipantList,
}) => ({
  accountDetail,
  configList,
  collaboratorChallengeRankList,
  teamGroupCollaboratorChallengeDetail,
  teamCollaboratorChallengeGoalListByTeamGroup,
  challengeSummary,
  challengeParticipantList,
});

const mapDispatchToProps = dispatch => ({
  configListActions: bindActionCreators(configListActions, dispatch),
  collaboratorChallengeRankListActions: bindActionCreators(
    collaboratorChallengeRankListActions,
    dispatch,
  ),
  teamGroupCollaboratorChallengeDetailActions: bindActionCreators(
    teamGroupCollaboratorChallengeDetailActions,
    dispatch,
  ),
  teamCollaboratorChallengeGoalListByTeamGroupActions: bindActionCreators(
    teamCollaboratorChallengeGoalListByTeamGroupActions,
    dispatch,
  ),
  challengeDeleteActions: bindActionCreators(challengeDeleteActions, dispatch),
  challengeSummaryActions: bindActionCreators(
    challengeSummaryActions,
    dispatch,
  ),
  challengeParticipantListActions: bindActionCreators(
    challengeParticipantListActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(styles)(
    withWidth()(injectIntl(TeamGroupCollaboratorChallengeDetail)),
  ),
);
