export const GET_EMPTY_TEAM_COLLABORATOR_GOAL_LIST =
  'GET_EMPTY_TEAM_COLLABORATOR_GOAL_LIST';
export const GET_TEAM_COLLABORATOR_GOAL_LIST =
  'GET_TEAM_COLLABORATOR_GOAL_LIST';
export const GET_TEAM_COLLABORATOR_GOAL_LIST_BY_DEFINITION_AND_TEAM =
  'GET_TEAM_COLLABORATOR_GOAL_LIST_BY_DEFINITION_AND_TEAM';
export const GET_TEAM_COLLABORATOR_GOAL_LIST_SUCCESS =
  'GET_TEAM_COLLABORATOR_GOAL_LIST_SUCCESS';
export const GET_TEAM_COLLABORATOR_GOAL_LIST_ERROR =
  'GET_TEAM_COLLABORATOR_GOAL_LIST_ERROR';
export const CLEAR_TEAM_COLLABORATOR_GOAL_LIST =
  'CLEAR_TEAM_COLLABORATOR_GOAL_LIST';
