import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import { updateRewardsAllocationsKeySlice } from './slices';

function* updateRewardAllocations(slice, endpoint, action) {
  try {
    const { rewardAllocationId, rewardAllocation } = action.payload;
    yield call(endpoint, rewardAllocationId, rewardAllocation);
    yield put(slice.actions.updateSuccess());
  } catch (error) {
    yield put(
      slice.actions.updateFailure(error?.response?.data?.error ?? 'UNKNOWN'),
    );
  }
}

function* watchUpdateRewardAllocations(slice, endpoint) {
  yield takeLatest(
    slice.actions.updateStart.type,
    updateRewardAllocations,
    slice,
    endpoint,
  );
}

export default function* updateRewardAllocationsSaga() {
  yield all([
    watchUpdateRewardAllocations(
      updateRewardsAllocationsKeySlice,
      api.rewardAllocations.update,
    ),
  ]);
}
