import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  success: false,
  loading: false,
  error: null,
};

const createDeleteRewardAllocationsImageSlice = name =>
  createSlice({
    name: name,
    initialState,
    reducers: {
      deleteStart: state => {
        state.success = false;
        state.loading = true;
        state.error = null;
      },
      deleteSuccess: state => {
        state.success = true;
        state.loading = false;
        state.error = null;
      },
      deleteFailure: (state, action) => {
        state.success = false;
        state.loading = false;
        state.error = action.payload;
      },
      clearDeleteRewardsAllocationsImageState: () => initialState,
    },
  });

export const deleteRewardsAllocationsImageKeySlice =
  createDeleteRewardAllocationsImageSlice('deleteRewardsAllocationsImageKey');

export const {
  deleteStart: deleteRewardsAllocationsImageKeyStart,
  deleteSuccess: deleteRewardsAllocationsImageKeySuccess,
  deleteFailure: deleteRewardsAllocationsImageKeyFailure,
  clearDeleteRewardsAllocationsImageState:
    deleteRewardsAllocationsImageKeyClear,
} = deleteRewardsAllocationsImageKeySlice.actions;

export default {
  deleteRewardsAllocationsImageKey:
    deleteRewardsAllocationsImageKeySlice.reducer,
};
