import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';

const ChallengeType = ({ type }) => {
  const icon = type != 'CT' && type != 'TP' ? faUser : faUsers;

  return (
    <div>
      <fontawesomeicon icon="{icon}"></fontawesomeicon>
    </div>
  );
};

export default ChallengeType;
