import React, { useState } from 'react';
import { Grid, IconButton, isWidthDown, withWidth } from '@material-ui/core';
import { useIntl } from 'react-intl';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { NavLink } from 'react-router-dom';
import {
  BlueText,
  BoldTitle,
  DefaultTitle,
  Loader,
  Tooltip,
} from '../../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const WrapperWidget = ({ title, url, loading, infoIcon, children, width }) => {
  const intl = useIntl();
  const isMobile = isWidthDown('xs', width);

  return (
    <>
      <grid container="" justifyContent="center" alignItems="center" style="{{" paddingTop:="" 10,="" border:="" '2px="" solid="" #DEE3ED',="" borderRadius:="" 20,="" backgroundColor:="" '#fff',="" overflow:="" 'hidden',="" }}="">
        {(url || title) && (
          <grid 0="" item="" xs="{12}" style="{{" padding:="" '0="" 10px="" 20px'="" }}="">
            <grid container="" justifyContent="space-between" alignItems="center">
              <grid item="">
                <grid container="">
                  <grid item="">
                    {title && (
                      <boldtitle 25="" lowercase="" style="{{" color:="" '#555555',="" fontSize:="" isMobile="" ?="" :="" 20,="" }}="">
                        {title}
                      </boldtitle>
                    )}
                  </grid>
                  <grid 5="" item="" style="{{" marginLeft:="" }}="">
                    {infoIcon && (
                      <tooltip title="{infoIcon}">
                        <bluetext>
                          <fontawesomeicon icon="{faInfoCircle}"></fontawesomeicon>
                        </bluetext>
                      </tooltip>
                    )}
                  </grid>
                </grid>
              </grid>
              <grid item="">
                {url && (
                  <navlink to="{url}" style="{{" textDecoration:="" 'none'="" }}="">
                    <defaulttitle lowercase="" style="{{" color:="" 'rgb(15,111,222)'="" }}="">
                      <grid container="" alignItems="center">
                        <grid 18="" item="" style="{{" fontSize:="" }}="">
                          {intl.formatMessage({ id: 'common.see_more' })}
                        </grid>

                        <grid item="">
                          <chevronrightroundedicon style="{{" marginBottom:="" -8,="" fontSize:="" 28,="" }}=""></chevronrightroundedicon>
                        </grid>
                      </grid>
                    </defaulttitle>
                  </navlink>
                )}
              </grid>
            </grid>
          </grid>
        )}
        <grid item="" xs="{12}">
          {loading && (
            <div style="{{" minHeight:="" 300,="" minWidth:="" 100,="" justifyContent:="" 'center',="" display:="" 'flex',="" }}="">
              <loader centered=""></loader>
            </div>
          )}
          {!loading && children}
        </grid>
      </grid>
    </>
  );
};

export default withWidth()(WrapperWidget);
