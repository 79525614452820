import React from 'react';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import {
  FixedTableCell,
  FlexibleTableCell,
  FullTableCell,
  RankEvolution,
  TableCell,
  TableChip,
  TableRow,
  TableRowHighlight,
  Avatar,
} from '../../../../../../components';
import _ from 'lodash';

const styles = {
  photo: {
    height: 34,
    width: 34,
  },
  tableWrapper: {
    boxShadow: '0 2px 16px 0 rgba(16,61,92,0.25)',
    overflowX: 'auto',
  },
  positionCell: {
    paddingLeft: 5,
    paddingRight: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

const PlayerRank = ({ rank, selected, raceFinisher, ...props }) => {
  const { classes } = props;
  const photo = rank.photo ? rank.photo : '/assets/img/user/avatar.svg';
  const color = !selected ? 'default' : 'primary';
  const TableRowComponent = raceFinisher ? TableRowHighlight : TableRow;
  const teamColor = rank.color ? rank.color : '#fff';
  const mobileScreen = isWidthDown('xs', props.width);
  const cellWidth = mobileScreen ? 120 : 'auto';
  return (
    <tablerowcomponent style="{{" background:="" selected="" ?="" '#E4F6E0'="" :="" 'auto'="" }}="">
      <fulltablecell 4="" style="{{" backgroundColor:="" teamColor="" ||="" 'white',="" width:="" }}=""></fulltablecell>
      <fulltablecell style="{{" whiteSpace:="" 'nowrap'="" }}="">
        <div 76="" 90="" className="{classes.positionCell}" style="{{" width:="" mobileScreen="" ?="" :="" }}="">
          <span>
            <tablechip color="default" label="{rank.rank" ?="" rank.rank="" :="" '-'}=""></tablechip>
          </span>
          {rank.evolution !== 0 && (
            <react.fragment>
              <span 8="" style="{{" marginLeft:="" }}="">
                <rankevolution evolution="{rank.evolution}"></rankevolution>
              </span>
              <span style="{{" marginLeft:="" 3,="" color:="" rank.evolution=""> 0
                      ? '#00E58D'
                      : rank.evolution < 0
                        ? '#E50000'
                        : 'auto',
                }}
              >
                {Math.abs(rank.evolution)}
              </span>
            </react.fragment>
          )}
        </div>
      </fulltablecell>
      <fulltablecell style="{{" verticalAlign:="" 'middle'="" }}="" color="{color}">
        <div 10="" style="{{" paddingLeft:="" 10,="" paddingRight:="" }}="">{rank.level}</div>
      </fulltablecell>
      <fixedtablecell 50="" style="{{" paddingRight:="" 0,="" verticalAlign:="" 'middle',="" width:="" }}="">
        <avatar src="{photo}" className="{classes.photo}" entityId="{_.get(rank," 'collaboratorId')}="" fallbackName="{`${_.get(rank," 'firstName')}="" ${_.get(="" rank,="" 'lastName',="" )}`}=""></avatar>
      </fixedtablecell>
      <flexibletablecell color="{color}" style="{{" minWidth:="" cellWidth,="" maxWidth:="" cellWidth="" }}="">
        {mobileScreen ? (
          <react.fragment>
            <div style="{{" whiteSpace:="" 'nowrap',="" overflow:="" 'hidden',="" textOverflow:="" 'ellipsis',="" }}="">
              {rank.firstName}
            </div>
            <div style="{{" whiteSpace:="" 'nowrap',="" overflow:="" 'hidden',="" textOverflow:="" 'ellipsis',="" }}="">
              {rank.lastName}
            </div>
          </react.fragment>
        ) : (
          <react.fragment>
            <div style="{{" whiteSpace:="" 'nowrap',="" overflow:="" 'hidden',="" textOverflow:="" 'ellipsis',="" }}="">
              {rank.firstName} {rank.lastName}
            </div>
          </react.fragment>
        )}
      </flexibletablecell>

      <tablecell align="center" color="{color}">
        {rank.points}
      </tablecell>
    </tablerowcomponent>
  );
};

export default withStyles(styles)(withWidth()(PlayerRank));
