import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  success: false,
  loading: false,
  error: null,
};

const createGetPointsTransactionsListSlice = name =>
  createSlice({
    name: name,
    initialState,
    reducers: {
      fetchStart: state => {
        state.success = false;
        state.loading = true;
        state.error = null;
      },
      fetchSuccess: (state, data) => {
        state.data = data.payload.data;
        state.success = true;
        state.loading = false;
        state.error = null;
      },
      fetchFailure: (state, action) => {
        state.success = false;
        state.loading = false;
        state.error = action.payload;
      },
      clearHistoryImportsState: () => initialState,
    },
  });

export const getPointsTransactionsListKeySlice =
  createGetPointsTransactionsListSlice('getPointsTransactionsListKey');

export const {
  fetchStart: getPointsTransactionsListKeyStart,
  fetchSuccess: getPointsTransactionsListKeySuccess,
  fetchFailure: getPointsTransactionsListKeyFailure,
  clearPointsTransactionsState: getHistoryImportsKeyClear,
} = getPointsTransactionsListKeySlice.actions;

export default {
  getPointsTransactionsKey: getPointsTransactionsListKeySlice.reducer,
};
