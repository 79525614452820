import React, { useState } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { ChallengeImage, ChallengeReward } from '../..';
import { DefaultText, TimerTag, BoldTitle } from '../../../../../components';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';

const styles = {
  imageContainer: {
    position: 'relative',
  },
  timerContainer: {
    position: 'absolute',
    right: 0,
    top: 16,
  },
  tooltip: {
    minWidth: 320,
  },
  transparentTooltip: {
    background: 'transparent',
  },
};

const ChallengeSimpleJti = ({
  challenge,
  fetchWonAwards,
  fetchCurrentRank,
  fetchGoalPoints,
  fetchTopParticipants,
  ...props
}) => {
  const intl = useIntl();
  const { classes } = props;
  const { account } = props.accountDetail;
  const [wonAwards, setWonAwards] = useState(
    fetchWonAwards ? null : challenge.wonAwards,
  );
  const [rank, setRank] = useState(fetchCurrentRank ? null : challenge.rank);
  const [wonAwardsLoading, setWonAwardsLoading] = useState(false);
  const [rankLoading, setRankLoading] = useState(false);
  const [initialized, setInitialized] = useState();
  const [goalPoints, setGoalPoints] = useState();
  const [topParticipants, setTopParticipants] = useState();

  const allowRank = account.hasChallengeRankAccess;

  if (!initialized) {
    setInitialized(true);
    if (fetchWonAwards) {
      setWonAwardsLoading(true);
      fetchWonAwards()
        .then(results => {
          setWonAwardsLoading(false);
          setWonAwards(results.data);
        })
        .catch(() => {
          setWonAwardsLoading(false);
        });
    }
    if (fetchCurrentRank) {
      setRankLoading(true);
      fetchCurrentRank()
        .then(results => {
          setRankLoading(false);
          setRank(results.data);
        })
        .catch(() => {
          setRankLoading(false);
        });
    }
    if (fetchGoalPoints) {
      fetchGoalPoints().then(results => {
        setGoalPoints(results.data);
      });
    }
    if (fetchTopParticipants) {
      fetchTopParticipants().then(results => {
        setTopParticipants(results.data);
      });
    }
  }

  const isTeamGroupChallenge = challenge.typeCode === 'TG';
  console.log(goalPoints);
  return (
    <div>
      <grid container="" spacing="{2}">
        <grid item="" xs="{12}">
          <div className="{classes.imageContainer}">
            <div className="{classes.timerContainer}">
              <timertag date="{challenge.end}"></timertag>
            </div>
            <challengeimage image="{challenge.custom_image" ||="" challenge.image}=""></challengeimage>
          </div>
        </grid>
        <grid item="" xs="{12}" style="{{" maxHeight:="" 49,="" overflow:="" 'hidden'="" }}="">
          <grid container="" spacing="{2}" style="{{" alignItems:="" 'baseline'="" }}="">
            <grid 0="" container="" spacing="{1}" direction="column" style="{{" padding:="" '5px="" 5px="" 5px'="" }}="">
              <grid item="">
                <boldtitle 1="" lowercase="" style="{{" lineHeight:="" }}="">
                  {challenge.name}
                </boldtitle>
              </grid>
              {!isTeamGroupChallenge && (
                <grid item="">
                  <grid container="" direction="row" spacing="{2}">
                    <grid item="">
                      {/* enable_manager_score is undefined for collaborator challenges */}
                      {challenge.enable_manager_score !== false && (
                        <grid item="">
                          <defaulttext lowercase="">
                            <span 15="" style="{{" fontWeight:="" 'bold',="" fontSize:="" }}="">
                              {intl
                                .formatMessage({ id: 'challenge.points' })
                                .format('')}
                            </span>
                            {goalPoints &&
                              parseFloat(goalPoints).toLocaleString()}
                          </defaulttext>
                        </grid>
                      )}
                    </grid>
                    <grid item="" xs=""></grid>
                    {goalPoints !== undefined && goalPoints > 0 ? (
                      <grid item="">
                        <fontawesomeicon icon="{faCheckCircle}" color="#4CAF50" style="{{" fontSize:="" 18,="" }}=""></fontawesomeicon>
                      </grid>
                    ) : (
                      <></>
                    )}
                  </grid>
                </grid>
              )}
            </grid>
            {wonAwardsLoading && (
              <grid item="">
                <circularprogress style="{{" width:="" 20,="" height:="" marginLeft:="" 10,="" color:="" '#00E58D',="" marginBottom:="" -5,="" }}=""></circularprogress>
              </grid>
            )}
          </grid>
        </grid>
      </grid>
    </div>
  );
};

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(withStyles(styles)(ChallengeSimpleJti));
