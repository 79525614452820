import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: null,
  hierarchyNodeUser: null,
  loading: false,
};

export const hierarchyNodeUserSlice = createSlice({
  name: 'hierarchyNodeUser',
  initialState,
  reducers: {
    clearHierarchyNodeUser: state => {
      state.error = null;

      state.loading = false;
    },
    fetchFailure: (state, action) => {
      state.error = action.payload;
      state.hierarchyNodeUser = null;
      state.loading = false;
    },
    fetchStart: state => {
      state.error = null;
      state.hierarchyNodeUser = null;
      state.loading = true;
    },
    setHierarchyNodeUser: (state, action) => {
      state.error = null;
      state.hierarchyNodeUser = action.payload;
      state.loading = false;
    },
  },
});

export const {
  clearHierarchyNodeUser,
  fetchStart: fetchActiveHierarchyNodeUserStart,
  setHierarchyNodeUser,
} = hierarchyNodeUserSlice.actions;

export default {
  activeHierarchyNodeUser: hierarchyNodeUserSlice.reducer,
};
