export const GET_TEAM_PERSONALIZED_CHALLENGE_LIST_BY_COLLABORATOR =
  'GET_TEAM_PERSONALIZED_CHALLENGE_LIST_BY_COLLABORATOR';

export const GET_TEAM_PERSONALIZED_CHALLENGE_LIST_BY_TEAM =
  'GET_TEAM_PERSONALIZED_CHALLENGE_LIST_BY_TEAM';
export const GET_TEAM_PERSONALIZED_CHALLENGE_LIST_BY_TEAM_GROUP =
  'GET_TEAM_PERSONALIZED_CHALLENGE_LIST_BY_TEAM_GROUP';
export const GET_TEAM_PERSONALIZED_CHALLENGE_LIST_SUCCESS =
  'GET_TEAM_PERSONALIZED_CHALLENGE_LIST_SUCCESS';
export const GET_TEAM_PERSONALIZED_CHALLENGE_LIST_ERROR =
  'GET_TEAM_PERSONALIZED_CHALLENGE_LIST_ERROR';
export const GET_TEAM_PERSONALIZED_CHALLENGE_LIST_CLEAR =
  'GET_TEAM_PERSONALIZED_CHALLENGE_LIST_CLEAR';
