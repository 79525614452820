import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import {
  Card,
  DefaultText,
  ErrorText,
  InfoText,
  Select,
  TextField,
  HiddenInput,
  DefaultTitle,
} from '../../../../../../../../components';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const LevelStep = ({
  initial,
  levels,
  remainingPoints,
  initialParticipantType,
}) => {
  const participantTypeIsManager = initialParticipantType === 'manager';
  const [badgeLevels, setLevels] = useState(
    participantTypeIsManager
      ? [
          {
            rank: null,
            target: 0,
            points: 0,
            level: levels[0]?.id,
            percentage: 0,
            isNew: true,
          },
        ]
      : initial || [],
  );
  const intl = useIntl();

  const addLevel = () => {
    setLevels([
      ...badgeLevels,
      {
        rank: null,
        target: null,
        points: null,
        level: null,
        percentage: 0,
        isNew: true,
      },
    ]);
  };

  const removeLevel = index => {
    setLevels(badgeLevels.filter((level, i) => i !== index));
  };

  const currentRemainingPoints =
    remainingPoints - _.sum(badgeLevels.map(level => level.points));

  return (
    <react.fragment>
      <grid container="" spacing="{2}" justify="center">
        <grid item="" xs="{12}">
          <card>
            <grid container="">
              <grid item="">
                <infotext>
                  {intl.formatMessage({ id: 'badge.creation.levels_title' })}
                </infotext>
                <defaulttext>{currentRemainingPoints} PTS</defaulttext>
              </grid>
            </grid>
          </card>
        </grid>
        <grid item="" xs="{12}">
          <grid container="" spacing="{1}">
            <grid item="" xs="{12}">
              <defaulttitle>
                {intl.formatMessage({
                  id: 'admin.badge_config.remaining_points_to_award',
                })}
              </defaulttitle>
            </grid>
            <grid item="" xs="{12}">
              <grid container="" spacing="{2}">
                {badgeLevels.map((level, index) => {
                  const number = index + 1;
                  const disabled = level.percentage > 0;
                  const removeButtonVisibility = disabled
                    ? 'collapse'
                    : 'visible';

                  return (
                    <grid key="{level.id}" item="" container="" xs="{6}" spacing="{2}">
                      <grid item="" xs="{12}">
                        <defaulttitle>
                          {intl
                            .formatMessage({
                              id: 'admin.badge_config.step_point_label',
                            })
                            .format(number)}
                        </defaulttitle>
                      </grid>
                      <grid item="" xs="{12}">
                        <card>
                          <grid container="" spacing="{2}">
                            <grid item="" xs="">
                              <textfield type="number" name="{`target[${index}]`}" label="{intl.formatMessage({" id:="" 'admin.badge_config.goal_label',="" })}="" lowercase="" initial="{level.target}" disabled="{disabled}" fullWidth="" required="" validations="isMoreThanOrEquals:0" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" isMoreThanOrEquals:="" 'common.form.is_more_than_or_equals_0_error',="" }}=""></textfield>
                            </grid>
                            <grid item="" xs="">
                              <select name="{`level[${index}]`}" label="{intl.formatMessage({" id:="" 'admin.badge_config.condition_label',="" })}="" options="{levels}" optionValueName="id" optionTextName="number" optionTextPrefix="Lvl " initial="{" participantTypeIsManager="" ?="" levels[0]?.id="" :="" level.level="" }="" disabled="{" !participantTypeIsManager="" true="" fullWidth="" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></select>
                            </grid>
                            <grid item="" xs="">
                              <textfield type="number" name="{`points[${index}]`}" label="{intl.formatMessage({" id:="" 'admin.badge_config.points_label',="" })}="" initial="{level.points}" lowercase="" disabled="{disabled}" fullWidth="" required="" onChange="{value" =="">
                                  setLevels(
                                    badgeLevels.map((level, currentIndex) => {
                                      if (index === currentIndex) {
                                        return Object.assign({}, level, {
                                          points: value,
                                        });
                                      }
                                      return level;
                                    }),
                                  )
                                }
                                validations='isMoreThanOrEquals:0'
                                validationErrors={{
                                  isDefaultRequiredValue: intl.formatMessage({
                                    id: 'common.form.required_error',
                                  }),
                                  isMoreThanOrEquals:
                                    'common.form.is_more_than_or_equals_0_error',
                                }}
                              />
                            </textfield></grid>
                            {index === badgeLevels.length - 1 && (
                              <grid item="" style="{{" visibility:="" removeButtonVisibility="" }}="">
                                <iconbutton 16="" size="small" style="{{" marginTop:="" }}="" onClick="{()" ==""> removeLevel(index)}
                                >
                                  <fontawesomeicon icon="{faTrashAlt}"></fontawesomeicon>
                                </iconbutton>
                              </grid>
                            )}
                          </grid>
                        </card>
                      </grid>
                    </grid>
                  );
                })}

                <grid item="" xs="{6}" container="" spacing="{2}">
                  <grid item="" xs="{12}"></grid>
                  <grid item="" xs="{12}">
                    <div onClick="{addLevel}" style="{{" cursor:="" 'pointer'="" }}="">
                      <card>
                        <defaulttext style="{{" textAlign:="" 'center',="" fontSize:="" 18,="" color:="" '#00E58D',="" }}="" lowercase="">
                          <fontawesomeicon icon="{faPlus}" style="{{" color:="" '#00E58D',="" textAlign:="" 'center'="" }}=""></fontawesomeicon>
                        </defaulttext>
                      </card>
                    </div>
                  </grid>
                </grid>
              </grid>
            </grid>
          </grid>
        </grid>
        <grid item="">
          <errortext lowercase="">
            <hiddeninput name="maxPoints" validations="isMoreThanOrEquals:0" validationErrors="{{" isMoreThanOrEquals:="" intl.formatMessage({="" id:="" 'admin.badge_config.points_condition',="" }),="" }}="" value="{currentRemainingPoints}"></hiddeninput>
          </errortext>
        </grid>
      </grid>
    </react.fragment>
  );
};

export default LevelStep;
