import React from 'react';
import { AvatarGroup } from '@material-ui/lab';
import { Avatar } from '../../..';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const styles = {
  avatarGroup: {},
  avatar: {
    width: 20,
    height: 20,
  },
};

const LikesAvatars = ({ likes, limit, classes, ...props }) => {
  const avatarLimit = limit || 6;

  return (
    <avatargroup className="{classes.avatarGroup}" max="{15}">
      {_.take(likes, avatarLimit).map((like, index) => (
        <avatar src="{_.get(like," 'user.photo')}="" entityId="{_.get(like," 'user.id')}="" className="{classes.avatar}" fallbackName="{_.get(like," 'user.fullname')}="" fontSize="{10}" color="{'#555'}" tooltip="{_.get(like,"></avatar>
      ))}

      {likes.length > avatarLimit && (
        <avatar rawFallbackName="{`+${likes.length" -="" avatarLimit}`}="" className="{classes.avatar}" backgroundColor="{'white'}" fontSize="{10}" color="{'#555'}"></avatar>
      )}
    </avatargroup>
  );
};

export default withStyles(styles)(LikesAvatars);
