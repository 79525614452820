import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import {
  Grid,
  MenuItem,
  Select,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  AccentText,
  Card,
  FileInput,
  InfoText,
  MainLayoutComponent,
  ProgressButton,
  TextField,
  RefreshButton,
  Avatar,
  LanguageSelect,
  I18nWrapper,
  DefaultTitle,
  PasswordField,
  Switch,
  Button,
  Loader,
} from '../../../../components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Resources from '../../../../Resources';
import * as accountUpdateActions from '../../../../services/Account/AccountUpdate/actions';
import * as userUpdatePasswordActions from '../../../../services/Users/UserUpdatePassword/actions';
import * as userIdentifierDefinitionListActions from '../../../../services/Users/UserIdentifierDefinitionList/actions';
import '../../../../helpers/FormsyHelper';
import { useIntl, injectIntl } from 'react-intl';
import { toast } from 'react-toastify';
import api from '../../../../data/api/api';
import _ from 'lodash';

const styles = {
  panel: {
    backgroundColor: 'initial',
    borderRadius: 'initial',
    boxShadow: 'none',
  },
  panelSummary: {
    padding: 0,
  },
  panelDetails: {
    padding: 0,
  },
  photo: {
    height: 100,
    width: 100,
  },
  refreshButton: {
    textAlign: 'center',
  },
};

const CollaboratorEdit = ({
  account,
  classes,
  width,
  loading,
  onSubmit,
  hidePassword,
  hideNotification,
  disableEmail,
  hideRefresh,
  displaySendEmail,
  ...props
}) => {
  const intl = useIntl();
  const mobileScreen = isWidthDown('xs', width);

  const photo = account.photo ? account.photo : '/assets/img/user/avatar.svg';

  const isJti = account.isJtiEnv;

  const [password, setPassword] = useState('');

  const { definitions, loading: userIdentifierDefinitionLoading } =
    props.userIdentifierList;

  useEffect(() => {
    props.userIdentifierDefinitionListActions.getUserIdentifierDefinitionList();
  }, []);

  const LanguageField = ({ initial }) => {
    const context = useContext(I18nWrapper.Context);
    return (
      <languageselect name="locale" label="{intl.formatMessage({" id:="" 'admin.user.locale'="" })}="" initial="{initial" ||="" context.locale}="" noCard=""></languageselect>
    );
  };

  const handleSendEmail = () => {
    api.users.sendNewPassword(account.id).then(() => {
      toast.success(
        intl.formatMessage({
          id: 'admin.user.update.create_new_password_success',
        }),
      );
      window.history.back();
    });
  };

  return (
    <formsy onValidSubmit="{onSubmit}">
      <grid container="" spacing="{4}">
        <grid item="" xs="{12}">
          <grid container="" spacing="{1}">
            <grid item="">
              <defaulttitle isContrast="">
                {intl.formatMessage({ id: 'admin.user.information_title' })}
              </defaulttitle>
            </grid>
            <grid item="" xs="{12}">
              <card>
                <grid container="" spacing="{2}">
                  <grid item="" xs="{12}" container="" justify="center">
                    <grid item="">
                      <avatar src="{photo}" className="{classes.photo}" entityId="{account.id}" fallbackName="{account.fullname}" fontSize="{48}"></avatar>
                    </grid>
                  </grid>
                  <grid item="" xs="{12}" container="" justify="center">
                    <grid item="">
                      <infotext>
                        {intl.formatMessage({
                          id: `roles.${account.role.code}`,
                        })}
                      </infotext>
                    </grid>
                  </grid>
                  <grid item="" xs="{12}" container="" justify="center">
                    <grid item="">
                      <fileinput name="photo" accept="image/*" onChange="{photo" ==""> {
                          onSubmit({ photo });
                        }}
                      />
                    </fileinput></grid>
                  </grid>

                  <grid item="" xs="{12}" container="" justify="flex-start">
                    <grid item="">
                      <languagefield initial="{account.locale}"></languagefield>
                    </grid>
                  </grid>

                  <grid item="" xs="{6}">
                    <textfield name="firstname" label="{intl.formatMessage({" id:="" 'account.first_name_label',="" })}="" initial="{account.firstname}" fullWidth="" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}="" disabled="{account.isJtiEnv}" lowercase=""></textfield>
                  </grid>
                  <grid item="" xs="{6}">
                    <textfield name="lastname" label="{intl.formatMessage({" id:="" 'account.last_name_label',="" })}="" initial="{account.lastname}" fullWidth="" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}="" disabled="{account.isJtiEnv}" lowercase=""></textfield>
                  </grid>
                  <grid item="" xs="{12}">
                    <textfield lowercase="" name="email" disabled="{disableEmail" ||="" account.isJtiEnv}="" label="{intl.formatMessage({" id:="" 'account.email_label'="" })}="" validations="isEmail" initial="{account.email}" fullWidth="" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" isEmail:="" 'common.form.email_error',="" }}=""></textfield>
                  </grid>
                  {account.role.code != 'A' && (
                    <grid item="" xs="{12}">
                      <textfield name="citation" label="{intl.formatMessage({" id:="" 'account.citation_label',="" })}="" initial="{account.citation}" fullWidth="" multiline="" lowercase=""></textfield>
                    </grid>
                  )}
                  <grid item="" xs="{12}">
                    {isJti && (
                      <textfield name="title" label="{intl.formatMessage({" id:="" 'account.title_label_pseudo',="" })}="" initial="{account.title}" fullWidth="" lowercase=""></textfield>
                    )}
                    {!isJti && (
                      <textfield name="title" label="{intl.formatMessage({" id:="" 'account.title_label',="" })}="" initial="{account.title}" fullWidth="" lowercase=""></textfield>
                    )}
                  </grid>
                </grid>
              </card>
            </grid>
          </grid>
        </grid>
        <grid 0="" item="" xs="{12}" style="{{" paddingTop:="" 0,="" paddingBottom:="" }}="">
          {!definitions && userIdentifierDefinitionLoading && (
            <loader centered=""></loader>
          )}
          {definitions && (
            <expansionpanel className="{classes.panel}">
              <expansionpanelsummary expandIcon="{<ExpandMoreIcon"></expansionpanelsummary>}
                className={classes.panelSummary}
              >
                <defaulttitle isContrast="">
                  {intl.formatMessage({ id: 'admin.user.identifiers' })}
                </defaulttitle>
              
              <expansionpaneldetails className="{classes.panelDetails}">
                <card>
                  <grid container="" spacing="{4}">
                    {[...Array(5).keys()].map(order => {
                      const definition = definitions.find(
                        d => d.order == order,
                      );
                      return (
                        <grid item="" xs="{6}" key="{`identifier_${order}`}">
                          <textfield name="{`identifiers[${order}]`}" label="{" _.get(definition,="" `name`)="" ||="" intl="" .formatMessage({="" id:="" 'admin.user.identifier',="" })="" .format(order="" +="" 1)="" }="" initial="{" _.get(account,="" `identifiers`,="" []).filter(="" i=""> i.definition.order == order,
                              )[0]?.value
                            }
                            disabled={
                              !_.get(definition, `player_editable`) ||
                              account.isJtiEnv
                            }
                            fullWidth
                            lowercase
                          />
                        </textfield></grid>
                      );
                    })}
                  </grid>
                </card>
              </expansionpaneldetails>
            </expansionpanel>
          )}
        </grid>
        {!hidePassword && (
          <grid item="" xs="{12}">
            <grid container="" spacing="{1}">
              <grid item="">
                <defaulttitle isContrast="">
                  {intl.formatMessage({
                    id: 'admin.user.update.password_title',
                  })}
                </defaulttitle>
              </grid>
              <grid item="" xs="{12}">
                <card>
                  <grid container="" spacing="{2}">
                    <grid item="" xs="{12}">
                      <infotext lowercase="">
                        {intl.formatMessage({ id: 'admin.user.password_info' })}
                      </infotext>
                    </grid>
                    <grid item="" xs="{6}">
                      <passwordfield lowercase="" type="password" name="password" onChange="{setPassword}" label="{intl.formatMessage({" id:="" 'account.password_label',="" })}="" fullWidth="" validations="{{" hasUppercaseCharacter:="" true,="" hasLowercaseCharacter:="" hasSpecialCharacter:="" hasMoreCharactersThan:="" 8,="" hasDigitCharacter:="" }}="" validationErrors="{{" intl.formatMessage({="" 'common.form.has_uppercase_character',="" }),="" 'common.form.has_lowercase_character',="" 'common.form.has_special_character',="" intl="" .formatMessage({="" 'common.form.has_more_characters_than',="" })="" .format(8),="" 'common.form.has_digit_character',=""></passwordfield>
                    </grid>
                    <grid item="" xs="{6}">
                      <passwordfield lowercase="" type="password" name="paswwordConfirm" label="{intl.formatMessage({" id:="" 'account.confirm_password_label',="" })}="" fullWidth="" validations="{password" ?="" 'equalsField:password'="" :="" null}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" equalsField:="" 'common.form.password_not_match_error',="" }}=""></passwordfield>
                    </grid>
                  </grid>
                </card>
              </grid>
            </grid>
          </grid>
        )}
        {!hideNotification && (
          <grid item="" xs="{12}">
            <grid container="" spacing="{1}">
              <grid item="">
                <defaulttitle isContrast="">
                  {intl.formatMessage({
                    id: 'admin.user.update.notifications_title',
                  })}
                </defaulttitle>
              </grid>
              <grid item="" xs="{12}">
                <card>
                  <grid container="" spacing="{2}">
                    <grid item="" xs="{12}">
                      {account && 'allow_pending_notifications' in account && (
                        <switch style="{{" width:="" '100%'="" }}="" name="allow_pending_notifications" initial="{account.allow_pending_notifications}" label="{intl.formatMessage({" id:="" 'admin.user.update.allow_pending_notifications',="" })}=""></switch>
                      )}
                    </grid>
                  </grid>
                </card>
              </grid>
            </grid>
          </grid>
        )}

        <grid item="" xs="{12}">
          <grid container="" spacing="{2}" justify="center">
            {!hideRefresh && (
              <grid 1="" 2="" item="" style="{{" order:="" mobileScreen="" ?="" :="" }}="">
                <refreshbutton color="secondary" className="{classes.refreshButton}"></refreshbutton>
              </grid>
            )}
            {displaySendEmail && (
              <grid item="">
                <button color="secondary" onClick="{handleSendEmail}">
                  {intl.formatMessage({
                    id: 'admin.user.update.create_new_password',
                  })}
                </button>
              </grid>
            )}
            <grid 1="" 2="" item="" style="{{" order:="" mobileScreen="" ?="" :="" }}="">
              <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" centered="" loading="{loading}"></progressbutton>
            </grid>
          </grid>
        </grid>
      </grid>
    </formsy>
  );
};

const mapStateToProps = ({ userIdentifierList }) => ({
  userIdentifierList,
});

const mapDispatchToProps = dispatch => ({
  userIdentifierDefinitionListActions: bindActionCreators(
    userIdentifierDefinitionListActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withWidth()(injectIntl(CollaboratorEdit))));
