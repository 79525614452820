import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getAccountDetailSuccess, getAccountDetailError } from './actions';
import * as types from './actionTypes';
import api from '../../../data/api/api';
import { HierarchyNodeTypes } from '../../../Spider/enums';
import { setHierarchyNodeUser } from '../../../auth';

function* getAccountDetail(action) {
  try {
    const [accountResponse, hierarchyNodesResponse] = yield all([
      call(api.account.get),
      call(api.hierarchyNodes.list, HierarchyNodeTypes.CONTRACT),
    ]);

    const { data: account } = accountResponse;
    const { data: hierarchyNodes } = hierarchyNodesResponse;
    const hierarchyNodeUser = hierarchyNodes.results.length
      ? hierarchyNodes.results[0]._context.highest_hierarchy_node_user
      : null;

    yield put(getAccountDetailSuccess(account));
    yield put(setHierarchyNodeUser(hierarchyNodeUser));
  } catch (e) {
    yield put(getAccountDetailError());
  }
}

function* watchAccountDetail() {
  yield takeLatest(types.GET_ACCOUNT_DETAIL, getAccountDetail);
}

export default watchAccountDetail;
