import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  FixedTableCell,
  FlexibleTableCell,
  TableCell,
  TableRow,
  RankEvolution,
} from '../../../../../../../../components';

const styles = {
  root: {
    cursor: 'pointer',
  },
};

const Rank = ({ image, name, rank, onClick, ...props }) => {
  const { classes } = props;
  return (
    <tablerow onClick="{onClick}" className="{classes.root}">
      <fixedtablecell align="center">{image}</fixedtablecell>
      <tablecell style="{{" fontSize:="" 16,="" fontWeight:="" 'bold'="" }}="">{name}</tablecell>
      <tablecell align="right">{rank.rank ? rank.rank : '-'}</tablecell>

      <tablecell align="right">{rank.points}</tablecell>
      <tablecell align="right">
        <rankevolution evolution="{rank.evolution}"></rankevolution>
      </tablecell>
    </tablerow>
  );
};

export default withStyles(styles)(Rank);
