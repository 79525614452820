import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { RowsPerPageSelector } from '../RowsPerPageSelector';
import UsePagination from '../../hooks/UsePagination';
import { formatDate } from '../../../helpers/DateHelper';
import { Participant } from './components/Participant';
import { amountStyles } from '../../helper/styles';
import { TransactionType } from './components/TransactionType';
import { CircularProgress, Pagination } from '@mui/material';
import { SubType } from './components/SubType';
import { systemColors } from '../../themes';
import { useLazyGetPointsTransactionsListQuery } from '@src/data/api/endpoints/pointsTransactions';

/**
 * @param {{queryParams: import("@async-calls/pointsTransactions").PointsListParams}} props
 */
export const PointsList = ({ queryParams }) => {
  const intl = useIntl();
  const [fetch, { data, isFetching, error }] =
    useLazyGetPointsTransactionsListQuery();
  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = UsePagination({
    dataLength: data?.count ? data?.count : 0,
  });

  useEffect(() => {
    fetch({
      ...queryParams,
      page: pagination.page,
      page_size: pagination.rowsPerPage,
    });
  }, [pagination.page, pagination.rowsPerPage]);

  const columns = [
    'date',
    'matricule',
    'participant',
    'type',
    'sub_type',
    'points',
  ].map(el => ({
    field: el,
    label: intl.formatMessage({
      id: `spider.points.tabs.list.table.${el}`,
    }),
  }));

  return (
    <box display="flex" flexDirection="column">
      <box display="flex" justifyContent="space-between">
        <box>
          {isFetching && <circularprogress color="primary" size="1.2rem"></circularprogress>}
          {Boolean(error) && (
            <typography style="{{" color:="" systemColors.errorRegular="" }}="">
              {error?.message}
            </typography>
          )}
        </box>
        <rowsperpageselector rowsPerPage="{pagination.rowsPerPage}" handleChangeRowsPerPage="{handleChangeRowsPerPage}"></rowsperpageselector>
      </box>
      <tablecontainer component="{Paper}" style="{{" maxWidth:="" '100%',="" overflow:="" 'scroll',="" maxHeight:="" '50dvh'="" }}="">
        <table stickyHeader="">
          <tablehead>
            <tablerow>
              {columns.map(column => {
                return (
                  <tablecell key="{column.field}" style="{{" wordBreak:="" 'keep-all',="" whiteSpace:="" 'nowrap'="" }}="">
                    {column.label}
                  </tablecell>
                );
              })}
            </tablerow>
          </tablehead>
          <tablebody>
            {Boolean(data?.results.length) &&
              data.results.map(tr => (
                <tablerow key="{tr.uuid}">
                  <tablecell>
                    <p>{formatDate(tr.date).replaceAll('/', '.')}</p>
                  </tablecell>
                  <tablecell>
                    <p>{tr.owner.first_identifier}</p>
                  </tablecell>
                  <tablecell>
                    <participant transaction="{tr}"></participant>
                  </tablecell>
                  <tablecell>
                    <transactiontype transaction="{tr}"></transactiontype>
                  </tablecell>
                  <tablecell>
                    <subtype transaction="{tr}"></subtype>
                  </tablecell>
                  <tablecell>
                    <typography style="{amountStyles(tr.amount"> 0)}>
                      {tr.amount}
                    </typography>
                  </tablecell>
                </tablerow>
              ))}
          </tablebody>
        </table>
      </tablecontainer>
      <box display="flex" width="100%" justifyContent="center" mt="1.5rem">
        <pagination count="{paginationCount}" onChange="{handleChangePage}" variant="outlined"></pagination>
      </box>
    </box>
  );
};
