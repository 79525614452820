import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import {
  faBullseye,
  faRandom,
  faSortAmountDown,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';
import {
  FullTableCell,
  RankEvolution,
  Table,
  TableBody,
  TableCell,
  FlexibleTableCell,
  TableChip,
  TableHead,
  TableHeadCell,
  TableRow,
  TableRowHighlight,
  FixedTableCell,
  ThemeWrapper,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import { useIntl } from 'react-intl';
import '../../../../helpers/NumberHelper';
import _ from 'lodash';

const styles = {
  tableWrapper: {
    boxShadow: '0 2px 16px 0 rgba(16,61,92,0.25)',
    overflowX: 'auto',
  },
  positionCell: {
    paddingLeft: 5,
    paddingRight: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

const TeamGoalRankList = ({
  ranks,
  teamId,
  account,
  goal,
  classes,

  ...props
}) => {
  const intl = useIntl();
  const hasPoints = _.get(goal, 'levels.length', 0) > 0;
  const mobileScreen = isWidthDown('xs', props.width);
  const cellWidth = mobileScreen ? 90 : 'auto';

  const { successColor, errorColor } = useContext(ThemeWrapper.Context);

  return (
    <div className="{classes.tableWrapper}">
      <table>
        <tablehead>
          <tablerow>
            <tableheadcell colSpan="{2}">
              <fontawesomeicon icon="{faSortAmountDown}"></fontawesomeicon>
            </tableheadcell>
            <tableheadcell>
              {intl.formatMessage({ id: 'common.teams' })}
            </tableheadcell>
            <tableheadcell style="{{" textAlign:="" 'center'="" }}="">
              <fontawesomeicon icon="{faBullseye}"></fontawesomeicon>
            </tableheadcell>
            {hasPoints && goal.allow_ranking_points && (
              <tableheadcell>
                {Resources.TEAM_GOAL_RANK_LIST_POINTS_COLUMN}
              </tableheadcell>
            )}
            {goal.allow_ranking_latest_value && (
              <tableheadcell style="{{" textAlign:="" 'center'="" }}="">
                <fontawesomeicon icon="{faChartLine}"></fontawesomeicon>
              </tableheadcell>
            )}
          </tablerow>
        </tablehead>
        <tablebody>
          {ranks.map(rank => {
            const selected = rank.team ? rank.team.id == teamId : false;
            const color = !selected ? 'default' : 'primary';

            const TableRowComponent = selected ? TableRowHighlight : TableRow;
            return (
              <tablerowcomponent style="{{" background:="" selected="" ?="" '#E4F6E0'="" :="" 'auto'="" }}="">
                <fulltablecell 4="" style="{{" backgroundColor:="" rank.team.color.hex,="" width:="" }}=""></fulltablecell>
                <fulltablecell>
                  <div 60="" 90="" className="{classes.positionCell}" style="{{" width:="" mobileScreen="" ?="" :="" }}="">
                    <span>
                      <tablechip color="default" label="{rank.rank" ?="" rank.rank="" :="" '-'}=""></tablechip>
                    </span>
                    {rank.evolution !== 0 && (
                      <react.fragment>
                        <span 8="" style="{{" marginLeft:="" }}="">
                          <rankevolution evolution="{rank.evolution}"></rankevolution>
                        </span>
                        <span style="{{" marginLeft:="" 3,="" color:="" rank.evolution=""> 0
                                ? successColor
                                : rank.evolution < 0
                                  ? errorColor
                                  : 'auto',
                          }}
                        >
                          {Math.abs(rank.evolution)}
                        </span>
                      </react.fragment>
                    )}
                  </div>
                </fulltablecell>
                <flexibletablecell color="{color}" style="{{" minWidth:="" cellWidth,="" maxWidth:="" whiteSpace:="" 'normal',="" }}="">
                  {rank.team.name}
                </flexibletablecell>
                <tablecell color="{color}" style="{{" textAlign:="" 'center'="" }}="">
                  <div>
                    {parseFloat(
                      ((rank.counter / rank.target) * 100).toFixed(2),
                    )}
                    %
                  </div>
                  {(_.get(account, 'role.code') === 'A' ||
                    (_.get(account, 'role.code') === 'M' &&
                      _.get(account, 'team.id') ===
                        _.get(rank, 'team.id'))) && (
                    <div style="{{" fontSize:="" 10,="" opacity:="" 0.8="" }}="">
                      {rank.counter.toLocaleString()}&nbsp;/&nbsp;
                      {rank.target.toLocaleString()}
                    </div>
                  )}
                </tablecell>
                {hasPoints && goal.allow_ranking_points && (
                  <tablecell color="{color}">{rank.points}</tablecell>
                )}
                {goal.allow_ranking_latest_value && (
                  <tablecell color="{color}" style="{{" textAlign:="" 'center',="" color:="" rank.latest_result="" ?="" successColor="" :="" 'auto',="" }}="">
                    {rank.latest_result ? `+${rank.latest_result}` : 0}
                  </tablecell>
                )}
              </tablerowcomponent>
            );
          })}
        </tablebody>
      </table>
    </div>
  );
};

export default withStyles(styles)(withWidth()(TeamGoalRankList));
