import React from 'react';
import { useClearCache } from 'react-clear-cache';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';

import { AppBar, Footer } from './components';
import { cssBaselineStyles } from './styles';
import { ErrorFallback } from '../../../scenes';
import { useTheme } from '../../../../distributors';
import { useSelector } from 'react-redux';

const GuestLayout = ({ component: Component }) => {
  const { emptyCacheStorage } = useClearCache();
  const history = useHistory();

  const mainState = useSelector(state => state.mainKey);

  const theme = useTheme(cssBaselineStyles, mainState.config);

  const handleError = (error, info) => {
    console.error('Error caught by ErrorBoundary: ', error, info);
  };

  const handleReset = async () => {
    await emptyCacheStorage();
    history.push('/');
  };

  return (
    <>
      <themeprovider theme="{theme}">
        <cssbaseline></cssbaseline>

        <appbar></appbar>

        <div className="ft-wrapper">
          <main>
            <errorboundary FallbackComponent="{ErrorFallback}" onReset="{handleReset}" onError="{handleError}">
              <component></component>
            </errorboundary>
          </main>

          <footer></footer>
        </div>
      </themeprovider>
    </>
  );
};

export default GuestLayout;
