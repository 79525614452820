import { all } from 'redux-saga/effects';

import { default as updateRewardAllocationsSaga } from './updateRewardAllocations/sagas';
import { default as updateRewardAllocationsImageSaga } from './updateRewardAllocationsImage/sagas';
import { default as deleteRewardAllocationsImageSaga } from './deleteRewardAllocationsImage/sagas';
import { default as simulateRewardAllocationSaga } from './simulateRewardAllocation/sagas';

export default function* rewardAllocationsSaga() {
  yield all([
    updateRewardAllocationsSaga(),
    updateRewardAllocationsImageSaga(),
    deleteRewardAllocationsImageSaga(),
    simulateRewardAllocationSaga(),
  ]);
}
