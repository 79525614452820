import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RoundedTab, RoundedTabs } from '../Tabs';
import { DefaultText } from '../Texts';

const RoleFilter = ({ onRoleChange }) => {
  const intl = useIntl();
  const [selectedRole, setSelectedRole] = useState(0);
  const history = useHistory();

  useEffect(() => {
    history.replace(`?current=${selectedRole}`);
  }, [selectedRole, history]);

  const handleChange = (event, newValue) => {
    setSelectedRole(newValue);
    if (onRoleChange) {
      onRoleChange(newValue);
    }
  };

  return (
    <div>
      <div 16="" style="{{" padding:="" }}="">
        <defaulttext align="{'center'}">
          {intl.formatMessage({ id: 'admin.notifications_rights.sub_title' })}
        </defaulttext>
      </div>
      <roundedtabs value="{selectedRole}" onChange="{handleChange}" variant="fullWidth">
        <roundedtab label="{intl.formatMessage({" id:="" 'roles.C'="" })}=""></roundedtab>
        <roundedtab label="{intl.formatMessage({" id:="" 'roles.M'="" })}=""></roundedtab>
        <roundedtab label="{intl.formatMessage({" id:="" 'roles.A'="" })}=""></roundedtab>
      </roundedtabs>
    </div>
  );
};

export default RoleFilter;
