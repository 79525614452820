import React from 'react';
import { withStyles } from '@mui/styles';
import { Typography } from '@material-ui/core';
import themeColors from '../../themes/colors/themeColors';
import useColorSystemCodeHelper from '../../hooks/UseColorSystemCodeHelper';

const styles = {
  alertWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '1rem',
    backgroundColor: themeColors.secondaryBg,
    borderRadius: '0.75rem',
  },
  alertIcon: {
    height: '100%',
    lineHeight: '0rem',
  },
  alertContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    textAlign: 'left',
  },
};

const Alert = ({
  Icon,
  Content,
  style,
  title,
  titleVariant = 'h4',
  titleComponent = 'h4',
  titleStyle = {},
  classes,
  type,
  onClick,
}) => {
  const { color, backgroundColor } = useColorSystemCodeHelper(type);
  return (
    <div className="{classes.alertWrapper}" style="{{" backgroundColor,="" alignItems:="" Content="" ?="" 'flex-start'="" :="" 'center',="" cursor:="" onClick="" 'pointer'="" 'default',="" color,="" ...style,="" }}="">
      <div className="{classes.alertIcon}">
        <icon></icon>
      </div>
      <div className="{classes.alertContent}">
        <typography variant="{titleVariant}" component="{titleComponent}" style="{{" color,="" fontWeight:="" 'bold',="" lineHeight:="" '1.5rem',="" ...titleStyle,="" }}="">
          {title}
        </typography>
        {Content && <content></content>}
      </div>
    </div>
  );
};

export default withStyles(styles)(Alert);
