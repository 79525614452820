import { useEffect, useState } from 'react';
import themeColors from '../themes/colors/themeColors';
import { SystemCode } from '../enums/systemCode';
import { systemColors } from '../themes';

const UseColorSystemCodeHelper = (type = SystemCode.INFO) => {
  const [color, setColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    switch (type) {
      case SystemCode.SUCCESS:
        setColor(systemColors.successRegular);
        setBackgroundColor(systemColors.successBg);
        break;
      case SystemCode.WARNING:
        setColor(systemColors.warningRegular);
        setBackgroundColor(systemColors.warningBg);
        break;
      case SystemCode.ERROR:
        setColor(systemColors.errorRegular);
        setBackgroundColor(systemColors.errorBg);
        break;
      default:
        setColor(systemColors.infoRegular);
        setBackgroundColor(themeColors.secondaryBg);
        break;
    }
  }, [type]);

  return { color, backgroundColor };
};

export default UseColorSystemCodeHelper;
