import React from 'react';
import { neutralColors } from '@src/Spider/themes';
import CardSvg from '@src/Spider/assets/img/carte-kadeo.svg';
import { Typography } from '@material-ui/core';
import { RewardAllocationsConfiguration } from './components/RewardAllocationsConfiguration';
import { ParticipantType } from '@src/Spider/enums/participantType';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  reward: {
    display: 'flex',
    padding: '1rem',
    flexDirection: 'column',
    gap: '2rem',
    alignSelf: 'stretch',
    borderRadius: '0.5rem',
    backgroundColor: neutralColors.neutralColdWhite,
  },
  rewardTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
};

const Reward = ({ reward, ...props }) => {
  const rewardAllocation = reward?._context?.allocation;
  return (
    <div className="{props.classes.reward}">
      <div className="{props.classes.rewardTitle}">
        <img src="{CardSvg}" alt="{'card-reward'}">

        <typography variant="{'h3'}" component="{'h3'}">
          {reward?.name}
        </typography>
      </div>

      <rewardallocationsconfiguration rewardLabel="{reward?.name}" rewardAllocation="{rewardAllocation}" configurations="{reward?.individuals_required_config" ??="" []}="" activeConfigurations="{rewardAllocation?.individuals_config" type="{ParticipantType.PHYSIC}"></rewardallocationsconfiguration>

      <rewardallocationsconfiguration rewardLabel="{reward?.name}" rewardAllocation="{rewardAllocation}" configurations="{reward?.legal_entities_required_config" ??="" []}="" activeConfigurations="{rewardAllocation?.legal_entities_config" type="{ParticipantType.MORALE}"></rewardallocationsconfiguration>
    </div>
  );
};

export default withStyles(styles)(Reward);
