import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  simulation: null,
  initializeSimulation: false,
  success: false,
  loading: false,
  error: null,
};

const createSimulateRewardAllocationsSlice = name =>
  createSlice({
    name: name,
    initialState,
    reducers: {
      simulateStart: state => {
        state.success = false;
        state.loading = true;
        state.error = null;
      },
      simulateSuccess: (state, data) => {
        const { initializeSimulation, ...payload } = data.payload;
        state.simulation = payload;
        if (initializeSimulation) {
          state.initializeSimulation = initializeSimulation;
        }
        state.success = true;
        state.loading = false;
        state.error = null;
      },
      simulateFailure: (state, action) => {
        state.success = false;
        state.loading = false;
        state.error = action.payload;
      },
      clearSimulateRewardsAllocationsState: state => {
        state.error = null;
      },
      resetSimulation: (state, data) => {
        state.simulation = {
          ...state.simulation,
          order_suggested: {
            ...state.simulation.order_suggested,
            monetary_value: 0,
            points: 0,
          },
        };
        state.success = true;
      },
      clearSimulationState: () => initialState,
    },
  });

export const simulateRewardsAllocationsKeySlice =
  createSimulateRewardAllocationsSlice('simulateRewardsAllocationsKey');

export const {
  simulateStart: simulateRewardsAllocationsKeyStart,
  simulateSuccess: simulateRewardsAllocationsKeySuccess,
  simulateFailure: simulateRewardsAllocationsKeyFailure,
  clearSimulateRewardsAllocationsState: simulateRewardsAllocationsKeyClear,
  resetSimulation,
  clearSimulationState,
} = simulateRewardsAllocationsKeySlice.actions;

export default {
  simulateRewardsAllocationsKey: simulateRewardsAllocationsKeySlice.reducer,
};
