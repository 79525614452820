import React from 'react';
import { CardMedia, Grid, Card, Hidden } from '@material-ui/core';
import {
  DefaultTitle,
  FileInput,
  HiddenInput,
} from '../../../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as systemImageUpdateActions from '../../../../../../services/SystemImages/SystemImageUpdate/actions';
import * as configListActions from '../../../../../../services/Configs/ConfigList/actions';
import * as configListUpdateActions from '../../../../../../services/Configs/ConfigListUpdate/actions';
import _ from 'lodash';

const styles = {
  logo: {
    height: 100,
    width: 200,
    backgroundSize: 'contain',
  },
};

const LogoSettings = ({ systemImageList, ...props }) => {
  const { images } = systemImageList;
  const { classes } = props;

  var logo = images ? images.find(x => x.code == 'LOGO') : null;

  return (
    <>
      <defaulttitle isContrast="">Logo</defaulttitle>
      <card>
        <grid 20="" container="" spacing="{2}" style="{{" margin:="" }}="">
          <grid item="" xs="{12}" container="" justifyContent="center">
            <grid item="">
              <cardmedia image="{_.get(logo," 'src',="" '="" assets="" img="" system="" logo.png')}="" className="{classes.logo}"></cardmedia>
            </grid>
          </grid>
          <grid item="" xs="{12}" container="" justifyContent="center">
            <grid item="">
              <fileinput name="logo" accept="image/*"></fileinput>
            </grid>
          </grid>
        </grid>
        <hiddeninput name="uuid" value="{_.get(logo," 'uuid')}=""></hiddeninput>
      </card>
    </>
  );
};

const mapStateToProps = ({
  systemImageList,
  systemImageUpdate,
  configList,
  configListUpdate,
}) => ({
  systemImageList,
  systemImageUpdate,
  configList,
  configListUpdate,
});

const mapDispatchToProps = dispatch => ({
  systemImageUpdateActions: bindActionCreators(
    systemImageUpdateActions,
    dispatch,
  ),
  configListActions: bindActionCreators(configListActions, dispatch),
  configListUpdateActions: bindActionCreators(
    configListUpdateActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(LogoSettings));
