import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    height: 8,
    backgroundColor: 'initial',
  },
};

export default withStyles(styles)(Divider);
