import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {
  IconButton,
  MainLayoutComponent,
  TeamSelector,
  Loader,
  AppBarSubTitle,
  AdministratorCollaboratorSelector,
  DefaultTitle,
} from '../../../../components';
import { Grid } from '@material-ui/core';

import { FilterSelector } from './components';
import { useIntl, injectIntl } from 'react-intl';
import * as configListActions from '../../../../services/Configs/ConfigList/actions';

class ChallengeHome extends MainLayoutComponent {
  constructor(props) {
    super(props);
    this.state = {
      team: null,
      filter: null,
    };
  }

  handleAdd() {
    this.props.history.push('/challenges/creation');
  }

  handleClick(id, type) {
    this.setState({
      ...this.state,
      team: id,
    });
    this.props.history.push(
      `/challenges/${type === 'teamGroup' ? 'department' : 'team'}/${id}`,
    );
  }

  selectFilter = filter => {
    this.setState({
      ...this.state,
      filter: filter,
    });
  };

  componentDidMount() {
    const { intl } = this.props;
    const { account } = this.props.accountDetail;

    this.props.handleTitle(
      account.challengeWording || intl.formatMessage({ id: 'challenge.title' }),
    );
    if (account.role.code == 'A' || account.role.code == 'S') {
      this.props.handleButtons(
        <iconbutton size="small" onClick="{this.handleAdd.bind(this)}">
          <fontawesomeicon icon="{faPlus}"></fontawesomeicon>
        </iconbutton>,
      );
    }
    // this.props.configListActions.getPermanentConfigList();
  }

  render() {
    const { account } = this.props.accountDetail;
    const { configs, loading } = this.props.configList;
    const { intl } = this.props;

    const handleCollaboratorClick = collaborator => {
      const collaboratorUrl = `/challenges/collaborator/${collaborator.id}`;
      this.props.history.push(collaboratorUrl);
    };

    if (!account.hasChallengeAccess) {
      return <redirect to="{'/'}"></redirect>;
    }

    if (
      account.role.code == 'A' ||
      (account.role.code == 'S' && !this.state.team)
    ) {
      return (
        <>
          <grid container="" spacing="{1}">
            <grid item="" xs="">
              <administratorcollaboratorselector 0="" contextUrl="challenges/department/" contextUrlOptions="{{" page:="" }}="" onClickCollaborator="{handleCollaboratorClick}" onClick="{this.handleClick.bind(this)}" teamGroupSelectable=""></administratorcollaboratorselector>
            </grid>
          </grid>
        </>
      );
    }

    if (account.role.code == 'C') {
      return (
        <redirect to="{`/challenges/collaborator/${account.id}${" this.state.filter="" ?="" :="" ''="" }`}=""></redirect>
      );
    }
    return (
      <redirect to="{`/challenges/team/${_.get(account," 'team.id')="" ||="" this.state.team}${="" this.state.filter="" ?="" :="" ''="" }`}=""></redirect>
    );

    // if (account.role.code == 'C') {
    //     return <redirect to="{`/challenges/collaborator/${account.id}`}"></redirect>
    // } else if (account.role.code == 'M' && account.team) {
    // } else if (account.role.code == 'A') {
    //     return (
    //         <div>
    //             <teamselector onClick="{this.handleClick.bind(this)}"></teamselector>
    //         </div>
    //     )
    // } else {
    //     return <div></div>
    // }
  }
}

const mapStateToProps = ({ accountDetail, configList }) => ({
  accountDetail,
  configList,
});

const mapDispatchToProps = dispatch => ({
  configListActions: bindActionCreators(configListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(injectIntl(ChallengeHome)));
