import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  IntercomWidgetLogin,
  LoginForm,
  LoginFormMobile,
  SSOPopup,
} from './components';
import * as authActions from '../../../../services/Auth/actions';
import * as Resources from '../../../../Resources';
import { injectIntl } from 'react-intl';
import api from '../../../../data/api/api';
import router from '../../../../data/router/router';
import local from '../../../../data/local/local';
import _ from 'lodash';
import { isMobileApp } from '../../../../helpers/MobileApp';
import { STORE } from '@src/data/local/localTypes';

class Login extends Component {
  constructor(props) {
    super(props);
    this.props.authActions.clearLogin();
    this.state = {
      customError: props.location?.state?.message
        ? props.intl.formatMessage({ id: props.location?.state?.message })
        : null,
    };
  }
  resetCustomError = () => {
    this.setState({
      ...this.state,
      customError: null,
    });
  }

  handleSubmit(model) {
    this.props.authActions.login({
      code: model.code.toLowerCase(),
      login: model.email.toLowerCase(),
      password: model.password,
      captcha: model.captcha,
    });
  }

  componentDidMount() {
    // if reduxState was not cleared at last logout, then clear it (only happens once)
    const oldReduxState = localStorage.getItem(STORE);
    if (oldReduxState) {
      local.removeStore();
    }
  }

  async handleSubmitSSO(model) {
    const { intl } = this.props;
    const clientEnvironment = _.replace(_.lowerCase(model.code), ' ', '');
    local.setClientEnvironment(clientEnvironment);
    const subdomain =
      clientEnvironment && clientEnvironment !== 'dev'
        ? `${clientEnvironment}.`
        : '';

    const baseUrlProtocol = process.env.REACT_APP_API_PROTOCOL;
    var baseUrl = process.env.REACT_APP_API_URL;

    try {
      if (isMobileApp()) {
        window.location.href = `${baseUrlProtocol}${subdomain}${baseUrl}workos/authorize/?base_url=${window.location.origin}`;
      } else {
        this.setState({
          ...this.state,
          SsoUrl: `${baseUrlProtocol}${subdomain}${baseUrl}workos/authorize/?base_url=${window.location.origin}`,
          SsoOpen: true,
        });
      }

      // const oauthUrlResponse = await api.partners.oauthAutorizeUrl()
      // if(oauthUrlResponse.data.authorizeUrl) {
      //   window.location.href = oauthUrlResponse.data.authorizeUrl
      // }
    } catch {
      this.setState({
        ...this.state,
        customError: intl.formatMessage({ id: 'login.sso_error_message' }),
      });
    }
  }

  ssoCallback = tokens => {
    if (_.get(tokens, 'access') && _.get(tokens, 'refresh')) {
      this.props.authActions.login({ token: tokens });
    } else {
      console.log('failed to log in');
    }
  };

  render() {
    return (
      <div>
        <intercomwidgetlogin appId="{'l5qh63aj'}"></intercomwidgetlogin>
        {!isMobileApp() && (
          <loginform onSubmit="{this.handleSubmit.bind(this)}" onSubmitSSO="{this.handleSubmitSSO.bind(this)}" customError="{this.state.customError}" resetCustomError="{this.resetCustomError}"></loginform>
        )}
        {isMobileApp() && (
          <loginformmobile onSubmit="{this.handleSubmit.bind(this)}" onSubmitSSO="{this.handleSubmitSSO.bind(this)}" customError="{this.state.customError}" resetCustomError="{this.resetCustomError}"></loginformmobile>
        )}
        {this.state.SsoUrl && this.state.SsoOpen && (
          <ssopopup open="{this.state.SsoOpen}" url="{this.state.SsoUrl}" onCallback="{this.ssoCallback}"></ssopopup>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(authActions, dispatch),
});

export default connect(null, mapDispatchToProps)(injectIntl(Login));
