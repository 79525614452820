import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useCallback,
} from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import Formsy from 'formsy-react';
import { Grid, IconButton, Chip } from '@material-ui/core';
import EmojiPicker from 'emoji-picker-react';
import {
  DefaultText,
  Dialog,
  DialogTitle,
  DialogContent,
  Avatar,
  ProgressButton,
  HiddenInput,
  TextField,
  Loader,
  PostFormDialogParams,
  Tooltip,
  BlueText,
} from '../../..';
import { LinkPreview } from '../LinkPreview';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImage,
  faPaperclip,
  faPlayCircle,
  faTimes,
  faCode,
  faChevronDown,
  faInfoCircle,
  faLaughBeam,
} from '@fortawesome/free-solid-svg-icons';
import GifBoxIcon from '@mui/icons-material/GifBox';
import ReactGiphySearchbox from 'react-giphy-searchbox';
import * as roleListActions from '../../../../../../services/Roles/RoleList/actions';
import * as userListActions from '../../../../../../services/Users/UserList/actions';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import api from '../../../../../../data/api/api';
import _ from 'lodash';

const styles = theme => {
  return {
    newPostButton: {
      padding: 10,
      border: '1px solid #ccc',
      borderRadius: 25,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
    titleContainer: {
      cursor: 'pointer',
      width: 400,
      '&:hover': {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
      },
    },
    dialog: {
      width: 700,
      minWidth: 700,
      height: 500,
    },
    dialogParams: {
      width: 550,
      minWidth: 500,
    },
    iconButton: {
      width: 40,
      height: 40,
    },
    textField: {
      width: '100%',
      '& .MuiInput-underline:before, .MuiInput-underline:after': {
        display: 'none',
      },
      '& textarea': {
        minHeight: 10,
        maxHeight: 200,
        overflowY: 'auto !important',
      },
    },
    mediaCloseIcon: {
      position: 'absolute',
      color: 'white',
      top: -10,
      right: -10,
      width: 25,
      height: 25,
      fontSize: 20,
      zIndex: 100,
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.main,
        color: 'white',
      },
    },
    link: {
      fontSize: 18,
      cursor: 'pointer',
      '&:hover, &.active': {
        color: 'rgb(15,111,222)',
        opacity: 1,
      },
    },
    filterChip: {
      marginRight: 5,
      marginBottom: 5,
      textTransform: 'none',
    },
    filterChips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    mentionSelected: {
      backgroundColor: '#E9E9E9',
      textTransform: 'capitalize',
      position: 'relative',
      maxHeight: 100,
      overflowY: 'auto',
    },
  };
};

const PostFormDialog = ({
  loading: creationLoading,
  classes,
  onSubmit,
  created,
  dialogOpen,
  setDialogOpen,
  userListActions,
  userList,
  post,
  width,
  title,
  ...props
}) => {
  const { account } = props.accountDetail;
  const { roles } = props.roleList;

  const isMobile = isWidthDown('xs', width);
  const isTablet = isWidthUp('sm', width) && isWidthDown('md', width);
  const isDesktop = isWidthUp('lg', width);

  const [image, setImage] = useState(_.get(post, 'image'));
  const [video, setVideo] = useState(_.get(post, 'video'));
  const [file, setFile] = useState(_.get(post, 'file'));
  const [embed, setEmbed] = useState(_.get(post, 'embed'));
  const [description, setDescription] = useState(
    _.get(post, 'description', ''),
  );
  const [linkPreview, setLinkPreview] = useState(_.get(post, 'link_preview'));
  const [openGraphLoading, setOpenGraphLoading] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [visibilityAll, setVisibilityAll] = useState(true);
  const [teamId, setTeamId] = useState(
    _.get(post, 'visibility.team.id') ||
      _.get(post, 'visibility.team') ||
      _.get(account, 'team.id'),
  );
  const [teamGroupId, setTeamGroupId] = useState(
    _.get(post, 'visibility.team_group.id') ||
      _.get(post, 'visibility.team_group'),
  );
  const [role, setRole] = useState(_.get(post, 'visibility.role'));
  const [selectedFromParamsTeamGroup, setSelectedFromParamsTeamGroup] =
    useState(
      account.team_group
        ? account.team_group
        : _.get(post, 'visibility.team_group', {}),
    );
  const [selectedFromParamsTeam, setSelectedFromParamsTeam] = useState(
    account.team ? account.team : _.get(post, 'visibility.team', {}),
  );
  const [selectedRadioButton, setSelectedRadioButton] = useState('');
  const [showGiphyPicker, setShowGiphyPicker] = useState(false);
  const [gif, setGif] = useState(_.get(post, 'gif'));
  const gifApiKey = process.env.REACT_APP_GIF_API_KEY;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const isCollaborator = account.role.code === 'C';
  const isAdministrator = account.role.code === 'A';
  const isSuperManager = account.role.code === 'S';
  const isManager = account.role.code === 'M';

  const emojiInputRef = useRef();
  const gifInputRef = useRef();
  const imageInput = useRef();
  const videoInput = useRef();
  const fileInput = useRef();
  const textFieldRef = useRef();
  const intl = useIntl();
  const gifIconRef = useRef(null);
  const emojiIconRef = useRef(null);
  const [positions, setPositions] = useState({
    gif: { top: 0, left: 0 },
    emoji: { top: 0, left: 0 },
  });
  const dialogContentRef = useRef();

  const debouncedFetchUsers = useCallback(
    _.debounce((search, callback) => {
      const full = true;
      api.users
        .list(
          true,
          null,
          1,
          search.replace('@', ''),
          null,
          null,
          null,
          null,
          full,
        )
        .then(response => {
          callback(
            response.data.users.map(user => ({
              id: user.id,
              display: user.fullname,
            })),
          );
        });
    }, 300),
    [],
  );

  const updatePosition = type => {
    const ref = type === 'gif' ? gifIconRef : emojiIconRef;
    if (ref.current && dialogContentRef.current) {
      const rect = ref.current.getBoundingClientRect();
      const dialogRect = dialogContentRef.current.getBoundingClientRect();

      setPositions(prev => ({
        ...prev,
        [type]: {
          top: rect.top - dialogRect.top,
          left: rect.left - dialogRect.left,
        },
      }));
    }
  };

  useLayoutEffect(() => {
    updatePosition('emoji');
    updatePosition('gif');
  }, [
    emojiIconRef.current,
    showEmojiPicker,
    gifIconRef.current,
    showGiphyPicker,
    width,
    dialogContentRef.current,
  ]);

  useEffect(() => {
    const handleResize = () => {
      updatePosition('emoji');
      updatePosition('gif');
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      updatePosition('emoji');
      updatePosition('gif');
    };
    if (dialogOpen) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dialogOpen]);

  const fullVisibility = intl.formatMessage({ id: 'newsfeed.for_everybody' });
  useEffect(() => {
    if (visibilityAll) {
      setSelectedRadioButton(fullVisibility);
    } else {
      setSelectedRadioButton('selected team');
    }
  }, [visibilityAll]);

  const handleOpenOptions = () => {
    setVisibleOptions(!visibleOptions);
    if (!visibilityAll) {
      setIsFilterVisible(true);
    }
  };

  const openGifPicker = () => {
    setShowGiphyPicker(!showGiphyPicker);
  };

  const openEmojiInput = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const openImageInput = () => {
    imageInput.current.click();
  };

  const openVideoInput = () => {
    videoInput.current.click();
  };

  const openFileInput = () => {
    fileInput.current.click();
  };

  const regexCheck = text => {
    if (typeof text === 'string') {
      const regex =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gm;
      const urls = text.match(regex);
      if (!linkPreview && !openGraphLoading && urls && urls.length > 0) {
        setOpenGraphLoading(true);
        api.openGraph
          .get(urls[0])
          .then(response => {
            setOpenGraphLoading(false);
            if (!response.data.error) {
              setLinkPreview(response.data);
            }
          })
          .catch(() => {
            setOpenGraphLoading(false);
            setLinkPreview();
          });
      }
    }
  };

  useEffect(() => {
    setDescription(_.get(post, 'description', ''));
    setImage(_.get(post, 'image'));
    setVideo(_.get(post, 'video'));
    setFile(_.get(post, 'file'));
    setGif(_.get(post, 'gif'));
    setLinkPreview(_.get(post, 'link_preview'));
    setTeamId(
      _.get(post, 'visibility.team.id') ||
        _.get(post, 'visibility.team') ||
        _.get(account, 'team.id'),
    );
    setTeamGroupId(
      _.get(post, 'visibility.team_group.id') ||
        _.get(post, 'visibility.team_group'),
    );
    if (post) {
      setSelectedFromParamsTeam(_.get(post, 'visibility.team'));
      setSelectedFromParamsTeamGroup(_.get(post, 'visibility.team_group'));
      setVisibilityAll(_.get(post, 'visibility.all'));
    }

    setRole(_.get(post, 'visibility.role'));
  }, [dialogOpen]);

  useEffect(() => {
    if (created && dialogOpen) {
      setDialogOpen(false);
    }
  }, [created]);

  useEffect(() => {
    if (video) {
      setImage();
      setFile();
      setLinkPreview();
      setEmbed();
      setGif();

      if (video.size > 300000000) {
        const videoSize = `${parseInt(video.size / 1000000)} Mo`;
        toast.error(
          intl
            .formatMessage({ id: 'newsfeed.video_size_error' })
            .format(videoSize),
        );
        setVideo();
      }
    }
  }, [video]);

  useEffect(() => {
    if (image) {
      setVideo();
      setFile();
      setLinkPreview();
      setEmbed();
      setGif();
    }
  }, [image]);

  useEffect(() => {
    if (file) {
      setVideo();
      setImage();
      setLinkPreview();
      setEmbed();
      setGif();
    }
  }, [file]);

  useEffect(() => {
    if (linkPreview) {
      setVideo();
      setImage();
      setFile();
      setEmbed();
      setGif();
    }
  }, [linkPreview]);

  useEffect(() => {
    if (embed) {
      setVideo();
      setImage();
      setFile();
      setLinkPreview();
      setGif();
    }
  }, [embed]);

  useEffect(() => {
    if (gif) {
      setVideo();
      setImage();
      setFile();
      setLinkPreview();
      setEmbed();
    }
  }, [gif]);

  useEffect(() => {
    if (!roles && !props.roleList.loading) {
      props.roleListActions.getRoleList();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        emojiInputRef.current &&
        !emojiInputRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
      if (gifInputRef.current && !gifInputRef.current.contains(event.target)) {
        setShowGiphyPicker(false);
      }
    };

    if (emojiInputRef.current || gifInputRef.current) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEmojiPicker, showGiphyPicker]);

  const resetFormState = () => {
    setSelectedRadioButton(fullVisibility);
    setVisibilityAll(true);
  };

  const handleSubmitForm = model => {
    const { url, title, image, site_name } = linkPreview || {};
    const gifUrl = gif ? _.get(gif, 'images.fixed_height.url', null) : null;
    if (teamId || teamGroupId || visibilityAll) {
      let descriptionText = description || '';

      descriptionText = descriptionText.replace(
        /@\[(.*?)\]\((.*?)\)/g,
        '@($2)',
      );
      onSubmit(
        Object.assign(
          {},
          model,
          {
            description: descriptionText,
            visibility: {
              team: selectedFromParamsTeam,
              team_group: selectedFromParamsTeamGroup,
              role: role,
              all: visibilityAll,
            },
          },
          {
            link_preview: linkPreview ? { url, title, image, site_name } : null,
            gif: gifUrl,
          },
        ),
      );
    }
    resetFormState();
  };

  const updateVisibility = isVisible => {
    setVisibleOptions(isVisible);
  };

  const onTextChange = (event, newValue) => {
    const value = event.target.value;
    setDescription(value);
    regexCheck(value);
  };

  const onSubmitTeamAndTeamGroup = (
    selectedDataTeamGroup,
    selectedDataTeam,
    teamId,
    teamGroupId,
    isFullVisibilitySelected,
    role,
  ) => {
    setSelectedFromParamsTeamGroup(selectedDataTeamGroup);
    setSelectedFromParamsTeam(selectedDataTeam);
    setTeamId(teamId);
    setTeamGroupId(teamGroupId);
    setVisibilityAll(isFullVisibilitySelected);
    setRole(role);
  };

  const handleRadioButtonChange = value => {
    setSelectedRadioButton(value);
  };

  const getRoleNameById = roleId => {
    return intl.formatMessage({
      id: `roles.${roles.find(r => r.id === parseInt(roleId))?.code}`,
    });
  };

  const onEmojiClick = (emojiObject, event) => {
    const currentDescription = description || '';
    const position = parseInt(textFieldRef.current.selectionStart);
    const newDescription = `${currentDescription.slice(0, position)}${
      emojiObject.emoji || ''
    }${currentDescription.slice(position)}`;
    setDescription(newDescription);
    regexCheck(newDescription);
    setShowEmojiPicker(!showEmojiPicker);
  };

  return (
    <div>
      {dialogOpen && (
        <dialog open="{dialogOpen}" onClose="{()" ==""> setDialogOpen(false)}
          classes={{
            paper: isMobile
              ? ''
              : visibleOptions
                ? classes.dialogParams
                : classes.dialog,
          }}
        >
          <dialogtitle onClose="{()" ==""> {
              setDialogOpen(false);
              setVisibleOptions(false);
            }}
          >
            {visibleOptions ? (
              <dialogtitle>
                {intl.formatMessage({ id: 'newsfeed.post_settings' })}
              </dialogtitle>
            ) : (
              title
            )}
          </dialogtitle>
          <dialogcontent style="{{" position:="" 'relative'="" }}="" ref="{dialogContentRef}">
            <grid container="" spacing="{2}" style="{{" margin:="" 0,="" width:="" '100%'="" }}="">
              {visibleOptions ? (
                <postformdialogparams post="{post}" teamGroup="{selectedFromParamsTeamGroup}" team="{selectedFromParamsTeam}" updateVisibility="{updateVisibility}" onSubmitTeamAndTeamGroup="{onSubmitTeamAndTeamGroup}" selectedRadioButton="{selectedRadioButton}" onRadioButtonChange="{handleRadioButtonChange}"></postformdialogparams>
              ) : (
                <>
                  <grid item="" xs="{12}">
                    <grid className="{classes.titleContainer}" container="" spacing="{2}" onClick="{handleOpenOptions}">
                      <grid item="">
                        {!post ? (
                          <avatar src="{account.photo}" fallbackName="{account.fullname}"></avatar>
                        ) : (
                          <avatar src="{post.author.photo}" fallbackName="{post.author.fullname}"></avatar>
                        )}
                      </grid>
                      <grid item="" xs="" container="" direction="column">
                        <grid container="">
                          <grid item="">
                            <defaulttext 16="" lowercase="" style="{{" fontSize:="" }}="">
                              {!post ? account.fullname : post.author.fullname}
                            </defaulttext>
                          </grid>
                          <grid item="">
                            <fontawesomeicon size="xs" icon="{faChevronDown}" style="{{" marginLeft:="" 5,="" fontSize:="" 11,="" fontWeight:="" 'bold',="" }}=""></fontawesomeicon>
                          </grid>
                          {!visibilityAll && role != null && (
                            <grid 5="" item="" style="{{" marginLeft:="" }}="">
                              <tooltip title="{intl" .formatMessage({="" id:="" 'newsfeed.post_visibility_role_tooltip',="" })="" .format(getRoleNameById(role))}="">
                                <bluetext>
                                  <fontawesomeicon icon="{faInfoCircle}"></fontawesomeicon>
                                </bluetext>
                              </tooltip>
                            </grid>
                          )}
                        </grid>
                        {account.title && (
                          <grid 5="" item="" style="{{" marginBottom:="" }}="">
                            <defaulttext 12="" lowercase="" style="{{" fontSize:="" }}="">
                              {account.title}
                            </defaulttext>
                          </grid>
                        )}
                        <grid 5="" item="" style="{{" marginBottom:="" }}="">
                          <div className="{classes.filterChips}">
                            <defaulttext 5="" lowercase="" style="{{" fontSize:="" 12,="" marginRight:="" }}="">
                              {intl.formatMessage({
                                id: 'newsfeed.share_button',
                              })}{' '}
                              :
                            </defaulttext>

                            {selectedFromParamsTeamGroup?.name &&
                              selectedFromParamsTeamGroup?.id !== 1 &&
                              !visibilityAll && (
                                <chip size="small" label="{selectedFromParamsTeamGroup.name}" style="{{" borderColor:="" '#333'="" }}="" variant="outlined" className="{classes.filterChip}"></chip>
                              )}
                            {selectedRadioButton === fullVisibility ||
                            selectedFromParamsTeamGroup?.id === 1 ? (
                              <chip size="small" label="{fullVisibility}" style="{{" borderColor:="" '#333'="" }}="" variant="outlined" className="{classes.filterChip}"></chip>
                            ) : (
                              (isManager || isCollaborator) && (
                                <chip size="small" label="{intl.formatMessage({" id:="" 'filter.my_team_label',="" })}="" style="{{" borderColor:="" account.team.color.hex,="" }}="" variant="outlined" className="{classes.filterChip}"></chip>
                              )
                            )}
                            {!visibilityAll && selectedFromParamsTeam?.name ? (
                              <chip size="small" label="{selectedFromParamsTeam?.name}" style="{{" borderColor:="" _.get(="" selectedFromParamsTeam,="" 'color.hex',="" )="" ||="" selectedFromParamsTeam.color,="" }}="" variant="outlined" className="{classes.filterChip}"></chip>
                            ) : (
                              ''
                            )}
                          </div>
                        </grid>
                      </grid>
                    </grid>
                  </grid>

                  <grid item="" xs="{12}">
                    <formsy onValidSubmit="{handleSubmitForm}">
                      <input accept="image/*" type="file" onChange="{e" ==""> setImage(e.target.files[0])}
                        ref={imageInput}
                        style={{ display: 'none' }}
                      />
                      <hiddeninput 0="" name="image" value="{image}" style="{{" margin:="" }}=""></hiddeninput>

                      <input accept="video/*" type="file" onChange="{e" ==""> setVideo(e.target.files[0])}
                        ref={videoInput}
                        style={{ display: 'none' }}
                      />
                      <hiddeninput 0="" name="video" value="{video}" style="{{" margin:="" }}=""></hiddeninput>

                      <input accept="*" type="file" onChange="{e" ==""> setFile(e.target.files[0])}
                        ref={fileInput}
                        style={{ display: 'none' }}
                      />
                      <hiddeninput 0="" name="file" value="{file}" style="{{" margin:="" }}=""></hiddeninput>
                      <hiddeninput 0="" name="embed" value="{embed}" style="{{" margin:="" }}=""></hiddeninput>
                      <hiddeninput 0="" name="gif" value="{gif}" style="{{" margin:="" }}=""></hiddeninput>
                      <grid container="" spacing="{2}">
                        <grid item="" xs="{12}" style="{{" paddingBottom:="" 80,="" minHeight:="" 120,="" maxHeight:="" 200,="" textTransform:="" 'lowercase',="" overflowY:="" 'auto',="" }}="">
                          <mentionsinput value="{description}" onChange="{onTextChange}" inputRef="{textFieldRef}" placeholder="{intl.formatMessage({" id:="" 'newsfeed.post_content_placeholder',="" })}="" style="{{" control:="" {="" fontSize:="" 15,="" outline:="" 'unset="" !important',="" },="" highlighter:="" overflow:="" 'hidden',="" input:="" margin:="" 0,="" border:="" 'none',="" textarea:="" suggestions:="" item:="" padding:="" '5px="" 15px',="" borderBottom:="" '1px="" solid="" rgba(0,0,0,0.15)',="" '&focused':="" backgroundColor:="" '#E9E9E9',="" }}="">
                            <mention trigger="@" data="{debouncedFetchUsers}" className="{classes.mentionSelected}"></mention>
                          </mentionsinput>
                          {(image ||
                            video ||
                            file ||
                            linkPreview ||
                            embed ||
                            gif) && (
                            <grid item="" xs="{12}" style="{{" position:="" 'relative'="" }}="">
                              <iconbutton size="medium" onClick="{()" ==""> {
                                  setImage();
                                  setVideo();
                                  setFile();
                                  setLinkPreview();
                                  setEmbed();
                                  setGif();
                                }}
                                className={classes.mediaCloseIcon}
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <fontawesomeicon icon="{faTimes}"></fontawesomeicon>
                              </iconbutton>
                              {image && (
                                <img src="{" typeof="" image="==" 'string'="" ?="" :="" URL.createObjectURL(image)="" }="" style="{{" width:="" '100%'="" }}="">
                              )}
                              {video && (
                                <grid item="" xs="{12}">
                                  <video width="100%" controls="">
                                    <source src="{" typeof="" video="==" 'string'="" ?="" :="" URL.createObjectURL(video)="" }="" type="video/mp4">
                                  </video>
                                </grid>
                              )}
                              {file && (
                                <grid item="" xs="{12}">
                                  <div style="{{" borderRadius:="" 5,="" overflow:="" 'hidden',="" }}="">
                                    <linkpreview openGraph="{{" title:="" _.get(file,="" 'name')="" ||="" _.last(file.split('="" ')),="" }}=""></linkpreview>
                                  </div>
                                </grid>
                              )}
                              {linkPreview && (
                                <grid item="" xs="{12}">
                                  <div style="{{" borderRadius:="" 5,="" overflow:="" 'hidden',="" }}="">
                                    <linkpreview openGraph="{linkPreview}"></linkpreview>
                                  </div>
                                </grid>
                              )}
                              {embed && (
                                <grid item="" xs="{12}">
                                  <iframe width="100%" height="315" src="{embed}" frameBorder="0" allowFullScreen=""></iframe>
                                </grid>
                              )}
                              {gif && (
                                <grid item="" xs="{12}" style="{{" display:="" 'flex',="" justifyContent:="" 'center',="" }}="">
                                  <img src="{" typeof="" gif="==" 'string'="" ?="" :="" _.get(gif,="" 'images.fixed_height.url')="" }="" alt="gif" style="{{" width:="" '100%'="" }}="">
                                </grid>
                              )}
                            </grid>
                          )}
                        </grid>
                        {openGraphLoading && (
                          <grid item="" xs="{12}">
                            <loader centered=""></loader>
                          </grid>
                        )}

                        <grid item="" xs="{12}" style="{{" padding:="" 0,="" position:="" 'absolute',="" bottom:="" left:="" right:="" backgroundColor:="" 'white',="" }}="">
                          <grid container="" spacing="{1}" alignItems="center" justifyContent="space-between" direction="column">
                            <grid item="" xs="">
                              <grid container="" spacing="{1}" alignItems="flex-start">
                                {isDesktop && (
                                  <grid item="">
                                    <iconbutton size="medium" onClick="{openEmojiInput}" className="{classes.iconButton}" ref="{emojiIconRef}">
                                      <fontawesomeicon icon="{faLaughBeam}"></fontawesomeicon>
                                    </iconbutton>
                                  </grid>
                                )}
                                <grid item="" ref="{gifIconRef}">
                                  <iconbutton size="medium" onClick="{openGifPicker}" className="{classes.iconButton}">
                                    <gifboxicon fontSize="large"></gifboxicon>
                                  </iconbutton>
                                </grid>
                                <grid item="">
                                  <iconbutton size="medium" onClick="{openImageInput}" className="{classes.iconButton}">
                                    <fontawesomeicon icon="{faImage}"></fontawesomeicon>
                                  </iconbutton>
                                </grid>
                                <grid item="">
                                  <iconbutton size="medium" onClick="{openVideoInput}" className="{classes.iconButton}">
                                    <fontawesomeicon icon="{faPlayCircle}"></fontawesomeicon>
                                  </iconbutton>
                                </grid>
                                <grid item="">
                                  <iconbutton size="medium" onClick="{openFileInput}" className="{classes.iconButton}">
                                    <fontawesomeicon icon="{faPaperclip}"></fontawesomeicon>
                                  </iconbutton>
                                </grid>
                                <grid item="">
                                  <iconbutton size="medium" onClick="{()" =="">
                                      setEmbed(
                                        window.prompt(
                                          intl.formatMessage({
                                            id: 'newsfeed.embed_prompt',
                                          }),
                                        ),
                                      )
                                    }
                                    className={classes.iconButton}
                                  >
                                    <fontawesomeicon icon="{faCode}"></fontawesomeicon>
                                  </iconbutton>
                                </grid>
                              </grid>
                            </grid>
                            <grid item="">
                              <progressbutton type="submit" loading="{creationLoading}" text="{intl.formatMessage({" id:="" 'common.submit',="" })}="" disabled="{!description}"></progressbutton>
                            </grid>
                          </grid>
                        </grid>
                        <grid item="" style="{{" position:="" 'absolute',="" top:="" positions.emoji.top="" -="" 310,="" left:="" positions.emoji.left="" 115,="" }}="" ref="{emojiInputRef}">
                          {showEmojiPicker && (
                            <emojipicker searchDisabled="{true}" previewConfig="{{" showPreview:="" false="" }}="" width="{250}" height="{300}" onEmojiClick="{onEmojiClick}"></emojipicker>
                          )}
                        </grid>
                        <grid item="" style="{{" position:="" 'absolute',="" top:="" isMobile="" ?="" '180px'="" :="" positions.gif.top="" -="" 265,="" left:="" '10px'="" positions.gif.left="" 115,="" zIndex:="" 1000,="" }}="" ref="{gifInputRef}">
                          {showGiphyPicker && (
                            <reactgiphysearchbox gifListHeight="200px" apiKey="{gifApiKey}" onSelect="{item" ==""> {
                                setGif(item);
                                setShowGiphyPicker(false);
                              }}
                            />
                          )}
                        </reactgiphysearchbox></grid>
                      </grid>
                    </formsy>
                  </grid>
                </>
              )}
            </grid>
          </dialogcontent>
        </dialog>
      )}
    </div>
  );
};

const mapStateToProps = ({ accountDetail, roleList, userList }) => ({
  accountDetail,
  roleList,
  userList,
});

const mapDispatchToProps = dispatch => ({
  roleListActions: bindActionCreators(roleListActions, dispatch),
  userListActions: bindActionCreators(userListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withWidth()(PostFormDialog)));
