import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Building, ProfileCircle } from 'iconsax-react';
import { systemColors } from '@src/Spider/themes';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import useDirtyPristine from '@src/Spider/hooks/UseDirtyPristine';
import Formsy from 'formsy-react';
import Button from '@spider:components/Button';
import { createTheme } from '@material-ui/core/styles';
import { RewardAllocationConfigurationImageCodes } from '@src/Spider/enums/rewardAllocationConfigurationImageCodes';
import { ConfigurationField } from '../ConfigurationField';
import { Loader } from '@src/components';
import { useUpdateRewardAllocationMutation } from '@async-calls/rewards';
import { ConfigurationImage } from 'src/Spider/scenes/Admin/components/ParametersTab/components/ProductsParameters/components/Reward/components/ConfigurationImage';
import { ParticipantType } from '@src/Spider/enums/participantType';
import { toast } from 'react-toastify';
import { CloseButtonIcon } from '@spider:components/CloseButtonIcon';

const styles = {
  configurationLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  configurationTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  configurationTitleLabel: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  configurationContentInputs: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [createTheme().breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [createTheme().breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  configurationContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  configurationContentLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '0rem 2.5rem !important',
    gap: '1rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogConfirmationActions: {
    gap: '0.5rem',
    [createTheme().breakpoints.up('md')]: {
      flexDirection: 'row !important',
      justifyContent: 'center !important',
      '&.MuiDialogActions-root > button': {
        margin: '0 !important',
      },
    },
  },
};

const RewardAllocationsConfiguration = ({
  rewardLabel,
  rewardAllocation,
  configurations,
  type = ParticipantType.MORALE,
  ...props
}) => {
  const intl = useIntl();

  const [updateRewardAllocation, updateRewardAllocationResult] =
    useUpdateRewardAllocationMutation();

  const activeConfigurations =
    (type === ParticipantType.MORALE
      ? rewardAllocation?.legal_entities_config
      : rewardAllocation?.individuals_config) ?? [];

  const [openDisableConfirmation, setOpenDisableConfirmation] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [enableConfiguration, setEnableConfiguration] = useState(
    activeConfigurations.length > 0,
  );
  const [configurationValues, setConfigurationValues] =
    useState(activeConfigurations);

  const disableButton = () => setCanSubmit(false);
  const enableButton = () => setCanSubmit(true);

  const { dirtyChange, resetPristinePage } = useDirtyPristine();

  const onConfigurationChange = configuration => {
    dirtyChange();
    const configurationValuesChanged = [...configurationValues];
    const configurationIndex = configurationValues.findIndex(
      configurationValue => configurationValue.code === configuration.code,
    );
    if (configurationIndex !== -1) {
      configurationValuesChanged.splice(configurationIndex, 1, configuration);
    } else {
      configurationValuesChanged.push(configuration);
    }
    setConfigurationValues(configurationValuesChanged);
  };

  const checkConfiguration = async event => {
    dirtyChange();
    setEnableConfiguration(event.target.checked);
    const config =
      type === ParticipantType.MORALE
        ? rewardAllocation.legal_entities_config
        : rewardAllocation.individuals_config;
    if (!event.target.checked && !!config) {
      setOpenDisableConfirmation(true);
    } else {
      setConfigurationValues([]);
    }
  };

  const onConfirmationClose = () => {
    setOpenDisableConfirmation(false);
    setEnableConfiguration(true);
  };

  const disableRewardAllocation = async () => {
    setOpenDisableConfirmation(false);
    const rewardAllocationDto = {};
    if (type === ParticipantType.MORALE) {
      rewardAllocationDto.legal_entities_config = null;
    } else {
      rewardAllocationDto.individuals_config = null;
    }

    setEnableConfiguration(true);
    await saveRewardAllocation({
      fixedCacheKey: `unique${rewardAllocation.uuid}`,
      id: rewardAllocation.uuid,
      rewardAllocation: rewardAllocationDto,
    });
  };

  const saveConfiguration = async () => {
    const rewardAllocationDto = {};
    const configs = enableConfiguration ? configurationValues : null;

    if (type === ParticipantType.MORALE) {
      rewardAllocationDto.legal_entities_config = configs;
    } else {
      rewardAllocationDto.individuals_config = configs;
    }

    await saveRewardAllocation({
      fixedCacheKey: `unique${rewardAllocation.uuid}`,
      id: rewardAllocation.uuid,
      rewardAllocation: rewardAllocationDto,
    });
  };

  const saveRewardAllocation = async updateDto => {
    try {
      await updateRewardAllocation(updateDto).unwrap();
      toast.success(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.rewards.update.success',
        }),
      );
    } catch (e) {
      toast.error(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.rewards.update.error',
        }),
      );
    }
  };

  return updateRewardAllocationResult.isLoading ? (
    <loader centered=""></loader>
  ) : (
    <formsy name="{'parameter-contract-details'}" onValidSubmit="{saveConfiguration}" onValid="{enableButton}" onInvalid="{disableButton}" className="{props.classes.configurationLayout}" onChange="{()" ==""> setIsFormDirty(true)}
    >
      <div className="{props.classes.configurationContentLayout}">
        <div className="{props.classes.configurationTitle}">
          <div className="{props.classes.configurationTitleLabel}">
            {type === ParticipantType.MORALE ? (
              <building color="{systemColors.errorRegular}"></building>
            ) : (
              <profilecircle color="{systemColors.errorRegular}"></profilecircle>
            )}

            <typography variant="{'subtitle1'}" style="{{" fontWeight:="" 'bold'="" }}="">
              {type === ParticipantType.MORALE
                ? intl.formatMessage({
                    id: 'spider.hierarchy_node.platform_customization.parameters.rewards.moralePerson',
                  })
                : intl.formatMessage({
                    id: 'spider.hierarchy_node.platform_customization.parameters.rewards.physicPerson',
                  })}
            </typography>
          </div>

          <formcontrol style="{{" display:="" 'flex',="" flexDirection:="" 'row'="" }}="">
            <formcontrollabel checked="{enableConfiguration}" control="{<Switch" color="{'primary'}"></formcontrollabel>}
              labelPlacement='start'
              label={
                enableConfiguration
                  ? intl.formatMessage({
                      id: 'spider.hierarchy_node.platform_customization.parameters.rewards.enabledPerson',
                    })
                  : intl.formatMessage({
                      id: 'spider.hierarchy_node.platform_customization.parameters.rewards.disabledPerson',
                    })
              }
              style={{ margin: 0 }}
              onChange={checkConfiguration}
            />
          </formcontrol>
        </div>

        {enableConfiguration && (
          <div className="{props.classes.configurationContent}">
            <div className="{props.classes.configurationContentInputs}">
              {configurations?.map((configuration, index) => (
                <configurationfield key="{index}" configuration="{configuration}" configurationDefaultValue="{" configurationValues.find(="" configurationValue="">
                        configurationValue.code === configuration.code,
                    )?.value ?? ''
                  }
                  onConfigurationChange={e => onConfigurationChange(e)}
                />
              ))}
            </configurationfield></div>

            <configurationimage rewardAllocationId="{rewardAllocation.uuid}" rewardLabel="{rewardLabel}" code="{" type="==" ParticipantType.MORALE="" ?="" RewardAllocationConfigurationImageCodes.LEGAL_ENTITIES_REWARD_ALLOCATION_IMAGE="" :="" RewardAllocationConfigurationImageCodes.INDIVIDUALS_REWARD_ALLOCATION_IMAGE="" }="" rewardAllocationImage="{" rewardAllocation.legal_entities_custom_image="" rewardAllocation.individuals_custom_image=""></configurationimage>
          </div>
        )}
      </div>

      {enableConfiguration && (
        <div className="{props.classes.actions}">
          <button color="{'primary'}" variant="{'outlined'}" size="{'small'}" type="{'submit'}" disabled="{!canSubmit" ||="" !isFormDirty}="">
            {intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.parameters.rewards.saveButtonLabel',
            })}
          </button>
        </div>
      )}

      <dialog open="{openDisableConfirmation}" onClose="{onConfirmationClose}">
        <dialogtitle style="{{" display:="" 'flex',="" flexDirection:="" 'row-reverse'="" }}="">
          <closebuttonicon onClick="{onConfirmationClose}"></closebuttonicon>
        </dialogtitle>

        <dialogcontent className="{props.classes.dialogContent}">
          <typography variant="{'h1'}" component="{'h1'}" className="{'underline-center'}" style="{{" textAlign:="" 'center'="" }}="">
            {intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.parameters.rewards.disableRewardConfirmation.title',
            })}
          </typography>

          <typography variant="{'subtitle1'}" style="{{" textAlign:="" 'center'="" }}="">
            {intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.parameters.rewards.disableRewardConfirmation.message',
            })}
          </typography>
        </dialogcontent>

        <dialogactions className="{props.classes.dialogConfirmationActions}">
          <button size="{'small'}" variant="{'outlined'}" color="{'primary'}" onClick="{onConfirmationClose}">
            {intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.parameters.rewards.disableRewardConfirmation.actions.no',
            })}
          </button>
          <button size="{'small'}" variant="{'contained'}" color="{'primary'}" onClick="{disableRewardAllocation}">
            {intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.parameters.rewards.disableRewardConfirmation.actions.yes',
            })}
          </button>
        </dialogactions>
      </dialog>
    </formsy>
  );
};

export default withStyles(styles)(RewardAllocationsConfiguration);
