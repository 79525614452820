import React, { useState, useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import {
  Spreadsheet,
  Loader,
  DefaultTitle,
  DefaultText,
  ProgressButton,
  Avatar,
  DatePicker,
  TextField,
  Tooltip,
  Button,
} from '../../../../../../components';
import { CollaboratorInputImageList } from '../';
import { ChallengeCollaboratorFilter, ChallengeSearchBar } from '../../../';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExchangeAlt,
  faArrowUp,
  faArrowDown,
  faEdit,
  faChevronLeft,
  faFile,
  faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
import {
  Grid,
  IconButton,
  withWidth,
  isWidthUp,
  CardMedia,
} from '@material-ui/core';
import * as collaboratorDataUpdateActions from '../../../../../../services/CollaboratorData/CollaboratorDataUpdate/actions';
import { hasImageExtension } from '../../../../../../helpers/UrlHelper';
import { useIntl, injectIntl } from 'react-intl';
import _ from 'lodash';

const styles = {
  spreadsheet: {
    paddingLeft: 0,
    width: '100%',
  },
  searchBar: {
    width: '40vw',
    maxWidth: '40vw',
  },
  userAvatar: {
    width: 30,
    height: 30,
  },
  link: {
    fontSize: 16,
    cursor: 'pointer',
    alignSelf: 'flex-start',
    color: 'rgb(15,111,222)',
    opacity: 1,
  },
  coverImage: {
    position: 'absolute',
    top: '-12px',
    height: 'calc(100% + 20px)',
    width: 'calc(50% + 20px)',
    borderRadius: '0 4px 4px 0',
  },
};

const CollaboratorDataSpreadsheet = ({
  data: fetchedData,
  hasNextDataPage,
  loading: dataLoading,
  kpi,
  classes,
  width,
  close,
  startDate,
  endDate,
  image: coverImage,
  reloadData,
  filterDataList,
  participantTeamIds,
  participantIds,
  exportToExcel,
  exportToCsv,
  showPersonalizedTeamsColumn,
  ...props
}) => {
  const intl = useIntl();
  const { loading, success, hasError: error } = props.collaboratorDataUpdate;
  const [data, setData] = React.useState();
  const [nextPage, setNextPage] = React.useState(1);
  const [replaceData, setReplaceData] = React.useState(true);
  const [grid, setGrid] = React.useState();
  const [sort, setSort] = React.useState(['firstname', 'lastname']);
  const [lastSort, setLastSort] = React.useState();
  const [lastSortDirection, setLastSortDirection] = React.useState();
  const [submitDisabled, setSubmitDisabled] = React.useState(false);
  const [currentData, setCurrentData] = React.useState();

  const [collaborator, setCollaborator] = useState();
  const [team, setTeam] = useState();
  const [teamGroup, setTeamGroup] = useState();
  const [search, setSearch] = useState();
  const searchRef = useRef(search);
  const nextPageRef = useRef(nextPage);

  const readOnly = kpi && !kpi.manual;
  const isDesktop = isWidthUp('md', width);

  useEffect(() => {
    setSubmitDisabled(false);
  }, [grid]);

  useEffect(() => {
    const sortDirection =
      lastSort && sort[0] === lastSort[0] && lastSortDirection === 'asc'
        ? 'desc'
        : 'asc';
    setLastSort(sort);
    setLastSortDirection(sortDirection);
    if (sort && sortDirection && data && kpi) {
      refreshGrid(sort, sortDirection);
    }
  }, [sort]);

  useEffect(() => {
    if (fetchedData) {
      if (!replaceData) {
        setGrid();

        setData(_.uniqWith([...data, ...fetchedData], _.isEqual));
      } else {
        setGrid();

        setData(fetchedData);
        setReplaceData(false);
      }
    }
  }, [fetchedData]);

  const onCollaboratorFilter = (
    team,
    collaborator,
    year,
    start,
    end,
    type,
    teamGroup,
  ) => {
    if (collaborator) {
      setTeam();
      setTeamGroup();
      setCollaborator(collaborator);
    } else if (team) {
      setTeam(team);
      setTeamGroup();
      setCollaborator();
    } else if (teamGroup) {
      setTeam();
      setTeamGroup(teamGroup);
      setCollaborator();
    }

    // setTeam(team)
    // setTeamGroup(teamGroup)
    // setCollaborator(collaborator)
    if (filterDataList) {
      setReplaceData(true);
      filterDataList(teamGroup, team, collaborator, 1, search);
    }
  };

  const handleSubmit = () => {
    let payload = _.flatten(grid)
      .filter(cell => cell.type === 'data' && cell.id)
      .map(cell =>
        cell.id === -1
          ? {
              id: cell.id,
              value: cell.value,
              kpi: cell.kpi,
              start: startDate,
              end: endDate,
              collaborator: cell.collaborator,
            }
          : cell.value !== null && cell.value !== undefined
            ? {
                id: cell.id,
                value: cell.value,
              }
            : { id: cell.id },
      );
    payload = payload.filter(
      d =>
        (d.id === -1 &&
          d.value !== null &&
          d.value !== undefined &&
          d.value > 0) ||
        d.id > 0,
    );
    props.collaboratorDataUpdateActions.updateCollaboratorData(payload);
  };

  const handleDataSubmit = model => {
    const formData = new FormData();
    formData.append('id', currentData.dataId);
    formData.append('value', model.value);
    formData.append('description', model.description || '');
    if (currentData.dataId == -1) {
      formData.append('collaborator', currentData.collaborator_id);
      formData.append('kpi', currentData.kpi_id);
      formData.append('start', startDate);
      formData.append('end', endDate);
    }

    const images = model.images
      ? _.compact(
          model.images.map((image, index) => {
            if (image && image.id) {
              return image.id;
            }
          }),
        )
      : [];

    const model_image_ids = _.get(model, 'images', []).map(i => parseInt(i.id));

    const deleted_images = _.get(currentData, 'images', []).filter(
      i => !model_image_ids.includes(parseInt(i.id)),
    );

    const image_uploads = model.images
      ? _.compact(
          model.images.map((image, index) => {
            if (image && !image.id) {
              return image;
            }
          }),
        )
      : [];

    const imageUploadSizeReached = image_uploads.some(
      image => image.size > 20000000,
    );
    deleted_images.forEach((image, index) => {
      formData.append(`deleted_images`, image.id);
    });
    image_uploads.forEach((image, index) => {
      formData.append(`image_uploads`, image);
    });

    if (imageUploadSizeReached) {
      toast.error(
        intl.formatMessage({
          id: 'challenge.kpi_results.form.file_size_error',
        }),
      );
      return;
    }

    // formData.append(`images`, model.images && _.compact(model.images.map(image => image.collaborator_input_id ? null : image)) || [])
    // if(newImage instanceof Blob) {
    //   formData.append('image', newImage)
    // }

    // props.collaboratorInputUpdateActions.updateCollaboratorInput(formData)
    props.collaboratorDataUpdateActions.updateCollaboratorData(formData);
  };
  const refreshGrid = (
    sort = ['firstname', 'lastname'],
    sortDirection = 'asc',
  ) => {
    const personalizedTeamsColumn = showPersonalizedTeamsColumn
      ? [
          {
            value: (
              <div style="{{" textAlign:="" 'center',="" cursor:="" 'pointer'="" }}="" onClick="{()" ==""> {
                  setSort(['name_team_personalised']);
                }}
              >
                <grid container="" spacing="{1}" justify="center">
                  <grid item="">
                    {intl.formatMessage({
                      id: 'common.team_personalised_name',
                    })}
                  </grid>
                  <grid item="">
                    <iconbutton 12="" size="small" style="{{" color:="" 'white',="" transform:="" sort[0]="" !="=" 'name_team_personalised'="" ?="" 'rotate(90deg)'="" :="" '',="" fontSize:="" 16,="" }}="">
                      <fontawesomeicon icon="{" sort[0]="==" 'name_team_personalised'="" ?="" sortDirection="==" 'asc'="" faArrowUp="" :="" faArrowDown="" faExchangeAlt="" }=""></fontawesomeicon>
                    </iconbutton>
                  </grid>
                </grid>
              </div>
            ),
            readOnly: true,
            className: 'dataCell baseCell period-M headerCell',
          },
        ]
      : [];
    let newGrid = [
      [
        {
          value: (
            <div style="{{" textAlign:="" 'center',="" cursor:="" 'pointer'="" }}="" onClick="{()" ==""> {
                setSort(['firstname', 'lastname']);
              }}
            >
              <grid container="" spacing="{1}" justify="center">
                <grid item="">
                  {intl.formatMessage({ id: 'common.collaborator' })}
                </grid>
                <grid item="">
                  <iconbutton 12="" size="small" style="{{" color:="" 'white',="" transform:="" sort[0]="" !="=" 'firstname'="" ?="" 'rotate(90deg)'="" :="" '',="" fontSize:="" 16,="" }}="">
                    <fontawesomeicon icon="{" sort[0]="==" 'firstname'="" ?="" sortDirection="==" 'asc'="" faArrowUp="" :="" faArrowDown="" faExchangeAlt="" }=""></fontawesomeicon>
                  </iconbutton>
                </grid>
              </grid>
            </div>
          ),
          readOnly: true,
          className: 'dataCell baseCell period-M headerCell',
        },
        {
          value: (
            <div style="{{" textAlign:="" 'center',="" cursor:="" 'pointer'="" }}="" onClick="{()" ==""> {
                setSort(['team']);
              }}
            >
              <grid container="" spacing="{1}" justify="center">
                <grid item="">{intl.formatMessage({ id: 'common.team' })}</grid>
                <grid item="">
                  <iconbutton 12="" size="small" style="{{" color:="" 'white',="" transform:="" sort[0]="" !="=" 'team'="" ?="" 'rotate(90deg)'="" :="" '',="" fontSize:="" 16,="" }}="">
                    <fontawesomeicon icon="{" sort[0]="==" 'team'="" ?="" sortDirection="==" 'asc'="" faArrowUp="" :="" faArrowDown="" faExchangeAlt="" }=""></fontawesomeicon>
                  </iconbutton>
                </grid>
              </grid>
            </div>
          ),
          readOnly: true,
          className: 'dataCell baseCell period-M headerCell',
        },
        {
          value: (
            <div style="{{" textAlign:="" 'center',="" cursor:="" 'pointer'="" }}="" onClick="{()" ==""> {
                setSort(['team_group']);
              }}
            >
              <grid container="" spacing="{1}" justify="center">
                <grid item="">
                  {intl.formatMessage({ id: 'common.team_group' })}
                </grid>
                <grid item="">
                  <iconbutton 12="" size="small" style="{{" color:="" 'white',="" transform:="" sort[0]="" !="=" 'team_group'="" ?="" 'rotate(90deg)'="" :="" '',="" fontSize:="" 16,="" }}="">
                    <fontawesomeicon icon="{" sort[0]="==" 'team_group'="" ?="" sortDirection="==" 'asc'="" faArrowUp="" :="" faArrowDown="" faExchangeAlt="" }=""></fontawesomeicon>
                  </iconbutton>
                </grid>
              </grid>
            </div>
          ),
          readOnly: true,
          className: 'dataCell baseCell period-M headerCell',
        },
        ...personalizedTeamsColumn,
        {
          value: (
            <div style="{{" textAlign:="" 'center',="" cursor:="" 'pointer'="" }}="" onClick="{()" ==""> {
                setSort(['description']);
              }}
            >
              <grid container="" spacing="{1}" justify="center">
                <grid item="">
                  {intl.formatMessage({
                    id: 'challenge.kpi_results.columns.indications',
                  })}
                </grid>
                <grid item="">
                  <iconbutton 12="" size="small" style="{{" color:="" 'white',="" transform:="" sort[0]="" !="=" 'description'="" ?="" 'rotate(90deg)'="" :="" '',="" fontSize:="" 16,="" }}="">
                    <fontawesomeicon icon="{" sort[0]="==" 'description'="" ?="" sortDirection="==" 'asc'="" faArrowUp="" :="" faArrowDown="" faExchangeAlt="" }=""></fontawesomeicon>
                  </iconbutton>
                </grid>
              </grid>
            </div>
          ),
          readOnly: true,
          className: 'dataCell baseCell period-M headerCell',
        },
        {
          value: (
            <div style="{{" textAlign:="" 'center',="" cursor:="" 'pointer'="" }}="">
              <grid container="" spacing="{1}" justify="center">
                <grid item="">
                  {intl.formatMessage({
                    id: 'challenge.kpi_results.columns.images',
                  })}
                </grid>
              </grid>
            </div>
          ),
          readOnly: true,
          className: 'dataCell baseCell period-M headerCell',
        },
        {
          value: (
            <div style="{{" textAlign:="" 'center',="" cursor:="" 'pointer'="" }}="" onClick="{()" ==""> {
                setSort(['dataValue']);
              }}
            >
              <grid container="" spacing="{1}" justify="center">
                <grid item="">
                  {intl.formatMessage({
                    id: 'challenge.kpi_results.columns.result',
                  })}
                </grid>
                <grid item="">
                  <iconbutton 12="" size="small" style="{{" color:="" 'white',="" transform:="" sort[0]="" !="=" 'dataValue'="" ?="" 'rotate(90deg)'="" :="" '',="" fontSize:="" 16,="" }}="">
                    <fontawesomeicon icon="{" sort[0]="==" 'dataValue'="" ?="" sortDirection="==" 'asc'="" faArrowUp="" :="" faArrowDown="" faExchangeAlt="" }=""></fontawesomeicon>
                  </iconbutton>
                </grid>
              </grid>
            </div>
          ),
          readOnly: readOnly,
          type: 'data',
          className: 'dataCell baseCell period-M headerCell',
        },
        {
          value: (
            <div style="{{" textAlign:="" 'center',="" cursor:="" 'pointer'="" }}="">
              <grid container="" spacing="{1}" justify="center">
                <grid item="">
                  {intl.formatMessage({
                    id: 'challenge.kpi_results.columns.actions',
                  })}
                </grid>
              </grid>
            </div>
          ),
          readOnly: true,
          className: 'dataCell baseCell period-M headerCell',
        },
      ],
    ];
    const currentData = data.map(line =>
      Object.assign({}, line, {
        dataValue:
          grid && grid.length > 0
            ? _.flatten(grid).find(
                cell => parseInt(cell.id) === parseInt(line.dataId),
              ).value
            : line.dataValue,
      }),
    );

    _.orderBy(
      currentData,
      sort,
      sort.map(s => sortDirection),
    ).forEach(line => {
      const personalizedTeamsColumn = showPersonalizedTeamsColumn
        ? [
            {
              value: (
                <grid 350="" item="" style="{{" minWidth:="" 180,="" maxWidth:="" }}="">
                  {line.name_team_personalised}
                </grid>
              ),
              readOnly: true,
              className: 'collaboratorCell noBorder whiteCell baseCell',
            },
          ]
        : [];
      newGrid = [
        ...newGrid,
        [
          {
            value: (
              <grid container="" spacing="{1}" style="{{" flexWrap:="" 'nowrap'="" }}="">
                <grid item="">
                  <avatar src="{" _.get(line,="" 'user.photo')="" ||="" '="" assets="" img="" user="" avatar.svg'="" }="" className="{classes.userAvatar}" fallbackName="{`${line.firstname}" ${line.lastname}`}=""></avatar>
                </grid>
                <grid 350="" item="" style="{{" minWidth:="" 180,="" maxWidth:="" }}="">
                  {line.firstname} {line.lastname}
                </grid>
              </grid>
            ),
            readOnly: true,
            className: 'collaboratorCell noBorder whiteCell baseCell',
          },
          {
            value: (
              <grid 350="" item="" style="{{" minWidth:="" 180,="" maxWidth:="" }}="">
                {line.team}
              </grid>
            ),
            readOnly: true,
            className: 'collaboratorCell noBorder whiteCell baseCell',
          },
          {
            value: (
              <grid 350="" item="" style="{{" minWidth:="" 180,="" maxWidth:="" }}="">
                {line.team_group}
              </grid>
            ),
            readOnly: true,
            className: 'collaboratorCell noBorder whiteCell baseCell',
          },
          ...personalizedTeamsColumn,

          {
            value: (
              <tooltip title="{line.description}" rootClass="{classes.tooltip}">
                <grid item="" style="{{" width:="" 200,="" textOverflow:="" 'ellipsis',="" overflow:="" 'hidden',="" whiteSpace:="" 'nowrap',="" }}="">
                  {line.description}
                </grid>
              </tooltip>
            ),
            readOnly: true,
            className: 'collaboratorCell baseCell whiteCell noBorder period-M',
          },
          {
            value: line.images ? (
              <grid container="" justify="center" spacing="{2}">
                {line.images.map((image, index) => (
                  <grid item="" key="{index}">
                    {hasImageExtension(image.path) ? (
                      <tooltip title="{" <img="" src="{image.path}" style="{{" maxWidth:="" 300,="" maxHeight:="" 250,="" cursor:="" 'pointer',="" }}=""></tooltip>
                        }
                        rootClass={classes.tooltip}
                      >
                        <a href="{image.path}" target="_blank" rel="noreferrer">
                          <img src="{image.path}" style="{{" maxWidth:="" 150,="" maxHeight:="" 20,="" cursor:="" 'pointer',="" }}="">
                        </a>
                      
                    ) : (
                      <tooltip title="{_.last(image.path.split('/'))}">
                        <a href="{image.path}" target="_blank" style="{{" color:="" '#333'="" }}="" rel="noreferrer">
                          <fontawesomeicon icon="{faFile}"></fontawesomeicon>
                        </a>
                      </tooltip>
                    )}
                  </grid>
                ))}
              </grid>
            ) : (
              ''
            ),
            readOnly: true,
            className: 'collaboratorCell baseCell whiteCell noBorder period-M',
          },
          {
            value: line.dataValue,
            readOnly: readOnly,
            type: 'data',
            id: line.dataId,
            kpi: line.kpi_id,
            collaborator: line.collaborator_id,
            className: 'dataCell baseCell period-M',
          },
          {
            value: (
              <grid container="" justify="center">
                <grid item="">
                  <iconbutton 7="" size="small" style="{{" fontSize:="" 16,="" marginBottom:="" }}="" onClick="{()" ==""> setCurrentData(line)}
                  >
                    <fontawesomeicon icon="{faEdit}"></fontawesomeicon>
                  </iconbutton>
                </grid>
              </grid>
            ),
            readOnly: true,
            className: 'collaboratorCell baseCell whiteCell noBorder period-M',
          },
        ],
      ];
    });

    setGrid(newGrid);
  };

  if (data && kpi && !grid) {
    refreshGrid();
  }

  if (success) {
    props.collaboratorDataUpdateActions.clearCollaboratorDataUpdate();
    toast.success(intl.formatMessage({ id: 'common.update_success_message' }));
    if (currentData) {
      setTimeout(() => {
        setCurrentData(null);
        if (reloadData) {
          setReplaceData(true);
          reloadData();
        }
      }, 100);
    } else {
      close();
    }
  }

  if (error) {
    props.collaboratorDataUpdateActions.clearCollaboratorDataUpdate();
    toast.error(intl.formatMessage({ id: 'common.update_error_message' }));
    if (!currentData) {
      close();
    }
  }

  const handleObserver = () => {
    if (hasNextDataPage && !dataLoading) {
      setNextPage(nextPage + 1);
    }
  };

  useEffect(() => {
    if (filterDataList && _.get(nextPageRef, 'current') !== nextPage) {
      filterDataList(teamGroup, team, collaborator, nextPage, search);
    }
    nextPageRef.current = nextPage;
  }, [nextPage]);

  useEffect(() => {
    if (filterDataList && _.get(searchRef, 'current') !== search) {
      setReplaceData(true);
      filterDataList(teamGroup, team, collaborator, 1, search);
    }
    searchRef.current = search;
  }, [search]);

  return (
    <react.fragment>
      {!currentData && (
        <react.fragment>
          <grid container="">
            <grid item="" xs="{12}">
              <grid 10="" container="" spacing="{1}" style="{{" marginBottom:="" }}="">
                {exportToCsv && (
                  <grid item="">
                    <button onClick="{()" ==""> exportToCsv()}>
                      <fontawesomeicon 5="" icon="{faFileDownload}" style="{{" marginRight:="" }}=""></fontawesomeicon>
                      {intl.formatMessage({ id: 'common.csv_export' })}
                    </button>
                  </grid>
                )}
                {exportToExcel && (
                  <grid item="">
                    <button onClick="{()" ==""> exportToExcel()}>
                      <fontawesomeicon 5="" icon="{faFileDownload}" style="{{" marginRight:="" }}=""></fontawesomeicon>
                      {intl.formatMessage({ id: 'common.excel_export' })}
                    </button>
                  </grid>
                )}
              </grid>
            </grid>
          </grid>

          <grid container="" spacing="{1}" style="{{" position:="" 'relative'="" }}="">
            <grid item="">
              <challengecollaboratorfilter onChange="{onCollaboratorFilter}" team="{team}" teamGroup="{teamGroup}" collaborator="{collaborator}" scopeTeams="{participantTeamIds}" scopeCollaborators="{participantIds}"></challengecollaboratorfilter>
            </grid>
            {filterDataList && (
              <grid 110="" item="" style="{{" position:="" 'absolute',="" left:="" }}="">
                <challengesearchbar search="{search}" onChange="{value" ==""> {
                    if (value !== search) {
                      setSearch(value);
                    }
                  }}
                  inputClass={classes.searchBar}
                />
              </challengesearchbar></grid>
            )}
          </grid>

          <react.fragment>
            <div 0="" 25="" 290="" style="{{" width:="" 'calc(100%="" +="" 20px)',="" maxHeight:="" `calc(100vh="" -="" ${isDesktop="" ?="" :="" 410}px="" ${="" kpi="" &&="" kpi.collaborator_editable="" }px)`,="" overflowY:="" 'overlay',="" overflowX:="" paddingRight:="" 10,="" }}="">
              {grid && (
                <spreadsheet grid="{grid}" baseClassName="{classes.spreadsheet}" onCellsChanged="{(changes," currentGrid)=""> {
                    setSubmitDisabled(true);
                    setGrid(currentGrid);
                  }}
                />
              )}
              <div 10="" style="{{" height:="" 40,="" marginTop:="" }}="">
                {dataLoading && <loader centered=""></loader>}

                {!dataLoading && hasNextDataPage && (
                  <grid container="" justify="center">
                    <grid item="" onClick="{handleObserver}" className="{classes.link}">
                      {intl.formatMessage({
                        id: 'challenge.kpi_results.see_more',
                      })}
                    </grid>
                  </grid>
                )}
              </div>
            </spreadsheet></div>
            <grid 15="" container="" justify="center" style="{{" marginTop:="" }}="">
              <grid item="">
                <formsy onValidSubmit="{handleSubmit}">
                  <progressbutton disabled="{readOnly" ||="" submitDisabled}="" type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" loading="{loading}" centered=""></progressbutton>
                </formsy>
              </grid>
            </grid>
          </react.fragment>
        </react.fragment>
      )}
      {currentData && (
        <grid 600="" container="" spacing="{2}" justify="space-between" style="{{" maxWidth:="" coverImage="" ?="" '100%'="" :="" }}="">
          <grid 6="" item="" xs="{12}" sm="{coverImage" ?="" :="" 12}="">
            <formsy onValidSubmit="{handleDataSubmit}">
              <grid container="" spacing="{2}">
                <grid item="" xs="{12}">
                  <grid container="" direction="column" alignItems="flex-start">
                    <grid item="" onClick="{()" ==""> setCurrentData()}
                      className={classes.link}
                    >
                      <fontawesomeicon 5="" icon="{faChevronLeft}" style="{{" marginRight:="" }}=""></fontawesomeicon>
                      {intl.formatMessage({
                        id: 'challenge.kpi_results.back_button',
                      })}
                    </grid>
                  </grid>
                </grid>
                <grid item="" xs="{12}" sm="{6}">
                  <textfield name="collaborator" label="{intl.formatMessage({" id:="" 'common.collaborator'="" })}="" initial="{`${currentData.firstname}" ${currentData.lastname}`}="" fullWidth="" disabled="" lowercase=""></textfield>
                </grid>

                <grid item="" xs="{12}">
                  <div style="{{" maxHeight:="" '40vh',="" overflow:="" 'auto',="" width:="" '100%',="" paddingRight:="" 20,="" paddingTop:="" 10,="" paddingBottom:="" }}="">
                    <grid container="" spacing="{2}" justifyContent="space-between">
                      <grid item="" xs="{12}">
                        <textfield name="value" label="{intl.formatMessage({" id:="" 'challenge.kpi_results.form.data_value',="" })}="" placeholder="{intl.formatMessage({" 'challenge.kpi_results.form.data_value_placeholder',="" initial="{currentData.dataValue}" type="number" fullWidth="" required="" lowercase=""></textfield>
                      </grid>
                      <grid item="" xs="{12}">
                        <textfield name="description" label="{intl.formatMessage({" id:="" 'challenge.kpi_results.form.data_description',="" })}="" placeholder="{intl.formatMessage({" 'challenge.kpi_results.form.data_description_placeholder',="" initial="{currentData.description}" fullWidth="" lowercase="" multiline=""></textfield>
                      </grid>
                      <grid item="" xs="{12}">
                        <collaboratorinputimagelist images="{currentData.images" ||="" []}=""></collaboratorinputimagelist>
                      </grid>
                    </grid>
                  </div>
                </grid>

                <grid 15="" item="" xs="{12}" style="{{" marginTop:="" }}="">
                  <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" loading="{loading}" centered=""></progressbutton>
                </grid>
              </grid>
            </formsy>
          </grid>
          {isDesktop && coverImage && (
            <grid item="" xs="{12}" sm="{6}">
              <cardmedia image="{coverImage}" className="{classes.coverImage}"></cardmedia>
            </grid>
          )}
        </grid>
      )}
    </react.fragment>
  );
};

const mapStateToProps = ({ collaboratorDataUpdate }) => ({
  collaboratorDataUpdate,
});

const mapDispatchToProps = dispatch => ({
  collaboratorDataUpdateActions: bindActionCreators(
    collaboratorDataUpdateActions,
    dispatch,
  ),
});

export default withWidth()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(CollaboratorDataSpreadsheet)),
);
