import { useCallback, useState } from 'react';

/**
 * @typedef {Object} UseDisclosureProps
 * @property {boolean} [open] - Controlled open state
 * @property {boolean} [defaultOpen] - Default open state
 * @property {Function} [onClose] - Callback when disclosure is closed
 * @property {Function} [onOpen] - Callback when disclosure is opened
 * @property {string} [id] - Optional ID
 */

/**
 * `useDisclosure` is a custom hook used to help handle common open, close, or toggle scenarios.
 * It can be used to control feedback component such as `Modal`, `AlertDialog`, `Drawer`, etc.
 *
 * @see Docs https://chakra-ui.com/docs/hooks/use-disclosure
 * @param {UseDisclosureProps} [props={}]
 * @returns {{
 *   open: boolean,
 *   onOpen: Function,
 *   onClose: Function,
 *   onToggle: Function
 * }}
 */
export function useDisclosure(props = {}) {
  const { onClose: _onClose, onOpen: _onOpen, open: openProp } = props;

  const [openState, setopen] = useState(props.defaultOpen || false);

  const open = openProp !== undefined ? openProp : openState;

  const isControlled = openProp !== undefined;

  const onClose = useCallback(() => {
    if (!isControlled) {
      setopen(false);
    }
    if (_onClose) _onClose();
  }, [isControlled, _onClose]);

  const onOpen = useCallback(() => {
    if (!isControlled) {
      setopen(true);
    }
    if (_onOpen) _onOpen();
  }, [isControlled, _onOpen]);

  const onToggle = useCallback(() => {
    if (open) {
      onClose();
    } else {
      onOpen();
    }
  }, [open, onOpen, onClose]);

  return {
    open,
    onOpen,
    onClose,
    onToggle,
  };
}
