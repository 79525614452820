import React from 'react';
import { AnonymousLayout } from '..';

const PartnerRoutes = ({ component: Component, ...rest }) => {
  const { path } = rest;

  return <anonymouslayout exact="" path="{path}" component="{Component}"></anonymouslayout>;
};

export default PartnerRoutes;
