import React from 'react';
import {
  QuarterFilter,
  MonthFilter,
  SemesterFilter,
  WeekFilter,
  YearFilter,
} from './components';
import { Select } from '..';

const weekPeriod = { id: '1', name: 'Semaine' };
const monthPeriod = { id: '2', name: 'Mois' };
const quarterPeriod = { id: '3', name: 'Trimestre' };
const semesterPeriod = { id: '4', name: 'Semestre' };
const yearPeriod = { id: '5', name: 'Année' };

const periods = [
  weekPeriod,
  monthPeriod,
  quarterPeriod,
  semesterPeriod,
  yearPeriod,
];

const PeriodFilter = ({}) => {
  const [period, setPeriod] = React.useState('');

  return (
    <div>
      <select name="period" label="Période" value="{period}" onChange="{setPeriod}" options="{periods}" optionValueName="id" optionTextName="name"></select>
      {period == weekPeriod.id && <weekfilter></weekfilter>}
      {period == monthPeriod.id && <monthfilter></monthfilter>}
      {period == quarterPeriod.id && <quarterfilter></quarterfilter>}
      {period == semesterPeriod.id && <semesterfilter></semesterfilter>}
      {period == yearPeriod.id && <yearfilter></yearfilter>}
    </div>
  );
};

export default PeriodFilter;
