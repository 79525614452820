import React from 'react';
import { Grid } from '@material-ui/core';
import {
  Card,
  DefaultText,
  DefaultTitle,
  InfoText,
  Linkify,
  TableChip,
  RichText,
} from '../../../../../../components';
import * as Resources from '../../../../../../Resources';
import { useIntl } from 'react-intl';

const ReadonlyAdviceList = ({ advices }) => {
  const intl = useIntl();
  const renderData = () => {
    return (
      <div>
        <grid container="" spacing="{2}">
          {advices.map(advice => {
            return (
              <grid key="{advice.id}" item="" xs="{12}">
                <grid container="" spacing="{2}">
                  <grid item="">
                    <tablechip label=">"></tablechip>
                  </grid>
                  <grid item="" xs="">
                    <linkify>
                      <richtext name="instruction" initial="{JSON.parse(advice.text)}" readOnly="{true}" noTool="" onChange="{()" ==""> {}}
                        fullWidth
                        multiline
                        required
                      />
                    </richtext></linkify>
                  </grid>
                </grid>
              </grid>
            );
          })}
        </grid>
      </div>
    );
  };

  const renderEmptyState = () => {
    return (
      <infotext>
        {intl.formatMessage({
          id: 'admin.goal.indication.coaching_empty_state',
        })}
      </infotext>
    );
  };

  return (
    <div>
      <grid container="" spacing="{1}">
        <grid item="" xs="{12}">
          <defaulttitle isContrast="">
            {intl.formatMessage({ id: 'admin.goal.indication.coaching_area' })}
          </defaulttitle>
        </grid>
        <grid item="" xs="{12}">
          <card>
            {advices && advices.length > 0 && renderData()}
            {(!advices || advices.length == 0) && renderEmptyState()}
          </card>
        </grid>
      </grid>
    </div>
  );
};

export default ReadonlyAdviceList;
