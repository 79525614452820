import React from 'react';
import {
  RankEvolutionDown,
  RankEvolutionEqual,
  RankEvolutionUp,
} from './components';

const RankEvolution = ({ evolution }) => {
  if (evolution > 0) {
    return <rankevolutionup></rankevolutionup>;
  } else if (evolution < 0) {
    return <rankevolutiondown></rankevolutiondown>;
  } else {
    return <react.fragment></react.fragment>;
  }
};

export default RankEvolution;
