import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Logo } from './components';

class Maintenance extends Component {
  render() {
    const logoData = require('../../assets/logo.png');

    return (
      <div>
        <grid container="" spacing="{4}">
          <grid item="" xs="{12}">
            <logo image="{logoData}"></logo>
          </grid>
          <grid item="" xs="{12}">
            <typography align="center" style="{{" color:="" 'white',="" fontSize:="" 18,="" textTransform:="" 'uppercase',="" }}="">
              Application en maintenance
            </typography>
            <typography align="center" style="{{" color:="" 'white',="" fontSize:="" 13,="" textTransform:="" 'uppercase',="" }}="">
              Revenez ultérieurement
            </typography>
          </grid>
        </grid>
      </div>
    );
  }
}

export default Maintenance;
