import React, { useEffect, useState } from 'react';
import {
  PointCommandIds,
  RewardAllocationConfigurationCodes,
} from '@spider:src/enums';
import { useSelector } from 'react-redux';
import { useAuth } from '@src/auth';
import useRewardConfigAccess from '@spider:src/hooks/UseRewardConfigAccess';

const UseConvertPointsHelper = () => {
  const { hierarchyNodeUser } = useAuth();
  const stepperData = useSelector(state => state.getStepperDataKey);
  const simulationRewardAllocation = useSelector(
    state => state.simulateRewardsAllocationsKey,
  );
  const { isRewardAllocationExist, getConfigByCode, getConfigByCallback } =
    useRewardConfigAccess({
      reward: stepperData.commandId,
    });

  const [minimumPoints, setMinimumPoints] = useState(null);
  const [maximumPoints, setMaximumPoints] = useState(
    hierarchyNodeUser.point_balance
      ? hierarchyNodeUser.point_balance.available
      : null,
  );
  const [currentBalanceTooLow, setCurrentBalanceTooLow] = React.useState(false);

  const isDataSavable = formData => {
    if (!formData || formData.points === null) return false;

    const numberPoints = Math.floor(formData.points);
    // numberPoints === 0 ||
    if (
      formData.points === null ||
      isNaN(numberPoints) ||
      formData.points.length === 0 ||
      numberPoints > maximumPoints ||
      numberPoints < minimumPoints
    )
      return false;

    return true;
  };

  useEffect(() => {
    if (!isRewardAllocationExist || !simulationRewardAllocation.simulation)
      return;
    let minimumPointsChanged = 0;

    const ckuCallback = config =>
      stepperData.commandId.uuid === PointCommandIds.CKU &&
      !hierarchyNodeUser.cku
        ? config.code ===
          RewardAllocationConfigurationCodes.MINIMUM_CREATION_AMOUNT
        : config.code ===
          RewardAllocationConfigurationCodes.MINIMUM_LOADING_AMOUNT;

    if (getConfigByCallback(ckuCallback, true)) {
      minimumPointsChanged += getConfigByCallback(ckuCallback).value;
    }

    if (
      getConfigByCode(RewardAllocationConfigurationCodes.MINIMUM_AMOUNT, true)
    ) {
      minimumPointsChanged += getConfigByCode(
        RewardAllocationConfigurationCodes.MINIMUM_AMOUNT,
      ).value;
    }

    setMinimumPoints(minimumPointsChanged);
    setMaximumPoints(simulationRewardAllocation.simulation.max_points_usable);
  }, [
    stepperData.commandId,
    hierarchyNodeUser,
    simulationRewardAllocation.simulation,
  ]);

  useEffect(() => {
    if (!isRewardAllocationExist) return;

    if (
      !hierarchyNodeUser.point_balance ||
      hierarchyNodeUser.point_balance.available < minimumPoints
    ) {
      setCurrentBalanceTooLow(true);
      return;
    }

    setCurrentBalanceTooLow(false);
  }, [hierarchyNodeUser, minimumPoints]);

  return {
    isDataSavable,
    minimumPoints,
    maximumPoints,
    currentBalanceTooLow,
    isRewardAllocationExist,
  };
};

export default UseConvertPointsHelper;
