import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  order: null,
  success: false,
  loading: false,
  error: null,
};

const createOrderRewardSlice = name =>
  createSlice({
    name: name,
    initialState,
    reducers: {
      orderStart: state => {
        state.success = false;
        state.loading = true;
        state.error = null;
      },
      orderSuccess: (state, data) => {
        state.order = data.payload;
        state.success = true;
        state.loading = false;
        state.error = null;
      },
      orderFailure: (state, action) => {
        state.success = false;
        state.loading = false;
        state.error = action.payload;
      },
      clearOrderRewardState: state => {
        state.success = false;
        state.loading = false;
        state.error = null;
      },
    },
  });

export const orderRewardKeySlice = createOrderRewardSlice('orderRewardKey');

export const {
  orderStart: orderRewardKeyStart,
  orderSuccess: orderRewardKeySuccess,
  orderFailure: orderRewardKeyFailure,
  clearOrderRewardState: orderRewardKeyClear,
} = orderRewardKeySlice.actions;

export default {
  orderRewardKey: orderRewardKeySlice.reducer,
};
