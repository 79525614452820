import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Tab, Typography } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Button } from '../../../../components';
import ImportDialog from '../../../../components/ImportDialog/ImportDialog';
import { ImportLogsTypes } from '../../../../enums/importLogsTypes';
import { ImportLogsHistory } from '../../../../components/importLogsHistory';
import { useHistory, useParams } from 'react-router-dom';
import { TitleContainer } from '../../../../components/TitleContainer';
import { PointsList } from '../../../../components/PointsList/PointsList';
import { OwnerEnum } from '@src/Spider/enums/ownerType';

const styles = {
  pointsBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
};

const AdminPoints = ({ ...props }) => {
  const intl = useIntl();
  const history = useHistory();
  const { contract } = useParams();

  const LIST_TAB = {
    label: intl.formatMessage({ id: 'spider.points.tabs.list.title' }),
    index: '1',
    url: 'list',
  };
  const HISTORY_TAB = {
    label: intl.formatMessage({ id: 'spider.points.tabs.history.title' }),
    index: '2',
    url: 'history',
  };

  const [tabValue, setTabValue] = useState(HISTORY_TAB.index);
  const [openImport, setOpenImport] = useState(false);
  const [refreshState, setRefreshState] = useState(false);

  const handleChange = (event, tab) => {
    setTabValue(tab);
  };

  const importPoints = () => {
    setOpenImport(true);
  };

  const refresh = importLogType => {
    if (
      importLogType === ImportLogsTypes.USERS &&
      history.location.pathname.includes('points')
    ) {
      history.push(`/nodes/${contract}/users`);
    } else {
      setRefreshState(!refreshState);
    }
  };

  return (
    <div className="{props.classes.pointsBody}">
      <titlecontainer>
        <typography variant="{'h1'}" component="{'h1'}" className="{'underline-left'}">
          {intl.formatMessage({ id: 'spider.points.title' })}
        </typography>
        <button color="{'primary'}" variant="{'outlined'}" size="{'large'}" onClick="{importPoints}">
          {intl.formatMessage({ id: 'spider.points.import' })}
        </button>
      </titlecontainer>
      <tabcontext value="{tabValue}">
        <div className="{'tabs-container'}">
          <tablist onChange="{handleChange}">
            <tab label="{LIST_TAB.label}" value="{LIST_TAB.index}" disableFocusRipple=""></tab>
            <tab label="{HISTORY_TAB.label}" value="{HISTORY_TAB.index}" disableFocusRipple=""></tab>
          </tablist>
          <tabpanel value="{LIST_TAB.index}">
            <pointslist queryParams="{{" id:="" contract,="" owner_types:="" [OwnerEnum.HIERARCHY_NODE_USER],="" }}=""></pointslist>
          </tabpanel>
          <tabpanel value="{HISTORY_TAB.index}">
            <importlogshistory refreshState="{refreshState}" importLogType="{ImportLogsTypes.POINTS}" emptyLabelParams="{intl.formatMessage({" id:="" 'spider.importLog.dataTable.no_data_points',="" })}=""></importlogshistory>
          </tabpanel>
        </div>
      </tabcontext>

      <importdialog open="{openImport}" setOpen="{setOpenImport}" onFileCreated="{refresh}" defaultTab="{'1'}"></importdialog>
    </div>
  );
};

export default withStyles(styles)(AdminPoints);
