import React from 'react';
import {
  DefaultTitle,
  TeamNode,
  TeamGroup,
  Collaborator,
  IconButton as MenuIconButton,
  Card,
  Loader,
} from '../../../../../../components';
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import _ from 'lodash';

const styles = theme => {
  return {
    thumbnail: {
      borderRadius: 20,
    },
    panel: {
      backgroundColor: 'initial',
      borderRadius: 'initial',
      boxShadow: 'none',
      marginTop: 10,
      '& .MuiExpansionPanelSummary-content': {
        margin: 0,
        width: '100%',
        '&.Mui-expanded': {
          margin: '0 0 12px 0',
        },
      },
    },
    panelSummary: {
      padding: 'initial',
      position: 'relative',
      '& .MuiButtonBase-root': {
        position: 'absolute',
        right: 10,
        top: '50%',
        marginTop: -15,
      },
    },
    panelDetails: {
      padding: 'initial',
      paddingLeft: 20,
      // paddingBottom: 24,
    },
    activeColorPrimary: {
      color: theme.palette.primary.main,
    },
  };
};

const Participants = ({
  participants,
  setParticipantsEditOpen,
  classes,
  challengeTypeCode,
  ...props
}) => {
  const intl = useIntl();

  const participantsByTeam = participants
    ? participants.reduce((acc, participant) => {
        const baseParticipant = participant.team
          ? participant.team
          : participant;
        const baseParent = participant.team ? 'team.' : '';

        if (_.get(participant, `${baseParent}parent.id`)) {
          const isTeamChallenge = challengeTypeCode === 'CT';
          // Team group
          if (!acc[baseParticipant.parent.id]) {
            acc[baseParticipant.parent.id] = {
              children: isTeamChallenge ? [] : {},
              teamGroup: baseParticipant.parent,
            };
          }

          if (isTeamChallenge) {
            acc[baseParticipant.parent.id].children = [
              ...acc[baseParticipant.parent.id].children,
              participant,
            ];
            return acc;
          }

          // Team
          if (!acc[baseParticipant.parent.id].children[baseParticipant.id]) {
            acc[baseParticipant.parent.id].children[baseParticipant.id] = {
              children: [],
              team: baseParticipant,
              type: 'team',
            };
          }

          acc[baseParticipant.parent.id].children[baseParticipant.id].children =
            [
              ...acc[baseParticipant.parent.id].children[baseParticipant.id]
                .children,
              participant,
            ];

          return acc;
          // participants have no team group
        } else if (_.get(participant, `${baseParent}id`)) {
          if (!acc[baseParticipant.id]) {
            acc[baseParticipant.id] = {
              children: [],
              team: baseParticipant,
              type: 'team',
            };
          }
          acc[baseParticipant.id].children = [
            ...acc[baseParticipant.id].children,
            participant,
          ];

          return acc;
        }
        return acc;
      }, {})
    : {};

  const generateParticipantsByParent = (participantsByParent, root) => {
    return Object.keys(participantsByParent).map(parentKey => {
      const nestedTeams =
        participantsByParent[parentKey].children &&
        !_.isArray(participantsByParent[parentKey].children)
          ? Object.keys(participantsByParent[parentKey].children).map(
              key => participantsByParent[parentKey].children[key],
            )
          : participantsByParent[parentKey].children;
      return (
        <grid 6="" item="" xs="{12}" sm="{root" ?="" :="" 12}="" key="{parentKey}">
          <expansionpanel className="{classes.panel}">
            {challengeTypeCode !== 'TP' && (
              <expansionpanelsummary expandIcon="{" challengeTypeCode="==" 'TP'="" ?="" null="" :="" <ExpandMoreIcon=""></expansionpanelsummary>
                }
                className={classes.panelSummary}
              >
                <grid container="">
                  <grid item="" xs="{12}">
                    <card className="{classes.thumbnail}">
                      {participantsByParent[parentKey].type === 'team' ? (
                        <teamnode team="{Object.assign(" {},="" participantsByParent[parentKey].team,="" {="" collaborators:="" participantsByParent[parentKey].children,="" },="" )}="" hideAvatars=""></teamnode>
                      ) : (
                        <teamgroup team="{Object.assign(" {},="" participantsByParent[parentKey].teamGroup,="" {="" teams:="" nestedTeams="" },="" )}="" hideTeamGroupUsers="" teamNumber=""></teamgroup>
                      )}
                    </card>
                  </grid>
                </grid>
              
            )}

            <expansionpaneldetails className="{classes.panelDetails}">
              {_.isArray(participantsByParent[parentKey].children) ? (
                <grid container="" spacing="{2}">
                  {participantsByParent[parentKey].children.map(child => {
                    const childKey = `C${child.id}`;
                    return (
                      <grid item="" xs="{12}" key="{childKey}">
                        {child.fullname ? (
                          <collaborator key="{childKey}" collaborator="{child}"></collaborator>
                        ) : (
                          <card className="{classes.thumbnail}">
                            <teamnode team="{Object.assign({}," child,="" {="" collaborators:="" child.collaborator_ids,="" })}="" hideAvatars=""></teamnode>
                          </card>
                        )}
                      </grid>
                    );
                  })}
                </grid>
              ) : (
                <react.fragment>
                  {participantsByParent[parentKey].children && (
                    <grid container="" spacing="{2}">
                      <grid item="" xs="{12}">
                        {generateParticipantsByParent(
                          participantsByParent[parentKey].children,
                        )}
                      </grid>
                    </grid>
                  )}
                </react.fragment>
              )}
            </expansionpaneldetails>
          </expansionpanel>
        </grid>
      );
    });
  };
  return (
    <div>
      <grid container="" spacing="{1}">
        <grid item="" xs="{12}">
          <grid container="" spacing="{2}">
            <grid item="">
              <defaulttitle isContrast="">
                {intl.formatMessage({
                  id: 'challenge.form.steps.participants',
                })}
              </defaulttitle>
            </grid>
            <grid item="">
              <defaulttitle>
                <menuiconbutton size="{'small'}" onClick="{setParticipantsEditOpen}" style="{{" marginTop:="" '-4px',="" fontSize:="" '18px',="" }}="">
                  <fontawesomeicon icon="{faEdit}" className="{classes.activeColorPrimary}"></fontawesomeicon>
                </menuiconbutton>
              </defaulttitle>
            </grid>
          </grid>
        </grid>
        <grid item="" xs="{12}">
          <grid container="" spacing="{2}">
            {!participants && (
              <grid item="" xs="{12}">
                <loader centered=""></loader>
              </grid>
            )}
            {participants && (
              <react.fragment>
                {challengeTypeCode === 'TG'
                  ? participants.map((participant, index) => (
                      <grid item="" xs="{12}" sm="{6}" key="{index}">
                        <card className="{classes.thumbnail}">
                          <teamgroup team="{Object.assign({}," participant,="" {="" teams:="" participant.teamGroups="" &&="" participant.teamGroups.length=""> 0
                                  ? participant.teamGroups
                                  : participant.allTeamIds,
                            })}
                            teamNumberWording={
                              participant.teamGroups &&
                              participant.teamGroups.length > 0
                                ? 'team_groups'
                                : 'teams'
                            }
                            hideTeamGroupUsers
                            teamNumber
                          />
                        </teamgroup></card>
                      </grid>
                    ))
                  : generateParticipantsByParent(participantsByTeam, true)}
              </react.fragment>
            )}
          </grid>
        </grid>
      </grid>
    </div>
  );
};

export default withStyles(styles)(Participants);
