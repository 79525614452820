import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    textDecoration: 'none',
  },
};

export default withStyles(styles)(Grid);
