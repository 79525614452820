import { all } from 'redux-saga/effects';

import { default as authSaga } from './auth/sagas';
import { default as baseSaga } from './base/sagas';
import { default as configSaga } from './config/sagas';
import { default as teamGroupSaga } from './teamGroup/sagas';
import { default as systemImageSaga } from './systemFiles/sagas';
import { default as settingsSaga } from './settings/sagas';
import { default as mainSaga } from './main/sagas';
import { default as importLogsSaga } from './importLogs/sagas';
import { default as contractDetailsSaga } from './contractDetails/sagas';
import { default as rewardsSaga } from './rewards/sagas';
import { default as rewardAllocationsSaga } from './rewardAllocations/sagas';
import { default as listsSagas } from './lists/sagas';

export default function* spiderSaga() {
  yield all([
    authSaga(),
    baseSaga(),
    configSaga(),
    teamGroupSaga(),
    systemImageSaga(),
    settingsSaga(),
    mainSaga(),
    importLogsSaga(),
    contractDetailsSaga(),
    rewardsSaga(),
    rewardAllocationsSaga(),
    listsSagas(),
  ]);
}
