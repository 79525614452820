import { withStyles } from '@mui/styles';
import { DecorationCard } from '../../scenes/Home/components/UserHome/components/DecorationCard';
import React from 'react';
import { useIntl } from 'react-intl';
import CommandCard from '../../assets/img/homeParticipantsConvert/ksc-card.png';
import { Link, Typography } from '@material-ui/core';

const styles = {
  sphere: {},
  design: {},
  rootHelper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1.5rem',
  },
};

const useKSC = () => {
  const intl = useIntl();
  // component card
  // form field to show per step

  const CommandCardComponent = () => (
    <img 2="" src="{CommandCard}" alt="empty" style="{{" zIndex:="" }}="">
  );

  const ComponentDecoration = ({ ...props }) => (
    <decorationcard title="{intl.formatMessage({" id:="" 'spider.convertPoints.home.ksc.title',="" })}="" classes="{props.classes}"></decorationcard>
  );

  const HelperAbout = ({ ...props }) => (
    <div className="{props.classes.rootHelper}">
      <div>
        <typography variant="{'h3'}" component="{'h3'}">
          {"Un catalogue d'offres négociées pour combler vos attentes à 100% !"}
        </typography>

        <div>
          <typography variant="{'body1'}" style="{{" fontWeight:="" 'bold'="" }}="">
            {'Pour en profiter, c’est simple :'}
          </typography>

          <div>Stepper</div>

          <typography variant="{'body1'}">{'Et  voilà !'}</typography>
          <typography variant="{'body2'}" style="{{" fontStyle:="" 'italic'="" }}="">
            {
              "Pour rappel, votre solde est valable pendant un an à partir de l'activation de votre compte."
            }
          </typography>

          <link className="{'bold" large'}="" onClick="{()" ==""> {}}>
            {'Tout savoir sur Kadéos Connect'}
          
        </div>
      </div>
      <div style="{{" background:="" 'linear-gradient(135deg,="" #FAAF0C="" 0%,="" #FFDC60="" 100%)',="" opacity:="" 0.7,="" }}="">
        <commandcardcomponent></commandcardcomponent>
      </div>
    </div>
  );

  const getInformationStepDefaultFormData = () => {
    return {
      phone: '',
    };
  };

  return {
    ComponentDecoration: withStyles(styles)(ComponentDecoration),
    cardDetails: null,
    Card: CommandCardComponent,
    HelperAbout: withStyles(styles)(HelperAbout),
    informationStep: {
      defaultForm: getInformationStepDefaultFormData(),
      showAndValidateBirthDate: false,
      alert: {
        PP: null,
        PM: null,
      },
      address: {
        PP: false,
        PM: false,
      },
      addressTitle: {
        PP: 'spider.convertPoints.stepper.informationStep.personalAddress.titleDelivery',
        PM: 'spider.convertPoints.stepper.informationStep.personalAddress.titleDelivery',
      },
    },
  };
};

export default useKSC;
