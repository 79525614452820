import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import { getPointsTransactionsListKeySlice } from './slices';

function* fetchPointsTransactions(slice, endpoint, action) {
  try {
    const { id, page, page_size, owner_types } = action.payload;
    const data = yield call(endpoint, id, page, page_size, owner_types);
    yield put(slice.actions.fetchSuccess(data));
  } catch (error) {
    yield put(
      slice.actions.fetchFailure(error?.response?.data?.error ?? 'UNKNOWN'),
    );
  }
}

function* watchPointsTransactions(slice, endpoint) {
  yield takeLatest(
    slice.actions.fetchStart.type,
    fetchPointsTransactions,
    slice,
    endpoint,
  );
}

export default function* crudPointsTransactionsSaga() {
  yield all([
    watchPointsTransactions(
      getPointsTransactionsListKeySlice,
      api.pointsTransactions.list,
    ),
  ]);
}

export { crudPointsTransactionsSaga as getPointsTransactionsSaga };
