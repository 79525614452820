import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import { getContractDetailsKeySlice } from './slices';

function* fetchContractDetails(slice, endpoint, action) {
  try {
    const { teamGroupId } = action.payload;
    const data = yield call(endpoint, teamGroupId);
    yield put(slice.actions.fetchSuccess(data));
  } catch (error) {
    yield put(
      slice.actions.fetchFailure(error?.response?.data?.error ?? 'UNKNOWN'),
    );
  }
}

function* watchContractDetails(slice, endpoint) {
  yield takeLatest(
    slice.actions.fetchStart.type,
    fetchContractDetails,
    slice,
    endpoint,
  );
}

export default function* contractDetailsSaga() {
  yield all([
    watchContractDetails(getContractDetailsKeySlice, api.contractDetails.get),
  ]);
}
