import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  IconButton,
  CardMedia,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faPlus,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  BlueText,
  Card,
  DefaultText,
  DefaultTitle,
  HiddenInput,
  Select,
  Switch,
  TextField,
  Tooltip,
  IconButton as MenuIconButton,
  GreenRadio,
} from '../../../../../../components';
import { ChallengeReward, ChallengeRewardCard } from '../../../';
import { useIntl } from 'react-intl';
import * as Resources from '../../../../../../Resources';
import * as challengeTypeUsablePointsActions from '../../../../../../services/ChallengeTypes/ChallengeTypeUsablePoints/actions';
import { uuidv4 } from '../../../../../../helpers/UUIDHelper';
import './helpers/FormsyHelper';
import _ from 'lodash';

const styles = {
  pointsField: {
    '& .MuiFormLabel-root': {
      marginLeft: 25,
    },
  },
};

const Awards = ({
  challengeId,
  challengeTypeCode,
  challengeTypeId,
  end,
  hasChallengeManager,
  initialAwards = [],
  initialLive = false,
  initialType,
  initialRewardType,
  isCreation,
  isDuplication,
  isUpdate,
  start,
  team,
  types,
  rewardTypes,
  setConfigRewardOpen,
  rewardImages,
  rewardCategories,
  classes,
  ...props
}) => {
  const intl = useIntl();
  const getInitialAwards = () => {
    if (initialAwards && initialAwards.length > 0) {
      const awardType = types.find(t => t.id === parseInt(initialType));
      return initialAwards
        .filter((award, index) => (awardType.code === 'M' ? index === 0 : true))
        .map(x => ({
          key: uuidv4(),
          points: x.points,
          reward: x.reward,
          target: x.target,
        }));
    } else {
      return [{ key: uuidv4(), points: null, reward: null, target: null }];
    }
  };

  const { points, loading } = props.challengeTypeUsablePoints;
  const maxAwardType = types.find(t => t.code === 'M').id;
  const stepAwardType = types.find(t => t.code === 'P').id;
  const finalInitialType = initialType ? initialType : maxAwardType;
  const finalInitialRewardType = initialRewardType
    ? initialRewardType
    : rewardTypes[0].id;
  const [awards, setAwards] = React.useState(getInitialAwards);
  const [type, setType] = React.useState(finalInitialType);
  // const [type, setType] = React.useState(1)
  const [rewardType, setRewardType] = React.useState(finalInitialRewardType);
  const isMaxAward = parseInt(type) === maxAwardType;
  const isStepAward = parseInt(type) === stepAwardType;

  const currentType = types.find(t => parseInt(type) === t.id);
  const currentRewardType = rewardTypes.find(
    t => parseInt(rewardType) === t.id,
  );
  const usablePoints = points
    ? !isMaxAward
      ? points.all
      : points.participant
    : 0;
  // console.log(_.slice(awards, 1));
  const icons = {
    R: require(
      `../../../../../../assets/img/system/challenge/icons/Ribbons.png`,
    ),
    M: require(
      `../../../../../../assets/img/system/challenge/icons/Rocket.png`,
    ),
    P: require(
      `../../../../../../assets/img/system/challenge/icons/Levels.png`,
    ),
  };

  const coinImage = require(
    `../../../../../../assets/img/system/challenge/icons/coin.png`,
  );
  const giftImage = require(
    `../../../../../../assets/img/system/challenge/icons/gift.png`,
  );
  const pointRewardImage = require(
    `../../../../../../assets/img/system/challenge/icons/points.png`,
  );

  useEffect(() => {
    if ((isCreation || isDuplication) && challengeTypeId && end && start) {
      const teamFilter =
        hasChallengeManager && challengeTypeCode === 'CM' ? team : null;
      props.challengeTypeUsablePointsActions.getChallengeTypeUsablePoints(
        challengeTypeId,
        start,
        end,
        teamFilter,
      );
    } else if (isUpdate) {
      if (!start || !end) {
        props.challengeTypeUsablePointsActions.getChallengeTypeUsablePointsByChallenge(
          challengeId,
        );
      } else {
        props.challengeTypeUsablePointsActions.getChallengeTypeUsablePointsByChallenge(
          challengeId,
          start,
          end,
        );
      }
    }
  }, [challengeTypeCode, challengeTypeId, end, start]);

  useEffect(() => {
    setConfigRewardOpen(false, awards);
  }, [awards]);

  function handleAddAwardClick() {
    setAwards(awards => [...awards, { key: uuidv4(), points: null }]);
  }

  function handleRemoveAwardClick(key) {
    setAwards(x => x.filter(y => y.key != key));
  }

  function handleTypeChange(newType) {
    setType(Number(newType));
  }
  function handleRewardTypeChange(event) {
    setRewardType(Number(event.target.value));
  }

  return (
    <div>
      <grid container="" spacing="{1}">
        <grid item="" xs="{12}">
          <grid container="" spacing="{1}">
            <grid item="">
              <defaulttitle isContrast="">
                {intl.formatMessage({ id: 'reward.title' })}
              </defaulttitle>
            </grid>
            {!isMaxAward && (
              <grid item="">
                <defaulttitle>
                  <menuiconbutton size="{'small'}" onClick="{handleAddAwardClick}" style="{{" marginTop:="" '-4px',="" color:="" '#00E58D',="" fontSize:="" '18px',="" }}="">
                    <fontawesomeicon icon="{faPlus}"></fontawesomeicon>
                  </menuiconbutton>
                </defaulttitle>
              </grid>
            )}
          </grid>
        </grid>
        <grid item="" xs="{12}">
          <grid container="" spacing="{4}" direction="column">
            <grid item="" xs="{12}">
              <radiogroup row="" name="rewardType" onChange="{handleRewardTypeChange}" value="{rewardType}">
                {rewardTypes.map(rewardType => (
                  <formcontrollabel value="{rewardType.id}" control="{<GreenRadio"></formcontrollabel>}
                    label={
                      <grid container="" spacing="{1}">
                        <grid item="">
                          <cardmedia 20="" image="{" rewardType.code="==" 'G'="" ?="" giftImage="" :="" coinImage="" }="" style="{{" height:="" 20,="" width:="" }}=""></cardmedia>
                        </grid>
                        <grid item="">
                          <defaulttext isContrast="" lowercase="">
                            {intl.formatMessage({
                              id: `challenge.reward_types.${rewardType.code}`,
                            })}
                          </defaulttext>
                        </grid>
                      </grid>
                    }
                  />
                ))}
              </radiogroup>

              <hiddeninput name="rewardType" value="{rewardType}"></hiddeninput>
            </grid>
            <grid item="">
              <grid container="" spacing="{2}">
                {awards.map((award, index) => {
                  const number = index + 1;
                  const labels = {
                    M:
                      challengeTypeCode === 'CT' || challengeTypeCode === 'TP'
                        ? intl.formatMessage({
                            id: 'challenge.award_list.team_max_point_label',
                          })
                        : intl.formatMessage({
                            id: 'challenge.award_list.collaborator_max_point_label',
                          }),
                    R:
                      challengeTypeCode === 'CT' || challengeTypeCode === 'TP'
                        ? intl
                            .formatMessage({
                              id: 'challenge.award_list.team_point_label',
                            })
                            .format(number)
                        : intl
                            .formatMessage({
                              id: 'challenge.award_list.collaborator_point_label',
                            })
                            .format(number),
                    C:
                      challengeTypeCode === 'CT' || challengeTypeCode === 'TP'
                        ? intl
                            .formatMessage({
                              id: 'challenge.award_list.team_point_label',
                            })
                            .format(number)
                        : intl
                            .formatMessage({
                              id: 'challenge.award_list.collaborator_point_label',
                            })
                            .format(number),
                    P: intl
                      .formatMessage({
                        id: 'challenge.award_list.step_point_label',
                      })
                      .format(number),
                  };
                  const label = labels[currentType.code];
                  const validations =
                    isMaxAward || currentType.code === 'P'
                      ? { isRankingDecreasing: true, isInt: true }
                      : { isRankingIncreasing: true, isInt: true };

                  const validationErrors =
                    isMaxAward || currentType.code === 'P'
                      ? {
                          isInt: intl.formatMessage({
                            id: 'common.form.is_int_error',
                          }),
                          isDefaultRequiredValue: intl.formatMessage({
                            id: 'common.form.required_error',
                          }),
                          isRankingDecreasing:
                            'Les récompenses doivent être croissantes',
                        }
                      : {
                          isInt: intl.formatMessage({
                            id: 'common.form.is_int_error',
                          }),
                          isDefaultRequiredValue: intl.formatMessage({
                            id: 'common.form.required_error',
                          }),
                          isRankingIncreasing:
                            'Les récompenses doivent être décroissantes',
                        };
                  const reward = award.reward
                    ? Object.assign({}, award.reward, {
                        image:
                          _.get(award, 'reward.image.path') ||
                          (award.reward.image && rewardImages
                            ? rewardImages.find(
                                i => i.id === parseInt(award.reward.image),
                              ).path
                            : null),
                        category: _.get(award, 'reward.category.id')
                          ? _.get(award, 'reward.category')
                          : rewardCategories &&
                            award.reward.category &&
                            rewardCategories.find(
                              c => c.id === parseInt(award.reward.category),
                            ),
                      })
                    : null;
                  return (
                    <grid key="{award.key}" item="" xs="{12}" sm="{6}" md="{4}">
                      <grid container="" spacing="{1}" direction="column">
                        <grid item="" xs="{12}">
                          <challengerewardcard>
                            <grid container="" spacing="{1}" alignItems="flex-end">
                              <grid item="" xs="{12}">
                                <grid container="" direction="column" spacing="{2}">
                                  <grid item="">
                                    <grid container="" justify="space-between">
                                      <grid item="">
                                        <defaulttitle>{label}</defaulttitle>
                                      </grid>

                                      {awards.length > 1 && (
                                        <grid item="">
                                          <iconbutton size="small" onClick="{()" =="">
                                              handleRemoveAwardClick(award.key)
                                            }
                                          >
                                            <fontawesomeicon icon="{faTrashAlt}"></fontawesomeicon>
                                          </iconbutton>
                                        </grid>
                                      )}
                                    </grid>
                                  </grid>
                                  {currentRewardType.code === 'G' && (
                                    <react.fragment>
                                      {currentType.code === 'P' && (
                                        <grid item="" xs="{8}">
                                          <textfield lowercase="" name="{`awardTarget[${index}]`}" label="{intl.formatMessage({" id:="" 'challenge.award_list.target_label',="" })}="" fullWidth="" required="" initial="{award.target}" validations="{{" isStepsIncreasing:="" true,="" isInt:="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" 'common.form.is_int_error',="" 'Les="" scores="" à="" atteindre="" doivent="" être="" croissants',=""></textfield>
                                        </grid>
                                      )}
                                      <grid item="" xs="{12}" style="{{" cursor:="" 'pointer'="" }}="" onClick="{()" =="">
                                          setConfigRewardOpen(
                                            true,
                                            awards,
                                            award,
                                            index,
                                            setAwards,
                                          )
                                        }
                                      >
                                        {award.reward && (
                                          <challengereward reward="{reward}"></challengereward>
                                        )}
                                        {!award.reward && (
                                          <card>
                                            <defaulttext style="{{" textAlign:="" 'center',="" color:="" '#00E58D',="" }}="" lowercase="">
                                              {intl.formatMessage({
                                                id: 'challenge.form.add_reward',
                                              })}
                                            </defaulttext>
                                          </card>
                                        )}
                                      </grid>
                                    </react.fragment>
                                  )}
                                  {currentRewardType.code === 'P' && (
                                    <react.fragment>
                                      {currentType.code === 'P' && (
                                        <grid item="" xs="{8}">
                                          <textfield lowercase="" name="{`awardTarget[${index}]`}" label="{intl.formatMessage({" id:="" 'challenge.award_list.target_label',="" })}="" fullWidth="" required="" initial="{award.target}" validations="{{" isStepsIncreasing:="" true,="" isInt:="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" 'common.form.is_int_error',="" 'Les="" scores="" à="" atteindre="" doivent="" être="" croissants',=""></textfield>
                                        </grid>
                                      )}
                                      <grid item="" xs="{12}">
                                        <cardmedia image="{pointRewardImage}" style="{{" height:="" 100,="" width:="" margin:="" 'auto',="" }}=""></cardmedia>
                                      </grid>
                                      <grid item="" xs="{12}" className="{classes.pointsField}" style="{{" position:="" 'relative'="" }}="">
                                        <cardmedia image="{coinImage}" style="{{" height:="" 20,="" width:="" position:="" 'absolute',="" left:="" 5,="" top:="" 3,="" }}=""></cardmedia>

                                        <textfield lowercase="" name="{`award[${index}]`}" label="Points" fullWidth="" required="" initial="{award.points}" validations="{validations}" validationErrors="{validationErrors}"></textfield>
                                      </grid>
                                    </react.fragment>
                                  )}
                                </grid>
                              </grid>
                            </grid>
                          </challengerewardcard>
                        </grid>
                      </grid>
                    </grid>
                  );
                })}
                {(isMaxAward || isStepAward) &&
                  currentRewardType.code !== 'G' && (
                    <grid item="" xs="">
                      <grid container="" alignItems="center">
                        <grid item="">
                          <switch name="live" label="{intl.formatMessage({" id:="" 'challenge.award_list.list_live_label',="" })}="" initial="{initialLive}"></switch>
                        </grid>
                        <grid item="">
                          <tooltip title="{intl.formatMessage({" id:="" 'challenge.award_list.list_live_infos',="" })}="">
                            <bluetext>
                              <fontawesomeicon icon="{faInfoCircle}"></fontawesomeicon>
                            </bluetext>
                          </tooltip>
                        </grid>
                      </grid>
                    </grid>
                  )}
              </grid>
            </grid>
          </grid>
        </grid>
      </grid>
    </div>
  );
};

const mapStateToProps = ({ challengeTypeUsablePoints }) => ({
  challengeTypeUsablePoints,
});

const mapDispatchToProps = dispatch => ({
  challengeTypeUsablePointsActions: bindActionCreators(
    challengeTypeUsablePointsActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Awards));
