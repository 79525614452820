import React, { useState } from 'react';
import { Grid, CardMedia } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useIntl, injectIntl } from 'react-intl';
import {
  DefaultText,
  BoldTitle,
  TeamSelector,
  AdministratorCollaboratorSelector,
  AppBarSubTitle,
  MainLayoutComponent,
} from '../../../../components';
import { Redirect } from 'react-router-dom';
import { CollaboratorSelect } from './components';

const styles = {
  icon: {
    width: 150,
    height: 150,
  },
  link: {
    cursor: 'pointer',
  },
};

const AdminGoalPointModeSelect = ({ onChange, classes, history, ...props }) => {
  const intl = useIntl();
  const global_icon = require(
    `../../../../assets/img/system/goalPoints/global.svg`,
  );
  const team_icon = require(
    `../../../../assets/img/system/goalPoints/team.svg`,
  );
  const individual_icon = require(
    `../../../../assets/img/system/goalPoints/teamwork-1.svg`,
  );

  const [mode, setMode] = useState();
  const [team, setTeam] = useState();
  const [collaborator, setCollaborator] = useState();

  if (mode === 'global') {
    history.push(
      `/admin/periods/${props.match.params.periodId}/goal-levels?type=C`,
    );
  }
  if (team && !collaborator && mode === 'team') {
    history.push(
      `/admin/periods/${props.match.params.periodId}/goal-levels?type=C&team=${team}`,
    );
  }
  if (collaborator && team && mode === 'individual') {
    history.push(
      `/admin/periods/${props.match.params.periodId}/goal-levels?type=C&collaborator=${collaborator}&team=${team}`,
    );
  }

  return (
    <react.fragment>
      {!mode && (
        <div style="{{" marginTop:="" '48px'="" }}="">
          <grid container="" direction="row" justify="center" spacing="{8}">
            <grid item="" onClick="{()" ==""> setMode('global')}
              className={classes.link}
            >
              <grid container="" spacing="{2}" direction="column" alignItems="center">
                <grid item="">
                  <cardmedia image="{global_icon}" className="{classes.icon}"></cardmedia>
                </grid>
                <grid item="" xs="">
                  <boldtitle isContrast="">
                    {intl.formatMessage({ id: 'admin.points.menu_global' })}
                  </boldtitle>
                </grid>
              </grid>
            </grid>
            <grid item="" onClick="{()" ==""> setMode('team')} className={classes.link}>
              <grid container="" spacing="{2}" direction="column" alignItems="center">
                <grid item="">
                  <cardmedia image="{team_icon}" className="{classes.icon}"></cardmedia>
                </grid>
                <grid item="" xs="">
                  <boldtitle isContrast="">
                    {intl.formatMessage({ id: 'admin.points.menu_team' })}
                  </boldtitle>
                </grid>
              </grid>
            </grid>
            <grid item="" onClick="{()" ==""> setMode('individual')}
              className={classes.link}
            >
              <grid container="" spacing="{2}" direction="column" alignItems="center">
                <grid item="">
                  <cardmedia image="{individual_icon}" className="{classes.icon}"></cardmedia>
                </grid>
                <grid item="" xs="">
                  <boldtitle isContrast="">
                    {intl.formatMessage({ id: 'admin.points.menu_individual' })}
                  </boldtitle>
                </grid>
              </grid>
            </grid>
          </grid>
        </div>
      )}

      {mode === 'team' && (
        <teamselector onClick="{team" ==""> {
            setTeam(team);
          }}
        />
      )}
      {mode === 'individual' && !team && (
        <administratorcollaboratorselector disableRedirect="" onClick="{team" ==""> {
            setTeam(team);
          }}
        />
      )}
      {mode === 'individual' && team && (
        <collaboratorselect team="{team}" onClick="{collaborator" ==""> {
            setCollaborator(collaborator.id);
          }}
        />
      )}
    </collaboratorselect></administratorcollaboratorselector></teamselector></react.fragment>
  );
};

class AdminGoalPointModeSelectWrapper extends MainLayoutComponent {
  componentDidMount() {
    const { intl } = this.props;
    this.props.activateReturn();
    this.props.handleTitle(intl.formatMessage({ id: 'admin.title' }));
    this.props.handleSubHeader(
      <appbarsubtitle title="{intl.formatMessage({" id:="" 'admin.points.title'="" })}=""></appbarsubtitle>,
    );
  }
  render() {
    const MainComponent = withRouter(
      withStyles(styles)(AdminGoalPointModeSelect),
    );
    return (
      <div>
        <maincomponent {...this.props}=""></maincomponent>
      </div>
    );
  }
}

export default injectIntl(AdminGoalPointModeSelectWrapper);
