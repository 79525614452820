import { useAuth } from '../../auth';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Cup,
  Home2,
  InfoCircle,
  Ranking,
  Setting2,
  UserOctagon,
  ShoppingCart,
} from 'iconsax-react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { UserRoles } from '../enums';
import useConvertPointsSteps from './UseConvertPointsSteps';

/**
 * Returns the authorized routes for the current user based on their role.
 *
 * @return {{menus: {text: string, icon: JSX.Element, src: string}[]}} An array of objects representing the authorized routes (menus) for the current user.
 */
const UseAuthorizedRoutes = () => {
  const { hierarchyNodeUser } = useAuth();
  const { contract, tab } = useParams();
  const intl = useIntl();

  const { sources: convertPointsSources } = useConvertPointsSteps();

  const adminMenuItems = useMemo(
    () => [
      {
        text: intl.formatMessage({ id: 'spider.sidebar.admin.home' }),
        icon: <home2></home2>,
        src: `/nodes/${contract}/home`,
      },
      {
        text: intl.formatMessage({ id: 'spider.sidebar.admin.participants' }),
        icon: <useroctagon></useroctagon>,
        src: `/nodes/${contract}/users`,
      },
      {
        text: intl.formatMessage({ id: 'spider.sidebar.admin.points' }),
        icon: <cup></cup>,
        src: `/nodes/${contract}/points`,
      },
      {
        text: intl.formatMessage({ id: 'spider.sidebar.admin.administration' }),
        icon: <setting2></setting2>,
        src: `/nodes/${contract}/administration/${tab ? tab : 'regulations'}`,
      },
    ],
    [contract, intl, tab],
  );

  const userMenuItems = useMemo(
    () => [
      {
        text: intl.formatMessage({ id: 'spider.sidebar.user.home' }),
        icon: <home2></home2>,
        src: `/nodes/${contract}/home`,
        sources: [...convertPointsSources],
      },
      {
        text: intl.formatMessage({ id: 'spider.sidebar.user.points' }),
        icon: <cup></cup>,
        src: `/nodes/${contract}/points`,
      },
      {
        text: intl.formatMessage({ id: 'spider.sidebar.user.orders' }),
        icon: <shoppingcart></shoppingcart>,
        src: `/nodes/${contract}/orders`,
      },
      {
        text: intl.formatMessage({ id: 'spider.sidebar.user.challenges' }),
        icon: <ranking></ranking>,
        src: `/nodes/${contract}/challenges`,
      },
      {
        text: intl.formatMessage({ id: 'spider.sidebar.user.help' }),
        icon: <infocircle></infocircle>,
        src: `/nodes/${contract}/help`,
      },
    ],
    [contract, intl],
  );

  const [menus, setMenus] = useState([]);

  useEffect(() => {
    if (hierarchyNodeUser.role === UserRoles.COLLABORATOR) {
      setMenus(userMenuItems);
    } else if (hierarchyNodeUser.role === UserRoles.ADMINISTRATOR) {
      setMenus(adminMenuItems);
    }
  }, [hierarchyNodeUser, contract, tab, adminMenuItems, userMenuItems]);

  return { menus };
};

export default UseAuthorizedRoutes;
