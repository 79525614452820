import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import { Grid, RadioGroup, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  BlueText,
  Button,
  Card,
  DefaultText,
  DefaultTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InfoText,
  Loader,
  ProgressButton,
  Select,
  Switch,
  TextField,
  Tooltip,
  NavigationSwitch,
  HiddenInput,
  GreenRadio,
  RichTextField,
} from '../../../../../../components';
import * as Resources from '../../../../../../Resources';
import { useIntl, injectIntl } from 'react-intl';
import * as categoryListActions from '../../../../../../services/Categories/CategoryList/actions';
import * as goalTypeListActions from '../../../../../../services/GoalTypes/GoalTypeList/actions';
import * as kpiListActions from '../../../../../../services/Kpis/KpiList/actions';
import * as kpiCreationActions from '../../../../../../services/Kpis/KpiCreation/actions';
import * as periodicityListActions from '../../../../../../services/Periodicities/PeriodicityList/actions';
import * as goalDefinitionUpdateActions from '../../../../../../services/GoalDefinitions/GoalDefinitionUpdate/actions';
import * as goalDefinitionActivationUpdateActions from '../../../../../../services/GoalDefinitions/GoalDefinitionActivationUpdate/actions';
import * as goalDefinitionRepartitionListActions from '../../../../../../services/GoalDefinitionRepartitions/GoalDefinitionRepartitionList/actions';
import * as unitListActions from '../../../../../../services/Units/UnitList/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faChevronDown,
  faChevronUp,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { toast } from 'react-toastify';

const styles = {
  indications: {
    '& .MuiInputBase-root': {
      display: 'none',
    },
  },
  link: {
    fontSize: 18,
    cursor: 'pointer',
    '&:hover, &.active': {
      color: 'rgb(15,111,222)',
      opacity: 1,
    },
  },
  kpiDialog: {
    width: 900,
  },
};

class Base extends Component {
  state = {
    kpi: null,
    open: false,
    showIndicationTools: false,
    newKpiOpen: false,
    submitConfirmOpen: false,
    indication: null,
    kpiCategory: null,
    kpiName: null,
    kpiUnit: null,
    kpiCustom: null,
    kpiCollaboratorEditable: null,
    model: null,
  };

  constructor(props) {
    super(props);
    this.props.goalDefinitionActivationUpdateActions.clearGoalDefinitionActivationUpdate();
  }
  componentDidMount() {
    this.props.categoryListActions.getActiveCategoryList();
    this.props.goalTypeListActions.getGoalTypeList();
    this.props.kpiListActions.getKpiList();
    this.props.periodicityListActions.getPeriodicityList();
    this.props.unitListActions.getUnitList();
    this.props.goalDefinitionRepartitionListActions.getGoalDefinitionRepartitionList();
  }

  onDisable() {
    this.props.goalDefinitionActivationUpdateActions.updateGoalDefinitionActivation(
      this.props.id,
      false,
    );
  }

  setOpen(open) {
    const { loading } = this.props.goalDefinitionActivationUpdate;
    if (!loading) {
      this.setState({
        ...this.state,
        open: open,
      });
    }
  }

  setKpiCategory = category => {
    this.setState({
      ...this.state,
      kpiCategory: category,
    });
  };

  setKpiUnit = unit => {
    this.setState({
      ...this.state,
      kpiUnit: unit,
    });
  };

  setKpiCustom = custom => {
    this.setState({
      ...this.state,
      kpiCustom: custom,
    });
  };

  setKpiCollaboratorEditable = editable => {
    this.setState({
      ...this.state,
      kpiCollaboratorEditable: editable,
    });
  };

  handleIndicationChange = newIndication => {
    this.setState({
      ...this.state,
      indication: newIndication,
    });
  };

  handleSubmit(model) {
    const { definition } = this.props.goalDefinitionDetail;

    const isCustom =
      this.state.kpiCustom === null
        ? definition.kpi.custom
        : this.state.kpiCustom;

    if (
      (parseInt(definition.kpi.id) === parseInt(model.kpi) || isCustom) &&
      definition.kpi.custom === isCustom
    ) {
      this.setState(
        {
          ...this.state,
          model,
        },
        this.performSubmit,
      );
    } else {
      this.setState({
        ...this.state,
        model,
        submitConfirmOpen: true,
      });
    }
  }

  performSubmit = () => {
    const { model } = this.state;
    const { definition } = this.props.goalDefinitionDetail;
    if (!model.editable) model.editable = false;
    model.period = this.props.period;

    const existingCustomKpi = definition.kpi.custom ? definition.kpi : {};
    const isCustom =
      this.state.kpiCustom === null
        ? definition.kpi.custom
        : this.state.kpiCustom;
    const customKpi = isCustom
      ? {
          customKpi: {
            id: existingCustomKpi.id,
            name: model.kpiName,
            unit: model.kpiUnit,
            collaboratorEditable:
              this.state.kpiCollaboratorEditable === null
                ? _.get(existingCustomKpi, 'collaborator_editable', false)
                : _.get(this.state, 'kpiCollaboratorEditable', false),
          },
        }
      : {};

    this.props.goalDefinitionUpdateActions.updateGoalDefinition(
      this.props.id,
      Object.assign(
        model,
        {
          indication: this.state.indication
            ? JSON.stringify(this.state.indication)
            : definition.indication,
        },
        customKpi,
      ),
    );
  };

  handleConfirmClick = () => {
    this.setState(
      {
        ...this.state,
        submitConfirmOpen: false,
      },
      this.performSubmit,
    );
  };

  renderLoader() {
    return <loader centered=""></loader>;
  }

  handleSubmitKpi = model => {
    this.props.kpiCreationActions.createKpi(model);
    this.onNewKpiClose();
  };

  onNewKpiClose = () => {
    this.setState({
      ...this.state,
      newKpiOpen: false,
    });
  };
  onNewKpiOpen = () => {
    this.setState({
      ...this.state,
      newKpiOpen: true,
    });
  };
  setSubmitConfirmOpen = value => {
    this.setState({
      ...this.state,
      submitConfirmOpen: value,
    });
  };

  renderData() {
    const { intl, classes } = this.props;
    const { categories } = this.props.categoryList;
    const { types } = this.props.goalTypeList;
    const { kpis } = this.props.kpiList;
    const { units } = this.props.unitList;
    const { periodicities: fetchedPeriodicities } = this.props.periodicityList;
    const { definition } = this.props.goalDefinitionDetail;
    const { repartitions } = this.props.goalDefinitionRepartitionList;
    const {
      loading: updateLoading,
      success,
      error,
    } = this.props.goalDefinitionUpdate;
    const { loading: activationUpdateLoading } =
      this.props.goalDefinitionActivationUpdate;
    const unit =
      definition.kpi.unit.name +
      (definition.kpi.unit.symbol ? ` (${definition.kpi.unit.symbol})` : '');
    const readonly = !definition.isActive;

    const periodicities =
      definition.kpi.periodicity.code === 'C'
        ? [
            {
              id: definition.kpi.periodicity.id,
              description: intl.formatMessage({ id: `kpi.periodicity.C` }),
              order: fetchedPeriodicities.length + 1,
              code: 'C',
            },
            ...fetchedPeriodicities,
          ]
        : fetchedPeriodicities;

    if (success) {
      this.props.goalDefinitionUpdateActions.clearGoalDefinitionUpdate();
      // this.props.history.goBack()
      toast.success(intl.formatMessage({ id: 'admin.goal.edit.success' }));
    }

    if (error) {
      toast.error(intl.formatMessage({ id: 'admin.goal.edit.error' }));
    }
    const kpi = definition.kpi;
    const kpiPeriodsList = kpi
      ? _.compact([
          kpi.start && kpi.end ? { start: kpi.start, end: kpi.end } : null,
          ...kpi.periods,
        ])
      : [];

    const kpiPeriods = (
      <react.fragment>
        {kpi && kpiPeriodsList.length > 0 && (
          <grid item="">
            <infotext>
              {intl.formatMessage({ id: 'admin.goal.kpi_periods_label' })}
            </infotext>
            <grid container="" spacing="{2}">
              {kpiPeriodsList.map(period => (
                <grid item="" key="{period.id}" xs="{12}">
                  {intl
                    .formatMessage({ id: 'admin.goal.indication.period_text' })
                    .format(
                      period.start.toDate2().toLocaleDateString(),
                      period.end.toDate2().toLocaleDateString(),
                    )}
                </grid>
              ))}
            </grid>
          </grid>
        )}
      </react.fragment>
    );

    const labels = {
      D: 'jour',
      W: 'semaine',
      M: 'mois',
      Q: 'trimestre',
      S: 'semestre',
      Y: 'an',
    };

    const explanationPeriods = {
      D: 'jours',
      W: 'semaines',
      M: 'mois',
      Q: 'trimestres',
      S: 'semestres',
      Y: 'années',
    };

    const goalRepartitionLabel =
      parseInt(definition.repartition.id) === _.get(repartitions, '[0].id')
        ? intl.formatMessage({ id: 'admin.goal.target_label' })
        : intl
            .formatMessage({ id: 'admin.goal.creation_target_label' })
            .format(
              labels[definition.periodicity.code],
              definition.type.code === 'C' ? 'individuel' : 'équipe',
            );
    const explanation =
      definition.repartition &&
      (definition.repartition.code === 'G'
        ? Resources[
            `ADMIN_GOAL_CREATION_REPARTITION_GLOBAL${
              definition.type.code === 'C' ? '' : '_TEAM'
            }`
          ].format(explanationPeriods[definition.periodicity.code])
        : Resources[
            `ADMIN_GOAL_CREATION_REPARTITION_INDIVIDUAL${
              definition.type.code === 'C' ? '' : '_TEAM'
            }`
          ].format(explanationPeriods[definition.periodicity.code]));

    const isCustom =
      this.state.kpiCustom !== null
        ? this.state.kpiCustom
        : _.get(definition, 'kpi.custom');
    const isCollaboratorEditable =
      this.state.kpiCollaboratorEditable !== null
        ? this.state.kpiCollaboratorEditable
        : _.get(definition, 'kpi.collaborator_editable');

    return (
      <div>
        <formsy onValidSubmit="{this.handleSubmit.bind(this)}">
          <grid container="" direction="column" spacing="{4}">
            <grid item="" container="" direction="row" spacing="{1}">
              <grid item="" xs="{12}">
                <defaulttitle>
                  {intl.formatMessage({ id: 'admin.goal.creation.kpi_title' })}
                </defaulttitle>
              </grid>
              <grid item="" xs="{12}">
                <card>
                  <grid container="" direction="row" spacing="{2}">
                    <grid item="" xs="{12}" sm="{6}">
                      <grid container="" direction="column" spacing="{2}">
                        <grid item="" xs="{12}">
                          <grid container="" alignItems="center" spacing="{1}" className="{classes.linkWrapper}">
                            <grid item="" onChange="{()" ==""> this.setKpiCustom(false)}
                              className={`${classes.link} ${
                                !isCustom ? 'active' : ''
                              }`}
                            >
                              {intl.formatMessage({
                                id: 'challenge.form.goals.automatic',
                              })}
                            </grid>
                            <grid item="">
                              <navigationswitch defaultChecked="{_.get(definition," 'kpi.custom')}="" color="default" checked="{isCustom}" inputProps="{{" 'aria-label':="" 'checkbox="" with="" default="" color',="" }}="" onChange="{e" =="">
                                  this.setKpiCustom(e.target.checked)
                                }
                              />
                              <hiddeninput name="{`custom`}" value="{_.get(definition," 'kpi.custom')}=""></hiddeninput>
                            </navigationswitch></grid>
                            <grid item="" onChange="{()" ==""> this.setKpiCustom(true)}
                              className={`${classes.link} ${
                                isCustom ? 'active' : ''
                              }`}
                            >
                              {intl.formatMessage({
                                id: 'challenge.form.goals.manual',
                              })}
                            </grid>
                          </grid>
                        </grid>

                        {!isCustom && (
                          <react.fragment>
                            <grid item="">
                              <select name="kpiCategory" initial="{_.get(definition," 'kpi.category.id')}="" emptyText="{intl.formatMessage({" id:="" 'filter.all_category_label',="" })}="" label="{intl.formatMessage({" 'admin.goal.category_label',="" options="{categories}" optionValueName="id" optionTextName="name" fullWidth="" onChange="{this.setKpiCategory}"></select>
                            </grid>
                            <grid item="">
                              <select name="kpi" label="{intl.formatMessage({" id:="" 'admin.goal.kpi_label',="" })}="" initial="{definition.kpi.id}" options="{kpis.filter(kpi" ==""> {
                                  const currentCategory =
                                    this.state.kpiCategory ||
                                    _.get(definition, 'kpi.category.id');
                                  return (
                                    kpi.id === definition.kpi.id ||
                                    !this.state.kpiCategory ||
                                    _.get(kpi, 'category.id') ===
                                      parseInt(this.state.kpiCategory)
                                  );
                                  // return kpi.id === definition.kpi.id || (kpi.periodicity.code !== 'C' && (!currentCategory || _.get(kpi, 'category.id') === parseInt(currentCategory)))
                                })}
                                optionValueName='id'
                                optionTextName='name'
                                fullWidth
                                required
                              />
                            </select></grid>
                            <grid item="">
                              <button onClick="{this.onNewKpiOpen}" text="nouveau">
                                <fontawesomeicon icon="{faPlus}"></fontawesomeicon>
                                &nbsp;nouveau kpi
                              </button>
                            </grid>
                          </react.fragment>
                        )}
                        {isCustom && (
                          <react.fragment>
                            <grid item="" xs="{12}">
                              <grid container="" alignItems="center" spacing="{1}" className="{classes.linkWrapper}">
                                <grid item="">
                                  <radiogroup row="" name="{`kpiCollaboratorEditable`}" onChange="{e" =="">
                                      this.setKpiCollaboratorEditable(
                                        e.target.value === 'collaborator',
                                      )
                                    }
                                    value={
                                      isCollaboratorEditable
                                        ? 'collaborator'
                                        : 'manager'
                                    }
                                    disabled
                                  >
                                    <formcontrollabel value="manager" control="{<GreenRadio"></formcontrollabel>}
                                      label={intl.formatMessage({
                                        id: 'challenge.form.goal_manager_editable_label',
                                      })}
                                    />

                                    <formcontrollabel value="collaborator" control="{<GreenRadio"></formcontrollabel>}
                                      label={intl.formatMessage({
                                        id: 'challenge.form.goal_collaborator_editable_label',
                                      })}
                                    />
                                  </radiogroup>

                                  <hiddeninput name="{`collaboratorEditable`}" value="{isCollaboratorEditable}"></hiddeninput>
                                </grid>
                              </grid>
                            </grid>
                            <grid item="" xs="{12}">
                              <textfield name="{`kpiName`}" initial="{" _.get(definition,="" 'kpi.custom')="" ?="" 'kpi.name')="" :="" ''="" }="" label="{intl.formatMessage({" id:="" 'challenge.form.goal_name_label',="" })}="" fullWidth="" required="" lowercase=""></textfield>
                            </grid>
                            <grid item="" xs="{12}">
                              <select fullWidth="" name="{`kpiUnit`}" initial="{" _.get(definition,="" 'kpi.custom')="" ?="" 'kpi.unit.id')="" :="" ''="" }="" label="{intl.formatMessage({" id:="" 'challenge.form.goal_unit_label',="" })}="" options="{units}" onChange="{this.setKpiUnit}" optionTextName="name" optionValueName="id" required=""></select>
                            </grid>
                          </react.fragment>
                        )}
                      </grid>
                    </grid>
                    <grid item="" xs="{12}" sm="{6}">
                      <grid container="" direction="column" spacing="{2}">
                        <grid item="">
                          <infotext>
                            {intl.formatMessage({
                              id: 'admin.goal.unit_label',
                            })}
                          </infotext>
                          <defaulttext 19="" lowercase="" style="{{" minHeight:="" }}="">
                            {unit}
                          </defaulttext>
                        </grid>
                        <grid item="">
                          <infotext>
                            {intl.formatMessage({
                              id: 'admin.goal.periodicity_label',
                            })}
                          </infotext>
                          <defaulttext 19="" lowercase="" style="{{" minHeight:="" }}="">
                            {_.get(definition.kpi, 'periodicity.description')}
                          </defaulttext>
                        </grid>
                        <grid item="">
                          <infotext>
                            {intl.formatMessage({
                              id: 'admin.goal.kpi_format_label',
                            })}
                          </infotext>
                          {definition.kpi && (
                            <defaulttext 19="" lowercase="" style="{{" minHeight:="" }}="">
                              {_.get(definition.kpi, 'manual')
                                ? 'Manuel'
                                : 'Automatique'}
                            </defaulttext>
                          )}
                        </grid>
                        {definition.kpi.periodicity.code === 'C' && kpiPeriods}
                      </grid>
                    </grid>
                  </grid>
                </card>
              </grid>
            </grid>
            <grid item="" container="" direction="column" spacing="{1}">
              <grid item="">
                <defaulttitle>
                  {intl.formatMessage({ id: 'admin.goal.creation.goal_title' })}
                </defaulttitle>
              </grid>
              <grid item="">
                <card>
                  <grid container="" spacing="{2}">
                    <grid item="" xs="{12}" sm="{6}">
                      <textfield lowercase="" name="name" initial="{definition.name}" label="{intl.formatMessage({" id:="" 'admin.goal.name_label',="" })}="" fullWidth="" required=""></textfield>
                    </grid>
                    <grid item="" xs="{12}" sm="{6}">
                      <select name="type" initial="{definition.type.id}" disabled="" label="{intl.formatMessage({" id:="" 'admin.goal.type_label',="" })}="" options="{types}" optionValueName="id" optionTextName="description" fullWidth="" required=""></select>
                    </grid>
                    <grid item="" xs="{12}" sm="{6}">
                      <select name="category" initial="{definition.category.id}" label="{intl.formatMessage({" id:="" 'admin.goal.category_label',="" })}="" options="{categories}" optionValueName="id" optionTextName="name" fullWidth="" required=""></select>
                    </grid>
                    <grid item="" xs="{12}" sm="{6}">
                      <select name="periodicity" initial="{definition.periodicity.id}" disabled="" label="{intl.formatMessage({" id:="" 'admin.goal.periodicity_label',="" })}="" options="{periodicities.filter(" p="">
                            p.order >=
                              _.get(definition.kpi, 'periodicity.order') &&
                            p.order > 1,
                        )}
                        optionValueName='id'
                        optionTextName='description'
                        fullWidth
                        required
                      />
                    </select></grid>
                    <grid item="" xs="{12}" sm="{6}">
                      <textfield type="number" name="target" label="{intl.formatMessage({" id:="" 'admin.goal.target_label',="" })}="" initial="{definition.target}" fullWidth="" disabled="{readonly}" required=""></textfield>
                    </grid>
                    <grid item="" xs="{12}" sm="{6}">
                      <textfield type="number" name="default" label="{intl.formatMessage({" id:="" 'admin.goal.default_label',="" })}="" initial="{definition.default}" fullWidth="" disabled="{readonly}" required=""></textfield>
                    </grid>
                    <grid item="" xs="{12}">
                      <richtextfield name="indication" initial="{JSON.parse(definition.indication)}" readOnly="{false}" onChange="{this.handleIndicationChange}" label="{intl.formatMessage({" id:="" 'admin.goal.indication_label',="" })}="" padding="{'5px" 0'}="" fullWidth="" multiline="" rowsMax="{10}" required=""></richtextfield>
                    </grid>
                  </grid>
                </card>
              </grid>
            </grid>
            <grid item="" container="" direction="column" spacing="{1}">
              <grid item="">
                <defaulttitle>
                  {intl.formatMessage({
                    id: 'admin.goal.creation.options_title',
                  })}
                </defaulttitle>
              </grid>
              <grid item="">
                <card>
                  <grid container="" direction="row" justify="space-around">
                    <grid item="">
                      <grid container="" spacing="{1}" direction="column">
                        <grid item="">
                          <grid container="" alignItems="center">
                            <grid item="">
                              <switch name="live" initial="{definition.live}" label="{intl.formatMessage({" id:="" 'admin.goal.live_label',="" })}=""></switch>
                            </grid>
                            <grid item="">
                              <tooltip title="{intl.formatMessage({" id:="" 'admin.goal.live_infos',="" })}="">
                                <bluetext>
                                  <fontawesomeicon icon="{faInfoCircle}"></fontawesomeicon>
                                </bluetext>
                              </tooltip>
                            </grid>
                          </grid>
                        </grid>

                        {_.get(definition.type, 'code') === 'C' && (
                          <grid item="">
                            <switch name="editable" initial="{definition.editable}" label="{intl.formatMessage({" id:="" 'admin.goal.editable_label',="" })}=""></switch>
                          </grid>
                        )}
                        {_.get(definition.type, 'code') === 'T' && (
                          <grid item="">
                            <switch name="admin_editable" initial="{definition.admin_editable}" label="{intl.formatMessage({" id:="" 'admin.goal.admin_editable_label',="" })}=""></switch>
                          </grid>
                        )}
                        <grid item="">
                          <switch name="allow_ranking" initial="{definition.allow_ranking}" label="{intl.formatMessage({" id:="" 'admin.goal.allow_ranking_label',="" })}=""></switch>
                        </grid>

                        <grid item="">
                          <switch name="allow_ranking_points" initial="{definition.allow_ranking_points}" label="{intl.formatMessage({" id:="" 'admin.goal.allow_ranking_points',="" })}=""></switch>
                        </grid>
                      </grid>
                    </grid>
                    <grid item="">
                      <grid container="" spacing="{1}" direction="column">
                        <grid item="">
                          <switch name="allow_over_target" initial="{definition.allow_over_target}" label="{intl.formatMessage({" id:="" 'admin.goal.allow_over_target_label',="" })}=""></switch>
                        </grid>
                        <grid item="">
                          <switch name="past_editable" initial="{definition.past_editable}" label="{intl.formatMessage({" id:="" 'admin.goal.past_editable_label',="" })}=""></switch>
                        </grid>
                        <grid item="">
                          <switch name="allow_ranking_latest_value" initial="{definition.allow_ranking_latest_value}" label="{intl.formatMessage({" id:="" 'admin.goal.allow_ranking_latest_value',="" })}=""></switch>
                        </grid>
                      </grid>
                    </grid>
                  </grid>
                </card>
              </grid>
            </grid>
          </grid>

          <grid 5="" container="" spacing="{4}" style="{{" marginTop:="" }}="">
            {!readonly && (
              <grid item="" xs="{12}">
                <grid container="" justify="space-between">
                  <grid item="">
                    <progressbutton type="button" color="secondary" text="{intl.formatMessage({" id:="" 'common.archive'="" })}="" disabled="{updateLoading}" centered="" onClick="{()" ==""> this.setOpen(true)}
                    />
                  </progressbutton></grid>
                  <grid item="">
                    <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" loading="{updateLoading}" centered=""></progressbutton>
                  </grid>
                </grid>
              </grid>
            )}
          </grid>
        </formsy>
        <dialog open="{this.state.open}" onClose="{()" ==""> this.setOpen(false)}>
          <dialogtitle>
            {intl
              .formatMessage({ id: 'admin.goal.disable_message' })
              .format(definition.name)}
          </dialogtitle>
          <dialogcontent>
            {intl.formatMessage({ id: 'admin.goal.disable_info' })}
          </dialogcontent>
          <dialogactions>
            <button onClick="{()" ==""> this.setOpen(false)} color='secondary'>
              {intl.formatMessage({ id: 'common.no' })}
            </button>
            <progressbutton type="button" text="{intl.formatMessage({" id:="" 'common.yes'="" })}="" loading="{activationUpdateLoading}" onClick="{this.onDisable.bind(this)}"></progressbutton>
          </dialogactions>
        </dialog>
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { categories, loading: categoryListLoading } =
      this.props.categoryList;
    const { types, loading: goalTypeListLoading } = this.props.goalTypeList;
    const { kpis, loading: kpiListLoading } = this.props.kpiList;
    const { units, loading: unitListLoading } = this.props.unitList;
    const { periodicities, loading: periodicityListLoading } =
      this.props.periodicityList;
    const { repartitions, loading: repartitionsLoading } =
      this.props.goalDefinitionRepartitionList;
    const loading =
      categoryListLoading ||
      goalTypeListLoading ||
      kpiListLoading ||
      periodicityListLoading ||
      repartitionsLoading ||
      unitListLoading;
    const { success } = this.props.goalDefinitionActivationUpdate;

    if (success) {
      this.props.goalDefinitionActivationUpdateActions.clearGoalDefinitionActivationUpdate();
      this.props.history.goBack();
    }

    const criticities = [
      { order: 1, name: 'Basse' },
      { order: 2, name: 'Moyenne' },
      { order: 3, name: 'Haute' },
    ];

    return (
      <div>
        {loading && this.renderLoader()}
        {!loading &&
          categories &&
          types &&
          kpis &&
          units &&
          periodicities &&
          repartitions &&
          this.renderData()}
        <dialog open="{this.state.newKpiOpen}" onClose="{this.onNewKpiClose}" classes="{{" paper:="" this.props.classes.kpiDialog="" }}="">
          <dialogtitle>Demande de création de KPI</dialogtitle>
          <formsy onValidSubmit="{this.handleSubmitKpi.bind(this)}">
            <grid container="" direction="column" spacing="{2}">
              <grid item="">
                <grid container="" direction="row" spacing="{2}">
                  <grid item="" xs="{12}" sm="{6}">
                    <select name="criticity" label="{intl.formatMessage({" id:="" 'admin.goal.criticity_label',="" })}="" options="{criticities}" optionValueName="order" optionTextName="name" fullWidth="" required=""></select>
                  </grid>
                  <grid item="" xs="{12}" sm="{6}">
                    <select name="category" label="{intl.formatMessage({" id:="" 'admin.goal.category_label',="" })}="" options="{categories}" optionValueName="id" optionTextName="name" fullWidth=""></select>
                  </grid>
                </grid>
              </grid>
              <grid item="" xs="{12}" sm="{12}">
                <textfield name="name" label="{intl.formatMessage({" id:="" 'admin.goal.kpi_name_label',="" })}="" fullWidth="" required=""></textfield>
              </grid>
              <grid item="" xs="{12}" sm="{12}">
                <textfield name="description" label="{intl.formatMessage({" id:="" 'admin.goal.description_label',="" })}="" fullWidth="" required="" multiline="" rows="{4}" variant="outlined"></textfield>
              </grid>
            </grid>
            <dialogactions>
              <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.submit'="" })}="" loading="{loading}" centered=""></progressbutton>
              <button onClick="{this.onNewKpiClose}" color="secondary">
                {intl.formatMessage({ id: 'common.cancel' })}
              </button>
            </dialogactions>
          </formsy>
        </dialog>
        <dialog open="{this.state.submitConfirmOpen}" onClose="{()" ==""> this.setSubmitConfirmOpen(false)}
        >
          <formsy>
            <dialogcontent>
              <defaulttext lowercase="">
                {intl.formatMessage({
                  id: 'admin.goal.update_confirmation_message',
                })}
              </defaulttext>
            </dialogcontent>
            <dialogactions>
              <button color="secondary" onClick="{()" ==""> this.setSubmitConfirmOpen(false)}
              >
                {intl.formatMessage({ id: 'common.no' })}
              </button>
              <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'common.yes'="" })}="" onClick="{this.handleConfirmClick}" loading="{loading}"></progressbutton>
            </dialogactions>
          </formsy>
        </dialog>
      </div>
    );
  }
}

const mapStateToProps = ({
  categoryList,
  goalTypeList,
  kpiList,
  periodicityList,
  goalDefinitionUpdate,
  goalDefinitionActivationUpdate,
  goalDefinitionRepartitionList,
  goalDefinitionDetail,
  unitList,
}) => ({
  categoryList,
  goalTypeList,
  kpiList,
  periodicityList,
  goalDefinitionUpdate,
  goalDefinitionActivationUpdate,
  goalDefinitionDetail,
  goalDefinitionRepartitionList,
  unitList,
});

const mapDispatchToProps = dispatch => ({
  categoryListActions: bindActionCreators(categoryListActions, dispatch),
  goalTypeListActions: bindActionCreators(goalTypeListActions, dispatch),
  kpiListActions: bindActionCreators(kpiListActions, dispatch),
  kpiCreationActions: bindActionCreators(kpiCreationActions, dispatch),
  periodicityListActions: bindActionCreators(periodicityListActions, dispatch),
  goalDefinitionUpdateActions: bindActionCreators(
    goalDefinitionUpdateActions,
    dispatch,
  ),
  goalDefinitionRepartitionListActions: bindActionCreators(
    goalDefinitionRepartitionListActions,
    dispatch,
  ),
  unitListActions: bindActionCreators(unitListActions, dispatch),
  goalDefinitionActivationUpdateActions: bindActionCreators(
    goalDefinitionActivationUpdateActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(injectIntl(Base))));
