import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import { deleteRewardsAllocationsImageKeySlice } from './slices';

function* deleteRewardAllocationsImage(slice, endpoint, action) {
  try {
    const { rewardAllocationId, image } = action.payload;
    yield call(endpoint, rewardAllocationId, image);
    yield put(slice.actions.deleteSuccess());
  } catch (error) {
    yield put(
      slice.actions.deleteFailure(error?.response?.data?.error ?? 'UNKNOWN'),
    );
  }
}

function* watchDeleteRewardAllocationsImage(slice, endpoint) {
  yield takeLatest(
    slice.actions.deleteStart.type,
    deleteRewardAllocationsImage,
    slice,
    endpoint,
  );
}

export default function* deleteRewardAllocationsImageSaga() {
  yield all([
    watchDeleteRewardAllocationsImage(
      deleteRewardsAllocationsImageKeySlice,
      api.rewardAllocations.deleteImage,
    ),
  ]);
}
