import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Collaborator,
  DefaultTitle,
  EmptyState,
  Loader,
  TeamSelector,
} from '../../../../components';
import { useIntl } from 'react-intl';
import * as teamListActions from '../../../../services/Teams/TeamList/actions';
import * as userListActions from '../../../../services/Users/UserList/actions';

import { ChallengeSearchBar } from '../../../../scenes/Challenges/components';

const styles = {
  panel: {
    backgroundColor: 'initial',
    borderRadius: 'initial',
    boxShadow: 'none',
  },
  panelSummary: {
    padding: 'initial',
  },
  panelDetails: {
    padding: 'initial',
    paddingBottom: 24,
  },
};

const AdministratorCollaboratorSelector = ({
  onClick,
  contextUrl,
  contextUrlOptions,
  disableRedirect,
  loadingAdmin,
  userListActions,
  forceSelector,
  full,
  ...props
}) => {
  const intl = useIntl();
  const { classes } = props;
  const { teams, loading } = props.teamList;
  const [team, setTeam] = useState();
  const [search, setSearch] = useState('');

  const { account } = props.accountDetail;
  const { users: collaborators } = props.userList;

  const urlParams = contextUrlOptions
    ? Object.keys(contextUrlOptions).reduce((acc, key) => {
        if (!acc) {
          acc = `${acc}?${key}=${contextUrlOptions[key]}`;
        } else {
          acc = `${acc}&${key}=${contextUrlOptions[key]}`;
        }
        return acc;
      }, '')
    : '';
  function renderData() {
    return (
      <div>
        {team && !disableRedirect ? (
          <redirect push="" to="{`${contextUrl}${team}${urlParams}`}"></redirect>
        ) : (
          <teamselector full="{!!full}" onClick="{(teamId," type)=""> {
              setTeam(teamId);
              if (onClick) {
                onClick(teamId, type);
              }
            }}
            teamGroupSelectable={props.teamGroupSelectable}
          />
        )}
      </teamselector></div>
    );
  }

  useEffect(() => {
    if (search) {
      userListActions.getUserList({
        isActive: true,
        full: !!full,
        limit: 30,
        search: search,
        teamGroupId: account.team_group.id,
        roleCode: 'C',
      });
    }
  }, [search]);

  const handleSearch = newValue => {
    setSearch(newValue);
  };
  return (
    <>
      <grid container="" spacing="{1}">
        <grid 10="" item="" style="{{" marginBottom:="" }}="" xs="{12}" sm="{4}">
          <challengesearchbar search="{search}" onChange="{handleSearch}" fullSize=""></challengesearchbar>
        </grid>
      </grid>
      {search && collaborators === null ? (
        <loader centered=""></loader>
      ) : (
        <grid container="" spacing="{2}">
          {search &&
            collaborators?.map(collaborator => {
              return (
                <grid key="{collaborator.id}" item="" xs="{12}" sm="{6}" md="{4}" lg="{3}" onClick="{()" ==""> {
                    if (collaborator?.team?.id) {
                      setTeam(collaborator.team.id);
                    }
                    props.onClickCollaborator(collaborator);
                  }}
                >
                  <collaborator key="{collaborator.id}" collaborator="{collaborator}"></collaborator>
                </grid>
              );
            })}
        </grid>
      )}
      {renderData()}
    </>
  );
};

const mapStateToProps = ({
  teamList,
  userList,
  teamCollaboratorList,
  accountDetail,
}) => ({
  teamList,
  userList,
  accountDetail,
  loadingAdmin: teamCollaboratorList.loading,
});

const mapDispatchToProps = dispatch => ({
  teamListActions: bindActionCreators(teamListActions, dispatch),
  userListActions: bindActionCreators(userListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(AdministratorCollaboratorSelector));
