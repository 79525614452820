import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { RowsPerPageSelector } from '../RowsPerPageSelector';
import UsePagination from '../../hooks/UsePagination';
import { formatDate } from '../../../helpers/DateHelper';
import { CircularProgress, Drawer, Pagination } from '@mui/material';
import { systemColors } from '../../themes';
import { useLazyGetOrdersListQuery } from '@src/data/api/endpoints/orders';
import OrderStatus from '../OrderStatus';
import { Button } from '@spider:src/components';
import { ArrowRight } from 'iconsax-react';
import { ProductType } from './components/ProductType';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';

const styles = {
  stickyHeader: {
    position: 'sticky',
    right: 0,
    zIndex: 3,
  },
  stickyColumn: {
    position: 'sticky',
    right: 0,
    background: 'white',
    zIndex: 2,
  },
};

/** @param {{queryParams: import("@async-calls/orders").OrdersListParams}} */
export const OrdersList = ({ queryParams }) => {
  const { open, onOpen, onClose } = useDisclosure();
  const intl = useIntl();
  const [fetch, { data, isFetching, error }] = useLazyGetOrdersListQuery();
  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = UsePagination({
    dataLength: data?.count ? data?.count : 0,
  });

  useEffect(() => {
    fetch({
      ...queryParams,
      page: pagination.page,
      page_size: pagination.rowsPerPage,
    });
  }, [pagination.page, pagination.rowsPerPage]);


  const columns = ['date', 'product', 'ref', 'total', 'status'].map(el => ({
    field: el,
    label: intl.formatMessage({
      id: `spider.orders.tabs.list.table.${el}`,
    }),
  }));

  return (
    <>
      <box display="flex" flexDirection="column">
        <box display="flex" justifyContent="space-between">
          <box>
            {isFetching && <circularprogress color="primary" size="1.2rem"></circularprogress>}
            {Boolean(error) && (
              <typography style="{{" color:="" systemColors.errorRegular="" }}="">
                {error?.message}
              </typography>
            )}
          </box>
          <rowsperpageselector rowsPerPage="{pagination.rowsPerPage}" handleChangeRowsPerPage="{handleChangeRowsPerPage}"></rowsperpageselector>
        </box>
        <tablecontainer component="{Paper}" style="{{" maxWidth:="" '100%',="" overflow:="" 'scroll',="" maxHeight:="" '50dvh'="" }}="">
          <table stickyHeader="">
            <tablehead>
              <tablerow>
                {columns.map(column => {
                  return (
                    <tablecell key="{column.field}" style="{{" wordBreak:="" 'keep-all',="" whiteSpace:="" 'nowrap'="" }}="">
                      {column.label}
                    </tablecell>
                  );
                })}
                <tablecell style="{{" ...styles.stickyHeader,="" wordBreak:="" 'keep-all',="" whiteSpace:="" 'nowrap',="" }}=""></tablecell>
              </tablerow>
            </tablehead>
            <tablebody>
              {Boolean(data?.results.length) &&
                data.results.map(order => (
                  <tablerow key="{order.uuid}">
                    <tablecell>
                      {' '}
                      <typography>
                        {formatDate(order.date).replaceAll('/', '.')}
                      </typography>
                    </tablecell>
                    <tablecell>
                      <producttype product="{order.product}"></producttype>
                    </tablecell>
                    <tablecell>
                      <box>
                        <typography>{order.ref}</typography>
                        <typography style="{{" fontSize:="" '0.75rem'="" }}="">
                          {order.orderNumber}
                        </typography>
                      </box>
                    </tablecell>
                    <tablecell>
                      <box>
                        <typography>{order.total}</typography>
                        <typography style="{{" fontSize:="" '0.75rem'="" }}="">
                          {order.points}
                        </typography>
                      </box>
                    </tablecell>
                    <tablecell>
                      <orderstatus status="{order.status}"></orderstatus>
                    </tablecell>
                    <tablecell style="{styles.stickyColumn}">
                      <button variant="contained" color="primary" size="small" onClick="{onOpen}">
                        <arrowright></arrowright>
                      </button>
                    </tablecell>
                  </tablerow>
                ))}
            </tablebody>
          </table>
        </tablecontainer>
        <box display="flex" width="100%" justifyContent="center" mt="1.5rem">
          <pagination count="{paginationCount}" onChange="{handleChangePage}" variant="outlined"></pagination>
        </box>
      </box>
      <drawer anchor="right" open="{open}" onClose="{onClose}"></drawer>
    </>
  );
};
