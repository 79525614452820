import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Grid,
  isWidthUp,
  withWidth,
  FormControl,
  InputLabel,
  CardMedia,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  AndroidButton,
  Card,
  TextField,
  DefaultText,
  BigText,
  ErrorText,
  IosButton,
  LinkedInButton,
  Logo,
  ProgressButton,
  Switch,
  LanguageSelect,
  I18nWrapper,
  HiddenInput,
  PasswordField,
} from '../../../../../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import * as authErrors from '../../../../../../services/Auth/errors';
import Formsy from 'formsy-react';
import KeyIcon from '../../../../../../assets/img/system/login/key.png';
import LogoBlack from '../../../../../../assets/logo_black.png';
import router from '../../../../../../data/router/router';

const useStyles = makeStyles({
  form: {
    // backgroundColor: '#2B2E45'
  },
  ssoSwitch: {
    position: 'absolute',
    right: '50%',
    marginRight: -50,
    // bottom: '10',
    marginTop: '-31px',
  },
  ssoSwitchMobile: {
    marginTop: 10,
    marginLeft: 'calc(50% - 50px)',
    width: 200,
  },
  ssoSwitchLabel: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    fontFamily: 'Avenir',
  },
  card: {
    borderRadius: 15,
    paddingTop: 5,
    paddingBottom: 5,
  },
  inputs: {
    '& label, & label.Mui-focused, & input:not(.Mui-error), & textarea:not(.Mui-error)':
      {
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: 16,
        fontFamily: 'Avenir',
        color: '#555555',
      },
  },
  submit_button: {
    '& .MuiButton-root': {
      width: '100%',
      height: 40,
      borderRadius: 5,
      textTransform: 'none',
      fontFamily: 'Avenir',
      fontSize: 18,
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
  logo: {
    width: 129,
    height: 53,
  },
  logoMobile: {
    width: 150,
    height: 60,
    margin: 'auto',
    marginBottom: 20,
  },
  connexionText: {
    textTransform: 'none',
    fontSize: 30,
    marginTop: -2,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    borderLeft: '2px solid #555',
    color: '#555',
    fontFamily: 'Avenir',
    fontWeight: 'bold',
  },
  connexionTextMobile: {
    textTransform: 'none',
    fontSize: 30,
    marginTop: -2,
    paddingTop: 5,
    paddingBottom: 5,
    color: '#555',
    fontFamily: 'Avenir',
    fontWeight: 'bold',
  },
});

const LoginForm = ({
  onSubmit,
  onSubmitSSO,
  customError,
  resetCustomError,
  ...props
}) => {
  const context = useContext(I18nWrapper.Context);
  const { loading, error } = props.auth;

  const [locale, setLocale] = useState(context.locale);
  const { detect } = require('detect-browser');
  const [isSSO, setIsSSO] = useState(false);
  const browser = detect();
  const isMobileApp =
    browser.name === 'ios-webview' || browser.name === 'chromium-webview';
  const classes = useStyles();
  const isDesktop = isWidthUp('sm', props.width);
  const ssoSwitchClass = isDesktop
    ? classes.ssoSwitch
    : classes.ssoSwitchMobile;
  const intl = useIntl();
  const [displayCaptcha, setDisplayCaptcha] = useState(
    error === 'connection_attempts_exceeded',
  );
  console.log(error);
  const [captcha, setCaptcha] = useState();

  const onChangeLanguage = value => {
    setLocale(value);
  };
  const onCaptchaChange = value => {
    setCaptcha(value);
  };

  useEffect(() => {
    context.selectLanguage(locale);
    localStorage.setItem('locale', locale);
  }, [locale]);

  useEffect(() => {
    if (!displayCaptcha && error === 'connection_attempts_exceeded') {
      setDisplayCaptcha(true);
    }
  }, [error]);
  return (
    <div>
      <grid 3="" container="" spacing="{isDesktop" ?="" :="" 2}="" justify="flex-end">
        <grid item="" container="" xs="{12}">
          {!isDesktop && (
            <grid item="" xs="{12}">
              <cardmedia className="{!isDesktop" ?="" classes.logoMobile="" :="" classes.logo}="" image="{LogoBlack}"></cardmedia>
            </grid>
          )}
          <grid item="" xs="" container="">
            {isDesktop && (
              <grid item="">
                <cardmedia className="{!isDesktop" ?="" classes.logoMobile="" :="" classes.logo}="" image="{LogoBlack}"></cardmedia>
              </grid>
            )}
            <grid item="">
              <bigtext className="{" !isDesktop="" ?="" classes.connexionTextMobile="" :="" classes.connexionText="" }="">
                {intl.formatMessage({ id: 'login.title' })}
              </bigtext>
            </grid>
          </grid>
          <grid 10="" item="" style="{{" marginTop:="" }}="">
            <formsy>
              <languageselect name="locale" initial="{locale}" onChange="{onChangeLanguage}"></languageselect>
            </formsy>
          </grid>
        </grid>

        <grid item="" xs="{12}">
          <card className="{classes.card}">
            <formsy onValidSubmit="{isSSO" ?="" onSubmitSSO="" :="" onSubmit}="">
              <grid container="" spacing="{2}">
                <grid item="" xs="{12}">
                  <div>
                    <grid container="" spacing="{2}" className="{classes.form}">
                      <grid item="" xs="{12}">
                        <textfield name="code" variant="outlined" placeholder="{intl.formatMessage({" id:="" 'login.code_placeholder',="" })}="" className="{classes.inputs}" label="{intl.formatMessage({" 'login.code_label'="" fullWidth="" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></textfield>
                      </grid>
                      {!isSSO && (
                        <react.fragment>
                          <grid item="" xs="{12}">
                            <textfield lowercase="{true}" variant="outlined" placeholder="{intl.formatMessage({" id:="" 'login.email_placeholder',="" })}="" className="{classes.inputs}" name="email" label="{intl.formatMessage({" 'login.email_label',="" fullWidth="" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></textfield>
                          </grid>
                          <grid item="" xs="{12}">
                            <passwordfield lowercase="{true}" variant="outlined" placeholder="●●●●●●●" className="{classes.inputs}" type="password" name="password" label="{intl.formatMessage({" id:="" 'login.password_label',="" })}="" fullWidth="" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" }}=""></passwordfield>
                          </grid>
                        </react.fragment>
                      )}
                    </grid>
                  </div>
                </grid>
                {error === authErrors.LOGIN_ERROR ||
                  (customError && (
                    <grid item="" xs="{12}">
                      <errortext align="center">
                        {customError ||
                          intl.formatMessage({ id: 'login.error' })}
                      </errortext>
                    </grid>
                  ))}
                {displayCaptcha && (
                  <grid item="" xs="{12}">
                    <recaptcha sitekey="6LctniwfAAAAAE8bs1iQ-_Uuvkqya66J2qbvyg2H" onChange="{onCaptchaChange}"></recaptcha>
                    <hiddeninput name="captcha" value="{captcha}"></hiddeninput>
                  </grid>
                )}

                <grid item="" xs="{12}" className="{classes.submit_button}">
                  <progressbutton type="submit" text="{intl.formatMessage({" id:="" 'login.submit_button'="" })}="" centered="" loading="{loading}"></progressbutton>
                </grid>
                {!isSSO && (
                  <grid item="" xs="{12}" style="{{" textAlign:="" 'center'="" }}="">
                    <link to="/reset-password" style="{{" textDecoration:="" 'none',="" color:="" 'rgb(15,111,222)',="" fontSize:="" 16,="" }}="">
                      {intl.formatMessage({ id: 'login.reset_password' })}
                    
                  </grid>
                )}
                <grid 30="" item="" xs="{12}" style="{{" position:="" 'relative',="" paddingTop:="" isDesktop="" ?="" :="" 0,="" }}="">
                  <div className="{ssoSwitchClass}">
                    <switch name="isSSO" initial="{isSSO}" label="{" <React.Fragment="">
                          <img src="{KeyIcon}" style="{{" height:="" '19px',="" marginBottom:="" '-5px',="" marginRight:="" '4px',="" }}="">
                          SSO
                        
                      }
                      onChange={() => {
                        setIsSSO(!isSSO);
                        resetCustomError();
                      }}
                      labelClass={classes.ssoSwitchLabel}
                      lightTheme
                    />
                  </switch></div>
                </grid>
              </grid>
            </formsy>
          </card>
        </grid>
        {!isMobileApp && (
          <grid 10="" item="" xs="{12}" style="{{" marginTop:="" }}="">
            <grid container="" justify="center" spacing="{2}">
              <grid item="" xs="{4}">
                <iosbutton></iosbutton>
              </grid>
              <grid item="" xs="{4}">
                <androidbutton></androidbutton>
              </grid>
            </grid>

            <div 10="" style="{{" marginTop:="" }}="">
              <grid container="" justify="center">
                <grid item="">
                  <linkedinbutton></linkedinbutton>
                </grid>
              </grid>
            </div>
          </grid>
        )}
      </grid>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps)(withWidth()(LoginForm));
