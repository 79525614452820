import * as types from './actionTypes';
import initialState from '../../../store/initialState';

const CollaboratorGoalListDuplication = (
  state = initialState.collaboratorGoalListDuplication,
  action,
) => {
  switch (action.type) {
    case types.DUPLICATE_COLLABORATOR_GOAL_LIST:
      return { ...state, success: false, loading: true, hasError: false };

    case types.DUPLICATE_COLLABORATOR_GOAL_LIST_SUCCESS:
      return { ...state, success: true, loading: false, hasError: false };

    case types.DUPLICATE_COLLABORATOR_GOAL_LIST_ERROR:
      return { ...state, success: false, loading: false, hasError: true };

    case types.CLEAR_COLLABORATOR_GOAL_LIST_DUPLICATION:
      return { ...state, success: false, loading: false, hasError: false };

    default:
      return state;
  }
};

export default CollaboratorGoalListDuplication;
