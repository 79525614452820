import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { Button, Select, Loader } from '../../../../../../components';
import * as Resources from '../../../../../../Resources';
import { injectIntl } from 'react-intl';
import * as teamListActions from '../../../../../../services/Teams/TeamList/actions';
import * as currentPeriodDetailActions from '../../../../../../services/Periods/CurrentPeriodDetail/actions';
import * as previousPeriodListActions from '../../../../../../services/Periods/PreviousPeriodList/actions';

class CollaboratorFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: props.team,
      collaborator: props.collaborator,
      year: props.year,
      display: true,
    };
  }

  componentDidMount() {
    this.props.teamListActions.getTeamList();
    this.props.currentPeriodDetailActions.getCurrentPeriodDetail();
    this.props.previousPeriodListActions.getPreviousPeriodList();
  }

  componentWillReceiveProps(props) {
    if (
      props.team != this.state.team ||
      props.collaborator != this.state.collaborator ||
      props.year != this.state.year
    ) {
      this.setState({
        ...this.state,
        team: props.team,
        collaborator: props.collaborator,
        year: props.year,
      });
    }
  }

  handleChange = name => value => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleTeamChange = value => {
    const { teams } = this.props.teamList;
    const team = teams.find(x => x.id == value);
    const collaboratorId =
      team && team.collaborators.length > 0 ? team.collaborators[0].id : null;

    this.setState(
      {
        ...this.state,
        team: value,
        collaborator: collaboratorId,
        display: false,
      },
      () => {
        this.setState({
          display: true,
        });
      },
    );
  };

  handleSubmit(model) {
    const collaborator =
      model.collaborator != null &&
      model.collaborator != -1 &&
      model.collaborator != undefined
        ? Number(model.collaborator)
        : null;
    this.props.onChange(collaborator, model.year);
    this.props.onClose();
  }

  renderData() {
    const { intl } = this.props;
    const { account } = this.props.accountDetail;
    const { teams } = this.props.teamList;
    const { period: currentPeriod } = this.props.currentPeriodDetail;
    const { periods: previousPeriods } = this.props.previousPeriodList;
    const selectedTeam = this.state.team
      ? teams.filter(team => team.id == this.state.team)[0]
      : null;
    const collaborators = selectedTeam ? selectedTeam.collaborators : null;
    const periods = [currentPeriod].concat(previousPeriods);

    return (
      <grid container="" spacing="{2}">
        {(account.role.code == 'A' || account.role.code == 'S') && (
          <grid item="" xs="{12}">
            <select name="team" label="{intl.formatMessage({" id:="" 'filter.team_label'="" })}="" options="{teams}" optionValueName="id" optionTextName="name" emptyDisabled="" fullWidth="" initial="{this.state.team}" onChange="{this.handleTeamChange.bind(this)}"></select>
          </grid>
        )}
        {account.role.code != 'C' && this.state.display && collaborators && (
          <grid item="" xs="{12}">
            <select name="collaborator" label="{intl.formatMessage({" id:="" 'filter.collaborator_label'="" })}="" options="{collaborators}" optionValueName="id" optionTextName="fullname" emptyDisabled="" fullWidth="" initial="{this.state.collaborator}" onChange="{this.handleChange('collaborator').bind(this)}"></select>
          </grid>
        )}
        <grid item="" xs="{12}">
          <select name="{'year'}" label="{intl.formatMessage({" id:="" 'filter.period_label'="" })}="" options="{periods}" optionValueName="{'id'}" optionTextName="{'name'}" emptyDisabled="" fullWidth="" initial="{this.state.year}" onChange="{this.handleChange('year').bind(this)}"></select>
        </grid>
      </grid>
    );
  }

  render() {
    const { account } = this.props.accountDetail;
    const { teams, loading } = this.props.teamList;
    const { period: currentPeriod } = this.props.currentPeriodDetail;
    const { periods: previousPeriods } = this.props.previousPeriodList;
    const { intl } = this.props;

    return (
      <div>
        <dialog open="{this.props.open}" onClose="{this.props.onClose}">
          <formsy onSubmit="{this.handleSubmit.bind(this)}">
            <dialogtitle>
              {intl.formatMessage({ id: 'filter.title' })}
            </dialogtitle>
            <dialogcontent>
              {loading && <loader centered=""></loader>}
              {!loading &&
                account &&
                teams &&
                currentPeriod &&
                previousPeriods &&
                this.renderData()}
            </dialogcontent>
            <dialogactions>
              <button onClick="{this.props.onClose}" color="secondary">
                {intl.formatMessage({ id: 'common.cancel' })}
              </button>
              <button type="submit">
                {intl.formatMessage({ id: 'filter.submit_button' })}
              </button>
            </dialogactions>
          </formsy>
        </dialog>
      </div>
    );
  }
}

const mapStateToProps = ({
  accountDetail,
  teamList,
  currentPeriodDetail,
  previousPeriodList,
}) => ({
  accountDetail,
  teamList,
  currentPeriodDetail,
  previousPeriodList,
});

const mapDispatchToProps = dispatch => ({
  teamListActions: bindActionCreators(teamListActions, dispatch),
  currentPeriodDetailActions: bindActionCreators(
    currentPeriodDetailActions,
    dispatch,
  ),
  previousPeriodListActions: bindActionCreators(
    previousPeriodListActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CollaboratorFilter));
