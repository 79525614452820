import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import { useGetRewardsListQuery } from '@async-calls/rewards';
import { Loader } from '@src/components';
import { useParams } from 'react-router-dom';
import { Reward } from '@spider:src/scenes/Admin/components/ParametersTab/components/ProductsParameters/components/Reward';

const styles = {
  rewardLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  rewards: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
};

const ProductsParameters = ({ ...props }) => {
  const intl = useIntl();
  const params = useParams();

  const { data, isLoading, isFetching } = useGetRewardsListQuery({
    hierarchyNodeId: params.contract,
    distributor: 'Edenred',
  });

  return isLoading || isFetching ? (
    <loader centered=""></loader>
  ) : (
    <div className="{props.classes.rewardLayout}">
      <typography variant="{'h4'}" component="{'h4'}">
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.rewards.title',
        })}
      </typography>

      <typography variant="{'subtitle1'}">
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.rewards.subtitle',
        })}
      </typography>

      {data?.count === 0 && (
        <typography variant="{'subtitle1'}">
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.rewards.empty',
          })}
        </typography>
      )}
      {data?.count > 0 && (
        <div className="{props.classes.rewards}">
          {data.results?.map(reward => (
            <reward key="{reward.uuid}" reward="{reward}"></reward>
          ))}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ProductsParameters);
