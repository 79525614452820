import React from 'react';
import {
  ValidatedTeamRewardOrderList,
  WaitingTeamRewardOrderList,
} from './components';
import { RewardOrderListExport, TrackingSubHeader } from '../../components';
import {
  IconButton as AppBarIconButton,
  MainLayoutComponent,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faPlus } from '@fortawesome/free-solid-svg-icons';

class TeamRewardOrderTracking extends MainLayoutComponent {
  state = { exportOpen: false, page: 0 };

  handleAdd() {
    this.props.history.push('/rewards/creation');
  }

  handleCloseExport() {
    this.setState({
      ...this.state,
      exportOpen: false,
    });
  }

  handleOpenExport() {
    this.setState({
      ...this.state,
      exportOpen: true,
    });
  }

  handlePageChange(page) {
    this.setState({
      ...this.state,
      page: page,
    });
  }

  componentDidMount() {
    const { intl } = this.props;
    this.props.handleTitle(intl.formatMessage({ id: 'reward.title' }));
    this.props.handleSubHeader(
      <trackingsubheader onChange="{this.handlePageChange.bind(this)}"></trackingsubheader>,
    );
    this.props.handleButtons(
      <div>
        <appbariconbutton 8="" size="small" onClick="{this.handleOpenExport.bind(this)}" style="{{" marginRight:="" }}="">
          <fontawesomeicon icon="{faFileUpload}"></fontawesomeicon>
        </appbariconbutton>
        <appbariconbutton size="small" onClick="{this.handleAdd.bind(this)}">
          <fontawesomeicon icon="{faPlus}"></fontawesomeicon>
        </appbariconbutton>
      </div>,
    );
    this.props.activateReturn();
  }

  render() {
    return (
      <div>
        {this.state.page === 0 && <waitingteamrewardorderlist></waitingteamrewardorderlist>}
        {this.state.page === 1 && <validatedteamrewardorderlist></validatedteamrewardorderlist>}
        <rewardorderlistexport open="{this.state.exportOpen}" onClose="{this.handleCloseExport.bind(this)}"></rewardorderlistexport>
      </div>
    );
  }
}

export default injectIntl(TeamRewardOrderTracking);
