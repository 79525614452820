import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { AvatarGroup } from '@material-ui/lab';
import { Tag } from '../../../Teams/components/Team/components';
import { DefaultTitle, ErrorText, InfoText, Avatar } from '../../..';
import * as Resources from '../../../../Resources';
import { useIntl } from 'react-intl';
import '../../../../helpers/StringHelper';
import _ from 'lodash';

const styles = {
  managerAvatar: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  avatarGroup: {},
  avatar: {
    width: 30,
    height: 30,
  },
  avatarFirst: {
    width: 40,
    height: 40,
    marginTop: '-5px',
  },
};

const TeamNode = ({ team, classes, hideAvatars, ...props }) => {
  const intl = useIntl();
  const players = _.get(team, 'collaborators.length');
  const managerPhoto = _.get(
    team,
    'manager.photo',
    '/assets/img/user/avatar.svg',
  );
  const collaboratorList = _.compact([
    _.get(team, 'manager', null),
    ..._.get(team, 'collaborators', []),
  ]);
  const avatarLimit = 4;
  const totalCollaboratorCount = team.collaborators_count
    ? team.collaborators_count
    : _.get(team, 'collaborators.length', 0);

  return (
    <div>
      <grid container="" spacing="{1}">
        <grid item="" xs="{12}" container="" justify="left">
          <grid item="" xs="" zeroMinWidth="">
            <defaulttitle style="{{" textAlign:="" 'left',="" fontWeight:="" 'bold',="" textTransform:="" 'none',="" fontSize:="" 16,="" }}="" noWrap="">
              {team.name}
            </defaulttitle>
          </grid>
          {team.color && (
            <grid item="" style="{{" marginLeft:="" 5,="" borderRadius:="" overflow:="" 'hidden',="" height:="" 18,="" }}="">
              <tag color="{team.color.hex}">
                {intl
                  .formatMessage({ id: 'team.collaborators_text' })
                  .format(totalCollaboratorCount)}
              </tag>
            </grid>
          )}
          <grid item="" xs="{12}" zeroMinWidth="" style="{{" textAlign:="" 'left'="" }}="">
            {team.manager && (
              <infotext 14="" style="{{" textTransform:="" 'none',="" fontSize:="" }}="" noWrap="">
                {intl
                  .formatMessage({ id: 'team.manager_text' })
                  .format(team.manager.firstname, team.manager.lastname)}
              </infotext>
            )}
            {!team.manager && (
              <errortext 14="" style="{{" textTransform:="" 'none',="" fontSize:="" }}="" noWrap="">
                {intl.formatMessage({ id: 'team.no_manager_text' })}
              </errortext>
            )}
          </grid>
        </grid>
        {!hideAvatars && (
          <grid item="" style="{{" width:="" '100%'="" }}="">
            <avatargroup className="{classes.avatarGroup}" max="{15}">
              {_.take(collaboratorList, avatarLimit).map(
                (collaborator, index) => (
                  <avatar 0="" 1="" src="{_.get(collaborator," 'photo')}="" entityId="{_.get(collaborator," 'id')}="" className="{" index="==" &&="" team.manager="" ?="" classes.avatarFirst="" :="" classes.avatar="" }="" fallbackName="{" _.get(collaborator,="" 'photo')="" ''="" 'fullname')="" ||="" 'rank')="" +="" fontSize="{12}" color="{'#555'}" borderColor="{" _.get(team,="" 'color.hex')="" tooltip="{_.get(collaborator," 'fullname')}=""></avatar>
                ),
              )}

              {((team.collaborators_count &&
                team.collaborators_count > avatarLimit) ||
                collaboratorList.length > avatarLimit) && (
                <avatar 1="" rawFallbackName="{`+" ${="" team.collaborators_count="" ?="" +="" -="" avatarLimit="" :="" collaboratorList.length="" }`}="" className="{classes.avatar}" backgroundColor="{'white'}" fontSize="{12}" color="{'#555'}"></avatar>
              )}
            </avatargroup>
          </grid>
        )}
      </grid>
    </div>
  );
};

export default withStyles(styles)(TeamNode);
