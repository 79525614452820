import { List } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    marginLeft: 32,
    marginTop: 15,
    marginRight: 32,
    padding: 0,
  },
};

export default withStyles(styles)(List);
