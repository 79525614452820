import { createTheme } from '@material-ui/core/styles';
import {
  appBarStyles,
  avatarStyles,
  buttonStyles,
  cardStyles,
  dialogStyles,
  drawerStyles,
  iconButtonStyles,
  linkStyles,
  listItemStyles,
  stepperStyles,
  tabsStyles,
  typographyStyles,
  textFieldStyles,
  tableDataStyles,
  paginationStyles,
  selectStyles,
  paperStyles,
  chipStyles,
  accordionStyles,
  alertStyles,
  checkboxStyles,
  switchStyles,
  menuStyles,
} from './styles';

const useEdenredTheme = (cssBaselineStyles, configObject) =>
  createTheme({
    ...typographyStyles(configObject),
    overrides: {
      ...cssBaselineStyles,
      ...appBarStyles,
      ...avatarStyles,
      ...buttonStyles,
      ...cardStyles,
      ...dialogStyles,
      ...drawerStyles,
      ...iconButtonStyles,
      ...linkStyles,
      ...listItemStyles(configObject),
      ...stepperStyles,
      ...tabsStyles,
      ...textFieldStyles,
      ...tableDataStyles,
      ...paginationStyles,
      ...selectStyles,
      ...paperStyles,
      ...chipStyles,
      ...accordionStyles,
      ...alertStyles,
      ...checkboxStyles,
      ...switchStyles,
      ...menuStyles,
    },
  });
export default useEdenredTheme;
