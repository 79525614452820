import React from 'react';
import { Grid, isWidthUp, withWidth } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { DefaultTitle, DefaultText } from '../../..';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const styles = {
  link: {
    textDecoration: 'none',
  },
  wrapper: {
    background: '#EEF3F7',
  },
};

const LinkPreview = ({ openGraph, classes, width, ...props }) => {
  const isDesktop = isWidthUp('md', width);
  const target = isDesktop ? { target: '_blank' } : {};
  return (
    <div className="{classes.wrapper}">
      <a href="{openGraph.url}" className="{classes.link}" {...target}="">
        <grid container="">
          {openGraph.image && (
            <grid item="" xs="{12}">
              <img src="{openGraph.image}" style="{{" width:="" '100%'="" }}="">
            </grid>
          )}
          <grid item="" xs="{12}" container="" style="{{" padding:="" '4px="" 10px'="" }}="">
            <grid item="" xs="{12}">
              <defaulttitle lowercase="" style="{{" overflowWrap:="" 'anywhere'="" }}="">
                {openGraph.title}
              </defaulttitle>
            </grid>
            <grid item="" xs="{12}">
              <defaulttext 11="" lowercase="" style="{{" fontSize:="" }}="">
                {openGraph.site_name}
              </defaulttext>
            </grid>
          </grid>
        </grid>
      </a>
    </div>
  );
};

export default withStyles(styles)(withWidth()(LinkPreview));
