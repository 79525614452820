import React, { useContext, useEffect, useState } from 'react';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Link } from 'react-router-dom';
import { Grid, CardMedia } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountDown, faGift } from '@fortawesome/free-solid-svg-icons';

import _ from 'lodash';
import {
  FixedTableCell,
  FlexibleTableCell,
  RankEvolution,
  Table,
  TableBody,
  TableChip,
  TableHead,
  TableHeadCell,
  TableRow,
  TableRowHighlight,
  FullTableCell,
  Avatar,
  Tooltip,
  Card,
  ThemeWrapper,
} from '../../../../components';
import { ChallengeReward } from '../';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

const styles = {
  avatarLink: {
    '& a ': {
      textDecoration: 'none',
    },
  },
  photo: {
    height: 28,
    width: 28,
  },
  levelIcon: {
    height: 28,
    width: 28,
  },
  tooltip: {
    minWidth: 320,
  },
  transparentTooltip: {
    background: 'transparent',
  },
  tableWrapper: {
    boxShadow: '0 2px 16px 0 rgba(16,61,92,0.25)',
    overflowX: 'auto',
    position: 'relative',
  },
  positionCell: {
    paddingLeft: 5,
    paddingRight: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

const CollaboratorChallengeRankList = ({
  ranks,
  collaboratorId,
  teamId,
  displayCollaboratorLevel,
  displayCollaboratorDepartment,
  displayCollaboratorTeam,
  hideHeader,
  configList,
  ...props
}) => {
  const intl = useIntl();
  const { classes } = props;

  const teamColors = ranks
    ? _.compact(ranks.map(rank => _.get(rank, 'collaborator.team.color.hex')))
    : [];
  const colspan = teamColors.length > 0 ? 2 : 1;
  const hasRanking = ranks.reduce((acc, rank) => rank.rank || acc, false);

  const hasRankAward = rank =>
    rank.awards.length > 0 &&
    ((rank.award_type_code === 'C' && rank.race_position) ||
      rank.award_type_code === 'R');

  const hasAwards = ranks.reduce(
    (acc, rank) => hasRankAward(rank) || acc,
    false,
  );

  const { successColor, errorColor } = useContext(ThemeWrapper.Context);

  const mobileScreen = isWidthDown('xs', props.width);

  const coinImage = require(
    `../../../../assets/img/system/challenge/icons/coin.png`,
  );
  const giftImage = require(
    `../../../../assets/img/system/challenge/icons/gift.png`,
  );
  const cellWidth = mobileScreen ? 100 : 'auto';

  let borderTop = false;
  return (
    <div className="{classes.tableWrapper}">
      <table>
        {!hideHeader && (
          <tablehead>
            <tablerow>
              <tableheadcell colspan="{colspan}">
                <fontawesomeicon icon="{faSortAmountDown}"></fontawesomeicon>
              </tableheadcell>
              {displayCollaboratorLevel && (
                <tableheadcell colSpan="{2}">
                  {intl.formatMessage({ id: 'challenge.ranking.level_column' })}
                </tableheadcell>
              )}
              <tableheadcell colSpan="{2}">
                {intl.formatMessage({
                  id: 'challenge.ranking.collaborator_column',
                })}
              </tableheadcell>
              {displayCollaboratorTeam && (
                <tableheadcell colSpan="{1}">
                  {intl.formatMessage({
                    id: 'challenge.ranking.collaborator_team_column',
                  })}
                </tableheadcell>
              )}
              {displayCollaboratorDepartment && (
                <tableheadcell colSpan="{1}">
                  {intl.formatMessage({
                    id: 'challenge.ranking.collaborator_team_group_column',
                  })}
                </tableheadcell>
              )}
              <tableheadcell>
                <grid container="" justify="center">
                  <grid 5="" item="" style="{{" marginRight:="" }}="">
                    <fontawesomeicon icon="{faGift}"></fontawesomeicon>
                  </grid>
                </grid>
              </tableheadcell>
              <tableheadcell style="{{" textAlign:="" 'center'="" }}="">
                {intl.formatMessage({ id: 'challenge.ranking.points_column' })}
              </tableheadcell>
            </tablerow>
          </tablehead>
        )}
        <tablebody>
          {ranks.map((rank, index) => {
            const photo = rank.collaborator.photo
              ? rank.collaborator.photo
              : '/assets/img/user/avatar.svg';
            const selected = rank.collaborator
              ? rank.collaborator.id == collaboratorId ||
                _.get(rank, 'collaborator.team.id') == teamId
              : false;
            const hasAward = hasRankAward(rank);
            const color = !selected && !hasAward ? 'default' : 'primary';
            const teamColor = _.get(rank, 'collaborator.team.color.hex');
            const isRaceMode = rank.award_type_code === 'C';

            if (
              hasAwards &&
              !hasAward &&
              hasRanking &&
              index > 0 &&
              borderTop === false
            ) {
              borderTop = index;
            }

            const TableRowComponent = hasAward ? TableRowHighlight : TableRow;
            const pointEvolution = rank.points - rank.previous_points;
            const positionAbsolute = rank.fixed ? { position: 'absolute' } : {};
            return (
              <tablerowcomponent key="{rank.id}" style="{{" borderTop:="" borderTop="==" index="" ?="" '2px="" solid="" #333'="" :="" '',="" background:="" selected="" '#E4F6E0'="" 'auto',="" ...positionAbsolute,="" }}="">
                <fulltablecell 4="" style="{{" backgroundColor:="" teamColor,="" width:="" 4,="" minWidth:="" }}=""></fulltablecell>
                <fulltablecell>
                  <div 76="" 90="" className="{classes.positionCell}" style="{{" width:="" mobileScreen="" ?="" :="" }}="">
                    <span>
                      <tablechip color="default" label="{rank.rank" ?="" rank.rank="" :="" '-'}=""></tablechip>
                    </span>

                    {rank.evolution !== 0 && (
                      <react.fragment>
                        <span 8="" style="{{" marginLeft:="" }}="">
                          <rankevolution evolution="{rank.evolution}"></rankevolution>
                        </span>
                        <span style="{{" marginLeft:="" 3,="" fontWeight:="" hasAward="" ?="" 'bold'="" :="" '',="" color:="" rank.evolution=""> 0
                                ? successColor
                                : rank.evolution < 0
                                  ? errorColor
                                  : 'auto',
                          }}
                        >
                          {Math.abs(rank.evolution)}
                        </span>
                      </react.fragment>
                    )}
                  </div>
                </fulltablecell>
                {displayCollaboratorLevel && (
                  <react.fragment>
                    <fulltablecell style="{{" verticalAlign:="" 'middle'="" }}="">
                      {_.get(rank, 'collaborator.level.icon.path') && (
                        <div 10="" style="{{" paddingLeft:="" }}="">
                          <avatar src="{_.get(rank," 'collaborator.level.icon.path')}="" className="{classes.levelIcon}" entityId="{rank.collaborator.id}"></avatar>
                        </div>
                      )}
                    </fulltablecell>
                    <fulltablecell>
                      <span style="{{" fontSize:="" 15,="" paddingLeft:="" 5,="" paddingRight:="" 10,="" }}="">
                        {_.get(rank, 'collaborator.level.number')}
                      </span>
                    </fulltablecell>
                  </react.fragment>
                )}

                <fixedtablecell style="{{" paddingRight:="" 0,="" verticalAlign:="" 'middle',="" width:="" 50,="" }}="">
                  <div className="{classes.avatarLink}">
                    {_.get(rank, 'collaborator.team.id') ? (
                      <link to="{`/teams/${_.get(" rank,="" 'collaborator.team.id',="" )}="" collaborators="" ${_.get(="" 'collaborator.id',="" detail`}="">
                        <avatar src="{photo}" className="{classes.photo}" entityId="{rank.collaborator.id}" fallbackName="{rank.collaborator.fullname}"></avatar>
                      
                    ) : (
                      <avatar src="{photo}" className="{classes.photo}" entityId="{rank.collaborator.id}" fallbackName="{rank.collaborator.fullname}"></avatar>
                    )}
                  </div>
                </fixedtablecell>

                <flexibletablecell style="{{" fontWeight:="" hasAward="" ?="" 'bold'="" :="" '',="" minWidth:="" cellWidth,="" maxWidth:="" }}="" color="{color}">
                  {mobileScreen ? (
                    <react.fragment>
                      <div style="{{" whiteSpace:="" 'nowrap',="" overflow:="" 'hidden',="" textOverflow:="" 'ellipsis',="" }}="">
                        {rank.collaborator.firstname}
                      </div>
                      <div style="{{" whiteSpace:="" 'nowrap',="" overflow:="" 'hidden',="" textOverflow:="" 'ellipsis',="" }}="">
                        {rank.collaborator.lastname}
                      </div>
                    </react.fragment>
                  ) : (
                    <react.fragment>
                      <div style="{{" whiteSpace:="" 'nowrap',="" overflow:="" 'hidden',="" textOverflow:="" 'ellipsis',="" }}="">
                        {rank.collaborator.firstname}{' '}
                        {rank.collaborator.lastname}
                      </div>
                    </react.fragment>
                  )}
                </flexibletablecell>
                {displayCollaboratorTeam && (
                  <flexibletablecell style="{{" fontWeight:="" hasAward="" ?="" 'bold'="" :="" '',="" minWidth:="" cellWidth,="" maxWidth:="" whiteSpace:="" 'normal',="" }}="" color="{color}">
                    {_.get(rank, 'collaborator.team.name')}
                  </flexibletablecell>
                )}
                {displayCollaboratorDepartment && (
                  <flexibletablecell style="{{" fontWeight:="" hasAward="" ?="" 'bold'="" :="" '',="" minWidth:="" cellWidth,="" maxWidth:="" whiteSpace:="" 'normal',="" }}="" color="{color}">
                    {_.get(rank, 'collaborator.team.parent.name')}
                  </flexibletablecell>
                )}
                <fixedtablecell style="{{" verticalAlign:="" 'middle'="" }}="" color="{color}">
                  {rank.awards && rank.awards.length > 0 && (
                    <grid container="" justify="center">
                      {rank.awards[0].reward ? (
                        <grid item="">
                          <tooltip 320="" className="{`${classes.tooltip}" ${classes.transparentTooltip}`}="" title="{" <div="" style="{{" minWidth:="" }}="">
                                <card>
                                  <challengereward reward="{rank.awards[0].reward}"></challengereward>
                                </card>
                              </tooltip></grid></grid></fixedtablecell></tablerowcomponent></tablebody></table></div>
                            }
                          >
                            <cardmedia image="{giftImage}" style="{{" height:="" 20,="" width:="" marginRight:="" 5,="" marginTop:="" -2,="" }}=""></cardmedia>
                          
                        
                      ) : (
                        <grid item="">
                          <tooltip title="{" <Grid="" container="" spacing="{1}">
                                <grid item="">{rank.awards[0].points}</grid>
                                <grid item="">
                                  <cardmedia 20="" image="{coinImage}" style="{{" height:="" 20,="" width:="" }}=""></cardmedia>
                                </grid>
                              </tooltip></grid>
                            }
                          >
                            <cardmedia image="{coinImage}" style="{{" height:="" 20,="" width:="" marginRight:="" 5,="" marginTop:="" -2,="" }}=""></cardmedia>
                          
                        
                      )}
                    
                  )}
                
                <flexibletablecell style="{{" fontWeight:="" hasAward="" ?="" 'bold'="" :="" '',="" textAlign:="" 'center',="" verticalAlign:="" 'middle',="" }}="" color="{color}">
                  <div>
                    {rank.points.toLocaleString()}
                    {isRaceMode ? `/${rank.goals_count}` : ''}
                  </div>
                  {pointEvolution !== 0 && (
                    <div style="{{" fontWeight:="" hasAward="" ?="" 'bold'="" :="" '',="" color:="" pointEvolution=""> 0
                            ? successColor
                            : pointEvolution < 0
                              ? errorColor
                              : 'auto',
                      }}
                    >
                      {pointEvolution > 0 ? '+' : pointEvolution < 0 ? '-' : ''}
                      <span 3="" style="{{" marginLeft:="" }}="">
                        {Math.abs(pointEvolution).toLocaleString()}
                      </span>
                    </div>
                  )}
                </flexibletablecell>
              
            );
          })}
        
      
    