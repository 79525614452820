import React from 'react';
import { BoldTitle, DefaultTitle } from '../../..';
import { CardMedia, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  icon: {
    width: 120,
    height: 120,
  },
  bigIcon: {
    width: 160,
    height: 160,
  },
};

const Category = ({ category, ...props }) => {
  const { classes, bigIcon } = props;
  return (
    <div>
      <grid container="" spacing="{1}" alignItems="center" direction="column">
        <grid item="">
          <cardmedia image="{category.icon}" className="{bigIcon" ?="" classes.bigIcon="" :="" classes.icon}=""></cardmedia>
        </grid>
        <grid item="">
          <boldtitle align="center" isContrast="">
            {category.name}
          </boldtitle>
        </grid>
      </grid>
    </div>
  );
};

export default withStyles(styles)(Category);
