import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { AccentText, DefaultText } from '../../../../../../components';
import { useIntl } from 'react-intl';
import '../../../../../../helpers/StringHelper';

const LevelCondition = ({ level, ...props }) => {
  const intl = useIntl();
  const { collaborator } = props.collaboratorDetail;
  const hasLevel = collaborator
    ? level.level <= collaborator.level.number
    : false;
  const icon = hasLevel ? faUnlock : faLock;
  const text = (
    <div>
      <fontawesomeicon icon="{icon}"></fontawesomeicon>{' '}
      {intl
        .formatMessage({ id: 'badge.level.condition_text' })
        .format(level.level)}
    </div>
  );
  return (
    <div>
      {hasLevel && (
        <accenttext 15="" style="{{" textTransform:="" 'none',="" fontSize:="" }}="">
          {text}
        </accenttext>
      )}
      {!hasLevel && (
        <accenttext lowercase="" style="{{" textTransform:="" 'none',="" fontSize:="" 22,="" fontWeight:="" 'bold'="" }}="">
          {text}
        </accenttext>
      )}
    </div>
  );
};

const mapStateToProps = ({ collaboratorDetail }) => ({
  collaboratorDetail,
});

export default connect(mapStateToProps)(LevelCondition);
