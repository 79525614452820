import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  success: false,
  loading: false,
  error: null,
};

const createGetParticipantsListSlice = name =>
  createSlice({
    name: name,
    initialState,
    reducers: {
      fetchStart: state => {
        state.success = false;
        state.loading = true;
        state.error = null;
      },
      fetchSuccess: (state, data) => {
        state.data = data.payload.data;
        state.success = true;
        state.loading = false;
        state.error = null;
      },
      fetchFailure: (state, action) => {
        state.success = false;
        state.loading = false;
        state.error = action.payload;
      },
      clear: () => initialState,
    },
  });

export const getParticipantsListKeySlice = createGetParticipantsListSlice(
  'getParticipantsListKey',
);

export const {
  fetchStart: getParticipantsListKeyStart,
  fetchSuccess: getParticipantsListKeySuccess,
  fetchFailure: getParticipantsListKeyFailure,
  clear,
} = getParticipantsListKeySlice.actions;

export default {
  getParticipantsKey: getParticipantsListKeySlice.reducer,
};
