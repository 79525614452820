import { ListItemIcon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    minWidth: 'initial',
    marginRight: 16,
  },
};

export default withStyles(styles)(ListItemIcon);
