import React from 'react';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Link } from 'react-router-dom';
import { Grid, CardMedia } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAmountDown,
  faRandom,
  faCheck,
  faFlagCheckered,
  faGift,
  faChartLine,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {
  FixedTableCell,
  FlexibleTableCell,
  RankEvolution,
  Table,
  TableBody,
  TableCell,
  TableChip,
  TableHead,
  TableHeadCell,
  TableRow,
  TableRowHighlight,
  FullTableCell,
  Avatar,
  DefaultText,
  Tooltip,
  Card,
  GridLink,
  AccentText,
} from '../../../../components';
import { ChallengeReward } from '../';
import { useIntl } from 'react-intl';

const styles = {
  wrapper: {
    background: 'white',
    padding: '7px 10px',
    alignItems: 'center',
    position: 'relative',
    height: 50,
    overflow: 'visible',
    borderRadius: 5,
    boxShadow: '0 2px 16px 0 rgba(16,61,92,0.25)',
  },
  photoWrapper: {
    border: '2px solid #00E58D',
    borderRadius: 35,
  },
  photo: {
    width: 45,
    height: 45,
    border: '2px solid transparent',
  },
};

const CollaboratorChallengeCurrentRank = ({ rank, challenge, ...props }) => {
  const intl = useIntl();
  const { classes } = props;

  const mobileScreen = isWidthDown('xs', props.width);

  const coinImage = require(
    `../../../../assets/img/system/challenge/icons/coin.png`,
  );
  const giftImage = require(
    `../../../../assets/img/system/challenge/icons/gift.png`,
  );
  const cellWidth = mobileScreen ? 100 : 'auto';

  let borderTop = false;

  const photo = rank.collaborator.photo
    ? rank.collaborator.photo
    : '/assets/img/user/avatar.svg';
  const selected = true;
  const hasAward =
    rank.awards.length > 0 &&
    ((rank.award_type_code === 'C' && rank.race_position) ||
      rank.award_type_code === 'R');
  const color = !selected && !hasAward ? 'default' : 'primary';
  const teamColor = _.get(rank, 'collaborator.team.color.hex');
  const isRaceMode = rank.award_type_code === 'C';

  return (
    <div className="{classes.wrapper}">
      <div 20="" style="{{" position:="" 'absolute',="" top:="" -20,="" left:="" }}="">
        {_.get(rank, 'collaborator.team.id') ? (
          <link to="{`/teams/${_.get(" rank,="" 'collaborator.team.id',="" )}="" collaborators="" ${_.get(rank,="" 'collaborator.id')}="" detail`}="">
            <div className="{classes.photoWrapper}">
              <avatar src="{photo}" className="{classes.photo}" entityId="{rank.collaborator.id}" fallbackName="{rank.collaborator.fullname}"></avatar>
            </div>
          
        ) : (
          <div className="{classes.photoWrapper}">
            <avatar src="{photo}" className="{classes.photo}" entityId="{rank.collaborator.id}" fallbackName="{rank.collaborator.fullname}"></avatar>
          </div>
        )}
      </div>
      <span 35="" style="{{" position:="" 'absolute',="" top:="" 15,="" left:="" }}="">
        <tablechip color="default" label="{rank.rank" ?="" rank.rank="" :="" '-'}=""></tablechip>
      </span>
      <grid container="" spacing="{2}" style="{{" position:="" 'absolute',="" top:="" 8,="" left:="" 80,="" alignItems:="" 'baseline',="" }}="">
        <grid 2="" item="" style="{{" marginTop:="" }}="">
          <defaulttext 16="" lowercase="" style="{{" fontSize:="" }}="">
            <span style="{{" fontWeight:="" 'bold'="" }}="">Score : </span>
            <span>{rank.points}</span>
          </defaulttext>
        </grid>
        <grid item="">
          <grid container="" spacing="{1}" style="{{" alignItems:="" 'center'="" }}="">
            <grid item="">
              <defaulttext lowercase="" style="{{" fontSize:="" 16,="" fontWeight:="" 'bold'="" }}="">
                {intl.formatMessage({ id: 'challenge.awards_title' })} :
              </defaulttext>
            </grid>

            {rank.awards && rank.awards.length === 0 && (
              <grid item="" style="{{" position:="" 'relative'="" }}="">
                <tooltip title="Aucun gain remporté">
                  <div>
                    <accenttext lowercase="" style="{{" position:="" 'absolute',="" top:="" -5,="" left:="" 15,="" color:="" '#00E58D',="" zIndex:="" 100,="" }}="">
                      <fontawesomeicon icon="{faLock}"></fontawesomeicon>
                    </accenttext>
                    <div style="{{" filter:="" 'grayscale(1)'="" }}="">
                      {_.get(challenge, 'rewardTypeCode') === 'G' ? (
                        <cardmedia 5="" image="{giftImage}" style="{{" height:="" 20,="" width:="" marginRight:="" }}=""></cardmedia>
                      ) : (
                        <cardmedia 5="" image="{coinImage}" style="{{" height:="" 20,="" width:="" marginRight:="" }}=""></cardmedia>
                      )}
                    </div>
                  </div>
                </tooltip>
              </grid>
            )}
            {rank.awards && rank.awards.length > 0 && (
              <react.fragment>
                {rank.awards[0].reward ? (
                  <grid item="">
                    <tooltip 320="" className="{`${classes.tooltip}" ${classes.transparentTooltip}`}="" title="{" <div="" style="{{" minWidth:="" }}="">
                          <card>
                            <challengereward reward="{rank.awards[0].reward}"></challengereward>
                          </card>
                        </tooltip></grid></react.fragment></grid></grid></grid></div>
                      }
                    >
                      <cardmedia 5="" image="{giftImage}" style="{{" height:="" 20,="" width:="" marginRight:="" }}=""></cardmedia>
                    
                  
                ) : (
                  <grid item="">
                    <tooltip title="{" <Grid="" container="" spacing="{1}">
                          <grid item="">{rank.awards[0].points}</grid>
                          <grid item="">
                            <cardmedia 20="" image="{coinImage}" style="{{" height:="" 20,="" width:="" }}=""></cardmedia>
                          </grid>
                        </tooltip></grid>
                      }
                    >
                      <cardmedia 5="" image="{coinImage}" style="{{" height:="" 20,="" width:="" marginRight:="" }}=""></cardmedia>
                    
                  
                )}
              
            )}
          
        
      
    