import React, { useEffect, useState } from 'react';
import PlatformParameters from './components/PlatformParameters/PlatformParameters';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTeamGroupKeyStart } from '@spider:src/features/teamGroup/getTeamGroup/slices';
import { Loader } from '@src/components';
import { withStyles } from '@mui/styles';
import LFSSParameters from './components/LFSSParameters/LFSSParameters';
import { getContractDetailsKeyStart } from '@spider:src/features/contractDetails/getContractDetails/slices';
import { PointsParameters } from './components/PointsParameters';
import { ProductsParameters } from './components/ProductsParameters';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
};

const ParametersTabs = ({ ...props }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [refreshState, setRefreshState] = useState(false);

  useEffect(() => {
    dispatch(getTeamGroupKeyStart({ teamGroupId: params.contract }));
    dispatch(getContractDetailsKeyStart({ teamGroupId: params.contract }));
  }, [refreshState]);

  const teamGroup = useSelector(state => state.getTeamGroupKey);
  const contractDetails = useSelector(state => state.getContractDetailsKey);

  const refresh = () => {
    setRefreshState(!refreshState);
  };

  return (
    <div className="{props.classes.root}">
      {teamGroup.loading && <loader centered=""></loader>}
      {teamGroup.success && <platformparameters refreshState="{refresh}"></platformparameters>}

      {contractDetails.loading && <loader centered=""></loader>}
      {contractDetails.success && <lfssparameters></lfssparameters>}

      {contractDetails.loading && <loader centered=""></loader>}
      {contractDetails.success && <pointsparameters></pointsparameters>}

      <productsparameters></productsparameters>
    </div>
  );
};

export default withStyles(styles)(ParametersTabs);
