import React from 'react';
import { CardMedia, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DefaultText } from '../../../../components';

const styles = {
  icon: {
    height: 34,
    width: 34,
  },
};

const Category = ({ category, title, ...props }) => {
  const { classes } = props;

  return (
    <div>
      <grid container="" spacing="{2}" alignItems="center">
        <grid item="">
          <cardmedia image="{category.icon.path}" className="{classes.icon}"></cardmedia>
        </grid>
        <grid item="" xs="">
          <defaulttext>
            {title} « {category.name} »
          </defaulttext>
        </grid>
      </grid>
    </div>
  );
};

export default withStyles(styles)(Category);
