import { ScreenRightDialog } from '../ScreenRightDialog';
import { DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Button } from '@spider:src/components';
import { FileInputDragAndDrop } from '@spider:src/components/FileInputDragAndDrop';
import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { CloseButtonIcon } from '@spider:src/components/CloseButtonIcon';

const styles = {
  dialogTitle: {
    display: 'flex !important',
    flexDirection: 'row-reverse !important',
    padding: '1rem !important',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '0rem 2.5rem 2.5rem 2.5rem !important',
    gap: '1rem',
  },
  previewImage: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    borderRadius: '16px',
  },
  image: {
    overflow: 'clip',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
};

const fileToDataUri = file =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = event => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

const RightDialogFileInput = ({
  open,
  setOpen,
  title,
  onValid,
  minSize,
  maxSize,
  multiple,
  authorizedFileTypes,
  alertComponent = () => <></>,
  ...props
}) => {
  const intl = useIntl();
  const [files, setFiles] = useState([]);
  const [dataUri, setDataUri] = useState('');

  const clearState = () => {
    setFiles([]);
    setDataUri('');
  };

  const fileChanged = files => {
    clearState();

    if (!files || files.find(file => !file) !== undefined) return;

    if (!multiple && files[0].type.includes('image')) {
      fileToDataUri(files[0]).then(dataUri => {
        setDataUri(dataUri);
      });
    }

    setFiles(files);
  };

  const onClose = () => {
    clearState();
    setOpen(false);
  };

  const onSubmitFile = () => {
    onValid(files);
    onClose();
  };

  return (
    <screenrightdialog open="{open}" onClose="{onClose}" direction="{'right'}">
      <dialogtitle className="{props.classes.dialogTitle}">
        <closebuttonicon onClick="{onClose}"></closebuttonicon>
      </dialogtitle>
      <dialogcontent className="{props.classes.dialogContent}">
        <typography variant="h2" component="h2" align="{'center'}">
          {title}
        </typography>

        {alertComponent()}

        <fileinputdraganddrop minSize="{minSize}" maxSize="{maxSize}" multiple="{multiple}" authorizedFileTypes="{authorizedFileTypes}" fileOrFilesChanged="{fileChanged}"></fileinputdraganddrop>
        {!multiple && files[0] && files[0].type.includes('image') && (
          <div className="{props.classes.previewImage}">
            <img alt="{intl.formatMessage({" id:="" 'spider.imageAlt.preview'="" })}="" src="{dataUri}" width="{'100%'}" className="{props.classes.image}">
          </div>
        )}
        <div className="{props.classes.actions}">
          {files && files.length > 0 && (
            <button color="{'primary'}" variant="{'contained'}" size="{'small'}" onClick="{()" ==""> onSubmitFile()}
            >
              {intl.formatMessage({ id: 'spider.common.submit' })}
            </button>
          )}
        </div>
      </dialogcontent>
    </screenrightdialog>
  );
};

export default withStyles(styles)(RightDialogFileInput);
