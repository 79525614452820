const systemColors = {
  errorBg: '#FFF4F3',
  errorRegular: '#DC150D',
  errorDark: '#BD0D00',
  infoDark: '#0041AC',
  infoLight: '#298EFA',
  infoRegular: '#0768F4',
  successBg: '#D1FADF',
  successRegular: '#027A48',
  warningBg: '#FFF4E6',
  warningRegular: '#CA420D',
};

export default systemColors;
