import * as Formsy from 'formsy-react';
import lodash from 'lodash';

Formsy.addValidationRule('isConditionalRequired', function () {
  return false;
});

Formsy.addValidationRule('isAlreadyUsedIn', function (_, value, array) {
  return array.find(item => item === value) === undefined;
});

Formsy.addValidationRule('isLessThan', function (values, value, otherField) {
  const number = Number(otherField);
  if (!Number.isNaN(number)) {
    return Number(value) < number;
  } else if (otherField) {
    return Number(value) < Number(values[otherField]);
  } else {
    return true;
  }
});

Formsy.addValidationRule(
  'isLessThanOrEquals',
  function (values, value, otherField) {
    const number = Number(otherField);
    if (!Number.isNaN(number)) {
      return Number(value) <= number;
    } else if (otherField) {
      return Number(value) <= Number(values[otherField]);
    } else {
      return true;
    }
  },
);

Formsy.addValidationRule('isMoreThan', function (values, value, otherField) {
  const number = Number(otherField);
  if (!Number.isNaN(number)) {
    return Number(value) > number;
  } else if (otherField) {
    return Number(value) > Number(values[otherField]);
  } else {
    return true;
  }
});

Formsy.addValidationRule(
  'isMoreThanOrEquals',
  function (values, value, otherField) {
    const number = Number(otherField);
    if (!Number.isNaN(number)) {
      return Number(value) >= number;
    } else if (otherField) {
      return Number(value) >= Number(values[otherField]);
    } else {
      return true;
    }
  },
);

// Password validation

Formsy.addValidationRule('hasLowercaseCharacter', function (_, value) {
  if (!value) {
    return true;
  }
  const regex = /(?=.*[a-z])/;
  return regex.test(value);
});

Formsy.addValidationRule('hasUppercaseCharacter', function (_, value) {
  if (!value) {
    return true;
  }
  const regex = /(?=.*[A-Z])/;

  return regex.test(value);
});

Formsy.addValidationRule('hasSpecialCharacter', function (_, value) {
  if (!value) {
    return true;
  }
  const regex = /(?=.*[^A-Za-z0-9])/;
  return regex.test(value);
});

Formsy.addValidationRule('hasDigitCharacter', function (_, value) {
  if (!value) {
    return true;
  }
  const regex = /(?=.*[0-9])/;
  return regex.test(value);
});

Formsy.addValidationRule(
  'hasMoreCharactersThan',
  function (values, value, minimum) {
    if (!value) {
      return true;
    }
    const regex = new RegExp(`(?=.{${minimum},})`);
    return regex.test(value);
  },
);

Formsy.addValidationRule('hasLessCharactersThan', function (_, value, maximum) {
  if (!value) {
    return true;
  }

  const regex = new RegExp(`^(?=.{1,${maximum}}$).*`);
  return regex.test(value);
});

Formsy.addValidationRule('isNumeric', function (_, value) {
  if (!value) {
    return true;
  }

  return !isNaN(lodash.toString(value).replace(',', '.'));
});

Formsy.addValidationRule('isPositive', (_, value) => parseFloat(value) >= 0);

Formsy.addValidationRule('isTrue', function (_, value) {
  return value == true;
});

Formsy.addValidationRule('isSlug', function (_, value) {
  const slugRegex = /^[a-z0-9]*(?:-[a-z0-9]+)*$/;
  if (!value) {
    return true;
  }
  return slugRegex.test(value);
});

Formsy.addValidationRule('isPhoneNumber', function (values, value, otherField) {
  if (!value) {
    return false;
  }
  return (
    value.length === 10 && (value.startsWith('06') || value.startsWith('07'))
  );
});

Formsy.addValidationRule(
  'isDateLessThan',
  function (values, value, otherField) {
    const date = new Date(value);
    if (!value || !otherField) {
      return false;
    }
    return date.getTime() < otherField.getTime();
  },
);

const securitySocialRangeEqualityValidator = (
  values,
  value,
  { length, equalsValue, index },
) => {
  if (!value) {
    return true;
  }
  if (!equalsValue || equalsValue.length === 0) {
    return true;
  }
  return value.substring(index, length) === equalsValue;
};

Formsy.addValidationRule(
  'securitySocialSecondRange',
  (values, value, equalsValue) => {
    const date = new Date(equalsValue);
    if (isNaN(date.valueOf())) {
      return true;
    }
    return securitySocialRangeEqualityValidator(values, value, {
      index: 1,
      length: 3,
      equalsValue: date.getFullYear().toString().substring(2, date.length),
    });
  },
);
Formsy.addValidationRule(
  'securitySocialThirdRange',
  (values, value, equalsValue) => {
    const date = new Date(equalsValue);
    if (isNaN(date.valueOf())) {
      return true;
    }
    const monthOneBased = date.getMonth() + 1;
    return securitySocialRangeEqualityValidator(values, value, {
      index: 3,
      length: 5,
      equalsValue:
        monthOneBased <= 9
          ? `0${monthOneBased.toString()}`
          : monthOneBased.toString(),
    });
  },
);
Formsy.addValidationRule(
  'securitySocialFourthRange',
  (values, value, equalsValue) =>
    securitySocialRangeEqualityValidator(values, value, {
      index: 5,
      length: 7,
      equalsValue,
    }),
);
Formsy.addValidationRule(
  'securitySocialFifthRange',
  (values, value, equalsValue) =>
    securitySocialRangeEqualityValidator(values, value, {
      index: 7,
      length: 10,
      equalsValue,
    }),
);
