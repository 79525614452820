export const GET_EMPTY_COLLABORATOR_GOAL_SUMMARY_LIST =
  'GET_EMPTY_COLLABORATOR_GOAL_SUMMARY_LIST';
export const GET_COLLABORATOR_GOAL_SUMMARY_LIST =
  'GET_COLLABORATOR_GOAL_SUMMARY_LIST';
export const GET_COLLABORATOR_GOAL_SUMMARY_LIST_BY_DEFINITION_AND_COLLABORATOR =
  'GET_COLLABORATOR_GOAL_SUMMARY_LIST_BY_DEFINITION_AND_COLLABORATOR';
export const GET_COLLABORATOR_GOAL_SUMMARY_LIST_ERROR =
  'GET_COLLABORATOR_GOAL_SUMMARY_LIST_ERROR';
export const GET_COLLABORATOR_GOAL_SUMMARY_LIST_SUCCESS =
  'GET_COLLABORATOR_GOAL_SUMMARY_LIST_SUCCESS';
export const CLEAR_COLLABORATOR_GOAL_SUMMARY_LIST =
  'CLEAR_COLLABORATOR_GOAL_SUMMARY_LIST';
