import * as types from './actionTypes';

export const createReward = (reward, rewardImage) => ({
  type: types.CREATE_REWARD,
  reward,
  rewardImage,
});

export const createRewardSuccess = () => ({
  type: types.CREATE_REWARD_SUCCESS,
});

export const createRewardError = () => ({
  type: types.CREATE_REWARD_ERROR,
});

export const clearRewardCreation = () => ({
  type: types.CLEAR_REWARD_CREATION,
});
