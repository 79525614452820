import instance from '../instance';
import { appendSearchParams } from '../../../helpers/UrlHelper';
const baseUrl = 'configs/';

const configs = {
  permanent(teamGroup = 1, codes = [], period = null, legacy = false) {
    const url = legacy
      ? `${baseUrl}permanent/`
      : `hierarchy-nodes/${teamGroup}/config`; // @todo endpoint dans team_group

    const params = new URLSearchParams();
    if (codes && codes.length > 0) {
      codes.forEach(code => {
        params.append('codes', code);
      });
    }
    return instance.get(appendSearchParams(url, { period }), { params });
  },
  detail(code) {
    const url = `${baseUrl}${code}/`;
    return instance.get(url);
  },
  update(id, value) {
    const url = `${baseUrl}${id}/`;
    return instance.patch(url, {
      value: value != null && value.toString() != '' ? value.toString() : null,
    });
  },
};

export default configs;
