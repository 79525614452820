import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { RowsPerPageSelector } from '../RowsPerPageSelector';
import UsePagination from '../../hooks/UsePagination';
import { Pagination, CircularProgress } from '@mui/material';
import { Participant } from './components/Participant';
import { PointsBalance } from './components/PointsBalance';
import HierarchyNodeUserStatus from '../../components/HierarchyNodeUserStatus';
import { systemColors } from '../../themes';
import EmptyTableImage from '../../assets/img/empty-table-participants.png';
import { EmptyTableView } from '../EmptyTableView';
import { Button } from '@src/Spider/components';
import { ArrowRight } from 'iconsax-react';
import { Link } from 'react-router-dom';
import { useLazyGetHierarchyNodeUsersListQuery } from '@src/data/api/endpoints/participants';
import { ParticipantUrl } from '@src/Spider/enums/participantUrl';

export const ParticipantsList = () => {
  const intl = useIntl();
  const { contract } = useParams();
  const [fetch, { data, isLoading, isFetching, error }] =
    useLazyGetHierarchyNodeUsersListQuery();
  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = UsePagination({
    dataLength: data?.count ? data?.count : 0,
  });

  useEffect(() => {
    fetch({
      id: contract,
      include_point_balance: true,
      include_manager: true,
      page: pagination.page,
      page_size: pagination.rowsPerPage,
    });
  }, [pagination.page, pagination.rowsPerPage]);

  const columns = [
    'matricule',
    'participant',
    'manager',
    'points',
    'status',
  ].map(el => ({
    field: el,
    label: intl.formatMessage({
      id: `spider.participants.tabs.list.table.${el}`,
    }),
  }));

  return (
    <>
      <box display="flex" flexDirection="column">
        <box display="flex" justifyContent="space-between">
          <box>
            {isFetching && <circularprogress color="primary" size="1.2rem"></circularprogress>}
            {Boolean(error) && (
              <typography style="{{" color:="" systemColors.errorRegular="" }}="">
                {error?.message}
              </typography>
            )}
          </box>
          <rowsperpageselector rowsPerPage="{pagination.rowsPerPage}" handleChangeRowsPerPage="{handleChangeRowsPerPage}"></rowsperpageselector>
        </box>
        <tablecontainer component="{Paper}" style="{{" maxWidth:="" '100%',="" overflow:="" 'scroll',="" maxHeight:="" '50dvh'="" }}="">
          <table stickyHeader="">
            <tablehead>
              <tablerow>
                {(isLoading || Boolean(data?.count)) &&
                  columns.map(column => {
                    return (
                      <tablecell key="{column.field}" style="{{" wordBreak:="" 'keep-all',="" whiteSpace:="" 'nowrap'="" }}="">
                        {column.label}
                      </tablecell>
                    );
                  })}
                <tablecell style="{{" wordBreak:="" 'keep-all',="" whiteSpace:="" 'nowrap'="" }}=""></tablecell>
              </tablerow>
            </tablehead>
            <tablebody>
              {data?.count > 0 &&
                data.results.map(user => (
                  <tablerow key="{user.uuid}">
                    <tablecell>
                      <typography>{user.first_identifier}</typography>
                    </tablecell>
                    <tablecell>
                      <participant owner="{user}"></participant>
                    </tablecell>
                    <tablecell>
                      {user?.manager ? (
                        <typography style="{{" whiteSpace:="" 'nowrap'="" }}="">
                          {user.manager.firstname} {user.manager.lastname}
                        </typography>
                      ) : (
                        '--'
                      )}
                    </tablecell>
                    <tablecell>
                      <pointsbalance point_balance="{user.point_balance}"></pointsbalance>
                    </tablecell>
                    <tablecell>
                      <hierarchynodeuserstatus status="{user.status}"></hierarchynodeuserstatus>
                    </tablecell>
                    <tablecell>
                      <button variant="contained" color="primary" size="small" component="{Link}" to="{`/nodes/${contract}/users/${user.uuid}/${ParticipantUrl.DETAILS}`}">
                        <arrowright></arrowright>
                      </button>
                    </tablecell>
                  </tablerow>
                ))}
              {data?.count === 0 && (
                <tablerow>
                  <tablecell>
                    <emptytableview imageSrc="{EmptyTableImage}" imageAlt="Aucun utilisateur" title="spider.participants.tabs.list.table.no-data.title" description="spider.participants.tabs.list.table.no-data.description"></emptytableview>
                  </tablecell>
                </tablerow>
              )}
            </tablebody>
          </table>
        </tablecontainer>
        <box display="flex" width="100%" justifyContent="center" mt="1.5rem">
          <pagination count="{paginationCount}" onChange="{handleChangePage}" variant="outlined"></pagination>
        </box>
      </box>
    </>
  );
};
