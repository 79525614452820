import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { PlayerRanking, RankingFilter } from '../../components';
import {
  AppBarSubTitle,
  EmptyState,
  Loader,
  MainLayoutComponent,
  IconButton,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import { injectIntl } from 'react-intl';
import * as collaboratorListActions from '../../../../services/Collaborators/CollaboratorList/actions';
import * as collaboratorGeneralRankListActions from '../../../../services/CollaboratorGeneralRanks/CollaboratorGeneralRankList/actions';
import { Redirect } from 'react-router';

class CollaboratorGeneralRanking extends MainLayoutComponent {
  constructor(props) {
    super(props);

    this.state = {
      filterOpen: false,
    };
  }

  componentDidMount() {
    const { intl } = this.props;
    this.props.activateReturn();
    this.props.handleTitle(intl.formatMessage({ id: 'ranking.title' }));
    this.props.handleSubHeader(
      <appbarsubtitle title="{intl.formatMessage({" id:="" 'ranking.collaborator_general_title'="" })}=""></appbarsubtitle>,
    );
    this.props.handleButtons(
      <iconbutton size="small" onClick="{this.handleFilterOpen.bind(this)}">
        <fontawesomeicon icon="{faSlidersH}"></fontawesomeicon>
      </iconbutton>,
    );
    this.props.handleMaxWidth('md');
    this.props.collaboratorGeneralRankListActions.getCollaboratorGeneralRankList(
      this.props.match.params.period,
    );
    this.props.collaboratorListActions.getCollaboratorList();
  }

  handleFilterOpen() {
    this.setState({
      ...this.state,
      filterOpen: true,
    });
  }

  handleFilterClose() {
    this.setState({
      ...this.state,
      filterOpen: false,
    });
  }

  refresh(team) {
    const { collaborator, period } = this.props.match.params;

    var url = `/rankings/collaborators/${collaborator}/general/${period}`;
    if (team) url += `?team=${team}`;
    this.props.history.replace(url);
  }

  handleFilterChange(team) {
    this.refresh(team);
  }

  renderLoader() {
    return <loader centered=""></loader>;
  }

  renderEmptyState() {
    const { intl } = this.props;
    return (
      <emptystate title="{intl.formatMessage({" id:="" 'ranking.empty_state_title'="" })}=""></emptystate>
    );
  }

  renderData() {
    const { ranks } = this.props.collaboratorGeneralRankList;
    const { collaborators } = this.props.collaboratorList;
    const { match } = this.props;
    const collaboratorId = match.params.collaborator;

    const currentCollaborator = collaborators.find(
      collaborator => collaborator.id === parseInt(collaboratorId),
    );

    if (!currentCollaborator) {
      return this.renderEmptyState();
    }

    const isManager = currentCollaborator.role.code === 'M';

    const filteredCollaborators = collaborators.filter(collaborator => {
      return isManager
        ? collaborator.role.code === 'M'
        : collaborator.role.code === 'C';
    });

    const filteredCollaboratorIds = filteredCollaborators.map(c => c.id);

    const filteredRanks = ranks.filter(rank =>
      filteredCollaboratorIds.includes(rank.collaboratorId),
    );

    const params = new URLSearchParams(window.location.search);
    const team = params.get('team');
    return (
      <playerranking ranking="{" team="" ?="" filteredRanks.filter(="" rank="">
                  _.get(
                    filteredCollaborators.find(
                      c => c.id === _.get(rank, 'collaboratorId'),
                    ),
                    'team.id',
                  ) === parseInt(team),
              )
            : filteredRanks
        }
        collaboratorId={collaboratorId}
      />
    );
  }

  render() {
    const { account } = this.props.accountDetail;
    const { ranks, loading: ranksLoading } =
      this.props.collaboratorGeneralRankList;
    const { collaborators, loading: collaboratorLoading } =
      this.props.collaboratorList;
    const loading = ranksLoading || collaboratorLoading;

    if (!account.hasRankingAccess) {
      return <redirect to="{`/`}"></redirect>;
    }

    if (!account.hasGeneralRankAccess) {
      return <redirect to="/"></redirect>;
    }

    // Filter by team
    const params = new URLSearchParams(window.location.search);
    const team = params.get('team');

    return (
      <div>
        {loading && this.renderLoader()}
        {!loading &&
          collaborators &&
          ranks &&
          ranks.length > 0 &&
          this.renderData()}
        {!loading && ranks && ranks.length === 0 && this.renderEmptyState()}
        {this.state.filterOpen && (
          <rankingfilter open="{this.state.filterOpen}" onClose="{this.handleFilterClose.bind(this)}" onChange="{this.handleFilterChange.bind(this)}" team="{team}"></rankingfilter>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  accountDetail,
  collaboratorList,
  collaboratorGeneralRankList,
}) => ({
  accountDetail,
  collaboratorList,
  collaboratorGeneralRankList,
});

const mapDispatchToProps = dispatch => ({
  collaboratorListActions: bindActionCreators(
    collaboratorListActions,
    dispatch,
  ),
  collaboratorGeneralRankListActions: bindActionCreators(
    collaboratorGeneralRankListActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CollaboratorGeneralRanking));
</playerranking>