import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import { patchTeamGroupKeySlice } from './slices';
import { setHierarchyNodeUser } from '../../../../auth';

function* patchTeamGroup(slice, endpoint, action) {
  try {
    const data = yield call(endpoint, action.payload.teamGroup);

    // todo: faire une nouvelle saga PATCH et appeler la feature update hierarchyNodeUser ensuite en fonction de la props à update, en l'occurence "code"
    yield put(slice.actions.updateSuccess(data));
    if (action.payload.teamGroup.code) {
      const { data: hierarchyNode } = yield call(
        api.hierarchyNodes.get,
        data.data.code,
      );
      yield put(
        setHierarchyNodeUser(
          hierarchyNode._context.highest_hierarchy_node_user,
        ),
      );
    }
  } catch (error) {
    yield put(
      slice.actions.updateFailure(error?.response?.data?.error ?? 'UNKNOWN'),
    );
  }
}

function* watchPatchTeamGroup(slice, endpoint) {
  yield takeLatest(
    slice.actions.updateStart.type,
    patchTeamGroup,
    slice,
    endpoint,
  );
}

export default function* patchTeamGroupSaga() {
  yield all([
    watchPatchTeamGroup(
      patchTeamGroupKeySlice,
      api.teamGroups.hierarchyNodesPatch,
    ),
  ]);
}
