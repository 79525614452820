import React from 'react';
import { Redirect } from 'react-router-dom';
import { AnonymousLayout } from '../AnonymousLayout';
import { connect } from 'react-redux';

const IntermediateRoutes = ({ component: Component, ...props }) => {
  const { path } = props;
  const { account } = props.accountDetail;

  if (!account) {
    return <redirect to="/login"></redirect>;
  }

  if (
    account &&
    account.useTermsAccepted &&
    account.privacyPolicyAccepted &&
    !account.forceResetPassword
  ) {
    return <redirect to="/"></redirect>;
  }
  if (
    account &&
    (!account.useTermsAccepted || !account.privacyPolicyAccepted) &&
    path !== '/accept-terms'
  ) {
    return <redirect to="/accept-terms"></redirect>;
  }

  if (
    account &&
    account.useTermsAccepted &&
    account.privacyPolicyAccepted &&
    account.forceResetPassword &&
    path !== '/force-reset-password'
  ) {
    return <redirect to="/force-reset-password"></redirect>;
  }

  return <anonymouslayout exact="" path="{path}" component="{Component}"></anonymouslayout>;
};

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(IntermediateRoutes);
