import React from 'react';
import { Grid } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import {
  Collaborator,
  DefaultText,
  Dialog,
  DialogContent,
  DialogTitle,
  Avatar,
} from '../../../../../../components';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import _ from 'lodash';
import { useIntl } from 'react-intl';

const styles = {
  dialog: {
    width: 500,
    minWidth: 500,
  },
  avatar: {
    height: 50,
    width: 50,
  },
  userName: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  userTitle: {
    fontSize: 14,
    color: '#9B9B9B',
  },
};

const LikesList = ({ likes, open, setOpen, classes, width, ...props }) => {
  const isMobile = isWidthDown('sm', width);
  const intl = useIntl();
  return (
    <dialog open="{open}" onClose="{()" ==""> setOpen(false)}
      classes={{ paper: isMobile ? '' : classes.dialog }}
    >
      <dialogtitle onClose="{()" ==""> setOpen(false)}>
        {intl.formatMessage({ id: 'newsfeed.likes_list_title' })}
      </dialogtitle>
      <dialogcontent>
        <div 8="" style="{{" padding:="" }}="">
          <grid container="" spacing="{3}">
            {likes &&
              likes.map((like, index) => (
                <grid item="" xs="{12}" key="{index}" style="{{" borderBottom:="" index="" <="" likes.length="" ?="" '1px="" solid="" #ccc'="" :="" 'none',="" }}="">
                  <grid container="" alignItems="stretch" spacing="{2}">
                    <grid item="">
                      <avatar src="{like.user.photo}" className="{classes.avatar}" entityId="{like.user.id}" fallbackName="{like.user.fullname}" fontSize="{20}"></avatar>
                    </grid>
                    <grid item="" xs="">
                      <grid container="" justifyContent="stretch" spacing="{1}" alignItems="center" style="{{" height:="" '100%'="" }}="">
                        <grid item="" xs="{12}">
                          <div>
                            <defaulttext lowercase="" className="{classes.userName}">
                              {like.user.fullname}
                            </defaulttext>
                          </div>
                          {like.user.title && (
                            <div>
                              <defaulttext lowercase="" className="{classes.userTitle}">
                                {like.user.title}
                              </defaulttext>
                            </div>
                          )}
                        </grid>
                      </grid>
                    </grid>
                  </grid>
                </grid>
              ))}
          </grid>
        </div>
      </dialogcontent>
    </dialog>
  );
};

export default withStyles(styles)(withWidth()(LikesList));
