import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  success: false,
  loading: false,
  error: null,
};

const createPatchTeamGroupSlice = name =>
  createSlice({
    name: name,
    initialState,
    reducers: {
      updateStart: state => {
        state.success = false;
        state.loading = true;
        state.error = null;
      },
      updateSuccess: state => {
        state.success = true;
        state.loading = false;
        state.error = null;
      },
      updateFailure: (state, action) => {
        state.success = false;
        state.loading = false;
        state.error = action.payload;
      },
      clearUpdateTeamGroupState: () => initialState,
    },
  });

export const patchTeamGroupKeySlice =
  createPatchTeamGroupSlice('patchTeamGroupKey');

export const {
  updateStart: patchTeamGroupKeyStart,
  updateSuccess: patchTeamGroupKeySuccess,
  updateFailure: patchTeamGroupKeyFailure,
  clearUpdateTeamGroupState: patchTeamGroupKeyClear,
} = patchTeamGroupKeySlice.actions;

export default {
  patchTeamGroupKey: patchTeamGroupKeySlice.reducer,
};
