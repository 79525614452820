export const GET_EMPTY_TEAM_GOAL_SUMMARY_LIST =
  'GET_EMPTY_TEAM_GOAL_SUMMARY_LIST';
export const GET_TEAM_GOAL_SUMMARY_LIST_BY_COLLABORATOR =
  'GET_TEAM_GOAL_SUMMARY_LIST_BY_COLLABORATOR';
export const GET_TEAM_GOAL_SUMMARY_LIST_BY_DEFINITION_AND_COLLABORATOR =
  'GET_TEAM_GOAL_SUMMARY_LIST_BY_DEFINITION_AND_COLLABORATOR';
export const GET_TEAM_GOAL_SUMMARY_LIST_BY_DEFINITION_AND_TEAM =
  'GET_TEAM_GOAL_SUMMARY_LIST_BY_DEFINITION_AND_TEAM';
export const GET_TEAM_GOAL_SUMMARY_LIST_BY_TEAM =
  'GET_TEAM_GOAL_SUMMARY_LIST_BY_TEAM';
export const GET_TEAM_GOAL_SUMMARY_LIST_SUCCESS =
  'GET_TEAM_GOAL_SUMMARY_LIST_SUCCESS';
export const GET_TEAM_GOAL_SUMMARY_LIST_ERROR =
  'GET_TEAM_GOAL_SUMMARY_LIST_ERROR';
export const CLEAR_TEAM_GOAL_SUMMARY_LIST = 'CLEAR_TEAM_GOAL_SUMMARY_LIST';
