import React from 'react';
import { useAuth } from '../../../auth';
import { UserRoles } from '../../enums';
import { AdminHome } from './components/AdminHome';
import { UserHome } from './components/UserHome';
import { Container } from '@material-ui/core';

const Points = () => {
  const { hierarchyNodeUser } = useAuth();

  return (
    <container maxWidth="xl">
      {hierarchyNodeUser.role === UserRoles.ADMINISTRATOR && <adminhome></adminhome>}
      {hierarchyNodeUser.role === UserRoles.COLLABORATOR && <userhome></userhome>}
    </container>
  );
};

export default Points;
