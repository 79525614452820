import React from 'react';
import Linkify from 'react-linkify';

const CustomLinkify = ({ ...props }) => {
  return (
    <linkify componentDecorator="{(decoratedHref," decoratedText,="" key)=""> (
        <a target="_blank" href="{decoratedHref}" key="{key}">
          {decoratedText}
        </a>
      )}
    >
      {props.children}
    </linkify>
  );
};

export default CustomLinkify;
