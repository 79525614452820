import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { neutralColors } from '../../../../../../themes';
import { Link, Typography } from '@material-ui/core';
import { PointPriceRatio } from '../PointPriceRatio';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@material-ui/core/styles';
import { ReactComponent as PlusIndicator } from '../../../../../../assets/img/homeParticipantsConvert/plus-indicator.svg';
import { Button } from '../../../../../../components';
import { useIntl } from 'react-intl';
import HomeConvertPointPP from '../../../../../../config/home-convert-point-pp.json';
import HomeConvertPointPM from '../../../../../../config/home-convert-point-pm.json';
import UseRewardConvertCard from '../../../../../../hooks/UseRewardConvertCard';
import { Loader } from '../../../../../../../components';
import UseLinkFileToUserType from '../../../../../../hooks/UseLinkFileToUserType';
import { ThemeContentDialog } from '../ThemeContentDialog';
import { ThemeCard } from '../ThemeCard';

const styles = {
  root: {
    display: 'flex',
    paddingBottom: '2rem',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: '1rem',
    background: neutralColors.neutralWhite,
    boxShadow: '0px 6px 10px 2px rgba(10, 31, 94, 0.12)',
  },
  rootHeader: {
    display: 'flex',
    padding: '2rem 0rem 6rem 0rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2.5rem',
    alignSelf: 'stretch',
    position: 'relative',
    overflow: 'clip',
    borderRadius: '1rem',
    background: '#FFFAEE',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    justifyContent: 'center',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    zIndex: 5,
    boxShadow: '#FFFAEE 0px 0px 2.5rem 5.25rem',
    backgroundColor: '#FFFAEE',
    textAlign: 'center',
    padding: '0rem 1rem',
    margin: 'auto',
    width: 'max-content',
    minWidth: '17rem',
    alignItems: 'center',
    borderRadius: '1rem',
    [createTheme().breakpoints.down('md')]: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  sphere: {
    width: '27.25rem',
    height: '26.0625rem',
    position: 'absolute',
    left: '-8rem',
    top: '-4.3125rem',
    borderRadius: '27.25rem',
    opacity: 0.3,
    filter: 'blur(100px)',
    background: 'linear-gradient(135deg, #FAAF0C 0%, #FFDC60 100%)',
  },
  blurryZones: {
    width: '8.3125rem',
    height: '10.5rem',
    flexShrink: 0,
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFFAEE 69.92%)',
  },
  logoLists: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    position: 'absolute',
  },
  logos: {
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'row',
    alignItems: 'flex-start',
    zIndex: 3,
  },
  logo: {
    display: 'flex',
    width: '5rem',
    height: '5rem',
    borderRadius: '5rem',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    backgroundColor: neutralColors.neutralWhite,
  },
  themes: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1',
    gap: '1rem',
  },
  themesCard: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-2rem',
    gap: '2.5rem',
  },
  snackInfo: {
    display: 'flex',
    padding: '0.25rem 0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    borderRadius: '0.5rem',
    border: '1px solid #FAAF0C',
    background: '#FFF',
    boxShadow: '-2px 2px 0px 0px #FAAF0C',
    width: 'fit-content',
  },
  pointPriceRatio: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  rewardIntroduction: {
    zIndex: 5,
    width: '60%',
    textAlign: 'center',
    margin: 'auto',
    gap: '0.5rem',
  },
  rewardSubIntroduction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    justifyContent: 'center',
  },
  rewardSubIntroductionIcon: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '0.5rem',
  },
};

const MainRewardConvertCard = ({
  reward,
  ComponentDecoration,
  cardDetails,
  convert,
  setOpenHelperAbout,
  setDisableConvert,
  ...props
}) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('md'));

  const { simulationRewardAllocation, defaultPoint, disableConvert } =
    UseRewardConvertCard(reward);
  const { linkObject } = UseLinkFileToUserType({
    moraleObj: HomeConvertPointPM,
    physicObj: HomeConvertPointPP,
  });

  const [isOpenThemeDetails, setIsOpenThemeDetails] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(null);

  const openThemeDetails = theme => {
    setCurrentTheme(theme);
    setIsOpenThemeDetails(true);
  };

  const openHelp = () => {
    // setOpenHelperAbout(true)
  };

  const LogoList = ({ logoList, styleOvv }) => {
    return (
      <div className="{props.classes.logos}" style="{styleOvv}">
        {logoList.map((logo, index) => {
          return (
            <div key="{index}" className="{props.classes.logo}">
              {logo && logo.length > 0 && (
                <img 2="" src="{logo}" alt="logo" style="{{" zIndex:="" }}="">
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const HeadContent = () => {
    return (
      <div className="{props.classes.headerContent}">
        <div className="{props.classes.snackInfo}">
          <typography variant="{'body1'}" style="{{" fontWeight:="" 'bold'="" }}="">
            {intl.formatMessage({
              id: 'spider.convertPoints.home.rewardsMain.designChipLabel',
            })}
          </typography>
        </div>

        <div className="{props.classes.pointPriceRatio}">
          <typography variant="{isMobile" ?="" 'h3'="" :="" 'h2'}="" component="{isMobile" style="{{" zIndex:="" 4,="" width:="" '15rem',="" margin:="" 'auto'="" }}="">
            {intl.formatMessage({
              id: 'spider.convertPoints.home.ksc.title',
            })}
          </typography>

          {simulationRewardAllocation.loading && <loader centered=""></loader>}
          {simulationRewardAllocation.success &&
            !simulationRewardAllocation.loading && (
              <pointpriceratio points="{simulationRewardAllocation.simulation.max_points_usable}" showInfo="{false}" pointsPrice="{" simulationRewardAllocation.simulation.order_suggested="" .monetary_value="" }=""></pointpriceratio>
            )}

          {!simulationRewardAllocation.loading &&
            !simulationRewardAllocation.success && (
              <pointpriceratio points="{defaultPoint}" pointsPrice="{0}"></pointpriceratio>
            )}
        </div>
      </div>
    );
  };

  const ConvertButton = () => (
    <button color="{'primary'}" variant="{'contained'}" size="{isMobile" ?="" ''="" :="" 'large'}="" style="{{" zIndex:="" 2,="" margin:="" 'auto'="" }}="" onClick="{()" ==""> convert(reward)}
      disabled={disableConvert}
    >
      {intl.formatMessage({
        id: 'spider.convertPoints.home.rewardsMain.convertAction',
      })}
    </button>
  );

  const LinkButton = () => (
    <link className="{'bold'}" onClick="{openHelp}">
      {intl.formatMessage({
        id: 'spider.convertPoints.home.rewardsCard.help2',
      })}
    
  );

  return (
    <div className="{props.classes.root}">
      <div className="{props.classes.rootHeader}">
        <div className="{props.classes.header}">
          <div className="{props.classes.logoLists}">
            {!isMobile && (
              <logolist logoList="{linkObject.firstLineLogos}" styleOvv="{{}}"></logolist>
            )}
            {!isMobile && (
              <logolist logoList="{linkObject.secondLineLogos}" styleOvv="{{" marginLeft:="" '-2.75rem'="" }}=""></logolist>
            )}
          </div>
          <headcontent></headcontent>
        </div>

        {isMobile && <convertbutton></convertbutton>}

        <div className="{props.classes.rewardIntroduction}">
          <typography variant="{'body1'}">
            {intl.formatMessage({ id: 'spider.convertPoints.home.ksc.intro' })}
          </typography>

          <div className="{props.classes.rewardSubIntroduction}">
            <div className="{props.classes.rewardSubIntroductionIcon}">
              <plusindicator></plusindicator>
            </div>
            <typography variant="{'body1'}">
              {intl.formatMessage({
                id: 'spider.convertPoints.home.ksc.introSupp',
              })}
            </typography>
          </div>

          {isMobile && <linkbutton></linkbutton>}
        </div>

        <div 1="" style="{{" zIndex:="" }}="" className="{props.classes.sphere}"></div>
      </div>

      <div className="{props.classes.themesCard}">
        {linkObject && linkObject.themes && (
          <div className="{props.classes.themes}">
            {linkObject.themes.map((theme, index) => {
              return (
                <themecard key="{index}" theme="{theme}" onClick="{()" ==""> openThemeDetails(theme)}
                />
              );
            })}
          </themecard></div>
        )}

        {!isMobile && (
          <div className="{props.classes.actions}">
            <convertbutton></convertbutton>
            <linkbutton></linkbutton>
          </div>
        )}
      </div>

      {currentTheme && (
        <themecontentdialog theme="{currentTheme}" setOpen="{setIsOpenThemeDetails}" open="{isOpenThemeDetails}" onConvert="{()" ==""> convert(reward)}
          disableConvert={disableConvert}
        />
      )}
    </themecontentdialog></div>
  );
};

export default withStyles(styles)(MainRewardConvertCard);
