import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  import: null,
  success: false,
  loading: false,
  error: null,
};

const createImportSlice = name =>
  createSlice({
    name: name,
    initialState,
    reducers: {
      createStart: state => {
        state.success = false;
        state.loading = true;
        state.error = null;
      },
      createSuccess: (state, data) => {
        state.import = data.payload;
        state.success = true;
        state.loading = false;
        state.error = null;
      },
      createFailure: (state, action) => {
        state.success = false;
        state.loading = false;
        state.error = action.payload;
      },
      clearCreateImportState: () => initialState,
    },
  });

export const createImportKeySlice = createImportSlice('createImportKey');

export const {
  createStart: createImportKeyStart,
  createSuccess: createImportKeySuccess,
  createFailure: createImportKeyFailure,
  clearCreateImportState: createImportKeyClear,
} = createImportKeySlice.actions;

export default {
  createImportKey: createImportKeySlice.reducer,
};
