import React from 'react';
import { Grid, CardMedia } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DefaultText } from '../../../../../../components';

const styles = {
  icon: {
    width: 150,
    height: 150,
  },
  link: {
    cursor: 'pointer',
  },
};

const ModeSelect = ({ onChange, classes }) => {
  const global_icon = require(
    `../../../../../../assets/img/system/badge/icons/REM.svg`,
  );
  const team_icon = require(
    `../../../../../../assets/img/system/badge/icons/TCO.svg`,
  );
  return (
    <grid container="" direction="row" justify="center" spacing="{8}">
      <grid item="" onClick="{()" ==""> onChange('global')} className={classes.link}>
        <grid container="" spacing="{2}" direction="column" alignItems="center">
          <grid item="">
            <cardmedia image="{global_icon}" className="{classes.icon}"></cardmedia>
          </grid>
          <grid item="" xs="">
            <defaulttext>Global</defaulttext>
          </grid>
        </grid>
      </grid>
      <grid item="" onClick="{()" ==""> onChange('team')} className={classes.link}>
        <grid container="" spacing="{2}" direction="column" alignItems="center">
          <grid item="">
            <cardmedia image="{team_icon}" className="{classes.icon}"></cardmedia>
          </grid>
          <grid item="" xs="">
            <defaulttext>Equipe</defaulttext>
          </grid>
        </grid>
      </grid>
    </grid>
  );
};

export default withStyles(styles)(ModeSelect);
