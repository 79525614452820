import React from 'react';
import { Hidden, Tooltip, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Dialog, DialogActions, DialogContent } from '..';
import { IconButton } from './components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
  tooltip: {
    backgroundColor: '#717171',
  },
  typography: {
    fontSize: 15,
    textTransform: 'none',
  },
  dialogPaper: {
    overflow: 'visible',
  },
});

const CustomTooltip = ({
  title,
  className,
  rootClass,
  closeButtonStyle,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const defaultCloseButtonStyle = {
    position: 'absolute',
    top: -10,
    right: -10,
    zIndex: 100,
  };
  const closeButtonStyles = closeButtonStyle || defaultCloseButtonStyle;

  return (
    <div className="{rootClass" ||="" classes.root}="">
      <hidden only="xs">
        <tooltip title="{" <React.Fragment="">
              <typography className="{classes.typography}">{title}</typography>
            
          }
          classes={{ tooltip: `${classes.tooltip} ${className}` }}
        >
          {props.children}
        </tooltip>
      </hidden>
      <hidden smUp="">
        <div onClick="{onOpen}">{props.children}</div>
        <dialog open="{open}" onClose="{onClose}" classes="{{" paper:="" classes.dialogPaper="" }}="">
          <dialogcontent>{title}</dialogcontent>
          <iconbutton size="small" color="primary" onClick="{onClose}" style="{closeButtonStyles}">
            <closeicon></closeicon>
          </iconbutton>
        </dialog>
      </hidden>
    </div>
  );
};

export default CustomTooltip;
