import { useEffect } from 'react';
import { useAuth } from '../../auth';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

const UseAuthorizedUser = (authorizedRoles = []) => {
  const history = useHistory();
  const auth = useAuth();
  const intl = useIntl();

  useEffect(() => {
    if (!auth || !auth.hierarchyNodeUser || !auth.hierarchyNodeUser.role) {
      return false;
    }

    if (!authorizedRoles.includes(auth.hierarchyNodeUser.role)) {
      toast.error(
        intl.formatMessage({ id: 'spider.auth.invalid_role.errorLabel' }),
      );
      history.push('/nodes');
    }
  }, [authorizedRoles, auth]);
};

export default UseAuthorizedUser;
