import updateRewardAllocationsReducers from './updateRewardAllocations/slices';
import updateRewardAllocationsImageReducers from './updateRewardAllocationsImage/slices';
import deleteRewardAllocationsImageReducers from './deleteRewardAllocationsImage/slices';
import simulateRewardAllocationReducers from './simulateRewardAllocation/slices';

export default {
  ...updateRewardAllocationsReducers,
  ...updateRewardAllocationsImageReducers,
  ...deleteRewardAllocationsImageReducers,
  ...simulateRewardAllocationReducers,
};
