import { extendedColors, neutralColors, systemColors } from '../colors';

const ChipStyles = {
  MuiChip: {
    root: {
      fontWeight: 700,
      '&.active': {
        backgroundColor: extendedColors.greenBg,
        color: extendedColors.greenDark,
      },
      '&.error': {
        background: systemColors.errorBg,
        color: systemColors.errorRegular,
      },
      '&.inactive': {
        backgroundColor: neutralColors.neutral100,
        color: neutralColors.neutral500,
      },
      '&.success': {
        background: systemColors.successBg,
        color: systemColors.successRegular,
      },
      '&.imported': {
        background: systemColors.successBg,
        color: systemColors.successRegular,
      },
      '&.partial': {
        background: systemColors.warningBg,
        color: systemColors.warningRegular,
      },
    },
  },
};

export default ChipStyles;
