import instance from '../instance';

const baseUrl = 'toucan/token/';
const refreshUrl = 'toucan/token/refresh/';

const toucanToken = {
  get(userId, goalDefinitionId) {
    return instance.get(baseUrl, {
      params: { user_id: userId, goal_definition_id: goalDefinitionId },
    });
  },
  refresh(refreshToken) {
    return instance.post(refreshUrl, { refreshToken });
  },
};

export default toucanToken;
