import React from 'react';
import { Grid, Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DefaultText, Avatar, DefaultTitle, InfoText } from '../../..';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

const styles = {
  root: {},
  avatar: {
    height: 40,
    width: 40,
    marginTop: 3,
  },
  borderRadius: {
    borderRadius: 20,
    padding: 15,
  },
  thumbnail: {
    cursor: 'pointer',
    transition: 'transform 200ms ease-in',
    '&:hover': {
      transform: 'scale(1.02)',
      border: '1px solid #00E58D',
    },
  },
  cardContent: {
    margin: 16,
  },
};

const CollaboratorSimple = ({ collaborator, displayTeam, ...props }) => {
  const { classes, intl } = props;
  const photo = collaborator.photo
    ? collaborator.photo
    : '/assets/img/user/avatar.svg';

  return (
    <div>
      <grid container="" spacing="{2}" style="{{" textAlign:="" 'left'="" }}="">
        <grid item="">
          <avatar src="{photo}" className="{classes.avatar}" entityId="{collaborator.id}" fallbackName="{collaborator.fullname}"></avatar>
        </grid>
        <grid item="" xs="" zeroMinWidth="">
          <div>
            <grid container="">
              <grid item="" xs="{12}" zeroMinWidth="">
                <defaulttitle noWrap="" style="{{" fontWeight:="" 'bold',="" textTransform:="" 'none',="" fontSize:="" 16,="" }}="">
                  {collaborator.firstname} {collaborator.lastname}
                </defaulttitle>
              </grid>
              {_.get(collaborator, 'level') && (
                <grid item="" xs="{12}" zeroMinWidth="">
                  <infotext 14="" style="{{" textTransform:="" 'none',="" fontSize:="" }}="" noWrap="">
                    {intl.formatMessage({ id: 'common.level' })}{' '}
                    {_.get(collaborator, 'level.number')}
                  </infotext>
                </grid>
              )}
              {displayTeam && (
                <grid item="" xs="{12}" zeroMinWidth="">
                  <infotext 14="" style="{{" textTransform:="" 'none',="" fontSize:="" }}="" noWrap="">
                    {_.get(collaborator, 'team.name')}
                  </infotext>
                </grid>
              )}
            </grid>
          </div>
        </grid>
      </grid>
    </div>
  );
};

export default withStyles(styles)(injectIntl(CollaboratorSimple));
