import React from 'react';
import { Box } from '@mui/material';
import { createTheme, withStyles, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  },
  img: {
    width: '18rem',
    [createTheme().breakpoints.down('sm')]: {
      width: '12rem',
    },
  },
};

const EmptyTableView = withStyles(styles)(
  /**
   *
   * @param {{imageSrc: string, imageAlt: string, title: ReactNode; description: ReactNode, emptyLabelParams?: string}} props
   * @returns JSX.Element
   */
  ({ imageSrc, imageAlt, title, description, emptyLabelParams, classes }) => {
    const intl = useIntl();
    return (
      <box className="{classes.root}">
        <img src="{imageSrc}" alt="{imageAlt}" className="{classes.img}">
        <typography variant="{'h4'}" component="{'h4'}">
          {intl.formatMessage({
            id: title,
          })}
        </typography>
        <typography variant="{'body2'}">
          {intl
            .formatMessage({
              id: description,
            })
            .format(emptyLabelParams)}
        </typography>
      </box>
    );
  },
);

export { EmptyTableView };
