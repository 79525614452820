import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  AccentText,
  BoldSpan,
  Button,
  Card,
  DefaultText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ErrorText,
  ProgressButton,
  RedButton,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import { useIntl } from 'react-intl';
import '../../../../helpers/StringHelper';

const styles = {
  divider: {
    marginLeft: -16,
    marginRight: -16,
  },
};

const RewardOrderSummary = ({
  recipientPoints,
  onOrderClick,
  onRefuseClick,
  onValidateClick,
  orderId,
  orderLoading,
  orderPoints,
  orderValue,
  updateLoading,
  ...props
}) => {
  const intl = useIntl();
  const { classes } = props;
  const remainingPoints =
    recipientPoints !== undefined && recipientPoints !== null
      ? recipientPoints - orderPoints
      : null;
  const [orderOpen, setOrderOpen] = React.useState(false);
  const [validateOpen, setValidateOpen] = React.useState(false);
  const [refuseOpen, setRefuseOpen] = React.useState(false);

  function changeOrderOpen(open) {
    setOrderOpen(open);
  }

  function changeRefuseOpen(open) {
    setRefuseOpen(open);
  }

  function changeValidateOpen(open) {
    setValidateOpen(open);
  }

  return (
    <div>
      <card>
        <grid container="" spacing="{2}">
          <grid item="" xs="{12}">
            <grid container="" spacing="{1}">
              {recipientPoints !== undefined && (
                <grid item="" xs="{12}">
                  <defaulttext>
                    {intl.formatMessage({
                      id: 'reward.order_summary.recipient_points_label',
                    })}{' '}
                    :{' '}
                    <boldspan>
                      {intl
                        .formatMessage({
                          id: 'reward.order_summary.recipient_points_value',
                        })
                        .format(recipientPoints || 0)}
                    </boldspan>
                  </defaulttext>
                </grid>
              )}
              <grid item="" xs="{12}">
                <divider className="{classes.divider}"></divider>
              </grid>
              <grid item="" xs="{12}">
                <defaulttext>
                  {onOrderClick
                    ? intl.formatMessage({
                        id: 'reward.order_summary.cart_points_label',
                      })
                    : intl.formatMessage({
                        id: 'reward.order_summary.order_points_label',
                      })}{' '}
                  :{' '}
                  <boldspan>
                    {intl
                      .formatMessage({
                        id: 'reward.order_summary.order_points_value',
                      })
                      .format(orderPoints)}
                  </boldspan>
                </defaulttext>
              </grid>
              <grid item="" xs="{12}">
                <divider className="{classes.divider}"></divider>
              </grid>
              <grid item="" xs="{12}">
                <defaulttext>
                  {intl.formatMessage({
                    id: 'reward.order_summary.order_value_label',
                  })}{' '}
                  : <boldspan>{'{0} €'.format(orderValue)}</boldspan>
                </defaulttext>
              </grid>
            </grid>
          </grid>
          {remainingPoints !== null && (
            <grid item="" xs="{12}">
              {remainingPoints >= 0 && (
                <accenttext>
                  {intl.formatMessage({
                    id: 'reward.order_summary.remaining_points_label',
                  })}{' '}
                  :{' '}
                  <boldspan>
                    {intl
                      .formatMessage({
                        id: 'reward.order_summary.remaining_points_value',
                      })
                      .format(remainingPoints)}
                  </boldspan>
                </accenttext>
              )}
              {remainingPoints < 0 && (
                <errortext>
                  {intl.formatMessage({
                    id: 'reward.order_summary.remaining_points_label',
                  })}{' '}
                  :{' '}
                  <boldspan>
                    {intl
                      .formatMessage({
                        id: 'reward.order_summary.remaining_points_value',
                      })
                      .format(remainingPoints)}
                  </boldspan>
                </errortext>
              )}
            </grid>
          )}
          {onValidateClick && onRefuseClick && (
            <grid item="" xs="{12}">
              <grid container="" spacing="{2}">
                <grid item="">
                  <button onClick="{()" ==""> changeValidateOpen(true)}>
                    {intl.formatMessage({ id: 'common.submit' })}
                  </button>
                </grid>
                <grid item="">
                  <redbutton onClick="{()" ==""> changeRefuseOpen(true)}>
                    {intl.formatMessage({
                      id: 'reward.order_summary.refuse_button',
                    })}
                  </redbutton>
                </grid>
              </grid>
            </grid>
          )}
          {onOrderClick && (
            <grid item="" xs="{12}">
              <button disabled="{remainingPoints" <="" 0}="" onClick="{()" ==""> changeOrderOpen(true)}
              >
                {intl.formatMessage({
                  id: 'reward.order_summary.order_button',
                })}
              </button>
            </grid>
          )}
        </grid>
      </card>
      {onOrderClick && (
        <dialog open="{orderOpen}" onClose="{()" ==""> changeOrderOpen(false)}>
          <dialogtitle>
            {intl
              .formatMessage({ id: 'reward.order_summary.confirm_order_title' })
              .format(orderPoints, orderValue)}
          </dialogtitle>
          <dialogcontent>
            {intl.formatMessage({
              id: 'reward.order_summary.confirm_order_message',
            })}
          </dialogcontent>
          <dialogactions>
            <button onClick="{()" ==""> changeOrderOpen(false)} color='secondary'>
              {intl.formatMessage({ id: 'common.no' })}
            </button>
            <progressbutton type="button" text="{intl.formatMessage({" id:="" 'common.yes'="" })}="" loading="{orderLoading}" onClick="{onOrderClick}"></progressbutton>
          </dialogactions>
        </dialog>
      )}
      {onRefuseClick && (
        <dialog open="{refuseOpen}" onClose="{()" ==""> changeRefuseOpen(false)}>
          <dialogtitle>
            {intl
              .formatMessage({
                id: 'reward.order_summary.confirm_refuse_title',
              })
              .format(orderId, orderPoints, orderValue)}
          </dialogtitle>
          <dialogcontent>
            {intl.formatMessage({
              id: 'reward.order_summary.confirm_refuse_message',
            })}
          </dialogcontent>
          <dialogactions>
            <button onClick="{()" ==""> changeRefuseOpen(false)} color='secondary'>
              {intl.formatMessage({ id: 'common.no' })}
            </button>
            <progressbutton type="button" text="{intl.formatMessage({" id:="" 'common.yes'="" })}="" loading="{updateLoading}" onClick="{onRefuseClick}"></progressbutton>
          </dialogactions>
        </dialog>
      )}
      {onValidateClick && (
        <dialog open="{validateOpen}" onClose="{()" ==""> changeValidateOpen(false)}>
          <dialogtitle>
            {intl
              .formatMessage({
                id: 'reward.order_summary.confirm_validate_title',
              })
              .format(orderId, orderPoints, orderValue)}
          </dialogtitle>
          <dialogcontent>
            {intl.formatMessage({
              id: 'reward.order_summary.confirm_validate_message',
            })}
          </dialogcontent>
          <dialogactions>
            <button onClick="{()" ==""> changeValidateOpen(false)} color='secondary'>
              {intl.formatMessage({ id: 'common.no' })}
            </button>
            <progressbutton type="button" text="{intl.formatMessage({" id:="" 'common.yes'="" })}="" loading="{updateLoading}" onClick="{onValidateClick}"></progressbutton>
          </dialogactions>
        </dialog>
      )}
    </div>
  );
};

export default withStyles(styles)(RewardOrderSummary);
