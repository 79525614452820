import React from 'react';
import { createTheme, Typography, useMediaQuery } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import MaintenancePageImage from '../../../assets/img/illustration-maintenance.png';
import { neutralColors } from '../../../themes';
import { Button } from '../../../components';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    flexDirection: 'row',
    [createTheme().breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      gap: '2rem',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2.5rem',
    [createTheme().breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  title: {
    [createTheme().breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    textAlign: 'unset',
    [createTheme().breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [createTheme().breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  image: {
    width: '24rem',
    [createTheme().breakpoints.down('sm')]: {
      width: '16rem',
    },
  },
};

/**
 * Renders the MaintenancePage component.
 *
 * @param {{ isPartialMaintenance: boolean, classes: Object, ...props: Object }}
 * @return {JSX.Element} The rendered MaintenancePage component.
 */
const MaintenancePage = ({ isPartialMaintenance, classes }) => {
  const intl = useIntl();
  const history = useHistory();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('sm'));

  const goBackHome = () => {
    history.push('/nodes');
  };

  return (
    <div className="{classes.root}">
      <div className="{classes.content}">
        <div className="{classes.description}">
          <typography variant="{'h1'}" component="{'h1'}" className="{`${isMobile" ?="" 'underline-center'="" :="" 'underline-left'}="" ${classes.title}`}="">
            {intl.formatMessage({
              id: 'spider.errorPages.maintenancePage.title',
            })}
          </typography>
          <p className="{classes.subtitle}">
            <typography component="span" variant="{'body2'}" style="{{" color:="" neutralColors.neutral600="" }}="">
              {intl.formatMessage({
                id: `spider.errorPages.maintenancePage.${isPartialMaintenance ? 'partial' : 'global'}.description1`,
              })}
            </typography>
            <typography component="span" variant="{'body2'}" style="{{" color:="" neutralColors.neutral600="" }}="">
              {intl.formatMessage({
                id: `spider.errorPages.maintenancePage.${isPartialMaintenance ? 'partial' : 'global'}.description2`,
              })}
            </typography>
          </p>
          {isPartialMaintenance && (
            <button variant="contained" color="primary" onClick="{goBackHome}">
              {intl.formatMessage({
                id: 'spider.errorPages.unknownPage.backButtonLabel',
              })}
            </button>
          )}
        </div>
      </div>
      <img className="{classes.image}" src="{MaintenancePageImage}" alt="{'Maintenance" image'}="">
    </div>
  );
};

const Page = withStyles(styles)(MaintenancePage);
export { Page as MaintenancePage };
