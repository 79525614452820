import React, { useMemo } from 'react';
import { Box, Tabs, Typography } from '@material-ui/core';
import { Tab } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { AdministrationUrl } from '../../enums/administrationUrl';
import { TitleContainer } from '../../components/TitleContainer';
import { RegulationsTab } from '@src/Spider/scenes/Admin/components/RegulationsTab';
import { PersonalizationTab } from '@src/Spider/scenes/Admin/components/PersonalizationTab';
import { ParametersTabs } from '@src/Spider/scenes/Admin/components/ParametersTab';
import { useRouteMatch } from 'react-router';

const styles = {
  adminBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  adminHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
};

const Admin = ({ ...props }) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const tabPaths = Object.values(AdministrationUrl);
  const tabs = useMemo(
    () =>
      tabPaths.map((el, i) => ({
        label: intl.formatMessage({
          id: `spider.hierarchy_node.platform_customization.adminTabLabel.${el}`,
        }),
        index: i,
        url: el,
      })),
    [intl, tabPaths],
  );

  const currentTab = location.pathname.split('/').pop();
  const currentTabIndex = tabPaths.indexOf(currentTab);
  const value = String(currentTabIndex !== -1 ? currentTabIndex : 0);

  const handleChange = (_, newValue) => {
    history.push(`${match.url}/${tabPaths[Number(newValue)]}`);
  };

  if (!tabPaths.includes(currentTab))
    return <redirect to="{`${match.url}/${AdministrationUrl.REGULATIONS}`}"></redirect>;

  return (
    <div className="{props.classes.adminBody}">
      <titlecontainer>
        <div className="{props.classes.adminHeader}">
          <typography variant="{'h1'}" component="{'h1'}" className="{'underline-left'}">
            {intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.title',
            })}
          </typography>
        </div>
      </titlecontainer>
      <box style="{{" backgroundColor:="" 'white',="" borderRadius:="" '1.5rem',="" marginTop:="" '2rem',="" }}="">
        <tabcontext value="{value}">
          <box sx="{{" borderBottom:="" 1,="" borderColor:="" 'divider'="" }}="">
            <tabs onChange="{handleChange}" value="{value}">
              {tabs.map((tab, index) => (
                <tab key="{index}" label="{tab.label}" value="{tab.index.toString()}" disableFocusRipple=""></tab>
              ))}
            </tabs>
          </box>
          <switch>
            <route path="{`${match.path}/${AdministrationUrl.REGULATIONS}`}" render="{()" ==""> (
                <tabpanel value="0">
                  <regulationstab></regulationstab>
                </tabpanel>
              )}
            />
            <route path="{`${match.path}/${AdministrationUrl.PERSONALIZATION}`}" render="{()" ==""> (
                <tabpanel value="1">
                  <personalizationtab></personalizationtab>
                </tabpanel>
              )}
            />
            <route path="{`${match.path}/${AdministrationUrl.PARAMETERS}`}" render="{()" ==""> (
                <tabpanel value="2">
                  <parameterstabs></parameterstabs>
                </tabpanel>
              )}
            />
          </route></route></route></switch>
        </tabcontext>
      </box>
    </div>
  );
};

export default withStyles(styles)(Admin);
