import React from 'react';
import { Route } from 'react-router-dom';
import { UserLayout } from './index';
import { GuestLayout } from '../GuestLayout';
import { AuthProvider } from '../../../../auth';
import useAuthorizedUser from '../../../hooks/UseAuthorizedUser';
import { MaintenanceProvider } from '../../../../auth/MaintenanceProvider';

const UserRoutes = ({
  component: Component,
  useGuestLayout = false,
  authorizedRoles = [],
  ...rest
}) => {
  useAuthorizedUser(authorizedRoles);
  const Layout = !useGuestLayout ? UserLayout : GuestLayout;
  return (
    <route {...rest}="" render="{props" ==""> (
        <authprovider>
          <maintenanceprovider>
            <layout component="{Component}" authorizedRoles="{authorizedRoles}" {...props}=""></layout>
          </maintenanceprovider>
        </authprovider>
      )}
    />
  );
};

export default UserRoutes;
</route>