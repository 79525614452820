import React from 'react';
import { useIntl } from 'react-intl';
import { Chip } from '@material-ui/core';
import { OrderStatuses } from '../enums';

/**
 * @typedef {import('@spider:src/enums/OrderStatuses').OrderStatusesType} OrderStatusesType
 * @param {{status: OrderStatusesType}} props
 */
const OrderStatus = ({ status }) => {
  const intl = useIntl();

  switch (status) {
    case OrderStatuses.CANCELLED:
      return (
        <chip label="{intl.formatMessage({" id:="" 'spider.order_status.cancelled',="" })}="" size="small" className="error"></chip>
      );
    case OrderStatuses.SAVED:
      return (
        <chip label="{intl.formatMessage({" id:="" 'spider.order_status.saved',="" })}="" size="small" color="warning"></chip>
      );
    case OrderStatuses.TREATED:
      return (
        <chip label="{intl.formatMessage({" id:="" 'spider.order_status.treated',="" })}="" size="small" className="success"></chip>
      );
    case OrderStatuses.DONE:
    default:
      return (
        <chip label="{intl.formatMessage({" id:="" 'spider.order_status.done',="" })}="" size="small" className="neutral"></chip>
      );
  }
};

export default OrderStatus;
