import React, { useState, useEffect } from 'react';
import { Dialog, Slide } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const styles = {
  paper: {
    padding: 16,
    '& > div:first-child, & > form > div:first-child': {
      marginTop: 0,
      paddingTop: 0,
    },
    '& > div:nth-child(2), & > div:nth-child(3), & > form > div:nth-child(2), & > form > div:nth-child(3)':
      {
        marginTop: 16,
      },
  },
};
const DialogComponent = withStyles(styles)(Dialog);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <slide direction="up" ref="{ref}" {...props}=""></slide>;
});

const CustomDialog = ({ width, ...props }) => {
  const mobileScreen = isWidthDown('xs', width);

  return (
    <react.fragment>
      {mobileScreen && (
        <dialogcomponent 50="" {...props}="" fullScreen="" style="{{" marginTop:="" }}="" TransitionComponent="{Transition}"></dialogcomponent>
      )}
      {!mobileScreen && <dialogcomponent {...props}=""></dialogcomponent>}
    </react.fragment>
  );
};

export default withWidth()(CustomDialog);
