export const GET_TEAM_COLLABORATOR_CHALLENGE_LIST =
  'GET_TEAM_COLLABORATOR_CHALLENGE_LIST';
export const GET_TEAM_COLLABORATOR_CHALLENGE_LIST_BY_TEAM_GROUP =
  'GET_TEAM_COLLABORATOR_CHALLENGE_LIST_BY_TEAM_GROUP';
export const GET_TEAM_COLLABORATOR_CHALLENGE_LIST_SUCCESS =
  'GET_TEAM_COLLABORATOR_CHALLENGE_LIST_SUCCESS';
export const GET_TEAM_COLLABORATOR_CHALLENGE_LIST_ERROR =
  'GET_TEAM_COLLABORATOR_CHALLENGE_LIST_ERROR';
export const GET_TEAM_COLLABORATOR_CHALLENGE_LIST_CLEAR =
  'GET_TEAM_COLLABORATOR_CHALLENGE_LIST_CLEAR';
