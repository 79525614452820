import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pristinePage: true,
  sideBarReset: false,
};

const createSettingPristineSlice = name =>
  createSlice({
    name: name,
    initialState,
    reducers: {
      dirtyPristine: state => {
        state.pristinePage = false;
      },
      sideBarUpdate: state => {
        state.sideBarReset = !state.sideBarReset;
      },
      clearPristineState: () => initialState,
    },
  });

export const settingPristineKeySlice =
  createSettingPristineSlice('settingPristineKey');

export const {
  dirtyPristine: settingPristineDirtyKey,
  sideBarUpdate: settingSideBarUpdateKey,
  clearPristineState: settingPristineKeyClear,
} = settingPristineKeySlice.actions;

export default {
  settingPristineKey: settingPristineKeySlice.reducer,
};
