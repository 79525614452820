import { useDispatch, useSelector } from 'react-redux';
import {
  updateSystemFilesKeyClear,
  updateSystemFilesKeyStart,
} from '../features/systemFiles/updateSystemFiles/slices';
import { useEffect, useState } from 'react';
import {
  deleteSystemFilesKeyClear,
  deleteSystemFilesKeyStart,
} from '../features/systemFiles/deleteSystemFiles/slices';
import useHandleToast from './UseHandleToast';

const UseSystemFile = ({
  onFileUpdated,
  onFileDeleted,
  overrideDeleteSystemFileKey = 'deleteSystemFilesKey',
  overrideUpdateSystemFileKey = 'updateSystemFilesKey',
  dispatchDeleteSystemFile = deleteSystemFilesKeyStart,
  dispatchClearDeleteSystemFile = deleteSystemFilesKeyClear,
  dispatchUpdateSystemFile = updateSystemFilesKeyStart,
  dispatchClearUpdateSystemFile = updateSystemFilesKeyClear,
}) => {
  const dispatch = useDispatch();
  const systemFileDeleted = useSelector(
    state => state[overrideDeleteSystemFileKey],
  );
  const systemFilesUpdated = useSelector(
    state => state[overrideUpdateSystemFileKey],
  );
  const [listener, setListener] = useState(false);

  const clearListener = () => {
    setListener(false);
  };

  useHandleToast(
    systemFileDeleted,
    dispatch,
    dispatchClearDeleteSystemFile,
    'spider.systemFile.delete.success',
    'spider.systemFile.delete.error',
    listener,
    clearListener,
  );

  useHandleToast(
    systemFilesUpdated,
    dispatch,
    dispatchClearUpdateSystemFile,
    'spider.systemFile.update.success',
    'spider.systemFile.update.error',
    listener,
    clearListener,
  );

  const deleteSystemFile = imageData => {
    setListener(true);
    dispatch(dispatchDeleteSystemFile({ imageId: imageData.uuid }));
  };

  // manage only lone file for now
  const updateSystemFile = (fileData, code, additionalPropsUpdate) => {
    setListener(true);
    const file = new FormData();
    file.append('code', code);
    file.append('src', fileData);
    dispatch(
      dispatchUpdateSystemFile({ code, file, ...additionalPropsUpdate }),
    );
  };

  useEffect(() => {
    if (!listener) return;

    if (systemFilesUpdated.systemFileUpdated !== null) {
      onFileUpdated(systemFilesUpdated.systemFileUpdated);
    }
    if (systemFileDeleted.success) {
      onFileDeleted();
    }
  }, [systemFilesUpdated, systemFileDeleted, dispatch]);

  return { deleteSystemFile, updateSystemFile };
};

export default UseSystemFile;
