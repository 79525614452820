import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Hidden,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faEdit,
  faFolderOpen,
} from '@fortawesome/free-solid-svg-icons';
import {
  HorizontalExplanation,
  RewardDetailImage,
  SubHeader,
  VerticalExplanation,
} from './components';
import {
  ShoppingCartAddingConfirmation,
  ShoppingCartButton,
} from '../../components';
import {
  AccentText,
  BoldSpan,
  Card,
  DefaultText,
  DefaultTitle,
  IconButton,
  InfoText,
  Linkify,
  MainLayoutComponent,
  Quantity,
  RichText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ProgressButton,
  Button,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import { injectIntl } from 'react-intl';
import '../../../../helpers/StringHelper';
import * as rewardDetailActions from '../../../../services/Rewards/RewardDetail/actions';
import * as shoppingCartActions from '../../../../services/ShoppingCart/actions';
import * as collaboratorDetailActions from '../../../../services/Collaborators/CollaboratorDetail/actions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const DEFAULT_QUANTITY = 1;

const styles = theme => {
  return {
    panel: {
      backgroundColor: 'initial',
      borderRadius: 'initial',
      boxShadow: 'none',
    },
    panelSummary: {
      margin: 'none',
      padding: 'initial',
    },
    panelSummaryIcon: {
      color: theme.palette.primary.main,
    },
    panelDetails: {
      padding: 'initial',
    },
  };
};

class RewardDetail extends MainLayoutComponent {
  state = { expanded: false, quantity: DEFAULT_QUANTITY };

  constructor(props) {
    super(props);
    this.id = null;
  }

  handleAddClick = (reward, confirm) => {
    const params = new URLSearchParams(window.location.search);
    const collaboratorId = params.get('collaborator_id');

    const { collaborator } = this.props.collaboratorDetail;

    const { items } = this.props.shoppingCart;
    const removableItems = items.filter(item =>
      collaboratorId
        ? !item.collaborator || item.collaborator.id !== collaborator.id
        : item.collaborator,
    );

    if (removableItems.length > 0) {
      if (confirm) {
        removableItems.forEach(item =>
          this.props.shoppingCartActions.removeItem(item),
        );
      } else {
        this.setState({
          ...this.state,
          confirmOpen: true,
          reward: reward,
          collaborator: collaborator,
        });
        return;
      }
    }

    const item = {
      reward: reward,
      quantity: this.state.quantity,
      collaborator: collaboratorId ? collaborator : null,
    };
    this.props.shoppingCartActions.addItem(item);
    this.setState({
      ...this.state,
      confirmOpen: false,
    });
  };

  setConfirmOpen = confirmOpen => {
    this.setState({
      ...this.state,
      confirmOpen: confirmOpen,
    });
  };

  loadData() {
    const params = new URLSearchParams(window.location.search);
    const collaboratorId = params.get('collaborator_id');
    const id = this.props.match.params.id;
    if (this.id !== id) {
      const { account } = this.props.accountDetail;
      this.id = id;
      this.props.handleButtons(
        <div style="{{" display:="" 'contents'="" }}="">
          {account.role.code === 'A' && (
            <iconbutton size="small" onClick="{()" =="">
                this.props.history.push(`/rewards/duplication/${id}`)
              }
              style={{ marginRight: 8 }}
            >
              <fontawesomeicon icon="{faCopy}"></fontawesomeicon>
            </iconbutton>
          )}
          {account.role.code === 'A' && (
            <iconbutton size="small" onClick="{()" =="">
                this.props.history.push(`/rewards/modification/${id}`)
              }
            >
              <fontawesomeicon icon="{faEdit}"></fontawesomeicon>
            </iconbutton>
          )}
          {account.role.code !== 'A' && <shoppingcartbutton></shoppingcartbutton>}
        </div>,
      );
      this.props.rewardDetailActions.getReward(id);
      if (collaboratorId) {
        this.props.collaboratorDetailActions.getCollaboratorDetail(
          collaboratorId,
        );
      }
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const collaboratorId = params.get('collaborator_id');

    const { intl } = this.props;
    const { collaborator } = this.props.collaboratorDetail;

    this.props.handleTitle(intl.formatMessage({ id: 'reward.title' }));
    this.props.handleSubHeader(
      <subheader onAddClick="{this.handleAddClick.bind(this)}" disableButton="{collaboratorId" &&="" !collaborator}=""></subheader>,
    );
    this.props.activateReturn();
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const params = new URLSearchParams(window.location.search);
    const collaboratorId = params.get('collaborator_id');
    const { collaborator } = this.props.collaboratorDetail;

    this.loadData();
    if (prevProps.collaboratorDetail.collaborator !== collaborator) {
      this.props.handleSubHeader(
        <subheader onAddClick="{this.handleAddClick.bind(this)}" disableButton="{collaboratorId" &&="" !collaborator}=""></subheader>,
      );
    }
  }

  getLastReward(reward) {
    var lastReward = reward;
    while (lastReward.new) {
      lastReward = lastReward.new;
    }
    return lastReward;
  }

  handleExpansionChange(event, expanded) {
    this.setState({
      ...this.state,
      expanded: expanded,
    });
  }

  handleQuantityChange(quantity) {
    this.setState({
      ...this.state,
      quantity: quantity,
    });
  }

  render() {
    const { intl } = this.props;
    const { classes } = this.props;
    const { account } = this.props.accountDetail;
    const { reward, loading } = this.props.rewardDetail;

    if (reward && !reward.isActive && reward.new) {
      const lastReward = this.getLastReward(reward);
      this.props.history.replace(`/rewards/detail/${lastReward.id}`);
    } else if (reward && !reward.isActive && !reward.new) {
      return <redirect to="/"></redirect>;
    }

    return (
      <div>
        {!loading && reward && (
          <grid container="" spacing="{1}">
            <grid item="" xs="{12}">
              <defaulttitle isContrast="">
                {intl.formatMessage({ id: 'reward.detail.description_area' })}
              </defaulttitle>
            </grid>
            <grid item="" xs="{12}">
              <card>
                <grid container="" spacing="{2}">
                  <grid item="" xs="">
                    <grid container="" spacing="{2}">
                      <grid item="" xs="">
                        <grid container="" spacing="{2}">
                          <grid item="" xs="{12}">
                            <defaulttext lowercase="">
                              <boldspan>{reward.name}</boldspan>
                            </defaulttext>
                          </grid>
                          <grid item="">
                            <defaulttext>
                              <boldspan>
                                <fontawesomeicon icon="{faFolderOpen}"></fontawesomeicon>{' '}
                                {reward.category.name}
                              </boldspan>
                            </defaulttext>
                          </grid>
                          <grid item="">
                            <defaulttext>
                              <boldspan>
                                {intl
                                  .formatMessage({
                                    id: 'reward.detail.value_text',
                                  })
                                  .format(reward.value)}
                              </boldspan>
                            </defaulttext>
                          </grid>
                        </grid>
                      </grid>
                      {((account.role.code === 'C' &&
                        reward.type.code === 'P') ||
                        (account.role.code === 'M' &&
                          reward.type.code === 'T')) && (
                        <grid item="">
                          <grid container="" spacing="{1}" direction="column" alignItems="center">
                            <grid item="">
                              <defaulttext>
                                {intl.formatMessage({
                                  id: 'reward.order_item_list.quantity_label',
                                })}
                              </defaulttext>
                            </grid>
                            <grid item="">
                              <quantity initial="{DEFAULT_QUANTITY}" minimum="{1}" onChange="{this.handleQuantityChange.bind(this)}"></quantity>
                            </grid>
                          </grid>
                        </grid>
                      )}
                      <grid item="" xs="{12}">
                        <linkify>
                          <richtext initial="{JSON.parse(reward.description)}" readOnly="{true}" onChange="{()" ==""> {}}
                          />
                        </richtext></linkify>
                      </grid>
                      <grid item="" xs="{12}">
                        <defaulttext>
                          <boldspan>
                            {intl.formatMessage({
                              id: 'reward.form.delivery_area',
                            })}
                          </boldspan>
                        </defaulttext>
                        <defaulttext lowercase="">
                          <boldspan>
                            {intl
                              .formatMessage({
                                id: 'reward.detail.delivery_place_text',
                              })
                              .format('')}
                          </boldspan>
                          {reward.deliveryPlace}
                        </defaulttext>
                        <defaulttext lowercase="">
                          <boldspan>
                            {intl
                              .formatMessage({
                                id: 'reward.detail.delivery_mode_text',
                              })
                              .format('')}
                          </boldspan>
                          {reward.deliveryMode}
                        </defaulttext>
                        {reward.deliveryTime && reward.deliveryTime !== '' && (
                          <defaulttext lowercase="">
                            <boldspan>
                              {intl
                                .formatMessage({
                                  id: 'reward.detail.delivery_time_text',
                                })
                                .format('')}
                            </boldspan>
                            {reward.deliveryTime}
                          </defaulttext>
                        )}
                      </grid>
                    </grid>
                  </grid>
                  <grid item="" xs="{12}" md="auto">
                    <rewarddetailimage image="{reward.image}"></rewarddetailimage>
                  </grid>
                  <grid item="" xs="{12}">
                    <expansionpanel expanded="{this.state.expanded}" onChange="{this.handleExpansionChange.bind(this)}" className="{classes.panel}">
                      <expansionpanelsummary expandIcon="{" <ExpandMoreIcon="" className="{classes.panelSummaryIcon}"></expansionpanelsummary>
                        }
                        className={classes.panelSummary}
                      >
                        <accenttext>
                          <boldspan>
                            {intl.formatMessage({
                              id: 'reward.detail.operation_title',
                            })}
                          </boldspan>
                        </accenttext>
                      
                      <expansionpaneldetails className="{classes.panelDetails}">
                        <hidden smDown="">
                          <horizontalexplanation></horizontalexplanation>
                        </hidden>
                        <hidden mdUp="">
                          <verticalexplanation></verticalexplanation>
                        </hidden>
                      </expansionpaneldetails>
                    </expansionpanel>
                  </grid>
                </grid>
              </card>
            </grid>
          </grid>
        )}
        {!this.state.confirmOpen && <shoppingcartaddingconfirmation></shoppingcartaddingconfirmation>}

        <dialog open="{this.state.confirmOpen}" onClose="{()" ==""> this.setConfirmOpen(false)}
        >
          <dialogtitle>
            {intl.formatMessage({
              id: 'collaborator.reward_order.erase_cart_warning',
            })}
          </dialogtitle>
          <dialogcontent>
            {intl.formatMessage({
              id: 'collaborator.reward_order.erase_cart_warning2',
            })}
          </dialogcontent>
          <dialogactions>
            <button onClick="{()" ==""> this.setConfirmOpen(false)}
              color='secondary'
            >
              {intl.formatMessage({ id: 'common.no' })}
            </button>
            <progressbutton type="button" text="{intl.formatMessage({" id:="" 'common.yes'="" })}="" onClick="{()" ==""> this.handleAddClick(this.state.reward, true)}
            />
          </progressbutton></dialogactions>
        </dialog>
      </div>
    );
  }
}

const mapStateToProps = ({
  accountDetail,
  rewardDetail,
  shoppingCart,
  collaboratorDetail,
}) => ({
  accountDetail,
  rewardDetail,
  shoppingCart,
  collaboratorDetail,
});

const mapDispatchToProps = dispatch => ({
  rewardDetailActions: bindActionCreators(rewardDetailActions, dispatch),
  collaboratorDetailActions: bindActionCreators(
    collaboratorDetailActions,
    dispatch,
  ),
  shoppingCartActions: bindActionCreators(shoppingCartActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(injectIntl(RewardDetail)));
