import React, { useEffect, useState } from 'react';
import { ColorButton } from '@spider:src/components/ColorButton';
import { ColorButtonGroup } from '@spider:src/components/ColorButtonGroup';
import { Button } from '@spider:src/components';
import {
  updateConfigKeyClear,
  updateConfigKeyStart,
} from '@spider:src/features/config/updateConfigs/slices';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import TextField from '@spider:src/components/TextField/TextField';
import Formsy from 'formsy-react';
import useHandleToast from '@spider:src/hooks/UseHandleToast';
import useDirtyPristine from '@spider:src/hooks/UseDirtyPristine';
import { ConfigCodes } from '@spider:src/enums/configCodes';
import { updateConfigProps } from '@spider:src/features/main/slices';

const styles = {
  mainColorBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const ColorPicker = ({ refreshState, ...props }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const params = useParams();
  const { dirtyChange, resetPristinePage } = useDirtyPristine();

  const colors = [
    '#ee225cff',
    '#1e4bed',
    '#4f95e8',
    '#40a54a',
    '#edde00',
    '#fda013',
    '#fd4c00',
    '#fd1919',
    '#f987f4',
    '#763ada',
    '#1a1a1a',
  ];
  const useConfigs = useSelector(state => state.getConfigsKey);
  const updateConfigsKey = useSelector(state => state.updateConfigsKey);
  const mainKey = useSelector(state => state.mainKey);

  const configurationMainColor = useConfigs.configs.find(
    config => config.code === ConfigCodes.MAIN_COLOR,
  );

  const [mainColor, setMainColor] = useState(
    configurationMainColor.value ?? '',
  );

  useHandleToast(
    updateConfigsKey,
    dispatch,
    updateConfigKeyClear,
    'spider.hierarchy_node.platform_customization.mainColor.update.success',
    'spider.hierarchy_node.platform_customization.mainColor.update.error',
  );

  const mainColorFieldChange = e => {
    mainColorChange(e.target.value);
  };

  const mainColorChange = color => {
    dirtyChange();
    setMainColor(color);
    dispatch(updateConfigProps({ props: 'mainColor', value: color }));
  };

  const saveAppMainColor = () => {
    resetPristinePage();
    dispatch(
      updateConfigKeyStart([
        {
          id: configurationMainColor.id,
          value: mainColor,
          teamGroupId: params.contract,
        },
      ]),
    );
  };

  useEffect(() => {
    return () => {
      setMainColor(mainKey.config.mainDefaultColor.value);
      dispatch(
        updateConfigProps({
          props: 'mainColor',
          value: mainKey.config.mainDefaultColor.value,
        }),
      );
    };
  }, []);

  return (
    <formsy name="{'main-color-admin'}" onValidSubmit="{saveAppMainColor}" className="{props.classes.mainColorBlock}">
      <typography variant="{'h3'}" component="{'h3'}">
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.mainColor.title',
        })}
      </typography>
      <typography variant="{'body1'}">
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.mainColor.subtitle',
        })}
      </typography>

      <div style="{{" display:="" 'flex',="" flexDirection:="" 'row',="" gap:="" '1rem',="" marginBottom:="" alignItems:="" 'end',="" }}="">
        <textfield name="{'mainColor'}" value="{mainColor}" label="{intl.formatMessage({" id:="" 'spider.hierarchy_node.platform_customization.mainColor.form.color',="" })}="" variant="{'outlined'}" size="{'small'}" style="{{" width:="" '20rem'="" }}="" required="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" 'common.form.required_error',="" }),="" onChange="{mainColorFieldChange}"></textfield>

        <colorbutton hexadecimalColor="{mainColor}" selected="{" mainColor="" &&="" mainColor.length=""> 0 &&
            colors.find(color => color === mainColor) === undefined
          }
        />
      </colorbutton></div>

      <div>
        <colorbuttongroup initialColor="{mainColor}" colors="{colors}" onSelectedColorChange="{mainColorChange}"></colorbuttongroup>
      </div>

      <div className="{props.classes.actions}">
        <button color="{'primary'}" variant="{'contained'}" size="{'small'}" type="{'submit'}">
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.mainColor.save_method.label',
          })}
        </button>
      </div>
    </formsy>
  );
};

export default withStyles(styles)(ColorPicker);
