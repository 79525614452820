import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import createSimulationRewardAllocationStore from '../../../../../../store/simulateRewardAllocation/store';
import { PointCommandIds } from '../../../../../../enums';
import { MainRewardConvertCard } from '../MainRewardConvertCard';
import { RewardConvertCard } from '../RewardConvertCard';
import { RewardHelperAboutDialog } from '../RewardHelperAboutDialog';
import useCommandReward from '../../../../../../hooks/CommandReward/UseCommandReward';

const RewardCard = ({ reward, ...props }) => {
  const store = createSimulationRewardAllocationStore();
  const [showComponent, setShowComponent] = useState(false);
  const [disableConvert, setDisableConvert] = useState(false);
  const [isOpenRewardHelperAbout, setIsOpenRewardHelperAbout] = useState(false);
  const { ComponentDecoration, cardDetails, HelperAbout } =
    useCommandReward(reward);

  useEffect(() => {
    setTimeout(() => {
      setShowComponent(true);
    }, 100);
  }, []);

  return (
    <provider store="{store}">
      {showComponent && reward.uuid === PointCommandIds.KSC && (
        <mainrewardconvertcard reward="{reward}" setDisableConvert="{setDisableConvert}" setOpenHelperAbout="{setIsOpenRewardHelperAbout}" {...props}=""></mainrewardconvertcard>
      )}
      {showComponent && reward.uuid !== PointCommandIds.KSC && (
        <rewardconvertcard reward="{reward}" setDisableConvert="{setDisableConvert}" cardDetails="{cardDetails}" ComponentDecoration="{ComponentDecoration}" setOpenHelperAbout="{setIsOpenRewardHelperAbout}" {...props}=""></rewardconvertcard>
      )}

      {/*<rewardhelperaboutdialog title="{'test'}" setOpen="{setIsOpenRewardHelperAbout}" open="{isOpenRewardHelperAbout}*/}" {="" *="" disableConvert="{disableConvert}" onConvert="{props.openConvertPointView}" AboutComponent="{HelperAbout}"></rewardhelperaboutdialog>*/}
    </provider>
  );
};

export default RewardCard;
