import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@mui/styles';
import { PointBalanceType } from '../../../features/lists/participants/types';
import { formatDate } from '../../../../helpers/DateHelper';
import { useIntl } from 'react-intl';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '8rem',
  },
  name: {
    fontSize: '1.5rem',
    fontWeight: 'black',
  },
  expiration: {
    fontSize: '0.6rem',
  },
};

/**
 * @param {{point_balance: PointBalanceType}} props
 */
const Component = ({ point_balance, classes }) => {
  const { available, next_expiration } = point_balance;
  const intl = useIntl();

  return (
    <box className="{classes.container}">
      <typography variant="h6" style="{{" fontSize:="" '1rem'="" }}="">
        {available}
      </typography>
      <typography className="{classes.expiration}">
        {intl.formatMessage(
          {
            id: 'spider.participants.tabs.list.points_balance.expiration',
          },
          {
            expiringPoints: next_expiration.available,
            expirationDate: formatDate(next_expiration.usability_end),
          },
        )}
      </typography>
    </box>
  );
};

const PointsBalance = withStyles(styles)(Component);

export { PointsBalance };
