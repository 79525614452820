import React from 'react';
import { Grid } from '@material-ui/core';
import { Tag } from './components';
import { DefaultTitle, ErrorText, InfoText, Avatar } from '../../..';
import * as Resources from '../../../../Resources';
import '../../../../helpers/StringHelper';
import { useIntl } from 'react-intl';
import _ from 'lodash';

const Team = ({ team, ...props }) => {
  const intl = useIntl();
  const players = team.collaborators.length;
  const managerPhoto =
    team.manager && team.manager.photo
      ? team.manager.photo
      : '/assets/img/user/avatar.svg';

  return (
    <div>
      <grid container="" spacing="{2}" style="{{" textAlign:="" 'left'="" }}="">
        <grid item="" xs="{2}">
          <avatar src="{managerPhoto}" entityId="{_.get(team," 'manager.id')}="" fallbackName="{_.get(team," 'manager.fullname')}=""></avatar>
        </grid>
        <grid item="" xs="{10}" container="">
          <grid item="" xs="" zeroMinWidth="">
            <defaulttitle noWrap="">{team.name}</defaulttitle>
          </grid>
          <grid item="" style="{{" marginLeft:="" 5,="" borderRadius:="" overflow:="" 'hidden',="" height:="" 18,="" }}="">
            <tag color="{team.color.hex}">
              {intl
                .formatMessage({ id: 'team.collaborators_text' })
                .format(players)}
            </tag>
          </grid>
          <grid item="" xs="{12}" zeroMinWidth="">
            {team.manager && (
              <infotext noWrap="">
                {intl
                  .formatMessage({ id: 'team.manager_text' })
                  .format(team.manager.firstname, team.manager.lastname)}
              </infotext>
            )}
            {!team.manager && (
              <errortext noWrap="">
                {intl.formatMessage({ id: 'team.no_manager_text' })}
              </errortext>
            )}
          </grid>
        </grid>
      </grid>
    </div>
  );
};

export default Team;
