import React from 'react';
import {
  Grid,
  IconButton,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  faInfoCircle,
  faTrashAlt,
  faEquals,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import Formsy from 'formsy-react';
import {
  BlueText,
  Card,
  DefaultText,
  DefaultTitle,
  HiddenInput,
  InfoText,
  TextField,
  Tooltip,
  TableChip,
  Button,
  Select,
  IconButton as MenuIconButton,
  NavigationSwitch,
  GreenRadio,
} from '../../../../../../../../components';
import * as Resources from '../../../../../../../../Resources';
import { useIntl } from 'react-intl';
import _ from 'lodash';

const styles = {
  linkWrapper: {},
  link: {
    fontSize: 18,
    cursor: 'pointer',
    '&:hover, &.active': {
      color: 'rgb(15,111,222)',
      opacity: 1,
    },
  },
  card: {
    height: '100%',
  },
};

const Goal = ({
  categories,
  deletionDisabled,
  goal,
  challengeTypeCode,
  index,
  kpis,
  units,
  onChange,
  onRemoveClick,
  classes,
  setNewKpiOpen,
  awardType,
  ...props
}) => {
  const intl = useIntl();
  const [category, setCategory] = React.useState(goal ? goal.category : null);
  const displayKpis = category
    ? kpis.filter(x => x.category && x.category.id == category)
    : kpis;
  const [goalName, setGoalName] = React.useState(goal ? goal.goalName : null);
  const [goalDescription, setGoalDescription] = React.useState(
    goal ? goal.goalDescription : null,
  );
  const [customKpi, setCustomKpi] = React.useState();
  const [kpi, setKpi] = React.useState(goal ? goal.kpi : null);
  const kpiObject = kpi ? kpis.find(x => x.id == kpi) : null;
  const number = index + 1;

  const [kpiCollaboratorEditable, setKpiCollaboratorEditable] = React.useState(
    (kpiObject && kpiObject.collaboratorEditable) || goal.collaboratorEditable
      ? 'collaborator'
      : 'manager',
  );

  const unit = _.get(kpiObject, 'unit.name');
  const periodicity = _.get(kpiObject, 'periodicity.description');
  const format = kpiObject
    ? kpiObject.manual
      ? intl.formatMessage({ id: 'challenge.form.goals.manual' })
      : intl.formatMessage({ id: 'challenge.form.goals.automatic' })
    : '';

  function handleCategoryChange(newCategory) {
    setCategory(Number(newCategory));
    setKpi(null);
  }

  function handleKpiChange(newKpi) {
    const kpiObject = kpis.find(x => x.id == parseInt(newKpi));
    setGoalName(kpiObject.name);
    setKpi(Number(newKpi));
  }

  const raceMode = _.get(awardType, 'code') === 'C';

  const goalTooltip = raceMode
    ? intl.formatMessage({ id: 'challenge.condition.race_condition_goal_info' })
    : intl.formatMessage({ id: 'challenge.form.goal_target_info_text' });
  const goalCustom = !goal.isNew ? _.get(goal, 'kpiObject.custom') : customKpi;

  return (
    <grid key="{goal.key}" item="" xs="{12}" sm="{6}">
      <card className="{classes.card}">
        <grid container="" spacing="{2}">
          <grid item="" xs="{12}" container="">
            <grid item="" xs="">
              <grid container="" spacing="{1}" alignItems="center" justify="space-between">
                <grid item="">
                  <defaulttitle>
                    {intl
                      .formatMessage({ id: 'challenge.form.goal_title' })
                      .format(number)}
                  </defaulttitle>
                  <hiddeninput name="{`number[${index}]`}" value="{number}"></hiddeninput>
                </grid>
              </grid>
            </grid>
            {!deletionDisabled && (
              <grid item="">
                <iconbutton size="small" onClick="{onRemoveClick}">
                  <fontawesomeicon icon="{faTrashAlt}"></fontawesomeicon>
                </iconbutton>
              </grid>
            )}
          </grid>
          <grid item="" xs="{12}">
            <grid container="" alignItems="center" spacing="{1}" className="{classes.linkWrapper}">
              <grid item="" onClick="{()" ==""> {
                  setCustomKpi(false);
                }}
                className={`${classes.link} ${!goalCustom ? 'active' : ''}`}
              >
                {intl.formatMessage({ id: 'challenge.form.goals.automatic' })}
              </grid>
              <grid item="">
                <navigationswitch onChange="{event" ==""> {
                    setCustomKpi(event.target.checked);
                  }}
                  defaultChecked={goalCustom}
                  color='default'
                  checked={goalCustom}
                  disabled={!goal.isNew}
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                />
                <hiddeninput name="{`custom[${index}]`}" value="{goalCustom}"></hiddeninput>
              </navigationswitch></grid>
              <grid item="" onClick="{()" ==""> {
                  setCustomKpi(true);
                }}
                className={`${classes.link} ${goalCustom ? 'active' : ''}`}
              >
                {intl.formatMessage({ id: 'challenge.form.goals.manual' })}
              </grid>
            </grid>
          </grid>
          {goalCustom && (
            <grid item="" xs="{12}">
              <grid container="" alignItems="center" spacing="{1}" className="{classes.linkWrapper}">
                <grid item="">
                  <radiogroup row="" name="{`kpiCollaboratorEditable[${index}]`}" onChange="{e" ==""> setKpiCollaboratorEditable(e.target.value)}
                    value={kpiCollaboratorEditable}
                  >
                    <formcontrollabel value="manager" control="{<GreenRadio" disabled="{!goal.isNew}"></formcontrollabel>}
                      label={intl.formatMessage({
                        id: 'challenge.form.goal_manager_editable_label',
                      })}
                    />

                    <formcontrollabel value="collaborator" control="{<GreenRadio" disabled="{!goal.isNew}"></formcontrollabel>}
                      label={intl.formatMessage({
                        id: 'challenge.form.goal_collaborator_editable_label',
                      })}
                    />
                  </radiogroup>

                  <hiddeninput name="{`kpiCollaboratorEditable[${index}]`}" value="{kpiCollaboratorEditable}"></hiddeninput>
                </grid>
              </grid>
            </grid>
          )}
          {!goalCustom && (
            <react.fragment>
              <grid item="" xs="{12}">
                <select emptyText="{intl.formatMessage({" id:="" 'filter.category_all_option',="" })}="" fullWidth="" initial="{category}" label="{intl.formatMessage({" 'challenge.form.goal_category_label',="" name="category" options="{categories}" optionTextName="name" optionValueName="id" onChange="{handleCategoryChange}"></select>
              </grid>

              <grid item="" xs="{12}">
                <select fullWidth="" initial="{kpi}" label="{intl.formatMessage({" id:="" 'challenge.form.goal_kpi_label',="" })}="" name="{`kpi[${index}]`}" options="{displayKpis}" optionTextName="name" optionValueName="id" required="" onChange="{handleKpiChange}"></select>
              </grid>
              <grid item="" xs="{12}">
                <textfield lowercase="" name="{`goalName[${index}]`}" label="{intl.formatMessage({" id:="" 'challenge.form.goal_name_label',="" })}="" fullWidth="" required="" initial="{_.get(goal," 'name',="" goalName)}="" validations="{{" hasLessCharactersThan:="" 128,="" }}="" validationErrors="{{" intl="" .formatMessage({="" 'common.form.has_less_characters_than',="" })="" .format(128),=""></textfield>
              </grid>
              <grid item="" xs="{12}">
                <textfield name="{`goalDescription[${index}]`}" label="{intl.formatMessage({" id:="" 'challenge.form.goal_description_label',="" })}="" fullWidth="" initial="{_.get(goal," 'description',="" goalDescription)}="" lowercase=""></textfield>
              </grid>
              <grid item="" xs="{12}">
                <grid container="">
                  <grid item="" xs="">
                    <defaulttext>
                      {intl.formatMessage({
                        id: 'challenge.form.goal_unit_label',
                      })}
                    </defaulttext>
                    <infotext lowercase="">{unit}</infotext>
                  </grid>
                  <grid item="" xs="">
                    <defaulttext>
                      {intl.formatMessage({
                        id: 'challenge.form.goal_periodicity_label',
                      })}
                    </defaulttext>
                    <infotext lowercase="">{periodicity}</infotext>
                  </grid>
                  <grid item="" xs="">
                    <defaulttext>
                      {intl.formatMessage({
                        id: 'challenge.form.goal_format_label',
                      })}
                    </defaulttext>
                    <infotext lowercase="">{format}</infotext>
                  </grid>
                </grid>
              </grid>
            </react.fragment>
          )}
          {goalCustom && (
            <react.fragment>
              <grid item="" xs="{12}">
                <textfield lowercase="" name="{`goalName[${index}]`}" label="{intl.formatMessage({" id:="" 'challenge.form.goal_name_label',="" })}="" fullWidth="" required="" initial="{_.get(goal," 'name',="" goalName)}="" validations="{{" hasLessCharactersThan:="" 128,="" }}="" validationErrors="{{" intl="" .formatMessage({="" 'common.form.has_less_characters_than',="" })="" .format(128),=""></textfield>
              </grid>
              <grid item="" xs="{12}">
                <textfield name="{`goalDescription[${index}]`}" label="{intl.formatMessage({" id:="" 'challenge.form.goal_description_label',="" })}="" fullWidth="" initial="{_.get(goal," 'description',="" goalDescription)}="" lowercase=""></textfield>
              </grid>
              <grid item="" xs="{12}">
                <select fullWidth="" initial="{_.get(goal," 'kpiObject.unit.id')}="" name="{`kpiUnit[${index}]`}" label="{intl.formatMessage({" id:="" 'challenge.form.goal_unit_label',="" })}="" options="{units}" optionTextName="name" optionValueName="id" required=""></select>
              </grid>

              <hiddeninput name="{`kpi[${index}]`}" value="{goal.kpi}"></hiddeninput>
            </react.fragment>
          )}
          <grid item="" xs="">
            <grid container="" spacing="{1}" alignItems="center">
              <grid item="">
                <defaulttext 16="" style="{{" fontSize:="" }}="">👉</defaulttext>
              </grid>
              <grid item="" xs="">
                <textfield name="{`target[${index}]`}" label="{" raceMode="" ?="" intl.formatMessage({="" id:="" 'challenge.form.goal_target_label2_race',="" })="" :="" 'challenge.form.goal_target_label2',="" }="" fullWidth="" required="" initial="{goal" goal.target="" null}="" validations="{{" isNumeric:="" true,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" 'common.form.required_error',="" }),="" 'common.form.numeric_error',=""></textfield>
              </grid>
              <grid item="">
                <grid container="" direction="column">
                  <grid item="">
                    <tooltip title="{goalTooltip}">
                      <bluetext 20="" style="{{" marginTop:="" }}="">
                        <fontawesomeicon icon="{faInfoCircle}"></fontawesomeicon>
                      </bluetext>
                    </tooltip>
                  </grid>
                  {!raceMode && (
                    <grid item="">
                      <defaulttext>
                        <fontawesomeicon icon="{faEquals}"></fontawesomeicon>
                      </defaulttext>
                    </grid>
                  )}
                </grid>
              </grid>
              {raceMode && (
                <grid item="" xs="">
                  <hiddeninput name="{`points[${index}]`}" value="{1}"></hiddeninput>
                </grid>
              )}
              {!raceMode && (
                <grid item="" xs="">
                  <textfield name="{`points[${index}]`}" label="{Resources.CHALLENGE_CREATION_GOAL_POINTS_LABEL2}" fullWidth="" required="" initial="{goal" ?="" goal.points="" :="" null}="" validations="{{" isNumeric:="" true,="" }}="" validationErrors="{{" isDefaultRequiredValue:="" intl.formatMessage({="" id:="" 'common.form.required_error',="" }),="" 'common.form.numeric_error',=""></textfield>
                </grid>
              )}
            </grid>
          </grid>
        </grid>
      </card>
    </grid>
  );
};

export default withStyles(styles)(Goal);
