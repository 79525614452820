import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PointSummary, Reward } from '..';
import {
  BasicSelect,
  Card,
  DefaultTitle,
  EmptyState,
  Loader,
} from '../../../../components';
import * as Resources from '../../../../Resources';
import { useIntl } from 'react-intl';
import _ from 'lodash';

const useStyles = makeStyles({
  zoom: {
    transition: 'transform 200ms',
    borderRadius: 20,
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },
});

const RewardStore = ({
  onAddClick,
  rewards,
  summary,
  collaborator,
  displayPanopliButton,
  loading,
  ...props
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const sortOptions = [
    { value: 1, text: intl.formatMessage({ id: 'reward.sort_options.asc' }) },
    { value: 2, text: intl.formatMessage({ id: 'reward.sort_options.desc' }) },
  ];

  const panopliRewards = rewards.filter(
    reward => _.get(reward, 'category.typeCode') === 'P',
  );

  const [sort, setSort] = React.useState(sortOptions[0].value);
  const sortedRewards =
    sort === 1
      ? rewards.sort(function (a, b) {
          const x = a.points;
          const y = b.points;
          return x < y ? -1 : x > y ? 1 : 0;
        })
      : rewards.sort(function (a, b) {
          const x = a.points;
          const y = b.points;
          return x < y ? 1 : x > y ? -1 : 0;
        });

  function handleSortChange(newSort) {
    setSort(Number(newSort));
  }
  return (
    <div>
      <grid container="" spacing="{4}">
        <grid item="" xs="{12}">
          {loading && <loader centered=""></loader>}
          {!loading && summary && (
            <pointsummary points="{summary.points}" usedPoints="{summary.usedPoints}" validatedValues="{summary.validatedValues}" waitingPoints="{summary.waitingPoints}" displayPanopliButton="{" &&="" panopliRewards.length=""> 0
              }
            />
          )}
        </pointsummary></grid>
        <grid item="" xs="{12}">
          <grid container="" spacing="{2}">
            <grid item="" xs="{12}">
              <grid container="" justify="space-between" alignItems="flex-end" spacing="{1}">
                <grid item="">
                  <defaulttitle isContrast="">
                    {intl.formatMessage({ id: 'reward.title' })}
                  </defaulttitle>
                </grid>
                <grid item="">
                  <basicselect name="hello" label="{intl.formatMessage({" id:="" 'reward.sort_by'="" })}="" initial="{sort}" options="{sortOptions}" optionValueName="value" optionTextName="text" emptyDisabled="" onChange="{handleSortChange}"></basicselect>
                </grid>
              </grid>
            </grid>
            <grid item="" xs="{12}">
              {rewards.length === 0 && (
                <emptystate title="{intl.formatMessage({" id:="" 'reward.store.empty_state_title',="" })}="" message="{intl.formatMessage({" 'reward.store.empty_state_message',=""></emptystate>
              )}
              {rewards.length > 0 && (
                <grid container="" spacing="{3}">
                  {sortedRewards.map(reward => {
                    return (
                      <grid key="{reward.id}" item="" xs="{12}" sm="{6}" md="{4}">
                        <card className="{classes.zoom}">
                          <reward reward="{reward}" onAddClick="{onAddClick}" collaborator="{collaborator}"></reward>
                        </card>
                      </grid>
                    );
                  })}
                </grid>
              )}
            </grid>
          </grid>
        </grid>
      </grid>
    </div>
  );
};

export default withRouter(RewardStore);
