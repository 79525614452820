import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Goal, GoalJti } from '../../../../components';
import { Loader, RoundedTab, RoundedTabs } from '../../../../../../components';
import * as Resources from '../../../../../../Resources';
import { useIntl } from 'react-intl';

const styles = {
  root: {
    padding: 16,
  },
};

const SubHeader = ({ activateRank, onChange, ...props }) => {
  const intl = useIntl();
  const { classes } = props;
  const { account } = props.accountDetail;
  const isJti = account.isJtiEnv;
  const { goal, loading: collaboratorGoalDetailLoading } =
    props.collaboratorGoalDetail;
  const { loading: collaboratorGoalRankListLoading } =
    props.collaboratorGoalRankList;
  const loading =
    collaboratorGoalDetailLoading || collaboratorGoalRankListLoading;
  const [value, setValue] = React.useState(0);

  const handleChange = (e, value) => {
    setValue(value);
    if (onChange) onChange(value);
  };

  const renderLoader = () => {
    return <loader centered=""></loader>;
  };

  const renderData = () => {
    if (isJti) {
      return <goaljti goal="{goal}"></goaljti>;
    }
    return <goal goal="{goal}"></goal>;
  };

  const editable =
    goal && // Admin and manager on solo goals
    ((goal.editable && account.role.code !== 'C') ||
      // Admin on team goals
      (goal && account.role.code === 'A'));

  return (
    <div>
      <div className="{classes.root}">
        {loading && renderLoader()}
        {!loading && goal && renderData()}
      </div>
      <roundedtabs 0="" 1="" value="{!activateRank" &&="" ?="" :="" value}="" onChange="{handleChange}" variant="fullWidth">
        {activateRank && (
          <roundedtab value="{0}" label="{intl.formatMessage({" id:="" 'admin.goal.detail.rank_tab'="" })}=""></roundedtab>
        )}
        <roundedtab value="{1}" label="{intl.formatMessage({" id:="" 'admin.goal.detail.indication_tab'="" })}=""></roundedtab>
        {editable && (
          <roundedtab value="{2}" label="{intl.formatMessage({" id:="" 'common.edit'="" })}=""></roundedtab>
        )}
      </roundedtabs>
    </div>
  );
};

const mapStateToProps = ({
  collaboratorGoalDetail,
  collaboratorGoalRankList,
  accountDetail,
}) => ({
  accountDetail,
  collaboratorGoalDetail,
  collaboratorGoalRankList,
});

export default connect(mapStateToProps)(withStyles(styles)(SubHeader));
