import { useDispatch } from 'react-redux';
import {
  settingPristineDirtyKey,
  settingPristineKeyClear,
} from '../features/settings/preventLeavePageWithoutSave/slices';

const UseDirtyPristine = () => {
  const dispatch = useDispatch();

  const dirtyChange = () => {
    dispatch(settingPristineDirtyKey());
  };

  const resetPristinePage = () => {
    dispatch(settingPristineKeyClear());
  };

  return { dirtyChange, resetPristinePage };
};

export default UseDirtyPristine;
