import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  Card,
  DefaultTitle,
  Switch,
  TextField,
} from '../../../../../../components';
import { useIntl } from 'react-intl';

const Options = ({
  enable_manager_score,
  player_visible_ranks,
  notify_updated,
}) => {
  const intl = useIntl();

  return (
    <grid container="" spacing="{2}">
      <grid item="" xs="{12}">
        <defaulttitle isContrast="">
          {intl.formatMessage({ id: 'admin.goal.creation.options_title' })}
        </defaulttitle>
      </grid>
      <grid item="" xs="{12}">
        <card>
          <grid container="" spacing="{2}">
            <grid item="" xs="{6}">
              <switch name="enable_manager_score" label="{intl.formatMessage({" id:="" 'challenge.form.enable_manager_score_label',="" })}="" initial="{enable_manager_score}" checked="{true}" onChange="{()" ==""> {}}
              />
            </switch></grid>
            <grid item="" xs="{6}">
              <textfield type="{'number'}" name="player_visible_ranks" initial="{player_visible_ranks}" label="{intl.formatMessage({" id:="" 'admin.access_rights.general_rankings_limit',="" })}="" fullWidth=""></textfield>
            </grid>
            <grid item="" xs="{6}">
              <switch name="notify_updated" initial="{notify_updated}" label="{intl.formatMessage({" id:="" 'challenge.form.notify_updated_label',="" })}="" fullWidth="" onChange="{()" ==""> {}}
              />
            </switch></grid>
          </grid>
        </card>
      </grid>
    </grid>
  );
};

export default Options;
