import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import { simulateRewardsAllocationsKeySlice } from './slices';

function* simulateRewardAllocation(slice, endpoint, action) {
  try {
    const { rewardAllocationId, pointToConvert, userId, initializeSimulation } =
      action.payload;
    const data = yield call(
      endpoint,
      rewardAllocationId,
      pointToConvert,
      userId,
    );
    yield put(
      slice.actions.simulateSuccess({ ...data.data, initializeSimulation }),
    );
  } catch (error) {
    yield put(
      slice.actions.simulateFailure(error?.response?.data?.error ?? 'UNKNOWN'),
    );
  }
}

function* watchSimulateRewardAllocation(slice, endpoint) {
  yield takeLatest(
    slice.actions.simulateStart.type,
    simulateRewardAllocation,
    slice,
    endpoint,
  );
}

export default function* simulateRewardAllocationsSaga() {
  yield all([
    watchSimulateRewardAllocation(
      simulateRewardsAllocationsKeySlice,
      api.rewardAllocations.simulate,
    ),
  ]);
}
